import { apiAxios as authAxios } from '../axios.config';

export const getCandidatureDocuments = (candidatureExternalId) => {
  return authAxios.get(
    `/api/candidature/${candidatureExternalId}/documents/search`
  );
};

export const getCandidatureDocumentsByDocType = (
  candidatureExternalId,
  documentTypeCode
) => {
  return authAxios.get(
    `/api/candidature/${candidatureExternalId}/documents/search`,
    {
      params: { type: documentTypeCode },
    }
  );
};

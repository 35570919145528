import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { TranslateBoolean } from '../../utils/TranslateBoolean';
import { TextInputField } from '../bootstrap/TextInputField';

export function ScoreDetailsTable({
  candidature,
  candidatureScoreDetails,
  setCandidatureScoreDetails,
  defaultCandidatureScoreDetails,
  correctedFields,
  setCorrectedFields,
  errors,
  setErrors,
  hasErrors,
  show,
  handleClose,
  updateMode,
}) {
  const intl = useIntl();

  function handleValueChange(e, fieldName) {
    const isJustificationField = fieldName.includes('Justification');

    if (isJustificationField) {
      correctedFields[fieldName] = e.target.value;
      setCorrectedFields({ ...correctedFields });
    } else {
      if (e.target.value.length === 0) {
        errors[fieldName] = intl.formatMessage({
          id: 'correctScoresModal.validationSchema.required.text',
        });
      } else if (isNaN(e.target.value)) {
        errors[fieldName] = intl.formatMessage({
          id: 'correctScoresModal.validationSchema.typeError.text',
        });
      } else {
        errors[fieldName] = '';
      }

      setErrors({ ...errors });
      updateFields(e.target.value, fieldName, isJustificationField);
      setCandidatureScoreDetails({ ...candidatureScoreDetails });
      setCorrectedFields({ ...correctedFields });
    }
  }

  function updateFields(valueInputed, fieldName, isJustificationField) {
    if (!isJustificationField) {
      correctedFields[fieldName] = valueInputed;

      if (valueInputed.length !== 0 && !isNaN(valueInputed)) {
        updateAdjustedConsiderationScore(valueInputed, fieldName);
        updateAdjustedTotalScore();
      }
    }
  }

  function handleCancel() {
    setCorrectedFields({ ...DEFAULT_CORRECTED_FIELDS });
    setCandidatureScoreDetails({ ...defaultCandidatureScoreDetails });
    setErrors({ ...DEFAULT_ERRORS });
    handleClose();
  }

  function handleConfirm() {
    setCandidatureScoreDetails({
      ...candidatureScoreDetails,
      ...correctedFields,
    });
    handleClose();
  }

  function updateAdjustedConsiderationScore(valueInputed, fieldName) {
    switch (fieldName) {
      case 'adjustedCandidatureYearsPoints':
        candidatureScoreDetails[
          'adjustedCandidatureYearsNumberConsiderationPoints'
        ] =
          Math.round(
            valueInputed *
              (candidatureScoreDetails['candidatureYearsNumberConsideration'] /
                100) *
              100
          ) / 100;
        break;

      case 'adjustedIncapacityPoints':
        candidatureScoreDetails['adjustedIncapacityConsiderationPoints'] =
          Math.round(
            valueInputed *
              (candidatureScoreDetails['incapacityConsideration'] / 100) *
              100
          ) / 100;
        break;

      case 'adjustedUntitledPoints':
        candidatureScoreDetails['adjustedUntitledConsiderationPoints'] =
          Math.round(
            valueInputed *
              (candidatureScoreDetails['untitledConsideration'] / 100) *
              100
          ) / 100;
        break;

      case 'adjustedDependentsPoints':
        candidatureScoreDetails['adjustedDependentsNumberConsiderationPoints'] =
          Math.round(
            valueInputed *
              (candidatureScoreDetails['dependentsNumberConsideration'] / 100) *
              100
          ) / 100;
        break;

      case 'adjustedOver65Points':
        candidatureScoreDetails['adjustedOver65ConsiderationPoints'] =
          Math.round(
            valueInputed *
              (candidatureScoreDetails['over65Consideration'] / 100) *
              100
          ) / 100;
        break;

      case 'adjustedMotivePointsEAA':
        candidatureScoreDetails['adjustedConsiderationMotiveEAAPoints'] =
          Math.round(
            valueInputed *
              (candidatureScoreDetails['considerationMotiveEAA'] / 100) *
              100
          ) / 100;
        break;

      default:
        break;
    }
  }

  function updateAdjustedTotalScore() {
    candidatureScoreDetails['adjustedTotalPoints'] =
      Math.round(
        (candidatureScoreDetails[
          'adjustedCandidatureYearsNumberConsiderationPoints'
        ] +
          candidatureScoreDetails['adjustedIncapacityConsiderationPoints'] +
          candidatureScoreDetails['adjustedUntitledConsiderationPoints'] +
          candidatureScoreDetails[
            'adjustedDependentsNumberConsiderationPoints'
          ] +
          candidatureScoreDetails['adjustedOver65ConsiderationPoints'] +
          candidatureScoreDetails['adjustedConsiderationMotiveEAAPoints']) *
          100
      ) / 100;
  }

  return (
    <Modal className='table-modal' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='scoreDetails.text' />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='row'>
          <div className='col-md-12'>
            <table className='table'>
              <thead>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage id='scoreDetailsTable.criterions' />
                    </label>
                  </th>
                  <th>
                    <label>
                      <FormattedMessage id='scoreDetailsTable.criterionsValue' />
                    </label>
                  </th>
                  <th>
                    <label>
                      <FormattedMessage id='scoreDetailsTable.score' />
                    </label>
                  </th>
                  <th style={{ width: '180px' }}>
                    <label>
                      <FormattedMessage id='scoreDetailsTable.adjustedScore' />
                    </label>
                  </th>
                  <th className='w-25'>
                    <label>
                      <FormattedMessage id='scoreDetailsTable.justification' />
                    </label>
                  </th>
                  <th>
                    <label>
                      <FormattedMessage id='scoreDetailsTable.consideration' />
                    </label>
                  </th>
                  <th>
                    <label>
                      <FormattedMessage id='scoreDetailsTable.considerationScore' />
                    </label>
                  </th>
                  <th>
                    <label>
                      <FormattedMessage id='scoreDetailsTable.adjustedConsiderationScore' />
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                {insertRowByCriterion(
                  candidature,
                  candidatureScoreDetails,
                  correctedFields,
                  'residencyStatus',
                  handleValueChange,
                  errors,
                  updateMode
                )}
                {insertRowByCriterion(
                  candidature,
                  candidatureScoreDetails,
                  correctedFields,
                  'incapacityElements',
                  handleValueChange,
                  errors,
                  updateMode
                )}
                {insertRowByCriterion(
                  candidature,
                  candidatureScoreDetails,
                  correctedFields,
                  'untitledAggregate',
                  handleValueChange,
                  errors,
                  updateMode
                )}
                {insertRowByCriterion(
                  candidature,
                  candidatureScoreDetails,
                  correctedFields,
                  'over65Elements',
                  handleValueChange,
                  errors,
                  updateMode
                )}
                {insertRowByCriterion(
                  candidature,
                  candidatureScoreDetails,
                  correctedFields,
                  'dependentsNumber',
                  handleValueChange,
                  errors,
                  updateMode
                )}
                {insertRowByCriterion(
                  candidature,
                  candidatureScoreDetails,
                  correctedFields,
                  'candidatureYearsNumber',
                  handleValueChange,
                  errors,
                  updateMode
                )}
              </tbody>
            </table>

            <p className='text-secondary ml-2'>
              <FormattedMessage id='scoreDetailsTable.totalPoints' />
              <strong>
                {candidatureScoreDetails
                  ? candidatureScoreDetails.totalPoints
                  : candidature.candidatureDto.candidatureEAA.scoreDetails
                      ?.totalPoints}
              </strong>
              <br />
              <FormattedMessage id='scoreDetailsTable.adjustedTotalPoints' />
              <strong>
                {candidatureScoreDetails
                  ? candidatureScoreDetails.adjustedTotalPoints
                  : candidature.candidatureDto.candidatureEAA.scoreDetails
                      ?.adjustedTotalPoints}
              </strong>
            </p>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button
          className='btn btn-link mr-auto'
          type='button'
          onClick={() => (updateMode ? handleCancel() : handleClose())}
        >
          <FormattedMessage id='all.cancelText' />
        </button>
        {updateMode && (
          <button
            className='btn btn-primary'
            type='button'
            onClick={() => handleConfirm()}
            disabled={hasErrors()}
          >
            <FormattedMessage id='all.saveChanges' />
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

function insertRowByCriterion(
  candidature,
  candidatureScoreDetails,
  correctedFields,
  criterion,
  handleValueChange,
  errors,
  updateMode
) {
  const candidatureEAA = candidature.candidatureDto.candidatureEAA;
  const scoreDetails = candidatureScoreDetails
    ? candidatureScoreDetails
    : candidatureEAA.scoreDetails;

  if (scoreDetails !== null) {
    switch (criterion) {
      case 'candidatureYearsNumber':
        return (
          <tr>
            <td>
              <FormattedMessage id='scoreDetailsTable.candidatureYears' />
            </td>
            <td>{candidatureEAA.candidatureYears}</td>
            <td>{scoreDetails.candidatureYearsPoints}</td>
            <td>
              {updateMode ? (
                <TextInputField
                  name='candidatureYearsPoints'
                  value={correctedFields.adjustedCandidatureYearsPoints}
                  placeholder={scoreDetails.adjustedCandidatureYearsPoints}
                  handleChange={(e) =>
                    handleValueChange(e, 'adjustedCandidatureYearsPoints')
                  }
                  isInvalid={errors.adjustedCandidatureYearsPoints}
                  errorMessage={errors.adjustedCandidatureYearsPoints}
                />
              ) : (
                scoreDetails.adjustedCandidatureYearsPoints
              )}
            </td>
            <td>
              {updateMode ? (
                <TextInputField
                  name='candidatureYearsNumberJustification'
                  value={correctedFields.candidatureYearsNumberJustification}
                  handleChange={(e) =>
                    handleValueChange(e, 'candidatureYearsNumberJustification')
                  }
                />
              ) : (
                scoreDetails.candidatureYearsNumberJustification
              )}
            </td>
            <td>{scoreDetails.candidatureYearsNumberConsideration}%</td>
            <td>{scoreDetails.candidatureYearsNumberConsiderationPoints}</td>
            <td>
              {scoreDetails.adjustedCandidatureYearsNumberConsiderationPoints}
            </td>
          </tr>
        );

      case 'incapacityElements':
        return (
          <tr>
            <td>
              <FormattedMessage id='scoreDetailsTable.incapacityElements' />
            </td>
            <td>{candidatureEAA.incElements}</td>
            <td>{scoreDetails.incapacityPoints}</td>
            <td>
              {updateMode ? (
                <TextInputField
                  name='incapacityPoints'
                  value={correctedFields.adjustedIncapacityPoints}
                  placeholder={scoreDetails.adjustedIncapacityPoints}
                  handleChange={(e) =>
                    handleValueChange(e, 'adjustedIncapacityPoints')
                  }
                  isInvalid={errors.adjustedIncapacityPoints}
                  errorMessage={errors.adjustedIncapacityPoints}
                />
              ) : (
                scoreDetails.adjustedIncapacityPoints
              )}
            </td>
            <td>
              {updateMode ? (
                <TextInputField
                  name='candidatureYearsNumberJustification'
                  value={correctedFields.incapacityJustification}
                  handleChange={(e) =>
                    handleValueChange(e, 'incapacityJustification')
                  }
                />
              ) : (
                scoreDetails.incapacityJustification
              )}
            </td>
            <td>{scoreDetails.incapacityConsideration}%</td>
            <td>{scoreDetails.incapacityConsiderationPoints}</td>
            <td>{scoreDetails.adjustedIncapacityConsiderationPoints}</td>
          </tr>
        );

      case 'untitledAggregate':
        return (
          <tr>
            <td>
              <FormattedMessage id='scoreDetailsTable.untitledAggregate' />
            </td>
            <td>
              <TranslateBoolean value={candidatureEAA.unititledAgregate} />
            </td>
            <td>{scoreDetails.untitledPoints}</td>
            <td>
              {updateMode ? (
                <TextInputField
                  name='untitledPoints'
                  value={correctedFields.adjustedUntitledPoints}
                  placeholder={scoreDetails.adjustedUntitledPoints}
                  handleChange={(e) =>
                    handleValueChange(e, 'adjustedUntitledPoints')
                  }
                  isInvalid={errors.adjustedUntitledPoints}
                  errorMessage={errors.adjustedUntitledPoints}
                />
              ) : (
                scoreDetails.adjustedUntitledPoints
              )}
            </td>
            <td>
              {updateMode ? (
                <TextInputField
                  name='candidatureYearsNumberJustification'
                  value={correctedFields.untitledJustification}
                  handleChange={(e) =>
                    handleValueChange(e, 'untitledJustification')
                  }
                />
              ) : (
                scoreDetails.untitledJustification
              )}
            </td>
            <td>{scoreDetails.untitledConsideration}%</td>
            <td>{scoreDetails.untitledConsiderationPoints}</td>
            <td>{scoreDetails.adjustedUntitledConsiderationPoints}</td>
          </tr>
        );

      case 'dependentsNumber':
        return (
          <tr>
            <td>
              <FormattedMessage id='scoreDetailsTable.dependentsNumber' />
            </td>
            <td>{candidatureEAA.dependentsNumber}</td>
            <td>{scoreDetails.dependentsPoints}</td>
            <td>
              {updateMode ? (
                <TextInputField
                  name='dependentsPoints'
                  value={correctedFields.adjustedDependentsPoints}
                  placeholder={scoreDetails.adjustedDependentsPoints}
                  handleChange={(e) =>
                    handleValueChange(e, 'adjustedDependentsPoints')
                  }
                  isInvalid={errors.adjustedDependentsPoints}
                  errorMessage={errors.adjustedDependentsPoints}
                />
              ) : (
                scoreDetails.adjustedDependentsPoints
              )}
            </td>
            <td>
              {updateMode ? (
                <TextInputField
                  name='candidatureYearsNumberJustification'
                  value={correctedFields.dependentsJustification}
                  handleChange={(e) =>
                    handleValueChange(e, 'dependentsJustification')
                  }
                />
              ) : (
                scoreDetails.dependentsJustification
              )}
            </td>
            <td>{scoreDetails.dependentsNumberConsideration}%</td>
            <td>{scoreDetails.dependentsNumberConsiderationPoints}</td>
            <td>{scoreDetails.adjustedDependentsNumberConsiderationPoints}</td>
          </tr>
        );

      case 'over65Elements':
        return (
          <tr>
            <td>
              <FormattedMessage id='scoreDetailsTable.over65Elements' />
            </td>
            <td>{candidatureEAA.over65Elements}</td>
            <td>{scoreDetails.over65Points}</td>
            <td>
              {updateMode ? (
                <TextInputField
                  name='over65Points'
                  value={correctedFields.adjustedOver65Points}
                  placeholder={scoreDetails.adjustedOver65Points}
                  handleChange={(e) =>
                    handleValueChange(e, 'adjustedOver65Points')
                  }
                  isInvalid={errors.adjustedOver65Points}
                  errorMessage={errors.adjustedOver65Points}
                />
              ) : (
                scoreDetails.adjustedOver65Points
              )}
            </td>
            <td>
              {updateMode ? (
                <TextInputField
                  name='candidatureYearsNumberJustification'
                  value={correctedFields.over65Justification}
                  handleChange={(e) =>
                    handleValueChange(e, 'over65Justification')
                  }
                />
              ) : (
                scoreDetails.over65Justification
              )}
            </td>
            <td>{scoreDetails.over65Consideration}%</td>
            <td>{scoreDetails.over65ConsiderationPoints}</td>
            <td>{scoreDetails.adjustedOver65ConsiderationPoints}</td>
          </tr>
        );

      case 'residencyStatus':
        return (
          <tr>
            <td>
              <FormattedMessage id='scoreDetailsTable.residentialSituation' />
            </td>
            <td>{candidatureEAA.requestMotive.description}</td>
            <td>{scoreDetails.motivePointsEAA}</td>
            <td>
              {updateMode ? (
                <TextInputField
                  name='motivePointsEAA'
                  value={correctedFields.adjustedMotivePointsEAA}
                  placeholder={scoreDetails.adjustedMotivePointsEAA}
                  handleChange={(e) =>
                    handleValueChange(e, 'adjustedMotivePointsEAA')
                  }
                  isInvalid={errors.adjustedMotivePointsEAA}
                  errorMessage={errors.adjustedMotivePointsEAA}
                />
              ) : (
                scoreDetails.adjustedMotivePointsEAA
              )}
            </td>
            <td>
              {updateMode ? (
                <TextInputField
                  name='candidatureYearsNumberJustification'
                  value={correctedFields.motiveEAAJustification}
                  handleChange={(e) =>
                    handleValueChange(e, 'motiveEAAJustification')
                  }
                />
              ) : (
                scoreDetails.motiveEAAJustification
              )}
            </td>
            <td>{scoreDetails.considerationMotiveEAA}%</td>
            <td>{scoreDetails.considerationMotiveEAAPoints}</td>
            <td>{scoreDetails.adjustedConsiderationMotiveEAAPoints}</td>
          </tr>
        );

      default:
        break;
    }
  }
}

const DEFAULT_CORRECTED_FIELDS = {
  adjustedCandidatureYearsPoints: '',
  adjustedIncapacityPoints: '',
  adjustedUntitledPoints: '',
  adjustedDependentsPoints: '',
  adjustedOver65Points: '',
  adjustedMotivePointsEAA: '',
  candidatureYearsNumberJustification: '',
  incapacityJustification: '',
  untitledJustification: '',
  dependentsJustification: '',
  over65Justification: '',
  motiveEAAJustification: '',
};

const DEFAULT_ERRORS = {
  adjustedCandidatureYearsPoints: '',
  adjustedIncapacityPoints: '',
  adjustedUntitledPoints: '',
  adjustedDependentsPoints: '',
  adjustedOver65Points: '',
  adjustedMotivePointsEAA: '',
};

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { ComponentLoading } from '../../../components/ComponentLoading';
import { DocumentFilterBar } from '../../../components/document/DocumentFilterBar';
import { DocumentTable } from '../../../components/document/DocumentTable';
import Loading from '../../../components/Loading';
import { SubTemplate } from '../../../components/SubTemplate';
import { useQuery } from '../../../hooks/useQuery';
import { getDocumentMember } from '../../../rest/candidature';
import { getCandidatureDocumentsByDocType } from '../../../rest/candidaturedocument';
import { getCandidatureByExternalId } from '../../../rest/candidatureuser';
import { getCandidatureDocumentTypes } from '../../../rest/documentType';
import { handleError, isNotBusinessError } from '../../../utils/handleError';

const getType = (query) => {
  return query && query.get('tipo');
};

export function CandidatureDocumentsSearch() {
  const query = useQuery();
  const { externalId } = useParams();
  const [selectedType, setSelectedType] = useState(getType(query));
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(true);
  const [error, setError] = useState(null);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [candidature, setCandidature] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const [candidature, documents, documentTypes] = await Promise.all([
          getCandidatureByExternalId(externalId),
          getCandidatureDocumentsByDocType(externalId, selectedType),
          getCandidatureDocumentTypes(externalId),
        ]);

        setDocuments(documents.data);
        setDocumentTypes(documentTypes.data);
        setCandidature(candidature.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
        setSearching(false);
      }
    };
    setSearching(true);
    fetchDocuments();
  }, [selectedType, externalId]);

  const filterDocuments = (type) => {
    setSelectedType(type);
  };

  const handleFileDownload = async (path, fileName) => {
    try {
      const response = await getDocumentMember(path);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      setError(error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate
      hasBackButton
      title={intl.formatMessage(
        { id: 'candidature.documents.page' },
        {
          candidature: candidature.candidatureDto.code,
          competition: candidature.candidatureDto.competitionCode,
        }
      )}
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            <AlertError error={error} />
            <DocumentFilterBar
              keyword={null}
              parentExternalId={null}
              onFilter={filterDocuments}
              isCandidatureDocuments={true}
              numberOfDocuments={documents.length}
              documentTypes={documentTypes}
              defaultFilter={selectedType}
              handleFileDownload={handleFileDownload}
              externalId={externalId}
              candidatureCode={candidature.candidatureDto.code}
              candidatureState={candidature.candidatureDto.state}
            />
            {searching ? (
              <ComponentLoading />
            ) : (
              <DocumentTable
                documents={documents}
                handleFileDownload={handleFileDownload}
                candidatureState={candidature.candidatureDto.state}
              />
            )}
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

import { Formik } from 'formik';
import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import { TextInputField } from '../bootstrap/TextInputField';

export function PermissionDialog({ show, handleClose, onUpdate, permission }) {
  const intl = useIntl();

  let schema = yup.object().shape({
    description: yup
      .string()
      .required(
        intl.formatMessage({ id: 'permissionForm.error.emptyDescription' })
      ),
  });

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='permissionForm.edit' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={permission}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            onUpdate(values, setSubmitting, setErrors);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-md-12'>
                  <h5 className='mb-4 py-2'>{permission.code}</h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <TextInputField
                    name='description'
                    value={values.description}
                    isInvalid={touched.description && errors.description}
                    handleChange={handleChange}
                    errorMessage={errors.description}
                    labelId='permissionForm.descriptionText'
                  />
                </div>
                <div className='col-md-12'>
                  <Form.Group controlId='zoneLimitation'>
                    <Form.Check
                      label={intl.formatMessage({
                        id: 'permissionForm.zoneLimitationText',
                      })}
                      name='zoneLimitation'
                      value={values.zoneLimitation}
                      type='checkbox'
                      checked={values.zoneLimitation}
                      onChange={handleChange}
                      isInvalid={
                        touched.zoneLimitation && errors.zoneLimitation
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.zoneLimitation}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className='form-actions'>
                <div className='ml-auto'>
                  <button
                    className={'btn btn-outline-primary'}
                    type='submit'
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id='all.submitButtonText' />
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

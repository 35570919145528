import { useIntl } from 'react-intl';

/**
 * Creates an empty error message
 *
 * @returns {{ show: boolean, type: string, message: string }}
 */
export const createEmptyErrorMessage = () => {
  return { show: true, type: 'danger', message: null };
};

export const useMessageObjectCreator = () => {
  const intl = useIntl();

  return {
    create: (error) => {
      if (error?.response?.status === 400) {
        const message = createEmptyErrorMessage();
        const data = error.response.data;
        if (data) {
          switch (data.exception) {
            case 'AlreadyExistsException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.AlreadyExistsException',
                },
                { value: data.message }
              );
              return message;

            case 'ObjectNotFoundException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.ObjectNotFoundException',
                },
                { value: data.message }
              );
              return message;

            case 'DrawAlreadyExistsException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.DrawAlreadyExistsException',
                },
                { value: data.message }
              );
              return message;

            case 'CatalogNotExistsException':
              const catalogCode = data.message?.split(':')[1];

              message.message = intl.formatMessage(
                {
                  id: 'exceptions.CatalogNotExistsException',
                },
                { catalogCode }
              );
              return message;

            case 'DivisionNotExistsException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.DivisionNotExistsException',
                },
                { value: data.message }
              );
              return message;

            case 'CompetitionNotExistsException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.CompetitionNotExistsException',
                },
                { value: data.message }
              );
              return message;

            case 'HabitationAlreadyExistsOnOtherCompetitionException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.HabitationAlreadyExistsOnOtherCompetitionException',
                },
                { value: data.message }
              );
              return message;

            case 'HabitationAlreadyExistsInCompetitionException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.HabitationAlreadyExistsInCompetitionException',
                },
                { value: data.message }
              );
              return message;

            case 'CompetitionHasNoDocumentsException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.CompetitionHasNoDocumentsException',
                },
                { value: data.message }
              );
              return message;

            case 'CompetitionAlreadyExistsException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.CompetitionAlreadyExistsException',
                },
                { value: data.message }
              );
              return message;
            case 'PermissionNotExistsException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.PermissionNotExistsException',
                },
                { value: data.message }
              );
              return message;
            case 'OperationNotAllowedException':
              message.message = intl.formatMessage({
                id: 'exceptions.OperationNotAllowedException',
              });
              return message;

            case 'ForceException':
              message.message = data.message;
              return message;

            case 'FileExtensionNotAllowedException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.FileExtensionNotAllowedException',
                },
                { value: data.message }
              );
              return message;

            case 'FrontendCustomException':
              message.message = data.message;
              return message;

            case 'NoHabitationsException':
              message.message = intl.formatMessage({
                id: 'exceptions.NoHabitationsException',
              });
              return message;
            case 'NoSelectedHabitationsException':
              message.message = intl.formatMessage({
                id: 'exceptions.NoSelectedHabitationsException',
              });
              return message;

            case 'InvalidCompetitionParticipationStateException':
              message.message = intl.formatMessage({
                id: 'exceptions.InvalidCompetitionParticipationStateException',
              });
              return message;

            case 'PaccException':
              message.message = data.message;
              return message;

            case 'NifAlreadyInCompetitionException':
              message.message = intl.formatMessage({
                id: 'exceptions.NifAlreadyInCompetitionException',
              });
              return message;

            case 'RequiredFieldsException':
              message.message = intl.formatMessage({
                id: 'exceptions.RequiredFieldsException',
              });
              return message;

            case 'NifNotCandidateException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.NifNotCandidateException',
                },
                { value: data.message }
              );
              return message;

            case 'MaxHabitationExceedsException':
              message.message = intl.formatMessage({
                id: 'exceptions.MaxHabitationExceedsException',
              });
              return message;

            case 'PreferenceOrderRepeatedException':
              message.message = intl.formatMessage({
                id: 'exceptions.PreferenceOrderRepeatedException',
              });
              return message;

            case 'EligibilityConditionAlreadyExistsException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.EligibilityConditionAlreadyExistsException',
                },
                { value: data.message }
              );
              return message;

            case 'OrderAlreadyExistsException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.OrderAlreadyExistsException',
                },
                { value: data.message }
              );
              return message;

            case 'InvalidDateException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.InvalidDateException',
                },
                {
                  value: intl.formatMessage({ id: data.message.split(':')[0] }),
                }
              );
              return message;

            case 'TermsConditionNotAcceptedException':
              message.message = intl.formatMessage({
                id: 'exceptions.TermsConditionNotAcceptedException',
              });
              return message;

            case 'DocumentTypeRequiredException':
              message.message = intl.formatMessage({
                id: 'exceptions.DocumentTypeRequiredException',
              });

              message.message += `<br /> ${data.message
                .replaceAll('|', `<br />`)
                .replaceAll(':', ' => ')}`;
              return message;

            case 'CandidatureExistsOnOtherCompetitionException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.CandidatureExistsOnOtherCompetitionException',
                },
                { value: data.message }
              );
              return message;
            case 'CompetitionHasNoHabitationsException':
              message.message = intl.formatMessage(
                {
                  id: 'exceptions.CompetitionHasNoHabitationsException',
                },
                { value: data.message }
              );
              return message;

            default:
              message.message = data.message;
              return message;
          }
        }
      }
      return { show: false, type: null, message: null };
    },
  };
};

export const createCustomErrorMessage = (message, status) => {
  return {
    response: {
      status: status || 400,
      data: { exception: 'FrontendCustomException', message: message },
    },
  };
};

export const createExceptionErrorMessage = (exception, message) => {
  return {
    response: {
      status: 400,
      data: { exception: exception, message: message },
    },
  };
};

import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { ActionNotificationForm } from '../../../../components/actionNotification/ActionNotificationForm';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import {
  getActionNotification,
  updateActionNotification,
} from '../../../../rest/templates';
import { handleError, isBusinessError } from '../../../../utils/handleError';

export function EditActionNotification() {
  const { actionNotificationCode } = useParams();

  const [actionNotification, setActionNotification] = useState(null);

  const [loading, setLoading] = useState(true);
  const [submited, setSubmited] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const { data: actionNotificationData } = await getActionNotification(
        actionNotificationCode
      );

      setActionNotification(actionNotificationData);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const update = async (actionNotification, setSubmitting, setErrors) => {
    try {
      await updateActionNotification(
        actionNotificationCode,
        actionNotification
      );

      setSubmitting(false);
      setSubmited(true);
      setLoading(false);
    } catch (error) {
      setError(error);
      setSubmitting(false);
      setLoading(false);
    }
  };

  const onSubmit = (actionNotification, setSubmitting, setErrors) => {
    setLoading(true);
    update(actionNotification, setSubmitting, setErrors);
  };

  if (loading) {
    return <Loading />;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  if (submited) {
    return <Redirect to={'/templates'} />;
  }

  return (
    <SubTemplate hasBackButton titleId={'template.edit'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-8 offset-lg-2'}>
            <AlertError error={error} />
            <ActionNotificationForm
              actionNotification={actionNotification}
              isUpdate={true}
              onSubmit={onSubmit}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { CandidatureState } from '../../models/CandidatureState';

export function CandidatureAllStatesFilterBar({
  states,
  defaultFilter,
  onFilter,
}) {
  const intl = useIntl();

  const [stateFilter, setStateFilter] = useState(
    defaultFilter ? defaultFilter : '-1'
  );

  const handleChange = (e) => {
    let stateF = e.target.value === '-1' ? null : e.target.value;
    onFilter(stateF);
    setStateFilter(stateF);
  };

  return (
    <form className={'p-0 border-0 mt-4'}>
      <div className={'form-inline'}>
        <div className={'form-group  big-input mr-3'}>
          <select
            className={'form-control rounded-0'}
            onChange={handleChange}
            value={stateFilter}
          >
            <option value={'-1'}>
              {intl.formatMessage({
                id: 'messages.allTypeText',
              })}
            </option>
            {CandidatureState.values().map((state, index) => {
              return (
                <option key={index} value={state}>
                  {intl.formatMessage({ id: 'CandidatureState.' + state })}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </form>
  );
}

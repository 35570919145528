import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { FaCaretDown, FaSearch } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';

const DEFAULT_VALUES = {
  districtId: null,
  countyId: null,
  minTypology: null,
  maxTypology: null,
  minIncapacityElements: null,
  maxIncapacityElements: null,
  minOver65Elements: null,
  maxOver65Elements: null,
  minDependentsNumber: null,
  maxDependentsNumber: null,
  untitledAggregate: null,
};

export function StagingCandidaturesFilterArea({
  districts,
  counties,
  typologies,
  maxNumberOfIncapacityElements,
  maxNumberOfDependents,
  maxNumberOfOver65Elements,
  onDistrictChange,
  fetchDataWithFilters,
}) {
  const intl = useIntl();
  const [filterValues, setFilterValues] = useState(DEFAULT_VALUES);

  function handleFilterValueChange(e, filterName) {
    if (filterName === 'districtId') {
      onDistrictChange(e.target.value);
    }

    filterValues[filterName] = e.target.value === '1' ? null : e.target.value;
    setFilterValues({ ...filterValues });
  }

  return (
    <form className='p-0 border-0 mt-4'>
      <div className='form-inline'>
        <div className='form-group big-input mr-3'>
          <select
            name='districtId'
            className='form-control rounded-0'
            onChange={(e) => handleFilterValueChange(e, 'districtId')}
            value={filterValues.districtId}
          >
            <option value='-1'>
              {intl.formatMessage({
                id: 'competitionForm.competitionDistrictText',
              })}
            </option>

            {districts.map((district) => (
              <option key={district.code} value={district.code}>
                {district.description}
              </option>
            ))}
          </select>
        </div>

        <div className='form-group big-input mr-3'>
          <select
            name='countyId'
            className='form-control rounded-0'
            onChange={(e) => handleFilterValueChange(e, 'countyId')}
            value={filterValues.countyId}
          >
            <option value='-1'>
              {intl.formatMessage({
                id: 'competitionForm.competitionCountyText',
              })}
            </option>

            {counties.map((county) => (
              <option key={county.code} value={county.code}>
                {county.description}
              </option>
            ))}
          </select>
        </div>

        <div className='form-group'>
          <button
            className='btn btn-primary'
            onClick={() => fetchDataWithFilters(filterValues)}
            type='button'
          >
            <FaSearch className='mx-0' />
          </button>
        </div>
      </div>

      <div>
        <Accordion className={'mb-3'}>
          <Accordion.Toggle className={'btn-accordion mt-1 mb-2'} eventKey={1}>
            <FormattedMessage id='all.advancedSearch' />
            <FaCaretDown className='ml-2' />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={1}>
            <div className='row'>
              <div className='col-md-3 mb-3'>
                <label>
                  <FormattedMessage id='candidature.minTypologyCodeText' />
                </label>
                <select
                  name='minTypology'
                  className='form-control rounded-0'
                  onChange={(e) => handleFilterValueChange(e, 'minTypology')}
                  value={filterValues.minTypology}
                >
                  <option value='-1'>
                    {intl.formatMessage({ id: 'all.emptySelect' })}
                  </option>

                  {typologies.map((typology) => (
                    <option key={typology.code} value={typology.code}>
                      {typology.description}
                    </option>
                  ))}
                </select>
              </div>

              <div className='col-md-3 mb-3'>
                <label>
                  <FormattedMessage id='candidature.maxTypologyCodeText' />
                </label>
                <select
                  name='maxTypology'
                  className='form-control rounded-0'
                  onChange={(e) => handleFilterValueChange(e, 'maxTypology')}
                  value={filterValues.maxTypology}
                >
                  <option value='-1'>
                    {intl.formatMessage({ id: 'all.emptySelect' })}
                  </option>

                  {typologies.map((typology) => (
                    <option key={typology.code} value={typology.code}>
                      {typology.description}
                    </option>
                  ))}
                </select>
              </div>

              <div className='col-md-3 mb-3'>
                <label>
                  <FormattedMessage id='stagingCandidaturesFilterArea.minIncapacityElements' />
                </label>
                <select
                  name='minIncapacityElements'
                  className='form-control rounded-0'
                  onChange={(e) =>
                    handleFilterValueChange(e, 'minIncapacityElements')
                  }
                  value={filterValues.minIncapacityElements}
                >
                  <option value='-1'>
                    {intl.formatMessage({ id: 'all.emptySelect' })}
                  </option>

                  {getPossibleValuesByMaxNumber(
                    maxNumberOfIncapacityElements
                  ).map((index, number) => (
                    <option key={index} value={number}>
                      {number}
                    </option>
                  ))}
                </select>
              </div>

              <div className='col-md-3 mb-3'>
                <label>
                  <FormattedMessage id='stagingCandidaturesFilterArea.maxIncapacityElements' />
                </label>
                <select
                  name='maxIncapacityElements'
                  className='form-control rounded-0'
                  onChange={(e) =>
                    handleFilterValueChange(e, 'maxIncapacityElements')
                  }
                  value={filterValues.maxIncapacityElements}
                >
                  <option value='-1'>
                    {intl.formatMessage({ id: 'all.emptySelect' })}
                  </option>

                  {getPossibleValuesByMaxNumber(
                    maxNumberOfIncapacityElements
                  ).map((index, number) => (
                    <option key={index} value={number}>
                      {number}
                    </option>
                  ))}
                </select>
              </div>

              <div className='col-md-3 mb-3'>
                <label>
                  <FormattedMessage id='stagingCandidaturesFilterArea.minOver65Elements' />
                </label>
                <select
                  name='minOver65Elements'
                  className='form-control rounded-0'
                  onChange={(e) =>
                    handleFilterValueChange(e, 'minOver65Elements')
                  }
                  value={filterValues.minOver65Elements}
                >
                  <option value='-1'>
                    {intl.formatMessage({ id: 'all.emptySelect' })}
                  </option>

                  {getPossibleValuesByMaxNumber(maxNumberOfOver65Elements).map(
                    (index, number) => (
                      <option key={index} value={number}>
                        {number}
                      </option>
                    )
                  )}
                </select>
              </div>

              <div className='col-md-3 mb-3'>
                <label>
                  <FormattedMessage id='stagingCandidaturesFilterArea.maxOver65Elements' />
                </label>
                <select
                  name='maxOver65Elements'
                  className='form-control rounded-0'
                  onChange={(e) =>
                    handleFilterValueChange(e, 'maxOver65Elements')
                  }
                  value={filterValues.maxOver65Elements}
                >
                  <option value='-1'>
                    {intl.formatMessage({ id: 'all.emptySelect' })}
                  </option>

                  {getPossibleValuesByMaxNumber(maxNumberOfOver65Elements).map(
                    (index, number) => (
                      <option key={index} value={number}>
                        {number}
                      </option>
                    )
                  )}
                </select>
              </div>

              <div className='col-md-3 mb-3'>
                <label>
                  <FormattedMessage id='stagingCandidaturesFilterArea.minDependentsNumber' />
                </label>
                <select
                  name='minDependentsNumber'
                  className='form-control rounded-0'
                  onChange={(e) =>
                    handleFilterValueChange(e, 'minDependentsNumber')
                  }
                  value={filterValues.minDependentsNumber}
                >
                  <option value='-1'>
                    {intl.formatMessage({ id: 'all.emptySelect' })}
                  </option>

                  {getPossibleValuesByMaxNumber(maxNumberOfDependents).map(
                    (index, number) => (
                      <option key={index} value={number}>
                        {number}
                      </option>
                    )
                  )}
                </select>
              </div>

              <div className='col-md-3 mb-3'>
                <label>
                  <FormattedMessage id='stagingCandidaturesFilterArea.maxDependentsNumber' />
                </label>
                <select
                  name='maxDependentsNumber'
                  className='form-control rounded-0'
                  onChange={(e) =>
                    handleFilterValueChange(e, 'maxDependentsNumber')
                  }
                  value={filterValues.maxDependentsNumber}
                >
                  <option value='-1'>
                    {intl.formatMessage({ id: 'all.emptySelect' })}
                  </option>

                  {getPossibleValuesByMaxNumber(maxNumberOfDependents).map(
                    (index, number) => (
                      <option key={index} value={number}>
                        {number}
                      </option>
                    )
                  )}
                </select>
              </div>

              <div className='col-md-3 mb-3'>
                <label>
                  <FormattedMessage id='candidature.candidatureEAA.unititledAgregate' />
                </label>
                <select
                  name='untitledAggregate'
                  className='form-control rounded-0'
                  onChange={(e) =>
                    handleFilterValueChange(e, 'untitledAggregate')
                  }
                  value={filterValues.untitledAggregate}
                >
                  <option value='-1'>
                    {intl.formatMessage({ id: 'all.emptySelect' })}
                  </option>

                  <option value='true'>
                    {intl.formatMessage({ id: 'all.yesText' })}
                  </option>
                  <option value='false'>
                    {intl.formatMessage({ id: 'all.noText' })}
                  </option>
                </select>
              </div>
            </div>
          </Accordion.Collapse>
        </Accordion>
      </div>
    </form>
  );
}

function getPossibleValuesByMaxNumber(maxNumber) {
  let values = [];

  for (let i = 0; i <= maxNumber; i++) {
    values.push(i);
  }

  return values;
}

import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

/**
 * @param  {Array<String>} arr
 */
function joinMultipleValues(arr) {
  if (arr.length === 0) {
    return '';
  }

  arr = arr.filter((value) => value.length > 0);

  return '|' + arr.join('|') + '|';
}

function getMultipleValues(parameter) {
  if (parameter.type === 'MULTI_VALUE') {
    if (parameter.value && parameter.value.length > 0) {
      if (parameter.value.startsWith('|') && parameter.value.endsWith('|')) {
        return parameter.value.slice(1, parameter.value.length - 1).split('|');
      }
      return [parameter.value];
    }
  }
  return [];
}

function ParameterForm({ parameter, setParameter, onSubmit, isUpdate }) {
  const intl = useIntl();

  const [values, setValues] = useState(getMultipleValues(parameter));

  const handleChange = (e) => {
    const tempParameter = { ...parameter };
    tempParameter[e.target.name] = e.target.value;

    if (e.target.name === 'type' && e.target.value === 'MULTI_VALUE') {
      setValues(getMultipleValues(tempParameter));
    }

    setParameter(tempParameter);
  };

  const handleClick = (e) => {
    e.preventDefault();

    let tempValues = [...values];

    if (!tempValues) {
      tempValues = [];
    }

    tempValues.push('');
    setValues(tempValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (parameter.type === 'MULTI_VALUE') {
      const value = joinMultipleValues(values);
      const parametersTemp = { ...parameter, value };

      setParameter(parametersTemp);
    }

    onSubmit(e);
  };

  const handleChangeMultipleValues = (e, index) => {
    const tempValues = [...values];

    tempValues[index] = e.target.value;

    setValues(tempValues);

    const value = joinMultipleValues(tempValues);

    const parametersTemp = { ...parameter, value };

    setParameter(parametersTemp);
  };

  const isDisabled = () => {
    return isUpdate && parameter.systemOperational;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId='code'>
        <Form.Label>
          <FormattedMessage id='parameterForm.codeText' />
        </Form.Label>
        <Form.Control
          type='text'
          name='code'
          value={parameter.code}
          onChange={handleChange}
          disabled={isDisabled()}
        />
      </Form.Group>

      <Form.Group controlId='name'>
        <Form.Label>
          <FormattedMessage id='parameterForm.nameText' />
        </Form.Label>
        <Form.Control
          type='text'
          name='name'
          value={parameter.name}
          onChange={handleChange}
        />
      </Form.Group>

      {parameter.type !== 'MULTI_VALUE' ? (
        <Form.Group controlId='value'>
          <Form.Label>
            <FormattedMessage id='parameterForm.valueText' />
          </Form.Label>
          <Form.Control
            type='text'
            name='value'
            value={parameter.value}
            onChange={handleChange}
          />
        </Form.Group>
      ) : (
        <div>
          <Button onClick={handleClick}>
            <FormattedMessage id='all.plusSymbol' />
          </Button>
          <Form.Group controlId='value'>
            <Form.Label>
              <FormattedMessage id='parameterForm.valueText' />
            </Form.Label>
            {values.map((value, index) => (
              <Form.Control
                key={index}
                type='text'
                name='value'
                value={value}
                onChange={(e) => handleChangeMultipleValues(e, index)}
              />
            ))}
          </Form.Group>
        </div>
      )}

      <Form.Group controlId='type'>
        <Form.Label>
          <FormattedMessage id='parameterForm.typeText' />
        </Form.Label>
        <Form.Control
          as='select'
          name='type'
          value={parameter.type}
          onChange={handleChange}
          disabled={isDisabled()}
        >
          <option value='NUMBER'>
            {intl.formatMessage({ id: 'parameterForm.numberText' })}
          </option>
          <option value='STRING'>
            {intl.formatMessage({ id: 'parameterForm.textText' })}
          </option>
          <option value='MULTI_VALUE'>
            {intl.formatMessage({ id: 'parameterForm.multipleValueText' })}
          </option>
        </Form.Control>
      </Form.Group>

      <Form.Group controlId='systemOperational'>
        <Form.Label>
          <FormattedMessage id='parameterForm.systemOperationalext' />
        </Form.Label>
        <Form.Control
          as='select'
          name='systemOperational'
          value={parameter.systemOperational}
          onChange={handleChange}
          disabled={isDisabled()}
        >
          <option value='true'>
            {intl.formatMessage({ id: 'all.yesText' })}
          </option>
          <option value='false'>
            {intl.formatMessage({ id: 'all.noText' })}
          </option>
        </Form.Control>
      </Form.Group>
      <div className='form-actions'>
        <div className='mr-auto'>
          <Link to={'/parametros'} variant='secondary'>
            <FormattedMessage id='all.backButtonText' />
          </Link>
        </div>
        <div className='ml-auto'>
          <Button variant='primary' type='submit'>
            <FormattedMessage id='all.submitButtonText' />
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default ParameterForm;

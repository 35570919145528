import React, { useState } from 'react';
import { Redirect } from 'react-router';
import ParameterForm from '../../../../components/parameters/ParameterForm';
import { SubTemplate } from '../../../../components/SubTemplate';
import { addParameter } from '../../../../rest/parameter';

function AddParameter() {
  const [parameter, setParameter] = useState({
    type: 'NUMBER',
    systemOperational: 'true',
  });
  const [submited, setSubmited] = useState(false);

  const createParameter = async () => {
    try {
      await addParameter(parameter);
      setSubmited(true);
    } catch (error) {
      console.log(error);
      if (error.response?.status === 401) {
        window.location.reload();
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    createParameter();
  };

  if (submited) {
    return <Redirect to='/parametros' />;
  }

  return (
    <SubTemplate hasBackButton titleId={'parameter.add'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-8 offset-lg-2'}>
            <ParameterForm
              parameter={parameter}
              setParameter={setParameter}
              onSubmit={onSubmit}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default AddParameter;

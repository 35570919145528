import dateFormat from 'date-fns/format';
import { Formik } from 'formik';
import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { ConfigObject } from '../../config';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';
import { EditorInputField } from '../bootstrap/EditorInputField';

export function ScheduleContractAndKeyDeliveryDialog({
  show,
  handleClose,
  handleConfirm,
  templateEmailBody,
  isReschedule,
}) {
  const DEFAULT_VALUES = {
    date: dateFormat(new Date(), ConfigObject.get().DATE_TIME_PATTERN),
    templateEmailBody: templateEmailBody,
  };

  let schema = yup.object().shape({
    date: yup
      .date()
      .min(new Date(), <FormattedMessage id='errors.publishDeadline.text' />),
  });

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isReschedule ? (
              <FormattedMessage id='candidature.dialog.rescheduleContractCelebrationAndKeyDelivery.text' />
            ) : (
              <FormattedMessage id='candidature.dialog.confirmRegisteredContract.text' />
            )}
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={DEFAULT_VALUES}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            handleConfirm(values, setSubmitting, setErrors);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <EditorInputField
                  name='templateEmailBody'
                  value={values.templateEmailBody}
                  errorMessage={errors.templateEmailBody}
                  isInvalid={
                    touched.templateEmailBody && errors.templateEmailBody
                  }
                  handleChange={handleChange}
                  hideOvelay={true}
                />
                <DateTimeInputField
                  name='date'
                  value={values.date}
                  errorMessage={errors.date}
                  isInvalid={touched.date && errors.date}
                  setFieldValue={setFieldValue}
                  labelId='candidature.dialog.confirmRegisteredContract.date'
                ></DateTimeInputField>
              </Modal.Body>

              <Modal.Footer>
                <button
                  className='btn btn-link mr-auto'
                  onClick={handleClose}
                  type='button'
                >
                  <FormattedMessage id='all.cancelText' />
                </button>

                <button
                  className={'btn btn-primary'}
                  type='submit'
                  disabled={isSubmitting}
                >
                  <FormattedMessage id='all.confirm' />
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { FaAngleLeft, FaAngleRight, FaTrashAlt } from 'react-icons/fa';
import ImageUploader from 'react-images-upload';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { ConfigObject } from '../../../../config';
import {
  addHabitationImage,
  deleteHabitationImage,
  getHabitationImage,
} from '../../../../rest/habitationimages';

function AddHabitationImage() {
  const { habitationExternalId } = useParams();

  const intl = useIntl();

  const [images, setImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { imageExtensionHabitation, imageSizeHabitation } = ConfigObject.get();

  async function fetchHabitation() {
    try {
      const { data: image } = await getHabitationImage(habitationExternalId);
      setImages(image);
      setLoading(false);
    } catch (error) {
      if (error.response?.status === 401) {
        window.location.reload();
      } else {
        setError(error.response);
        setLoading(false);
      }
    }
  }
  useEffect(() => {
    fetchHabitation();
  }, []);

  async function onFileChange(event) {
    try {
      const formData = new FormData();
      formData.append('Attachment', event[0], event[0].name);
      await addHabitationImage(habitationExternalId, formData);
      await fetchHabitation();
    } catch (error) {}
  }

  async function deleteImage(imageName) {
    try {
      await deleteHabitationImage(habitationExternalId, imageName);
      setLoading(true);
    } catch (error) {
      console.log(error);
      setError(error.response);
      setLoading(false);
    }
  }

  const renderPrevButton = ({ isDisabled }) => {
    return (
      <span style={{ opacity: isDisabled ? '0.5' : 1 }}>
        <FaAngleLeft />
      </span>
    );
  };

  const renderNextButton = ({ isDisabled }) => {
    return (
      <span style={{ opacity: isDisabled ? '0.5' : 1 }}>
        <FaAngleRight />
      </span>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <SubTemplate
      hasBackButton
      titleId={'habitationImage.TitleText'}
      subTitleId='competitions.competitionText'
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-md-3 mb-5'}>
            <ImageUploader
              buttonText={intl.formatMessage({
                id: 'documents.insertImageText',
              })}
              onChange={(e) => onFileChange(e)}
              singleImage={true}
              imgExtension={[imageExtensionHabitation]}
              maxFileSize={1024 * 1024 * imageSizeHabitation}
              label={
                intl.formatMessage({
                  id: 'documents.labelSizeText',
                }) +
                imageSizeHabitation +
                intl.formatMessage({ id: 'all.mbSize' }) +
                intl.formatMessage({ id: 'documents.labelTypeText' }) +
                imageExtensionHabitation.replaceAll('|', ',')
              }
              fileTypeError={intl.formatMessage({
                id: 'documents.TypeErrorText',
              })}
              fileSizeError={intl.formatMessage({
                id: 'documents.SizeErrorText',
              })}
            />
          </div>
          <div className={'col-md-9 mb-5'}>
            <legend className='mt-2'>
              <FormattedMessage id='habitation.images.text' />
              {images.length}
            </legend>
            {images.length ? (
              <AliceCarousel
                renderPrevButton={renderPrevButton}
                renderNextButton={renderNextButton}
              >
                {images.map((image) => (
                  <div>
                    <div
                      key={image.name}
                      className='habitation-img'
                      style={{
                        backgroundImage: `url(${image.path})`,
                      }}
                    ></div>
                    <button
                      className='btn btn-secondary my-4'
                      onClick={() => deleteImage(image.name)}
                    >
                      <FaTrashAlt />
                      <FormattedMessage id='all.deleteImage' />
                    </button>
                  </div>
                ))}
              </AliceCarousel>
            ) : (
              <span></span>
            )}
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default AddHabitationImage;

import { FormattedMessage } from 'react-intl';

import { FaMapMarkerAlt } from 'react-icons/fa';

export function DisplayableHabitationDetails({ habitation, displayFields }) {
  /**
   * @param  {String} fieldName
   * @param  {Array.<String>} displayables
   */
  const isToDisplay = (fieldName) => {
    return displayFields.some((x) => x === fieldName);
  };

  return (
    <div>
      {/** Address Information */}
      <legend className='mt-0'>
        <div className='row'>
          <div className='col-auto mr-auto'>
            {habitation.platform == 'EAA' ? (
              <FormattedMessage id='habitationForm.habitationAddressTextEAA' />
            ) : (
              <FormattedMessage id='habitationForm.habitationAddressText' />
            )}
          </div>
          {isToDisplay('habitationAddress.longitude') &&
            isToDisplay('habitationAddress.latitude') &&
            habitation.habitationAddress.longitude &&
            habitation.habitationAddress.latitude && (
              <div className='col-auto ml-auto'>
                <a
                  href={`https://www.google.com/maps?q=${habitation.habitationAddress.latitude},${habitation.habitationAddress.longitude}`}
                  target='blank'
                  rel='noopener noreferrer'
                >
                  <FaMapMarkerAlt />
                  <FormattedMessage id='habitationForm.showLocation' />
                </a>
                <a
                  href={`https://www.google.com/maps?q=${habitation.habitationAddress.latitude},${habitation.habitationAddress.longitude}`}
                  className={'maps-google'}
                >{`https://www.google.com/maps?q=${habitation.habitationAddress.latitude},${habitation.habitationAddress.longitude}`}</a>
              </div>
            )}
        </div>
      </legend>
      <div className='row'>
        {isToDisplay('habitationAddress.streetName') &&
          habitation.platform != 'EAA' && (
            <DisplayFixedFields
              titleId={'habitationForm.habitationAddressStreetText'}
              name={'habitationAddress.streetName'}
              object={habitation}
              bold={true}
            />
          )}

        {isToDisplay('habitationAddress.lot') && (
          <DisplayFixedFields
            titleId={'habitationForm.habitationAddressLotText'}
            name={'habitationAddress.lot'}
            object={habitation}
            bold={true}
          />
        )}

        {isToDisplay('habitationAddress.floor') &&
          habitation.platform != 'EAA' && (
            <DisplayFixedFields
              titleId={'habitationForm.habitationAddressFloorLevelText'}
              name={'habitationAddress.floor'}
              object={habitation}
              bold={true}
            />
          )}

        {isToDisplay('habitationAddress.location') &&
          habitation.platform == 'EAA' && (
            <DisplayFixedFields
              titleId={'habitationForm.habitationAddressBurgText'}
              name={'habitationAddress.location'}
              object={habitation}
              bold={true}
            />
          )}

        {isToDisplay('habitationAddress.streetName') &&
          habitation.platform == 'EAA' && (
            <DisplayFixedFields
              titleId={'habitationForm.habitationAddressAddressText'}
              name={'habitationAddress.streetName'}
              object={habitation}
              bold={true}
            />
          )}

        {isToDisplay('habitationAddress.postalCode') && (
          <DisplayFixedFields
            titleId={'habitationForm.habitationAddressPostalCodeText'}
            name={'habitationAddress.postalCode'}
            object={habitation}
            bold={true}
          />
        )}
        {isToDisplay('habitationAddress.location') &&
          habitation.platform != 'EAA' && (
            <DisplayFixedFields
              titleId={'habitationForm.habitationAddressLocationText'}
              name={'habitationAddress.location'}
              object={habitation}
              bold={true}
              className={'col-sm-8'}
            />
          )}
      </div>
      <div className={'row'}>
        {isToDisplay('habitationAddress.parishName') && (
          <DisplayFixedFields
            titleId={'habitationForm.habitationAddressParishNameText'}
            name={'habitationAddress.parishName'}
            object={habitation}
            bold={true}
          />
        )}

        {isToDisplay('habitationAddress.countyName') && (
          <DisplayFixedFields
            titleId={'habitationForm.habitationAddressCountyNameText'}
            name={'habitationAddress.countyName'}
            object={habitation}
            bold={true}
          />
        )}

        {isToDisplay('habitationAddress.districtName') && (
          <DisplayFixedFields
            titleId={'habitationForm.habitationAddressDistrictNameText'}
            name={'habitationAddress.districtName'}
            object={habitation}
            bold={true}
          />
        )}

        {isToDisplay('habitationAddress.autonomousFraction') && (
          <DisplayFixedFields
            titleId={'habitationForm.habitationAddressAutonomousFractionText'}
            name={'habitationAddress.autonomousFraction'}
            object={habitation}
            bold={true}
          />
        )}
      </div>
      {habitation.platform !== 'EAA' && (
        <legend>
          <FormattedMessage id='candidature.candidatureMembersText.characterizationNameText' />
        </legend>
      )}
      <div className='row'>
        {isToDisplay('externalId') && (
          <DisplayFixedFields
            titleId={'habitation.externalIdText'}
            name={'externalId'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('code') && (
          <DisplayFixedFields
            titleId={'habitation.codeText'}
            name={'code'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}
      </div>
      <div className={'row'}>
        {isToDisplay('buildingType.description') && (
          <DisplayFixedFields
            titleId={'habitationForm.buildingTypeText'}
            name={'buildingType.description'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('typology.description') && (
          <DisplayFixedFields
            titleId={'habitationForm.typologyText'}
            name={'typology.description'}
            object={habitation}
            bold={true}
            className={habitation.platform == 'EAA' ? 'col-sm-4' : 'col-sm-3'}
          />
        )}

        {isToDisplay('habitationAddress.floor') &&
          habitation.platform == 'EAA' && (
            <DisplayFixedFields
              titleId={'habitationForm.habitationAddressFloorLevelText'}
              name={'habitationAddress.floor'}
              object={habitation}
              bold={true}
              className={'col-sm-4'}
            />
          )}

        {isToDisplay('totalLandArea') && habitation.platform == 'EAA' && (
          <DisplayFixedFields
            titleId={'habitationForm.totalLandAreaTextEAA'}
            name={'totalLandArea'}
            object={habitation}
            suffixId='habitationForm.squareAreaUnit'
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('floor.description') && (
          <DisplayFixedFields
            titleId={'habitationForm.floorText'}
            name={'floor.description'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('rentToApply') && (
          <DisplayFixedFields
            titleId={'habitationForm.rentToApplyText'}
            name={'rentToApply'}
            object={habitation}
            suffixId='habitationForm.euroUnit'
            bold={true}
            className={'col-sm-3'}
          />
        )}
      </div>
      <div className={'row'}>
        {isToDisplay('privateGrossArea') && (
          <DisplayFixedFields
            titleId={'habitationForm.privateGrossAreaText'}
            name={'privateGrossArea'}
            object={habitation}
            suffixId='habitationForm.squareAreaUnit'
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('minOccupation') && (
          <DisplayFixedFields
            titleId={'habitationForm.minOccupationAsNumberBedroomsText'}
            name={'minOccupation'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('numberOfBathroom') && (
          <DisplayFixedFields
            titleId={'habitationForm.numberOfBathroomText'}
            name={'numberOfBathroom'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}
      </div>
      <div className={'row'}>
        {isToDisplay('floorAccess.description') && (
          <DisplayFixedFields
            titleId={'habitationForm.floorAccessText'}
            name={'floorAccess.description'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}
        {isToDisplay('stairAccess') && (
          <DisplayFixedFields
            titleId={'habitationForm.stairAccessText'}
            name={'stairAccess'}
            object={habitation}
            bold={true}
            className={habitation.platform != 'EAA' ? 'col-sm-3' : 'col-sm-4'}
          />
        )}
        {isToDisplay('floorAcessDesc') && (
          <DisplayFixedFields
            titleId={'habitationForm.floorAccessText'}
            name={'floorAcessDesc'}
            object={habitation}
            bold={true}
            className={habitation.platform != 'EAA' ? 'col-sm-3' : 'col-sm-4'}
          />
        )}
        {isToDisplay('clothesline') && (
          <DisplayFixedFields
            titleId={'habitationForm.clotheslineText'}
            name={'clothesline'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}
      </div>
      <div className={'row'}>
        {isToDisplay('kitchenEquipment.description') && (
          <DisplayFixedFields
            titleId={'habitationForm.kitchenEquipmentText'}
            name={'kitchenEquipment.description'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('furniture.description') && (
          <DisplayFixedFields
            titleId={'habitationForm.furnitureText'}
            name={'furniture.description'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('parking.description') && (
          <DisplayFixedFields
            titleId={'habitationForm.parkingText'}
            name={'parking.description'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}
      </div>
      <div className={'row'}>
        {isToDisplay('energeticCertificate.description') && (
          <DisplayFixedFields
            titleId={'habitationForm.energeticCertificateText'}
            name={'energeticCertificate.description'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('conservationState.description') && (
          <DisplayFixedFields
            titleId={'habitationForm.conservationStateText'}
            name={'conservationState.description'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}
      </div>
      <div className={'row'}>
        {isToDisplay('providerNif') && (
          <DisplayFixedFields
            titleId={'habitationForm.providerNifText'}
            name={'providerNif'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('providerName') && (
          <DisplayFixedFields
            titleId={'habitationForm.providerNameText'}
            name={'providerName'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('modality.code') && (
          <DisplayFixedFields
            titleId={'habitationForm.modalityText'}
            name={'modality.name'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('maxRent') && (
          <DisplayFixedFields
            titleId={'habitationForm.maxRentText'}
            name={'maxRent'}
            object={habitation}
            suffixId='habitationForm.euroUnit'
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('platformCode') && (
          <DisplayFixedFields
            titleId={'habitationForm.platformCodeText'}
            name={'platformCode'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('certExpirationDate') && (
          <DisplayFixedFields
            titleId={'habitationForm.certExpirationDateText'}
            name={'certExpirationDate'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('certIssuedDate') && (
          <DisplayFixedFields
            titleId={'habitationForm.certIssuedDateText'}
            name={'certIssuedDate'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('bedroomArea') && (
          <DisplayFixedFields
            titleId={'habitationForm.bedroomAreaText'}
            name={'bedroomArea'}
            suffixId='habitationForm.squareAreaUnit'
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('totalBedroomsArea') && (
          <DisplayFixedFields
            titleId={'habitationForm.totalBedroomsAreaText'}
            name={'totalBedroomsArea'}
            object={habitation}
            suffixId='habitationForm.squareAreaUnit'
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('privateBathroom') && (
          <DisplayFixedFields
            titleId={'habitationForm.privateBathroomText'}
            name={'privateBathroom'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('representative') && (
          <DisplayFixedFields
            titleId={'habitationForm.representativeText'}
            name={'representative'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('matrixArticle') && (
          <DisplayFixedFields
            titleId={'habitationForm.matrixArticleText'}
            name={'matrixArticle'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('fraction') && (
          <DisplayFixedFields
            titleId={'habitationForm.fractionText'}
            name={'fraction'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('permitBuildingType.description') && (
          <DisplayFixedFields
            titleId={'habitationForm.permitBuildingTypeText'}
            name={'permitBuildingType.description'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('dependentGrossArea') && (
          <DisplayFixedFields
            titleId={'habitationForm.dependentGrossAreaText'}
            name={'dependentGrossArea'}
            object={habitation}
            suffixId='habitationForm.squareAreaUnit'
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('totalLandArea') && habitation.platform != 'EAA' && (
          <DisplayFixedFields
            titleId={'habitationForm.totalLandAreaText'}
            name={'totalLandArea'}
            object={habitation}
            suffixId='habitationForm.squareAreaUnit'
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('buildingArea') && (
          <DisplayFixedFields
            titleId={'habitationForm.buildingAreaText'}
            name={'buildingArea'}
            object={habitation}
            suffixId='habitationForm.squareAreaUnit'
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('fractionIntegratedLandArea') && (
          <DisplayFixedFields
            titleId={'habitationForm.fractionIntegratedLandAreaText'}
            name={'fractionIntegratedLandArea'}
            object={habitation}
            suffixId='habitationForm.squareAreaUnit'
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('fractionsIntegratedLandArea') && (
          <DisplayFixedFields
            titleId={'habitationForm.fractionsIntegratedLandAreaText'}
            name={'fractionsIntegratedLandArea'}
            object={habitation}
            suffixId='habitationForm.squareAreaUnit'
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('fractionAreaPct') && (
          <DisplayFixedFields
            titleId={'habitationForm.fractionAreaPctText'}
            name={'fractionAreaPct'}
            object={habitation}
            suffixId='habitationForm.squareAreaUnit'
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('platform') && (
          <DisplayFixedFields
            titleId={'habitationForm.platformText'}
            name={'platform'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('fractionCodePLGP') && (
          <DisplayFixedFields
            titleId={'habitationForm.fractionCodePLGPText'}
            name={'fractionCodePLGP'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}

        {isToDisplay('competitionCode') && (
          <DisplayFixedFields
            titleId={'habitationForm.competitionCodeText'}
            name={'competitionCode'}
            object={habitation}
            bold={true}
            className={'col-sm-3'}
          />
        )}
      </div>
    </div>
  );
}

function valueFromObject(path, obj) {
  var paths = path.split('.'),
    current = obj,
    i;

  for (i = 0; i < paths.length; ++i) {
    const v = current[paths[i]];
    if (typeof v !== 'number' && !v && typeof v !== 'boolean') {
      return undefined;
    } else {
      current = current[paths[i]];
    }
  }
  return current;
}

function DisplayFixedFields({
  titleId,
  name,
  object,
  suffixId,
  bold,
  className,
}) {
  const value = valueFromObject(name, object);
  const isBoolean = typeof value === 'boolean';
  if (typeof value !== 'number' && !value && !isBoolean) {
    return (
      <div className={className || 'col-sm-4'}>
        <label>
          <FormattedMessage id={titleId} />
        </label>

        <p>
          <i>
            {' '}
            <FormattedMessage id={'all.noInformation'} />
          </i>
        </p>
      </div>
    );
  }

  return (
    <div className={className || 'col-sm-4'}>
      <label>
        <FormattedMessage id={titleId} />
      </label>

      <p style={{ fontWeight: bold ? 'bolder' : 'normal' }}>
        {isBoolean && (value ? 'Sim' : 'Não')}
        {isBoolean || (
          <>
            {value}
            {suffixId && (
              <span className='ml-1'>
                <FormattedMessage id={suffixId} />
              </span>
            )}
          </>
        )}
      </p>
    </div>
  );
}

import { Formik } from 'formik';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { TranslateBoolean } from '../../utils/TranslateBoolean';
import { EditorInputField } from '../bootstrap/EditorInputField';

export function ActionNotificationForm({
  actionNotification,
  onSubmit,
  isUpdate,
}) {
  const schema = yup.object().shape({
    code: yup
      .string()
      .required(
        <FormattedMessage id='actionNotificationForm.code.required.message' />
      ),
    description: yup
      .string()
      .required(
        <FormattedMessage id='actionNotificationForm.description.required.message' />
      ),
    systemOperational: yup
      .string()
      .required(
        <FormattedMessage id='actionNotificationForm.systemOperational.required.message' />
      ),
    templateEmailTitle: yup
      .string()
      .test(
        'value-is-empty',
        <FormattedMessage id='actionNotificationForm.error.isEmpty.message' />,
        (value) => value !== '<p></p>\n'
      )
      .required(
        <FormattedMessage id='actionNotificationForm.template.required.message' />
      ),
    templateEmailBody: yup
      .string()
      .test(
        'value-is-empty',
        <FormattedMessage id='actionNotificationForm.error.isEmpty.message' />,
        (value) => value !== '<p></p>\n'
      )
      .required(
        <FormattedMessage id='actionNotificationForm.template.required.message' />
      ),
    templateInternalNoteTitle: yup
      .string()
      .test(
        'value-is-empty',
        <FormattedMessage id='actionNotificationForm.error.isEmpty.message' />,
        (value) => value !== '<p></p>\n'
      )
      .required(
        <FormattedMessage id='actionNotificationForm.template.required.message' />
      ),
    templateInternalNoteBody: yup
      .string()
      .test(
        'value-is-empty',
        <FormattedMessage id='actionNotificationForm.error.isEmpty.message' />,
        (value) => value !== '<p></p>\n'
      )
      .required(
        <FormattedMessage id='actionNotificationForm.template.required.message' />
      ),
  });

  return (
    <Formik
      initialValues={actionNotification}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        onSubmit(values, setSubmitting, setErrors);
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Form.Group controlId='code'>
            <Form.Label>
              <FormattedMessage id='actionNotification.codeText' />
            </Form.Label>
            <Form.Control
              type='text'
              name='code'
              value={values.code}
              onChange={handleChange}
              isInvalid={touched.code && errors.code}
              disabled={isUpdate}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.code}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId='description'>
            <Form.Label>
              <FormattedMessage id='actionNotification.descriptionText' />
            </Form.Label>
            <Form.Control
              type='text'
              name='description'
              value={values.description}
              isInvalid={touched.description && errors.description}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.description}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId='systemOperational'>
            <Form.Label>
              <FormattedMessage id='actionNotification.systemOperational' />
            </Form.Label>
            <label>
              <TranslateBoolean value={values.systemOperational} />
            </label>
          </Form.Group>

          <EditorInputField
            name='templateEmailTitle'
            labelId='actionNotification.templateEmailTitle'
            value={values.templateEmailTitle}
            handleChange={handleChange}
            isInvalid={touched.templateEmailTitle && errors.templateEmailTitle}
            errorMessage={errors.templateEmailTitle}
          ></EditorInputField>

          <EditorInputField
            name='templateEmailBody'
            labelId='actionNotification.templateEmailBody'
            value={values.templateEmailBody}
            handleChange={handleChange}
            isInvalid={touched.templateEmailBody && errors.templateEmailBody}
            errorMessage={errors.templateEmailBody}
          ></EditorInputField>

          <EditorInputField
            name='templateInternalNoteTitle'
            labelId='actionNotification.templateInternalNoteTitle'
            value={values.templateInternalNoteTitle}
            handleChange={handleChange}
            isInvalid={
              touched.templateInternalNoteTitle &&
              errors.templateInternalNoteTitle
            }
            errorMessage={errors.templateInternalNoteTitle}
          ></EditorInputField>

          <EditorInputField
            name='templateInternalNoteBody'
            labelId='actionNotification.templateInternalNoteBody'
            value={values.templateInternalNoteBody}
            handleChange={handleChange}
            isInvalid={
              touched.templateInternalNoteBody &&
              errors.templateInternalNoteBody
            }
            errorMessage={errors.templateInternalNoteBody}
          ></EditorInputField>

          <div className='form-actions'>
            <div className='mr-auto'>
              <Link to={'/templates'} variant='secondary'>
                <FormattedMessage id='all.backButtonText' />
              </Link>
            </div>
            <div className='ml-auto'>
              <Button variant='primary' type='submit'>
                <FormattedMessage id='all.submitButtonText' />
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

import React, { useEffect, useState } from 'react';
import { ComponentLoading } from '../../../../components/ComponentLoading';
import MessageFilterBar from '../../../../components/messages/MessageFilterBar';
import MessageList from '../../../../components/messages/MessageList';
import { PaginationComponent } from '../../../../components/PaginationComponent';
import { SubTemplate } from '../../../../components/SubTemplate';
import { MessageDirection } from '../../../../models/MessageDirection';
import { MessageType } from '../../../../models/MessageType';
import { getAdminMessages } from '../../../../rest/messages';
import { getPlatforms } from '../../../../rest/platform';
import { handleError, isNotBusinessError } from '../../../../utils/handleError';

const MAX_RESULTS = 15;
const DEFAULT_INITIAL_PAGE = 0;

export function ListMessages() {
  const [platforms, setPlatforms] = useState([]);
  const [messagesData, setMessagesData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);
  const [currentFilters, setCurrentFilters] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);

  const fetchPlatforms = async () => {
    try {
      const { data: platformsData } = await getPlatforms();
      setPlatforms(platformsData);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMessages = async (index) => {
    try {
      const { data: messagesData } = await getAdminMessages(
        null,
        null,
        MessageType.INTERNAL,
        MessageDirection.FROM_CANDIDATE,
        null,
        null,
        null,
        null,
        null,
        index,
        MAX_RESULTS
      );
      setMessagesData(messagesData);
      setCurrentPage(index);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlatforms();
    if (isFiltered) {
      filterMessages(currentFilters, currentPage);
    } else {
      fetchMessages(currentPage);
    }
  }, [currentPage]);

  const filterMessages = async (filters, index) => {
    setMessagesData({});
    setLoading(true);
    try {
      const { data: messagesData } = await getAdminMessages(
        filters.nif,
        filters.keyword,
        filters.type,
        filters.direction,
        filters.state,
        filters.platform,
        filters.parentExternalId,
        filters.competitionNumber,
        filters.region,
        index ? index : currentPage,
        MAX_RESULTS
      );
      setMessagesData(messagesData);
      setIsFiltered(true);
      setCurrentFilters(filters);
      setCurrentPage(index);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate hasBackButton titleId='messages.messagesText'>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            <MessageFilterBar
              isAdmin={true}
              defaultDirectionFilter={MessageDirection.FROM_CANDIDATE}
              platforms={platforms}
              onSubmit={filterMessages}
            />
            {loading ? (
              <ComponentLoading />
            ) : (
              <>
                <MessageList messages={messagesData.messages} />
                <PaginationComponent
                  dataPerPage={MAX_RESULTS}
                  totalData={messagesData.numberOfMessages}
                  onPageChange={onPageChange}
                  currentPage={currentPage}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

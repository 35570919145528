import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import Loading from '../../components/Loading';
import PlatformCard from '../../components/platform/PlatformCard';
import { useQuery } from '../../hooks/useQuery';
import { getPlatforms } from '../../rest/platform';
import { Arrays } from '../../utils/Arrays';
import {DeleteDialog} from "../../components/bootstrap/DeleteDialog";
import {GenericInformationDialog} from "../../components/bootstrap/GenericInformationDialog";
import {ConfigObject} from "../../config";

const hasCode = (query) => {
  return query && query.get('code');
};

function Home() {
  const [platforms, setPlatforms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showGeneric, setShowGeneric] = useState(true);

  const handleCloseGeneric = () => setShowGeneric(false);
  const handleShow = () => setShowGeneric(true);

  const query = useQuery();

  useEffect(() => {
    //If the query parameter has a code then it is part of the authentication process.
    if (hasCode(query)) {
      return;
    }

    async function fetchData() {
      try {
        const { data } = await getPlatforms();
        setPlatforms(data);
        setLoading(false);
      } catch (error) {
        if (error.response?.status === 401) {
          window.location.reload();
        }
        console.log(error);
      }
    }
    fetchData();
  }, []);

  if (hasCode(query)) {
    const code = query.get('code');
    return <Redirect to={`/login/nph/${code}`} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className={'bg-intro text-center'}>
        <p>
          <FormattedMessage id='home.headerTitle' />
        </p>
      </div>
      <div className={'container text-intro home'}>
        <div className={'row mb-5 pb-5 pt-5 border-bottom'}>
          <div className={'col-lg-8 offset-lg-2 mb-5 px-5'}>
            <h1>
              <FormattedMessage id='home.textTitle' />
            </h1>
            <div className={'intro-text pt-1'}>
              <p>
                <FormattedMessage
                  id='home.introductoryText1'
                  defaultMessage=' '
                  values={{
                    ihru: 'IHRU',
                    code: (ihru) => (
                      <a
                        href='http://www.ihru.pt/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        {ihru}
                      </a>
                    ),
                  }}
                />
              </p>
              <p className='mb-2'>
                <FormattedMessage id='home.introductoryText2' />
              </p>
              <p className='mb-2'>
                <FormattedMessage id='home.introductoryText3' />
              </p>
              <ul>
                <li className={'mb-2'}>
                  <strong>
                    <a href='https://paa.portaldahabitacao.pt/' target='blank'>
                      <FormattedMessage id='home.textPAA' />
                    </a>
                  </strong>
                </li>
                <li className={'mb-2'}>
                  <strong>
                    <a href='https://eaa.portaldahabitacao.pt/' target='blank'>
                      <FormattedMessage id='home.textEAA' />
                    </a>
                  </strong>
                </li>
                <li className={'mb-2'}>
                  <strong>
                    <a href='https://www.portaldahabitacao.pt/web/guest/procura-uma-casa-para-arrendar' target='blank'>
                      <FormattedMessage id='home.textPAS' />
                    </a>
                  </strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={'row mb-5 pt-3'}>
          <div className={'col-md-12 text-center'}>
            <h2 className={'mb-4'}>
              <FormattedMessage id='home.textCompetitions' />
            </h2>
            <h3 className={'font-weight-normal text-secondary'}>
              <FormattedMessage id='all.textHabitational' />
            </h3>
          </div>
        </div>
        <div className={'row justify-content-md-center'}>
          {platforms
            .filter((platform) => platform.type === 'HABITATIONAL')
            .map((platform, index) => (
              <PlatformCard key={index} platform={platform} />
            ))}
        </div>
        {!Arrays.isEmpty(
          platforms.filter((platform) => platform.type === 'OTHER')
        ) && (
          <div>
            <div className={'row justify-content-md-center'}>
              <div className={'col-md-4 mb-5 pb-5 border-bottom'}></div>
            </div>

            <div className={'row mb-5 pt-3'}>
              <div className={'col-md-12 text-center'}>
                <h3 className={'font-weight-normal text-secondary'}>
                  <FormattedMessage id='all.textOthers' />
                </h3>
              </div>
            </div>
            <div className={'row justify-content-md-center'}>
              {platforms
                .filter((platform) => platform.type === 'OTHER')
                .map((platform, index) => (
                  <PlatformCard key={index} platform={platform} />
                ))}
            </div>
          </div>
        )}
        {ConfigObject.get().genericMessageBody &&
              <GenericInformationDialog
                  show={showGeneric}
                  handleClose={handleCloseGeneric}
                  title={ConfigObject.get().genericMessageTitle}
                  body={ConfigObject.get().genericMessageBody}
                  confirmButtonMessage={ConfigObject.get().genericMessageButton}
              />
        }


      </div>
    </>
  );
}

export default Home;

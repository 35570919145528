import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

export function EnumSelectField({
  name,
  labelId,
  label,
  options,
  value,
  preffixDescriptionId,
  handleChange,
  isInvalid,
  errorMessage,
  nullOption,
  disabled,
  defaultValue,
  defaultOptionDisabled,
}) {
  const intl = useIntl();

  return (
    <Form.Group controlId={name}>
      <Form.Label>
        {labelId && <FormattedMessage id={labelId} />}
        {label && label}
      </Form.Label>
      <Form.Control
        as='select'
        name={name}
        onChange={(e) => handleChange(e)}
        value={value}
        isInvalid={isInvalid}
        disabled={disabled}
        defaultValue={defaultValue ?? "-1"}
      >
        {nullOption && (
          <option value={defaultValue ?? "-1"} disabled={defaultOptionDisabled}>{intl.formatMessage({ id: 'all.chooseOne' })}</option>
        )}
        {options.map((option) => (
          <option key={option} value={option}>
            {preffixDescriptionId
              ? intl.formatMessage({ id: `${preffixDescriptionId}.${option}` })
              : option}
          </option>
        ))}
      </Form.Control>
      {errorMessage && (
        <Form.Control.Feedback type='invalid'>
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import {
  hasPermission,
  isCandidate,
} from '../../../../authentication/authenticationHelper';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import { AlertSuccess } from '../../../../components/bootstrap/AlertSuccess';
import Loading from '../../../../components/Loading';
import { ReplyMessageDialog } from '../../../../components/messages/ReplyMessageDialog';
import { SubTemplate } from '../../../../components/SubTemplate';
import { useGlobalContext } from '../../../../context';
import { MessageDirection } from '../../../../models/MessageDirection';
import { MessageType } from '../../../../models/MessageType';
import { getMessage, replyMessage } from '../../../../rest/messages';
import {
  TranslateMessageDirectionFilter,
  TranslateMessageSubject,
  TranslateMessageType,
} from '../../../../utils/TranslateStates';

const showReplyButton = (accessInfo, message) => {
  if (message.type !== MessageType.INTERNAL) {
    return false;
  }

  if (
    isCandidate(accessInfo) &&
    message.direction === MessageDirection.TO_CANDIDATE
  ) {
    return true;
  }

  if (
    message.direction === MessageDirection.FROM_CANDIDATE &&
    hasPermission('REPLY_MESSAGE', accessInfo)
  ) {
    return true;
  }
  return false;
};

function MessageDetails() {
  const [messageDetails, setMessageDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const intl = useIntl();

  const { externalId, competitionCode } = useParams();

  const { accessInfo } = useGlobalContext();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const { data: message } = await getMessage(externalId);
        setMessageDetails(message);
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [externalId]);

  const onSendMessage = async (newMessage, parentMessage) => {
    setLoading(true);
    try {
      await replyMessage(parentMessage.externalId, newMessage);

      setSuccessMessage(
        intl.formatMessage({
          id: isCandidate(accessInfo)
            ? 'candidate.message.success'
            : 'backofficeuser.message.success',
        })
      );
      setShowReplyForm(false);
    } catch (error) {
      console.log(error);
      setSuccessMessage(null);
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <SubTemplate
      hasBackButton
      title={messageDetails.title ? parse(messageDetails.title) : 'No title'}
      subTitle={intl.formatMessage(
        { id: 'messages.detailsText' },
        { date: messageDetails.creationTs }
      )}
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-9'}>
            <AlertError error={error} />
            <AlertSuccess message={successMessage} />
            <div className={'form bg-white'}>
              <div className={'row'}>
                {messageDetails.personName != null && (
                  <div className={'col-lg-4'}>
                    <label>
                      <FormattedMessage id='messages.personNameText' />
                    </label>
                    <p>{messageDetails.personName}</p>
                  </div>
                )}
                {messageDetails.emailAddressFrom != null && (
                  <div className={'col-lg-4'}>
                    <label>
                      <FormattedMessage id='messages.fromText' />
                    </label>
                    <p>{messageDetails.emailAddressFrom}</p>
                  </div>
                )}
                {messageDetails.emailAddressTo != null && (
                  <div className={'col-lg-4'}>
                    <label>
                      <FormattedMessage id='messages.toText' />
                    </label>
                    <p>{messageDetails.emailAddressTo}</p>
                  </div>
                )}
                {messageDetails.mobile != null && (
                  <div className={'col-lg-4'}>
                    <label>
                      <FormattedMessage id='messages.mobileText' />
                    </label>
                    <p>{messageDetails.mobile}</p>
                  </div>
                )}
                {messageDetails.candidaturePath != null && (
                  <div className={'col-lg-6'}>
                    <label>
                      <FormattedMessage id='candidature.candidatureTitle' />
                    </label>
                    <p>
                      <Link to={messageDetails.candidaturePath}>
                        {messageDetails.candidatureCode} -{' '}
                        {messageDetails.competitionCode}
                      </Link>
                    </p>
                  </div>
                )}
                {messageDetails.nif != null && (
                  <div className={'col-lg-6'}>
                    <label>
                      <FormattedMessage id='candidature.nif' />
                    </label>
                    <p>{messageDetails.nif}</p>
                  </div>
                )}
              </div>
              <label>
                <FormattedMessage id='messages.subjectText' />
              </label>
              <p>
                <TranslateMessageSubject value={messageDetails.subject} />
              </p>
              <div className={'mt-4'}>
                <label>
                  <FormattedMessage id='messages.messageText' />
                </label>
                <p>
                  {messageDetails.body ? parse(messageDetails.body) : 'No body'}
                </p>
              </div>

              {messageDetails?.parentExternalId && (
                <div className={'mt-4'}>
                  <a
                    href={`/perfil/mensagem/${messageDetails.parentExternalId}`}
                  >
                    <FormattedMessage id='messages.previous.message' />
                  </a>
                </div>
              )}

              {messageDetails.messages.length > 0 && (
                <>
                  <legend className={'mt-4 mb-2'}>
                    <FormattedMessage id='messages.replies' />
                  </legend>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>
                          <label>
                            <FormattedMessage id='messages.titleText' />
                          </label>
                        </th>
                        <th>
                          <label>
                            <FormattedMessage id='messages.directionText' />
                          </label>
                        </th>
                        <th>
                          <label>
                            <FormattedMessage id='messages.subjectText' />
                          </label>
                        </th>
                        <th>
                          <label>
                            <FormattedMessage id='messages.typeText' />
                          </label>
                        </th>
                        <th>
                          <label>
                            <FormattedMessage id='messages.dateText' />
                          </label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {messageDetails.messages.map((message) => (
                        <tr key={message.externalId}>
                          <td>
                            <Link
                              to={`/perfil/mensagem/${message.externalId}`}
                              style={{
                                fontWeight:
                                  message?.state === 'PENDING'
                                    ? 'bolder'
                                    : 'normal',
                              }}
                            >
                              {message.title
                                ? parse(message.title)
                                : 'No title'}
                            </Link>
                          </td>
                          <td>
                            {' '}
                            {TranslateMessageDirectionFilter(
                              message.direction
                            )}{' '}
                          </td>
                          <td>
                            <TranslateMessageSubject value={message.subject} />
                          </td>
                          <td>
                            <TranslateMessageType value={message.type} />
                          </td>
                          <td>{message.creationTs}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>

            {showReplyForm && (
              <ReplyMessageDialog
                message={messageDetails}
                onSendMessage={onSendMessage}
                handleClose={() => setShowReplyForm(false)}
              />
            )}

            <div className='form-actions'>
              <div className='mr-auto'>
                <Link
                  to={
                    accessInfo.type === 'keycloak'
                      ? '/mensagens'
                      : '/perfil/mensagens'
                  }
                  variant='secondary'
                >
                  <FormattedMessage id='all.backButtonText' />
                </Link>
              </div>

              {!showReplyForm && showReplyButton(accessInfo, messageDetails) && (
                <div className='ml-auto'>
                  <button
                    className={'btn btn-outline-primary'}
                    type='button'
                    onClick={() => setShowReplyForm(true)}
                  >
                    <FormattedMessage id='all.reply' />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default MessageDetails;

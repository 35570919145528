import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { getByKeyword } from '../../rest/eligibilityCondition';
import { ClickableConditionLabel } from './ClickableConditionLabel';

export function SearchConditions({ onClickCondition }) {
  const [eligibilityConditions, setEligityConditions] = useState([]);
  const [search, setSearch] = useState([]);

  const isShowNoResults = () => {
    return search && search.length >= 3 && eligibilityConditions.length <= 0;
  };

  const handleOnClick = (condition) => {
    onClickCondition(condition);
    setEligityConditions([]);
    setSearch('');
  };

  useEffect(() => {
    const searchConditions = async () => {
      try {
        const { data } = await getByKeyword(search);
        setEligityConditions(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (search && search.length >= 3) {
      searchConditions();
    } else {
      setEligityConditions([]);
    }
  }, [search]);

  return (
    <div className='col-md-6'>
      <Form.Group controlId='endRegistrationDate'>
        <Form.Label>
          <FormattedMessage id='competitionForm.searchCondition' />
        </Form.Label>
        <Form.Control
          type='text'
          name='searchInput'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Form.Group>
      {isShowNoResults() && (
        <div className='position-relative'>
          <div className='no-results'>
            <FormattedMessage id='info.noResult' />
          </div>
        </div>
      )}
      {!isShowNoResults() && eligibilityConditions.length > 0 && (
        <div className='position-relative'>
          <div className='search-results'>
            {eligibilityConditions.map((eli, index) => {
              return (
                <ClickableConditionLabel
                  onClickCondition={handleOnClick}
                  key={index}
                  condition={eli}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchConditions;

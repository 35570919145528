import axios from 'axios';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  FaCheck,
  FaPencilAlt,
  FaRegBell,
  FaRegFileAlt,
  FaTimes,
} from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { saveInStorageAccessInfo } from '../../../authentication/authenticationHelper';
import { SubTemplate } from '../../../components/SubTemplate';
import { useGlobalContext } from '../../../context';

function UserProfile() {
  const { accessInfo } = useGlobalContext();
  const [editEmail, setEditEmail] = useState(false);
  const [email, setEmail] = useState(accessInfo.email);

  const handleEdit = () => setEditEmail(true);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const intl = useIntl();

  const updateEmail = async () => {
    try {
      await axios({
        headers: {
          Authorization: 'Bearer ' + accessInfo.access_token,
          'tenant-id': 'tenant-a',
        },
        method: 'put',
        url: `/api/user`,
        data: {
          nif: accessInfo.nif,
          email: email,
        },
      });
      const userInfo = JSON.parse(localStorage.getItem('access_info'));
      userInfo.email = email;
      saveInStorageAccessInfo(userInfo);
      accessInfo.email = email;

      setEditEmail(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SubTemplate hasBackButton titleId='userProfile.profileText'>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-md-9'}>
            <div className={'form'}>
              <label>
                <FormattedMessage id='userProfile.nameText' />
              </label>
              <p className={'font-weight-bold'}>{accessInfo.name}</p>
              <label>
                <FormattedMessage id='userProfile.NIFText' />
              </label>
              <p className={'font-weight-bold'}>{accessInfo.nif}</p>
              <label>
                <FormattedMessage id='userProfile.emailText' />
              </label>
              {!editEmail ? (
                <p className={'font-weight-bold'}>{accessInfo.email}</p>
              ) : (
                <Form.Group controlId='code'>
                  <Form.Control
                    type='text'
                    placeholder={intl.formatMessage({
                      id: 'userProfile.emailText',
                    })}
                    name='email'
                    value={email}
                    onChange={handleChange}
                  />
                </Form.Group>
              )}
            </div>
          </div>
          <div className={'col-md-3 side-menu'}>
            {!editEmail ? (
              <div className='mx-2 mb-4'>
                <Link
                  to={`/perfil/candidaturasUtilizador`}
                  className={'btn btn-primary mb-3'}
                >
                  <FaRegFileAlt />
                  <FormattedMessage id='candidatureUser.candidatureText' />
                </Link>
                <Link
                  to={`/perfil/mensagens`}
                  className={'btn btn-outline-primary mb-3'}
                >
                  <FaRegBell />
                  <FormattedMessage id='messages.messagesText' />
                </Link>
                <button
                  onClick={handleEdit}
                  className={'btn btn-outline-primary mb-3'}
                >
                  <FaPencilAlt />
                  <FormattedMessage id='userProfile.editText' />
                </button>
              </div>
            ) : (
              <div className='mx-2 mb-4'>
                <button
                  onClick={() => updateEmail()}
                  className={'btn btn-outline-primary mb-3'}
                >
                  <FaCheck />
                  <FormattedMessage id='userProfile.saveEmailText' />
                </button>
                <button
                  onClick={() => setEditEmail(false)}
                  className={'btn btn-outline-secondary mb-3'}
                >
                  <FaTimes />
                  <FormattedMessage id='all.cancelText' />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default UserProfile;

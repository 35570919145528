import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import { CandidatureFilterBar } from '../../../../components/candidature/CandidatureFilterBar';
import { ComponentLoading } from '../../../../components/ComponentLoading';
import { EmptyResults } from '../../../../components/EmptyResults';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { getCandidatureUser } from '../../../../rest/candidatureuser';
import { handleError, isNotBusinessError } from '../../../../utils/handleError';
import {
  TranslateCandidatureState,
  TranslateCompetitionState,
} from '../../../../utils/TranslateStates';

function CandidaturesUser() {
  const [candidatures, setCandidatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const { data: filteredCandidatures } = await getCandidatureUser(true);

      setCandidatures(filteredCandidatures);
    } catch (error) {
      setError(error);
    } finally {
      setSearch(false);
      setLoading(false);
    }
  };

  const filterCandidatures = async (active) => {
    setSearch(true);

    try {
      const { data: filteredCandidatures } = await getCandidatureUser(active);

      setCandidatures(filteredCandidatures);
    } catch (error) {
      setError(error);
    } finally {
      setSearch(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate hasBackButton titleId='candidatureUser.candidatureText'>
      <div className={'container'}>
        <div className={'row'}>
          <div className='col-lg-12'>
            <AlertError error={error} />
            <CandidatureFilterBar onSubmit={filterCandidatures} />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            {search ? (
              <ComponentLoading />
            ) : candidatures.length ? (
              <table className='table'>
                <thead>
                  <tr>
                    <th>
                      <label>
                        <FormattedMessage id='candidatureUser.candidatureCodeText' />
                      </label>
                    </th>
                    <th>
                      <label>
                        <FormattedMessage id='candidatureUser.candidatureStateText' />
                      </label>
                    </th>
                    <th>
                      <label>
                        <FormattedMessage id='candidatureUser.competitionCodeText' />
                      </label>
                    </th>
                    <th>
                      <label>
                        <FormattedMessage id='candidatureUser.competitionStateText' />
                      </label>
                    </th>
                    <th>
                      <label>
                        <FormattedMessage id='candidatureUser.platformText' />
                      </label>
                    </th>
                    <th>
                      <label>
                        <FormattedMessage id='candidatureUser.dateText' />
                      </label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {candidatures.map((candidature) => (
                    <tr>
                      <td>{candidature.candidatureCode}</td>
                      <td>
                        <Link
                          to={`/programas/${encodeURIComponent(
                            candidature.platformCode
                          )}/concursos/${encodeURIComponent(
                            candidature.competitionCode
                          )}/candidatura/${candidature.externalId}`}
                        >
                          <TranslateCandidatureState
                            state={candidature.candidatureState}
                          />
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/programas/${encodeURIComponent(
                            candidature.platformCode
                          )}/concursos/${encodeURIComponent(
                            candidature.competitionCode
                          )}`}
                          variant='secondary'
                        >
                          {candidature.competitionCode}
                        </Link>
                      </td>
                      <td>
                        <TranslateCompetitionState
                          value={candidature.competitionState}
                        />
                      </td>
                      <td>
                        <Link
                          to={`/programas/${encodeURIComponent(
                            candidature.platformCode
                          )}/concursos`}
                          variant='secondary'
                        >
                          {candidature.platformCode}
                        </Link>
                      </td>
                      <td>{candidature.stateModifiedTs}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <EmptyResults />
            )}
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default CandidaturesUser;

import React, { useEffect, useState } from 'react';
import { getTermsAndConditions } from '../rest/termsAndConditions';
import { handleError, isBusinessError } from '../utils/handleError';
import { ComponentLoading } from './ComponentLoading';

function isAccepted(code, acceptedConditions) {
  return acceptedConditions.find((condition) => {
    return condition.code === code;
  }).value;
}

export function TermsAndConditions({ setValue, value, isDisplay, platformCode }) {
  const [termsAndConditions, setTermsAndConditions] = useState(
    isDisplay ? value : null
  );
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [acceptedConditions, setAcceptedConditions] = useState(value);

  useEffect(() => {
    const fetchTermsAndConditions = async () => {
      try {
        const { data: tncs } = await getTermsAndConditions(platformCode);

        if (!acceptedConditions) {
          setAcceptedConditions(
            tncs.map((tnc) => {
              return { code: tnc.code, value: false };
            })
          );
        } else {
          tncs.forEach((tnc) => {
            if (!acceptedConditions.some((x) => x.code === tnc.code)) {
              acceptedConditions.push({ code: tnc.code, value: false });
            }
          });
          setAcceptedConditions([...acceptedConditions]);
        }
        setTermsAndConditions(tncs);
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    if (!isDisplay) {
      fetchTermsAndConditions();
    } else {
      setLoading(false);
    }
  }, []);

  const handleOnAcceptCondition = (condition) => {
    acceptedConditions.find((c) => c.code === condition.code).value =
      !isAccepted(condition.code, acceptedConditions);
    setAcceptedConditions([...acceptedConditions]);
    setValue(acceptedConditions);
  };

  if (loading) {
    return <ComponentLoading />;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  return (
    <div>
      {termsAndConditions.map((condition) => {
        return (
          <div className='row mt-1' key={condition.code}>
            <div className='col-display-contents'>
              <input
                onChange={() => handleOnAcceptCondition(condition)}
                checked={isAccepted(condition.code, acceptedConditions)}
                type='checkbox'
                disabled={isDisplay}
              />
            </div>
            <div className='col md-9'>
              <label>{condition.description}</label>
            </div>
          </div>
        );
      })}
    </div>
  );
}

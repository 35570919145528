import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

function Annunnciation({ competition, platformCode }) {
  return (
    <li>
      <div className={'row my-4'}>
        <div className={'col-md-12'}>
          <Link
            to={`/programas/${platformCode}/concursos/${encodeURIComponent(
              competition.code
            )}`}
          >
            <FormattedMessage id='annunciations.competition' />
            {competition.code}
          </Link>
          <span className={'text-secondary'}>
            {' - '}
            <FormattedMessage id={`competitionState.${competition.state}`} />
          </span>
          <small className={'text-secondary d-block'}>
            <div className='result-legend'>
              <FormattedMessage id='competitions.registrationDeadline' />
            </div>
            <FormattedMessage id='annunciations.startText' />
            <strong>{competition.startRegistrationDate}</strong>
            <span className={'mx-2'}>·</span>
            <FormattedMessage id='annunciations.endText' />
            <strong>{competition.endRegistrationDate}</strong>
          </small>
        </div>
      </div>
    </li>
  );
}

export default Annunnciation;

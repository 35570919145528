import { Formik } from 'formik';
import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

function DrawDialog({ handleCloseDrawModal, showModalDraw, handleStartDraw }) {
  const EMPTY_DRAW_REQUEST = {
    cadence: 0,
  };
  const intl = useIntl();

  let schema = yup.object().shape({
    cadence: yup.number().required(),
  });
  return (
    <div>
      <Modal show={showModalDraw} onHide={handleCloseDrawModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='draw.start.text' />
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={EMPTY_DRAW_REQUEST}
          validationSchema={schema}
          validate={(values) => {
            const errors = {};
            if (
              !values.cadence ||
              values.cadence === null ||
              values.cadence < 1
            ) {
              errors.cadence = intl.formatMessage({
                id: 'draw.error.emptyCadence',
              });
            }
            return errors;
          }}
          onSubmit={(cadence, { setSubmitting, setErrors }) => {
            handleStartDraw(cadence, setSubmitting, setErrors);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId='cadence'>
                  <Form.Label>
                    <FormattedMessage id='draw.cadence.form' />
                  </Form.Label>
                  <Form.Control
                    type='number'
                    name='cadence'
                    value={values.cadence}
                    onChange={handleChange}
                    isInvalid={touched.cadence && errors.cadence}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.cadence}
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <button
                  className='btn btn-link mr-auto'
                  onClick={handleCloseDrawModal}
                  type='button'
                >
                  <FormattedMessage id='all.cancelText' />
                </button>

                <button
                  className={'btn btn-primary ml-auto'}
                  type='submit'
                  disabled={isSubmitting}
                >
                  <FormattedMessage id='all.start' />
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

export default DrawDialog;

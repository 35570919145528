import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export function TextAreaInputField({
  name,
  value,
  handleChange,
  labelId,
  label,
  errorMessage,
  isInvalid,
  disabled,
}) {
  return (
    <Form.Group controlId={name}>
      <Form.Label>
        {label && label}
        {labelId && <FormattedMessage id={labelId} />}
      </Form.Label>
      <Form.Control
        as='textarea'
        rows={4}
        name={name}
        value={value}
        onChange={handleChange}
        isInvalid={isInvalid}
        disabled={disabled}
      />
      <Form.Control.Feedback type='invalid'>
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

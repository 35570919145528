import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { WithdrawalResignDialog } from './WithdrawalResignDialog';

export function ConfirmWithdrawalDialog({ show, handleClose, handleConfirm }) {
  const [showWithdrawalResignDialog, setShowWithdrawalResignDialog] =
    useState(false);

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='candidature.dialog.confirmWithdrawal.text' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id='candidature.dialog.confirmWithdrawal.question' />
        </Modal.Body>

        <Modal.Footer>
          <button
            className='btn btn-link mr-auto'
            onClick={handleClose}
            type='button'
          >
            <FormattedMessage id='all.cancelText' />
          </button>

          <button
            className={'btn btn-primary'}
            type='submit'
            onClick={() => handleConfirm({ flag: true })}
          >
            <FormattedMessage id='all.yesText' />
          </button>

          <button
            className={'btn btn-primary'}
            type='submit'
            onClick={() => setShowWithdrawalResignDialog(true)}
          >
            <FormattedMessage id='all.noText' />
          </button>
        </Modal.Footer>
      </Modal>
      <WithdrawalResignDialog
        show={showWithdrawalResignDialog}
        handleClose={() => setShowWithdrawalResignDialog(false)}
        handleConfirm={handleConfirm}
        titleId='candidature.resign.button'
        actionId='candidature.resign.action'
        reasonId='messages.resign.reason'
      />
    </div>
  );
}

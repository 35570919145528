import { useIntl } from 'react-intl';

export function TranslateCandidatureState({ state }) {
  const intl = useIntl();
  return intl.formatMessage({ id: 'CandidatureStateTitle.' + state });
}

export function TranslateCompetitionState(state) {
  const intl = useIntl();

  return intl.formatMessage({ id: `competitionState.${state.value}` });
}

export function TranslateMessageState(props) {
  const intl = useIntl();
  switch (props.value) {
    case 'PENDING':
      return intl.formatMessage({
        id: 'messages.pendingText',
      });
    case 'SENT':
      return intl.formatMessage({
        id: 'messages.sentText',
      });
    case 'REPLIED':
      return intl.formatMessage({
        id: 'messages.repliedText',
      });
    case 'ERROR':
      return intl.formatMessage({
        id: 'messages.errorText',
      });
    default:
      return props.value;
  }
}

export function TranslateMessageStateInternal(props) {
  const intl = useIntl();
  switch (props.value) {
    case 'PENDING':
      return intl.formatMessage({
        id: 'messages.notRead',
      });
    case 'SENT':
      return intl.formatMessage({
        id: 'messages.read',
      });
    case 'REPLIED':
      return intl.formatMessage({
        id: 'messages.repliedText',
      });
    case 'ERROR':
      return intl.formatMessage({
        id: 'messages.errorText',
      });
    default:
      return props.value;
  }
}

export function TranslateMessageStateFilter(props) {
  const intl = useIntl();
  switch (props) {
    case 'PENDING':
      return intl.formatMessage({
        id: 'messages.pendingText',
      });
    case 'SENT':
      return intl.formatMessage({
        id: 'messages.sentText',
      });
    case 'REPLIED':
      return intl.formatMessage({
        id: 'messages.repliedText',
      });
    case 'ERROR':
      return intl.formatMessage({
        id: 'messages.errorText',
      });
    default:
      return props;
  }
}

export function TranslateMessageSubject(props) {
  const intl = useIntl();
  switch (props.value) {
    case 'GENERAL':
      return intl.formatMessage({
        id: 'messages.generalText',
      });
    case 'CANDIDATURE':
      return intl.formatMessage({
        id: 'messages.candidatureText',
      });
    case 'OTHER':
      return intl.formatMessage({
        id: 'messages.otherText',
      });
    default:
      return props.value;
  }
}

export function TranslateMessageSubjectFilter(props) {
  const intl = useIntl();
  switch (props) {
    case 'PAA':
      return intl.formatMessage({
        id: 'messages.programPAASubjectText',
      });
    case 'PAS':
      return intl.formatMessage({
        id: 'messages.programPASSubjectText',
      });
    case 'EAA':
      return intl.formatMessage({
        id: 'messages.programEAASubjectText',
      });
    case 'NOPROGRAM':
      return intl.formatMessage({
        id: 'messages.noProgramSubjectText',
      });
    case 'GENERAL':
      return intl.formatMessage({
        id: 'messages.generalText',
      });
    case 'CANDIDATURE':
      return intl.formatMessage({
        id: 'messages.candidatureText',
      });
    case 'OTHER':
      return intl.formatMessage({
        id: 'messages.otherText',
      });
    default:
      return props;
  }
}

export function TranslateMessageType(props) {
  const intl = useIntl();
  switch (props.value) {
    case 'INTERNAL':
      return intl.formatMessage({
        id: 'messages.internalText',
      });
    case 'EMAIL':
      return intl.formatMessage({
        id: 'messages.emailText',
      });
    default:
      return props.value;
  }
}

export function TranslateMessageTypeFilter(props) {
  const intl = useIntl();
  switch (props) {
    case 'INTERNAL':
      return intl.formatMessage({
        id: 'messages.internalText',
      });
    case 'EMAIL':
      return intl.formatMessage({
        id: 'messages.emailText',
      });
    default:
      return props;
  }
}

export function TranslateMessageDirectionFilter(props) {
  const intl = useIntl();
  switch (props) {
    case 'TO_CANDIDATE':
      return intl.formatMessage({
        id: 'messages.toCandidateText',
      });
    case 'FROM_CANDIDATE':
      return intl.formatMessage({
        id: 'messages.fromCandidateText',
      });
    default:
      return props;
  }
}

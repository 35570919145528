import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import Loading from '../../../components/Loading';
import { SubTemplate } from '../../../components/SubTemplate';
import { MessageRegion } from '../../../models/MessageRegion';
import { getSubjects, sendPublicMessage } from '../../../rest/messages';

const EMPTY_CLARIFICATION_REQUEST = {
  title: '',
  personName: '',
  body: '',
  emailAddressFrom: '',
  mobile: '',
  subject: '',
  region: '',
  competitionNumber: '',
};

export function ClarificationRequest() {
  const [message, setMessage] = useState({
    show: false,
    type: 'success',
    message: '',
  });

  const [loading, setLoading] = useState(true);
  const [subjects, setSubjects] = useState([]);
  const [hideForm, setHideForm] = useState(false);

  const intl = useIntl();

  const handleSubmitting = async (values, setSubmitting, setErrors) => {
    try {
      if (values.subject !== 'OTHER') {
        values.title = values.subject;
      }
      await sendPublicMessage(values);
      setSubmitting(false);
      setMessage({
        show: true,
        type: 'success',
        message: intl.formatMessage({
          id: 'clarificationRequestForm.successMessageSent.text',
        }),
      });
      setHideForm(true);
    } catch (error) {
      if (error.response?.status === 500) {
        setMessage({
          show: true,
          type: 'danger',
          message: intl.formatMessage({ id: 'all.unexpectedErrorMessage' }),
        });
      }

      setSubmitting(false);
    }
  };

  const fetchSubjects = async () => {
    try {
      const { data } = await getSubjects();
      setSubjects(data);
      setLoading(false);
    } catch (error) {
      setSubjects([]);
      setLoading(false);
    }
  };

  let schema = yup.object().shape({
    title: yup.string(),
    personName: yup
      .string()
      .required(
        intl.formatMessage({ id: 'clarificationRequestForm.error.emptyName' })
      ),
    body: yup
      .string()
      .required(
        intl.formatMessage({ id: 'clarificationRequestForm.error.emptyBody' })
      ),
    emailAddressFrom: yup.string().email(
      intl.formatMessage({
        id: 'clarificationRequestForm.error.invalidEmail',
      })
    ),
    mobile: yup.string(),
    subject: yup.string().required(
      intl.formatMessage({
        id: 'clarificationRequestForm.error.selectSubject',
      })
    ),
    competitionNumber: yup.string(),
    region: yup.string().required(
      intl.formatMessage({
        id: 'clarificationRequestForm.error.selectRegion',
      })
    ),
  });

  useEffect(() => {
    fetchSubjects();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <SubTemplate hasBackButton titleId={'clarificationRequestForm.titleText'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-8 offset-lg-2 mb-5'}>
            {message.show && (
              <Alert variant={message.type}>{message.message}</Alert>
            )}
            {!hideForm && (
              <Formik
                initialValues={EMPTY_CLARIFICATION_REQUEST}
                validationSchema={schema}
                validate={(values) => {
                  const errors = {};
                  if (!values.emailAddressFrom) {
                    errors.emailAddressFrom = intl.formatMessage({
                      id: 'clarificationRequestForm.error.emptyEmail',
                    });
                  }
                  if (values.subject === 'OTHER' && !values.title) {
                    errors.title = intl.formatMessage({
                      id: 'clarificationRequestForm.error.emptyTitle',
                    });
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                  handleSubmitting(values, setSubmitting, setErrors);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <div className='row'>
                      <div className='col-md-12 contact-text mb-4'>
                        <h5 className='mb-4 pb-2 border-bottom'>
                          Formulário de contacto
                        </h5>
                        <div>
                          <FormattedMessage
                            id={'clarificationRequestForm.subTitle1'}
                          />
                        </div>
                        <div>
                          <FormattedMessage
                            id={'clarificationRequestForm.subTitle2'}
                          />
                        </div>
                        <div>
                          <FormattedMessage
                            id={'clarificationRequestForm.subTitle4'}
                          />{' '}
                          <a
                            href='https://www.portaldahabitacao.pt/web/guest/contacte-nos'
                            target='blank'
                          >
                            <FormattedMessage
                              id={'clarificationRequestForm.portalContacts'}
                            />
                          </a>
                          .
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                        <Form.Group controlId='subject'>
                          <Form.Label>
                            <FormattedMessage id='clarificationRequestForm.subjectText' />
                          </Form.Label>
                          <Form.Control
                            as='select'
                            name='subject'
                            value={values.subject}
                            onChange={handleChange}
                            isInvalid={touched.subject && errors.subject}
                            custom
                          >
                            <option>
                              {intl.formatMessage({
                                id: 'clarificationRequestForm.selectSubject',
                              })}
                            </option>
                            {subjects.map((subject) => {
                              return (
                                <option key={subject} value={subject}>
                                  {intl.formatMessage({
                                    id: `clarificationRequestForm.subject_${subject}`,
                                  })}
                                </option>
                              );
                            })}
                          </Form.Control>
                          <Form.Control.Feedback type='invalid'>
                            {errors.subject}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      {values.subject === 'OTHER' && (
                        <div className='col-md-12'>
                          <Form.Group controlId='title'>
                            <Form.Label>
                              <FormattedMessage id='clarificationRequestForm.subjectDescriptionText' />
                            </Form.Label>
                            <Form.Control
                              type='text'
                              name='title'
                              value={values.title}
                              onChange={handleChange}
                              isInvalid={touched.title && errors.title}
                              disabled={values.subject !== 'OTHER'}
                              className={
                                values.subject !== 'OTHER' && 'disabled'
                              }
                            />
                            <Form.Control.Feedback type='invalid'>
                              {errors.title}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      )}

                      <div className='col-md-12'>
                        <Form.Group controlId='personName'>
                          <Form.Label>
                            <FormattedMessage id='clarificationRequestForm.personNameText' />
                          </Form.Label>
                          <Form.Control
                            type='text'
                            name='personName'
                            value={values.personName}
                            onChange={handleChange}
                            isInvalid={touched.personName && errors.personName}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {errors.personName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className='col-md-6'>
                        <Form.Group controlId='emailAddressFrom'>
                          <Form.Label>
                            <FormattedMessage id='clarificationRequestForm.emailText' />
                          </Form.Label>
                          <Form.Control
                            type='text'
                            name='emailAddressFrom'
                            value={values.emailAddressFrom}
                            onChange={handleChange}
                            isInvalid={
                              touched.emailAddressFrom &&
                              errors.emailAddressFrom
                            }
                          />
                          <Form.Control.Feedback type='invalid'>
                            {errors.emailAddressFrom}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className='col-md-6'>
                        <Form.Group controlId='mobile'>
                          <Form.Label>
                            <FormattedMessage id='clarificationRequestForm.mobileText' />
                          </Form.Label>
                          <Form.Control
                            type='phonenumber'
                            name='mobile'
                            value={values.mobile}
                            onChange={handleChange}
                            isInvalid={touched.mobile && errors.mobile}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {errors.mobile}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group controlId='competitionNumber'>
                          <Form.Label>
                            <FormattedMessage id='clarificationRequestForm.competitionNumberText' />
                          </Form.Label>
                          <Form.Control
                            type='text'
                            name='competitionNumber'
                            value={values.competitionNumber}
                            onChange={handleChange}
                            isInvalid={
                              touched.competitionNumber &&
                              errors.competitionNumber
                            }
                          />
                          <Form.Control.Feedback type='invalid'>
                            {errors.competitionNumber}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className='col-md-6'>
                        <Form.Group controlId='region'>
                          <Form.Label>
                            <FormattedMessage id='clarificationRequestForm.regionText' />
                          </Form.Label>
                          <Form.Control
                            as='select'
                            name='region'
                            value={values.region}
                            onChange={handleChange}
                            isInvalid={touched.region && errors.region}
                            custom
                          >
                            <option>
                              {intl.formatMessage({
                                id: 'clarificationRequestForm.selectRegion',
                              })}
                            </option>
                            {MessageRegion.values().map((region) => {
                              return (
                                <option key={region} value={region}>
                                  {intl.formatMessage({
                                    id: `clarificationRequestForm.region_${region}`,
                                  })}
                                </option>
                              );
                            })}
                          </Form.Control>
                          <Form.Control.Feedback type='invalid'>
                            {errors.region}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                        <Form.Group controlId='body'>
                          <Form.Label>
                            <FormattedMessage id='clarificationRequestForm.bodyText' />
                          </Form.Label>
                          <Form.Control
                            as='textarea'
                            rows={4}
                            name='body'
                            value={values.body}
                            onChange={handleChange}
                            isInvalid={touched.body && errors.body}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {errors.body}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <div className='contact-text'>
                          <div>
                            <FormattedMessage id='clarificationRequestForm.footerText' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-actions'>
                      <div className='ml-auto'>
                        <button
                          className={'btn btn-outline-primary'}
                          type='submit'
                          disabled={isSubmitting}
                        >
                          <FormattedMessage id='all.submitButtonText' />
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

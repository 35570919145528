import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

export function SelectBooleanField({
  name,
  labelId,
  label,
  isInvalid,
  errorMessage,
  setFieldValue,
  value,
  nullOption,
  onChange
}) {
  const intl = useIntl();

  useEffect(() => {
    if (!nullOption && !value && setFieldValue) {
      setFieldValue(name, 'true');
    }
  }, []);

  function handleChange(e) {
    if (setFieldValue) {
      setFieldValue(name, e.target.value === '-1' ? null : e.target.value);
    }

    if (onChange) {
      onChange(e.target.value === '-1' ? null : e.target.value);
    }
  }

  return (
    <Form.Group controlId={name}>
      <Form.Label>
        {labelId && <FormattedMessage id={labelId} />}
        {label && label}
      </Form.Label>
      <Form.Control
        as='select'
        name={name}
        onChange={(e) => handleChange(e)}
        value={value}
        isInvalid={isInvalid}>
        {nullOption && <option value='-1'>{intl.formatMessage({ id: 'all.chooseOne' })}</option>}
        <option value='true'>{intl.formatMessage({ id: 'all.yesText' })}</option>
        <option value='false'>{intl.formatMessage({ id: 'all.noText' })}</option>
      </Form.Control>
      {errorMessage && <Form.Control.Feedback type='invalid'>{errorMessage}</Form.Control.Feedback>}
    </Form.Group>
  );
}

import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { Redirect, useParams } from 'react-router-dom';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import FaqForm from '../../../../components/faqs/FaqForm';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { deleteFaqAPI, getFaq, updateFaq } from '../../../../rest/faq';
import { handleError, isNotBusinessError } from '../../../../utils/handleError';

function EditFaq() {
  const [submited, setSubmited] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [faq, setFaq] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const { externalId } = useParams();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const { data: faq } = await getFaq(externalId);
        setFaq(faq);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchInitialData();
  }, [externalId]);

  const saveFaq = async (faqToSave, setSubmitting) => {
    try {
      await updateFaq(externalId, faqToSave);
      setSubmited(true);
      setSubmitting(false);
      setLoading(false);
    } catch (error) {
      setSubmitting(false);
      setError(error);
      setLoading(false);
    }
  };

  async function deleteFaq(externalId) {
    try {
      await deleteFaqAPI(externalId);
      handleClose();
      setDeleted(true);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  if (deleted) {
    return <Redirect to={`/perguntas/${faq.platform}`} />;
  }
  if (submited) {
    return <Redirect to={`/perguntas/${faq.platform}`} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate hasBackButton titleId={'editFaqs.editFaqText'}>
      <div className={'container'}>
        <div className={'row'}>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id='editFaqs.deleteFaqText' />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormattedMessage id='editFaqs.deleteFaqQuestionText' />
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                <FormattedMessage id='all.cancelText' />
              </Button>
              <Button
                variant='primary'
                onClick={() => deleteFaq(faq.externalId)}
              >
                <FormattedMessage id='all.deleteText' />
              </Button>
            </Modal.Footer>
          </Modal>
          <div className={'col-lg-8 offset-lg-2'}>
            <AlertError error={error} />
            <FaqForm faq={faq} save={saveFaq} />
            <div className={'row mt-5'}>
              <div className={'col-md-12'}>
                <button
                  onClick={handleShow}
                  className={'btn btn-outline-primary'}
                >
                  <FaTrashAlt /> <FormattedMessage id='all.deleteText' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default EditFaq;

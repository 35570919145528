import { apiAxios as authAxios } from '../axios.config';

export const getCandidaturesByCompetition = (
  competitionCode,
  platformCode,
  index,
  size,
  state,
  sortFilters,
  inputFilter
) => {
  return authAxios.get(
    `/api/platform/${platformCode}/competitions/${competitionCode}/candidatures`,
    {
      params: {
        index: index,
        size: size,
        state: state,
        sortFilters: sortFilters ? constructSortFiltersString(sortFilters) : '',
        searchQuery: inputFilter ? inputFilter : '',
      },
    }
  );
};

export const getAdmittedCandidaturesByCompetition = (
  competitionCode,
  platformCode,
  index,
  size
) => {
  return authAxios.get(
    `/api/platform/${platformCode}/competitions/${competitionCode}/admittedCandidatures`,
    { params: { index: index, size: size } }
  );
};

export const getCandidatureByExternalId = (externalId) => {
  return authAxios.get(`/api/candidature/${externalId}`);
};

export const getDocumentMember = (path) => {
  return authAxios.get(path, { responseType: 'blob' });
};

function constructSortFiltersString(sortFilters) {
  let finalString = '';

  sortFilters.forEach((sf) => {
    finalString = finalString.concat(sf.type + ':' + sf.value + ',');
  });

  return finalString;
}

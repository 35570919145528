export const SubjectType = {
  PAA: 'PAA',
  EAA: 'EAA',
  NOPROGRAM: 'NOPROGRAM',
  GENERAL: 'GENERAL',
  CANDIDATURE: 'CANDIDATURE',
  OTHER: 'OTHER',
  values: () => {
    return ['PAA', 'EAA', 'NOPROGRAM', 'GENERAL', 'CANDIDATURE', 'OTHER'];
  },
};

import { Formik } from 'formik';
import React, { useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Redirect, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { useMessageObjectCreator } from '../../../../hooks/errorMessage';
import { getHabitationFromPAA } from '../../../../rest/competition';
import { handleError, isBusinessError } from '../../../../utils/handleError';

const EMPTY_OBJECT = {
  nif: '',
  certificate: '',
};

function AddHabitation() {
  const { competitionCode } = useParams();
  const { platformCode } = useParams();
  const creator = useMessageObjectCreator();

  const decodedCompetitionCode = decodeURIComponent(competitionCode);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [submited, setSubmited] = useState();
  const [message, setMessage] = useState({
    show: false,
    type: null,
    message: null,
  });

  const fetchHabitation = async (values, setSubmitting, setErrors) => {
    try {
      const { data: habitationData } = await getHabitationFromPAA(
        encodeURIComponent(decodedCompetitionCode),
        platformCode,
        values.nif,
        encodeURIComponent(values.certificate)
      );
      localStorage.setItem('habitationData', JSON.stringify(habitationData));
      setSubmited(true);
      setSubmitting(false);
      setLoading(false);
    } catch (error) {
      setError(error);
      setMessage(creator.create(error));
      setLoading(false);
    }
  };

  const onSubmit = (values, setSubmitting, setErrors) => {
    setLoading(true);
    fetchHabitation(values, setSubmitting, setErrors);
  };

  if (loading) {
    return <Loading />;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  if (submited) {
    return (
      <Redirect
        to={`/programas/${platformCode}/concursos/${encodeURIComponent(
          competitionCode
        )}/alojamentos/adicionar`}
      />
    );
  }

  let schema = yup.object().shape({
    nif: yup.string().required('Por favor introduza o NIF'),
    certificate: yup.string().required('Por favor introduza o certificado'),
  });

  return (
    <SubTemplate hasBackButton titleId={'habitationForm.title'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-8 offset-lg-2'}>
            {message.show && (
              <Alert variant={message.type}>{message.message}</Alert>
            )}
            <Formik
              initialValues={EMPTY_OBJECT}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                onSubmit(values, setSubmitting, setErrors);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className='col-md-12'>
                      <Form.Group controlId='competitionCode'>
                        <Form.Label>
                          <FormattedMessage id='habitationForm.competitionCode' />
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='competitionCode'
                          value={decodedCompetitionCode}
                          disabled
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Form.Group controlId='nif'>
                        <Form.Label>
                          <FormattedMessage id='habitationForm.nifText' />
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='nif'
                          value={values.nif}
                          onChange={handleChange}
                          isInvalid={touched.nif && errors.nif}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.nif}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className='col-md-6'>
                      <Form.Group controlId='certificate'>
                        <Form.Label>
                          <FormattedMessage id='habitationForm.certificateText' />
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='certificate'
                          value={values.certificate}
                          onChange={handleChange}
                          isInvalid={touched.certificate && errors.certificate}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.certificate}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>

                  <div className='form-actions'>
                    <div className='mr-auto'>
                      <Link
                        to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                          competitionCode
                        )}`}
                        variant='secondary'
                      >
                        <FormattedMessage id='all.backButtonText' />
                      </Link>
                    </div>
                    <div className='ml-auto'>
                      <button
                        className='btn btn-primary'
                        type='submit'
                        disabled={isSubmitting}
                      >
                        <FormattedMessage id='all.submitButtonText' />
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default AddHabitation;

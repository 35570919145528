import React, { useState } from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { AddConditionDialog } from '../condition/AddConditionDialog';
import EditConditionDialog from '../condition/EditConditionDialog';
import SearchConditions from '../condition/SearchConditions';
import { ScrollToTop } from '../ScrollToTop';

export function CompetitionStep3Form({
  competition,
  setCompetition,
  setFieldValue,
  validateForm,
  numberOfSteps,
  step,
}) {
  if (!competition.eligibilities) {
    competition.eligibilities = [];
  }

  const [showAddCondition, setShowAddCondition] = useState(false);
  const handleCloseAddCondition = () => setShowAddCondition(false);
  const handleShowAddCondition = () => setShowAddCondition(true);

  const [showEditCondition, setShowEditCondition] = useState(false);
  const handleCloseEditCondition = () => setShowEditCondition(false);
  const handleShowEditCondition = () => setShowEditCondition(true);

  const [conditionToEdit, setConditionToEdit] = useState(null);

  const handleSelectedCondition = (selectedCondition) => {
    if (
      !competition.eligibilities.find((x) => x.code === selectedCondition.code)
    ) {
      competition.eligibilities.push(selectedCondition);
      setCompetition({ ...competition });
    }
  };

  const showEditModal = (condition) => {
    setConditionToEdit(condition);
    handleShowEditCondition();
  };

  const handleEditedCondition = (condition) => {
    const tempValues = { ...competition };

    const editedEligibilityConditions = tempValues.eligibilities.filter(
      (x) => x.code !== condition.code
    );
    editedEligibilityConditions.push(condition);
    tempValues.eligibilities = editedEligibilityConditions;
    setCompetition({ ...tempValues });
    setConditionToEdit(null);
    handleCloseEditCondition();
  };

  const handleRemove = (condition) => {
    const tempValues = { ...competition };
    tempValues.eligibilities = tempValues.eligibilities.filter(
      (x) => x.code !== condition.code
    );

    setCompetition(tempValues);
  };

  const handleNextStep = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setFieldValue('step', competition.step + 1);
    }
  };

  return (
    <div>
      <ScrollToTop />

      <h5 className='mb-4 pb-2 border-bottom'>
        <FormattedMessage
          id='all.steps'
          values={{ step: step, numberOfSteps: numberOfSteps }}
        />
        {' - '}
        <FormattedMessage id='competition.form.title.conditions' />
      </h5>
      <div className='row'>
        <SearchConditions onClickCondition={handleSelectedCondition} />
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <h5 className='mt-3 mb-2'>
            <FormattedMessage id='platformForm.assigned.conditions' />
          </h5>
          <button
            type='button'
            className='btn btn-outline-secondary'
            onClick={handleShowAddCondition}
          >
            <FormattedMessage id='condition.create.label' />
          </button>
          {competition.eligibilities.length <= 0 && (
            <div className='my-4'>
              <p>
                <em>
                  <FormattedMessage id='condition.empty' />
                </em>
              </p>
            </div>
          )}
          <ul className='results'>
            {competition.eligibilities.map((x, index) => {
              return (
                <li key={index}>
                  <div className='row my-4'>
                    <div className='col-sm-9 align-self-center'>{x.name}</div>
                    <div className='col-sm-3 text-right'>
                      <button
                        type='button'
                        className={'btn btn-link ml-3'}
                        onClick={() => showEditModal(x)}
                      >
                        <FaPencilAlt />
                      </button>
                      <button
                        type='button'
                        className={'btn btn-link ml-3'}
                        onClick={() => handleRemove(x)}
                      >
                        <FaTrashAlt />
                      </button>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {showAddCondition && (
        <AddConditionDialog
          handleClose={handleCloseAddCondition}
          show={showAddCondition}
          onCreated={handleSelectedCondition}
        />
      )}
      {showEditCondition && (
        <EditConditionDialog
          handleClose={handleCloseEditCondition}
          onEdit={handleEditedCondition}
          show={showEditCondition}
          selectedCondition={conditionToEdit}
        />
      )}
      <div className='form-actions'>
        <div className='mr-auto'>
          <button
            className='btn btn-link'
            type='button'
            onClick={(e) => setFieldValue('step', competition.step - 1)}
          >
            <FormattedMessage id='all.previousButtonText' />
          </button>
        </div>
        <div className='ml-auto'>
          <button
            className='btn btn-primary'
            type='submit'
            onClick={(e) => handleNextStep()}
          >
            <FormattedMessage id='all.nextButtonText' />
          </button>
        </div>
      </div>
    </div>
  );
}

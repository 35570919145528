import React from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export function AlertCandidateDocumentSubmissionModal({ show, handleClose }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Alert variant='warning'>
          <FormattedMessage id='documents.alertToSubmitDocuments.message' />
        </Alert>
      </Modal.Body>
      {/* <Modal.Footer>
        <button
          className='btn btn-link mr-auto'
          onClick={handleClose}
          type='button'
        >
          <FormattedMessage id='all.closeText' />
        </button>
      </Modal.Footer> */}
    </Modal>
  );
}

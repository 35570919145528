import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { DocumentDescriptor } from "../../models/DocumentDescriptor";
import { EnumSelectField } from "../bootstrap/EnumSelectField";
import { TextInputField } from "../bootstrap/TextInputField";

export function DocumentTypeForm({ docType, onSave, isUpdate, onBack }) {
  const [documentType, setDocumentType] = useState(docType);
  const [errors, setErrors] = useState({
    code: null,
    description: null,
    descriptor: null,
  });

  const handleChange = (e) => {
    documentType[e.target.name] = e.target.value;
    errors[e.target.name] = null;
    setDocumentType({ ...documentType });
    setErrors({ ...errors });
  };

  return (
    <Form>
      <TextInputField
        name="code"
        value={documentType.code}
        handleChange={handleChange}
        labelId="documentType.code"
        errorMessage={errors.code}
        isInvalid={documentType.hasOwnProperty('code') && !documentType?.code}
        disabled={isUpdate}
      />

      <TextInputField
        name="description"
        value={documentType.description}
        handleChange={handleChange}
        labelId="documentType.description"
        errorMessage={errors.description}
        isInvalid={documentType.hasOwnProperty('description') && !documentType?.description}
      />

      <EnumSelectField
        name="descriptor"
        labelId="documentType.descriptor"
        options={DocumentDescriptor.values()}
        value={documentType.descriptor}
        preffixDescriptionId={"DocumentDescriptor"}
        handleChange={handleChange}
        isInvalid={documentType.hasOwnProperty('descriptor') && !documentType?.descriptor}
        errorMessage={errors.descriptor}
        nullOption={true}
        disabled={isUpdate}
        defaultOptionDisabled
      />

      <div className="form-actions">
        <div className="mr-auto">
          <button type="button" className="btn btn-link" onClick={onBack}>
            <FormattedMessage id="all.backButtonText" />
          </button>
        </div>
        <div className="ml-auto">
          <button
            className="btn btn-outline-primary"
            type="button"
            disabled={
              !documentType.descriptor ||
              !documentType.description ||
              !documentType.code
            }
            onClick={() => onSave(documentType)}
          >
            <FormattedMessage id="all.submitButtonText" />
          </button>
        </div>
      </div>
    </Form>
  );
}

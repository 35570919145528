import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory, useParams } from 'react-router-dom';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import CompetitionForm from '../../../../components/competition/CompetitionForm';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { getCompetitionByCompetitionCode, updateCompetition } from '../../../../rest/competition';
import { getPlatform } from '../../../../rest/platform';
import { handleError, isBusinessError } from '../../../../utils/handleError';
import { PlatformType } from '../../../../models/PlatformType';

export function EditCompetition() {
  const { competitionCode: encodedUri } = useParams();
  const { platformCode } = useParams();
  const code = decodeURIComponent(encodedUri);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [platform, setPlatform] = useState(null);
  const [competition, setCompetition] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: competition } = await getCompetitionByCompetitionCode(
          encodedUri,
          platformCode
        );

        const { data: platform } = await getPlatform(competition.platform);
        setCompetition({
          ...competition,
          priorityCriterias: platform.priorityCriterias,
          priorityCriteriasChosen: competition.priorityCriterias,
          hasPriorityCriterias: competition.hasPriorityCriterias ? 'true' : 'false'
        });
        setPlatform(platform);
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [encodedUri]);

  const update = async (type, competition, setSubmitting, setErrors) => {
    try {
      if (!competition.maxAccommodations) {
        competition.maxAccommodations = -1;
      }

      if (platformCode === PlatformType.PAS) {
        competition.priorityCriterias = competition.priorityCriteriasChosen;
      }

      await updateCompetition(code, platformCode, competition);

      setCompetition(competition);
      setSubmitting(false);

      if (type === 'finalSubmitAndAddHabitation') {
        history.replace(
          `/programas/${competition.platform}/concursos/${encodeURIComponent(
            competition.code
          )}/alojamentos/pre/adicionar`
        );
      } else {
        history.replace(
          `/programas/${competition.platform}/concursos/${encodeURIComponent(competition.code)}`
        );
      }
    } catch (error) {
      setError(error);
      setSubmitting(false);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (type, competition, setSubmitting, setErrors) => {
    setLoading(true);
    update(type, competition, setSubmitting, setErrors);
  };

  if (loading) {
    return <Loading></Loading>;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate titleId={'competitions.editCompetitionText'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-8 offset-lg-2'}>
            <AlertError error={error} />
            <CompetitionForm
              platform={platform}
              competition={competition}
              onSubmit={onSubmit}
              isUpdate={true}
              setError={setError}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export function ErrorLabel({ id, error }) {
  if (error.show && error.fields.find((x) => x.id === id)) {
    return (
      <div className='error-label'>
        <small className='text-danger'>
          {error.fields.find((x) => x.id === id).message}
        </small>
      </div>
    );
  } else {
    return <></>;
  }
}

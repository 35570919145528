import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import DateSubmissionDocumentsModal from '../../../../components/candidature/DateSubmissionDocumentsModal';
import DocumentsValidationAccording from '../../../../components/candidature/DocumentsValidationAccording';
import { ScoreDetailsTable } from '../../../../components/candidature/ScoreDetailsTable';
import ValidationChangeDateSubmission from '../../../../components/candidature/ValidationChangeDateSubmission';
import { AuthenticatedDownloadLink } from '../../../../components/document/AuthenticatedDownloadLink';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { createCustomErrorMessage } from '../../../../hooks/errorMessage';
import { CompetitionType } from '../../../../models/CompetitionType';
import { ValidateOptions } from '../../../../models/ValidateOptions';
import { getDocumentMember } from '../../../../rest/candidature';
import {
  getCandidatureByExternalId,
  getCandidatureDocument,
  giveMoreTime,
  validateDocumentsCandidature,
} from '../../../../rest/candidatureuser';
import { getCompetitionByCompetitionCode } from '../../../../rest/competition';
import { getActionNotificationByCandidatureState } from '../../../../rest/templates';
import { Arrays } from '../../../../utils/Arrays';
import { handleError, isNotBusinessError } from '../../../../utils/handleError';

function DocumentsValidation() {
  const { platformCode } = useParams();
  const { competitionCode } = useParams();
  const { externalId } = useParams();

  const [loading, setLoading] = useState(true);
  const [isSubmitedNewDate, setSubmittedNewDate] = useState(false);
  const [isSubmited, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [documentsMember, setMemeberDocuments] = useState({});
  const [documents, setDocuments] = useState({});
  const [finalDocuments, setFinalDocuments] = useState({});
  const [candidature, setCandidature] = useState({});
  const [candidatureScoreDetails, setCandidatureScoreDetails] = useState({});
  const [actionNotification, setActionNotification] = useState({});
  const [defaultScoreDetails, setDefaultScoreDetails] = useState({});
  const [validateDateChange, setValidateDateChange] = useState(false);
  const [showDateChangeModal, setShowDateChangeModal] = useState(false);
  const [showCorrectScoresModal, setShowCorrectScoresModal] = useState(false);
  const [message, setMessage] = useState({
    show: false,
    type: null,
    message: null,
  });

  const [correctedFields, setCorrectedFields] = useState(
    DEFAULT_CORRECTED_FIELDS
  );

  const [errors, setErrors] = useState(DEFAULT_ERRORS);

  const intl = useIntl();
  const history = useHistory();

  const fetchData = async () => {
    try {
      const [{ data: documents }, { data: candidature }] = await Promise.all([
        await getCandidatureDocument(externalId),
        await getCandidatureByExternalId(externalId),
      ]);
      setDocuments(documents);
      setMemeberDocuments(documents.memberDocuments);
      setCandidature(candidature);
      const { data: actionNotification } =
        await getActionNotificationByCandidatureState(
          candidature.candidatureDto.state,
          candidature.candidatureDto.competitionType
        );
      setActionNotification(actionNotification);
      if (
        candidature.candidatureDto.competitionType ===
        CompetitionType.SERIALIZATION
      ) {
        setCandidatureScoreDetails(
          candidature.candidatureDto.candidatureEAA.scoreDetails
        );
        setDefaultScoreDetails(
          candidature.candidatureDto.candidatureEAA.scoreDetails
        );
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const correctedFieldsHasErrors = () => {
    if (
      candidature.candidatureDto.competitionType !==
      CompetitionType.SERIALIZATION
    ) {
      return false;
    }

    let hasErrors = false;
    const keys = Object.keys(errors);

    keys.forEach((key) => {
      if (errors[key].length !== 0) {
        hasErrors = true;
      }
    });

    return hasErrors;
  };

  const correctedFieldsIsEmpty = () => {
    if (
      candidature.candidatureDto.competitionType !==
      CompetitionType.SERIALIZATION
    ) {
      return false;
    }

    let isEmpty = true;
    const keys = Object.keys(correctedFields);

    keys.forEach((key) => {
      if (correctedFields[key].length !== 0) {
        isEmpty = false;
      }
    });

    return isEmpty;
  };

  const validation = () => {
    let isValid = true;
    let isEmpty = true;

    if (correctedFieldsIsEmpty()) {
      setError(
        createCustomErrorMessage(
          intl.formatMessage({ id: 'documents.error.scoresEmpty.message' })
        )
      );
      return false;
    }

    if (correctedFieldsHasErrors()) {
      setError(
        createCustomErrorMessage(
          intl.formatMessage({ id: 'documents.error.scoresErrors.message' })
        )
      );
      return false;
    }

    if (
      Arrays.isEmpty(documents.generalDocuments) &&
      Arrays.isEmpty(documents.memberDocuments[0].documentTypes)
    ) {
      return true;
    }
    if (
      (finalDocuments.generalDocuments == null &&
        documents.generalDocuments.length > 0) ||
      (finalDocuments.memberDocuments == null &&
        documents.memberDocuments[0].documentTypes.length > 0)
    ) {
      isEmpty = false;
    }

    if (isEmpty) {
      finalDocuments.generalDocuments.every((document) => {
        if (document.validation == null) {
          isEmpty = false;
          return false;
        }
        return true;
      });

      finalDocuments.memberDocuments.every((member) => {
        member.documentTypes.every((document) => {
          if (document.validation == null) {
            isEmpty = false;
            return false;
          }
          return true;
        });
        return true;
      });

      if (isEmpty) {
        const numberOfInvalidatedDocuments =
          finalDocuments.generalDocuments.filter(
            (doc) => doc.validation === ValidateOptions.NO
          ).length;
        if (numberOfInvalidatedDocuments > 0) {
          isValid = false;
        }
        if (isValid) {
          let numberOfInvalidatedMemberDocuments;
          finalDocuments.memberDocuments.every((member) => {
            numberOfInvalidatedMemberDocuments = member.documentTypes.filter(
              (doc) => doc.validation === ValidateOptions.NO
            ).length;
            if (numberOfInvalidatedMemberDocuments > 0) {
              isValid = false;
              return false;
            }
            return true;
          });
        }
      }
    }
    if (!isEmpty) {
      setMessage({
        show: true,
        type: 'danger',
        message: intl.formatMessage({
          id: 'exceptions.RequiredFieldsException',
        }),
      });
    } else if (!isValid) {
      setValidateDateChange(true);
    } else {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSend = {
      documents: finalDocuments,
      correctedScores: candidatureScoreDetails,
    };

    if (validation()) {
      setLoading(true);
      try {
        await validateDocumentsCandidature(externalId, dataToSend);
        setLoading(false);
        setSubmitted(true);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
  };

  const handleCloseValidateDateModal = () => {
    setValidateDateChange(false);
  };

  const handleCloseShowDateChangeModal = () => {
    setValidateDateChange(false);
    setShowDateChangeModal(false);
  };

  const onClickSubmit = async (e) => {
    setLoading(true);
    const dataToSend = {
      documents: finalDocuments,
      correctedScores: candidatureScoreDetails,
    };
    try {
      await validateDocumentsCandidature(externalId, dataToSend);
      setLoading(false);
      setSubmitted(true);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const onSubmitNewDate = async (values) => {
    setLoading(true);
    try {
      await giveMoreTime(externalId, values);
      setLoading(false);
      setSubmittedNewDate(true);
    } catch (error) {
      setError(error);
      setShowDateChangeModal(false);
      setLoading(false);
    }
  };

  const onClickChangeDate = () => {
    setValidateDateChange(false);
    setShowDateChangeModal(true);
  };

  const handleChangeGeneral = (e, documentType) => {
    const tempDocuments = { ...documents };
    const docValidation = tempDocuments.generalDocuments.filter(
      (docType) => docType.code === documentType
    );
    docValidation[0].validation = e.target.value;
    const finalDoc = tempDocuments.generalDocuments.filter(
      (x) => x.code !== documentType
    );
    finalDoc.push(docValidation[0]);
    tempDocuments.generalDocuments = finalDoc;
    setFinalDocuments(tempDocuments);
  };

  const handleChangeMember = (e, documentType, memberCode) => {
    const tempDocuments = [...documentsMember];
    const tempAllDocuments = { ...documents };
    const member = tempDocuments.filter((m) => m.code === memberCode);
    const docValidation = member[0].documentTypes.filter(
      (docType) => docType.code === documentType
    );
    docValidation[0].validation = e.target.value;
    const finalDoc = member[0].documentTypes.filter(
      (x) => x.code !== documentType
    );
    finalDoc.push(docValidation[0]);
    const finalMember = tempDocuments.filter((m) => m.code !== memberCode);
    finalMember.push(member[0]);
    tempAllDocuments.memberDocuments = finalMember;
    setFinalDocuments(tempAllDocuments);
  };

  const handleFilesDownload = async (path, fileName) => {
    setLoading(true);

    try {
      const response = await getDocumentMember(path);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  if (isSubmited) {
    history.replace(
      `/programas/${platformCode}/concursos/${competitionCode}/candidatura/${externalId}?docValidation=true`
    );
  }

  if (isSubmitedNewDate) {
    history.replace(
      `/programas/${platformCode}/concursos/${competitionCode}/candidatura/${externalId}?newDate=true`
    );
  }

  return (
    <SubTemplate
      hasBackButton
      titleId={'documents.validation.text'}
      titleValues={{
        candidatureCode: documents.candidatureCode,
        competitionCode: decodeURIComponent(competitionCode),
      }}
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            <AlertError error={error} />
            {message.show && (
              <Alert
                dismissible
                onClose={() =>
                  setMessage({
                    show: false,
                  })
                }
                variant={message.type}
              >
                {message.message}
              </Alert>
            )}
            <button
              className='btn btn-outline-primary mb-2'
              onClick={() =>
                handleFilesDownload(
                  `/api/candidature/${externalId}/documents/all`,
                  `documentos_candidatura_${documents.candidatureCode}.zip`
                )
              }
            >
              <FaDownload />
              <FormattedMessage id='documents.downloadAll' />
            </button>
            {candidature.candidatureDto.competitionType ===
              CompetitionType.SERIALIZATION && (
              <button
                className='btn btn-outline-primary mb-2 ml-2'
                onClick={() => setShowCorrectScoresModal(true)}
              >
                <FormattedMessage id='correctScores.button.text' />
              </button>
            )}
            <legend className='mb-3'>
              <FormattedMessage id='documents.candidature.generalDocument' />
            </legend>
            {documents.generalDocuments.map((documentType) => (
              <>
                <div className='row'>
                  <div className='col-md-6'>
                    <label>
                      {documentType.description}
                      {documentType.required && (
                        <span className={'required'}>*</span>
                      )}
                    </label>
                  </div>
                  <div className='col-md-6 text-right'>
                    <DocumentsValidationAccording
                      required={documentType.required}
                      documentType={documentType.code}
                      handleChange={handleChangeGeneral}
                      memberCode={null}
                    />
                  </div>
                </div>
                {!Arrays.isEmpty(documentType.documents) && (
                  <>
                    <div className='form bg-white pb-3 pt-0 mb-4'>
                      <div className='row mt-3'>
                        <div className='col-lg-10'>
                          <span className='font-small'>
                            <FormattedMessage id='documents.fileName.text' />
                          </span>
                        </div>
                      </div>

                      {documentType.documents != null &&
                        documentType.documents.map((docs) => (
                          <>
                            <div className='border-top py-2'>
                              <div className='row'>
                                <div className='col-lg-10'>
                                  <AuthenticatedDownloadLink
                                    url={docs.path}
                                    filename={docs.name}
                                  >
                                    {docs.name}
                                  </AuthenticatedDownloadLink>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </>
                )}
              </>
            ))}

            {documentsMember.map((memberDocument) => (
              <>
                {Arrays.isNotEmpty(memberDocument.documentTypes) && (
                  <legend className='mt-5'>
                    <FormattedMessage id='documents.candidature.memberDocument' />{' '}
                    {memberDocument.name}
                  </legend>
                )}
                {memberDocument.documentTypes.map((documentType) => (
                  <>
                    <div className='row'>
                      <div className='col-md-6'>
                        <label>
                          {documentType.description}
                          {documentType.required && (
                            <span className={'required'}>*</span>
                          )}
                        </label>
                      </div>
                      <div className='col-md-6 text-right'>
                        <DocumentsValidationAccording
                          required={documentType.required}
                          documentType={documentType.code}
                          handleChange={handleChangeMember}
                          memberCode={memberDocument.code}
                        />
                      </div>
                    </div>
                    {!Arrays.isEmpty(documentType.documents) && (
                      <>
                        <div className='form bg-white pb-3 pt-0 mb-4'>
                          <div className='row mt-3'>
                            <div className='col-lg-10'>
                              <span className='font-small'>
                                <FormattedMessage id='documents.fileName.text' />
                              </span>
                            </div>
                          </div>

                          {documentType.documents != null &&
                            documentType.documents.map((docs, index) => (
                              <>
                                <div className='border-top py-2'>
                                  <div className='row'>
                                    <div className='col-lg-6'>
                                      <AuthenticatedDownloadLink
                                        url={docs.path}
                                        filename={docs.name}
                                      >
                                        {docs.name}
                                      </AuthenticatedDownloadLink>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                        </div>
                      </>
                    )}
                  </>
                ))}
              </>
            ))}
            <div className='form-actions'>
              <div className='ml-auto'>
                <button
                  className='btn btn-primary'
                  onClick={(e) => handleSubmit(e)}
                >
                  <FormattedMessage id='all.submitButtonText' />
                </button>
              </div>
            </div>
          </div>
          <ValidationChangeDateSubmission
            show={validateDateChange}
            handleClose={handleCloseValidateDateModal}
            onClickSubmit={onClickSubmit}
            onClickChangeDate={onClickChangeDate}
          />
          <DateSubmissionDocumentsModal
            show={showDateChangeModal}
            handleClose={handleCloseShowDateChangeModal}
            onSubmit={onSubmitNewDate}
            actionNotification={
              platformCode === 'EAA' ? actionNotification : null
            }
          />
          {candidature.candidatureDto.competitionType ===
            CompetitionType.SERIALIZATION && (
            <ScoreDetailsTable
              show={showCorrectScoresModal}
              handleClose={() => setShowCorrectScoresModal(false)}
              candidature={candidature}
              candidatureScoreDetails={candidatureScoreDetails}
              setCandidatureScoreDetails={setCandidatureScoreDetails}
              defaultCandidatureScoreDetails={defaultScoreDetails}
              correctedFields={correctedFields}
              setCorrectedFields={setCorrectedFields}
              errors={errors}
              setErrors={setErrors}
              hasErrors={correctedFieldsHasErrors}
              updateMode={true}
            />
          )}
        </div>
      </div>
    </SubTemplate>
  );
}

const DEFAULT_CORRECTED_FIELDS = {
  adjustedCandidatureYearsPoints: '',
  adjustedIncapacityPoints: '',
  adjustedUntitledPoints: '',
  adjustedDependentsPoints: '',
  adjustedOver65Points: '',
  adjustedMotivePointsEAA: '',
  candidatureYearsNumberJustification: '',
  incapacityJustification: '',
  untitledJustification: '',
  dependentsJustification: '',
  over65Justification: '',
  motiveEAAJustification: '',
};

const DEFAULT_ERRORS = {
  adjustedCandidatureYearsPoints: '',
  adjustedIncapacityPoints: '',
  adjustedUntitledPoints: '',
  adjustedDependentsPoints: '',
  adjustedOver65Points: '',
  adjustedMotivePointsEAA: '',
};

export default DocumentsValidation;

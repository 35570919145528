import { Formik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import { TextAreaInputField } from '../bootstrap/TextAreaInputField';

export function WithdrawalResignDialog({
  show,
  handleClose,
  handleConfirm,
  titleId,
  actionId,
  reasonId,
}) {
  const INITIAL_VALUES = {
    reason: '',
  };
  const intl = useIntl();

  let schema = yup.object().shape({
    reason: yup
      .string()
      .required(intl.formatMessage({ id: 'messages.emptyReason' })),
  });
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id={titleId} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id='candidature.dialog.withdrawalResign.body' />
        </Modal.Body>
        <Formik
          initialValues={INITIAL_VALUES}
          validationSchema={schema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            handleConfirm(
              { flag: false },
              values.reason,
              setSubmitting,
              setErrors
            );
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <TextAreaInputField
                  name='reason'
                  value={values.reason}
                  handleChange={handleChange}
                  labelId={reasonId}
                  errorMessage={errors.reason}
                  isInvalid={errors.reason && touched.reason}
                />
              </Modal.Body>

              <Modal.Footer>
                <button
                  className='btn btn-link mr-auto'
                  onClick={handleClose}
                  type='button'
                >
                  <FormattedMessage id='all.cancelText' />
                </button>

                <button
                  className={'btn btn-primary ml-auto'}
                  type='submit'
                  disabled={isSubmitting}
                >
                  <FormattedMessage id={actionId} />
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

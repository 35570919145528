import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  constructAccessInfoFromKeycloak,
  saveInStorageAccessInfo,
} from '../../authentication/authenticationHelper';
import { useGlobalContext } from '../../context';
import { useQuery } from '../../hooks/useQuery';
import { getUserPermissions } from '../../rest/authentication.api';

const getRedirectPath = (redirectPath) => {
  if (!redirectPath) {
    return '';
  }

  if (redirectPath === 'null' || redirectPath === 'undefined') {
    return '';
  } else {
    return redirectPath;
  }
};

function KeycloakAuthentication(props) {
  const { keycloak, initialized } = useKeycloak();
  const { setAccessInfo, setGlobalLoading } = useGlobalContext();
  const query = useQuery();

  const redirectPath = query.get('r');

  let history = useHistory();

  let from = getRedirectPath(redirectPath);

  const fetchPermissionsAndSave = async () => {
    try {
      const { data: permissions } = await getUserPermissions(
        keycloak.token,
        'keycloak'
      );
      const access_info = constructAccessInfoFromKeycloak(
        keycloak,
        permissions
      );
      saveInStorageAccessInfo(access_info);
      setAccessInfo(access_info);
      setGlobalLoading(false);
      history.replace(`/${from}`);
    } catch (error) {
      setGlobalLoading(false);
      history.replace('/');
    }
  };

  useEffect(() => {
    setGlobalLoading(true);

    if (!initialized) {
      return;
    }
    if (!keycloak.authenticated) {
      keycloak.login();
    } else {
      fetchPermissionsAndSave();
    }
  }, [initialized]);

  return <div />;
}

export default KeycloakAuthentication;

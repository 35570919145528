import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ErrorLabel } from '../ErrorLabel';

export function EligibilityConditionForm({
  condition,
  setCondition,
  handleClick,
  isUpdate,
  errors,
  setErrors,
  onBack,
}) {
  const handleChange = (e) => {
    const tempCondition = { ...condition };
    tempCondition[e.target.name] = e.target.value;

    if (errors.fields.find((x) => e.target.name === x.id)) {
      let fields = errors.fields.filter((x) => e.target.name !== x.id);
      if (!fields) {
        fields = [];
      }
      const updatedErrors = { ...errors, fields: fields, system: null };
      setErrors(updatedErrors);
    }
    setCondition(tempCondition);
  };

  return (
    <Form>
      <Form.Group controlId='code'>
        <Form.Label>
          <FormattedMessage id='condition.code' />
        </Form.Label>
        <Form.Control
          type='text'
          name='code'
          value={condition.code}
          onChange={handleChange}
          disabled={isUpdate}
        />
        <ErrorLabel error={errors} id={'code'} />
      </Form.Group>

      <Form.Group controlId='name'>
        <Form.Label>
          <FormattedMessage id='condition.name' />
        </Form.Label>
        <Form.Control
          type='text'
          name='name'
          value={condition.name}
          onChange={handleChange}
          disabled={isUpdate}
        />
        <ErrorLabel error={errors} id={'name'} />
      </Form.Group>

      <Form.Group controlId='description'>
        <Form.Label>
          <FormattedMessage id='condition.description' />
        </Form.Label>
        <Form.Control
          type='text'
          name='description'
          value={condition.description}
          onChange={handleChange}
        />
      </Form.Group>

      <div className='form-actions'>
        <div className='mr-auto'>
          <button type='button' className='btn btn-link' onClick={onBack}>
            <FormattedMessage id='all.backButtonText' />
          </button>
        </div>
        <div className='ml-auto'>
          <button
            className='btn btn-outline-primary'
            type='button'
            onClick={handleClick}
          >
            <FormattedMessage id='all.submitButtonText' />
          </button>
        </div>
      </div>
    </Form>
  );
}

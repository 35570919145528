import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import { PlatformType } from '../../models/PlatformType';
import { HabitationCardList } from '../habitation/HabitationCardList';

function CandidatureOrderHabitation({
  selectedHabitations,
  handleBack,
  handleSubmited,
  platformCode
}) {
  const [habitationsCandidature, setHabitationsCandidature] = useState(selectedHabitations);

  const handleChangePosition = (habitation, index) => {
    habitation.order = index + 1;
    selectedHabitations.find(
      (h) => h.habitation.externalId === habitation.habitation.externalId
    ).order = index + 1;
  };

  return (
    <>
      <div className={'form'}>
        <h5 className='mb-4 pb-2 border-bottom'>
          <FormattedMessage
            id={`${
              platformCode === PlatformType.PAS
                ? 'candidature.pas.step4of5Text'
                : 'candidature.preferenceOrderText'
            }`}
          />
        </h5>
        <p className='mb-0'>
          <strong>
            {habitationsCandidature.length}{' '}
            <FormattedMessage
              id={`candidature.numberHabitationsText${
                habitationsCandidature.length === 1 ? 'Single' : ''
              }`}
            />
          </strong>
        </p>
        {selectedHabitations.length > 1 ? (
          <>
            <p className='mb-4'>
              <FormattedMessage id='candidature.orderText' />
            </p>
            <ReactSortable
              list={habitationsCandidature}
              setList={setHabitationsCandidature}
              sortable={null}>
              {habitationsCandidature.map((habitation, index) => (
                <HabitationCardList
                  key={index}
                  habitation={habitation}
                  order={true}
                  onChange={handleChangePosition(habitation, index)}
                  showArrow={true}
                />
              ))}
            </ReactSortable>
          </>
        ) : (
          <>
            {habitationsCandidature.map((habitation, index) => (
              <HabitationCardList
                key={index}
                habitation={habitation}
                order={true}
                onChange={handleChangePosition(habitation, index)}
              />
            ))}
          </>
        )}
      </div>
      <div className='form-actions'>
        <div className='mr-auto'>
          <Link onClick={handleBack} className='btn btn-link'>
            <FormattedMessage id='all.backButtonText' />
          </Link>
        </div>
        <div className='ml-auto'>
          <Button variant='primary' onClick={handleSubmited}>
            <FormattedMessage id='all.nextButtonText' />
          </Button>
        </div>
      </div>
    </>
  );
}

export default CandidatureOrderHabitation;

import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useMessageObjectCreator } from '../../hooks/errorMessage';
import { ScrollToTop } from '../ScrollToTop';
import parse from 'html-react-parser';

export function AlertError({ error }) {
  const creator = useMessageObjectCreator();

  const [message, setMessage] = useState({ show: false });

  useEffect(() => {
    setMessage(creator.create(error));
  }, [error]);

  if (!message.show) {
    return <></>;
  }

  return (
    <>
      <ScrollToTop />
      <Alert
        dismissible
        onClose={() =>
          setMessage({
            show: false,
          })
        }
        variant={message.type}
      >
        {parse(message.message)}
      </Alert>
    </>
  );
}

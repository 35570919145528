import React from 'react';

export function ClickableDocumentTypeLabel({
  documentType,
  onClickDocumentType,
}) {
  return (
    <div
      className='search-item'
      onClick={() => onClickDocumentType(documentType)}
    >
      {documentType.description}
    </div>
  );
}

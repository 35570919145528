import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export function DeliveryKeysDialog({ show, handleClose, handleConfirm }) {
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='candidature.delivery.keys' />
          </Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <FormattedMessage id='candidature.delivery.keys.question' />
          </Modal.Body>

          <Modal.Footer>
            <button
              className='btn btn-link mr-auto'
              onClick={handleClose}
              type='button'
            >
              <FormattedMessage id='all.cancelText' />
            </button>

            <button
              className={'btn btn-primary ml-auto'}
              type='button'
              onClick={handleConfirm}
            >
              <FormattedMessage id='all.yesText' />
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import ImageUploader from 'react-images-upload';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { hasPermission } from '../../../../authentication/authenticationHelper';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import { DocumentTable } from '../../../../components/document/DocumentTable';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { ConfigObject } from '../../../../config';
import { useGlobalContext } from '../../../../context';
import { DocumentDescriptor } from '../../../../models/DocumentDescriptor';
import {
  addCompetitionDocument,
  deleteCompetitionDocument,
  getCompetitionDocuments,
} from '../../../../rest/competitiondocument';
import { getDocumentTypes } from '../../../../rest/documentType';
import { handleError, isNotBusinessError } from '../../../../utils/handleError';
import { createAcceptedTypesLabel } from '../../../../utils/Utils';

export function CompetitionDocumentsManagment() {
  const { competitionCode } = useParams();
  const { platformCode } = useParams();
  const { competitionDocumentExtensions, competitionDocumentSize } =
    ConfigObject.get();

  const [documents, setDocuments] = useState({});
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [description, setDescription] = useState('');
  const [types, setTypes] = useState({});
  const [documentType, setDocumentType] = useState('');

  const { accessInfo } = useGlobalContext();

  const intl = useIntl();
  async function fetchCompetition() {
    try {
      const { data: document } = await getCompetitionDocuments(
        competitionCode,
        platformCode
      );
      const { data: type } = await getDocumentTypes(
        DocumentDescriptor.COMPETITION
      );
      setDocuments(document);
      setTypes(type);
      setDocumentType(type[0].code);
      setDescription(type[0].description);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCompetition();
  }, []);

  const onFileChange = (event) => {
    const fileUpload = event[0];
    setSelectedFile(fileUpload);
    setError(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      setError(null);
      const formData = new FormData();
      formData.append('Attachment', selectedFile, selectedFile.name);
      formData.append('Description', description);
      formData.append('Type', documentType);
      addDocument(formData);
    } catch (error) {}
  };
  async function addDocument(formData) {
    try {
      setLoading(true);
      const { data } = await addCompetitionDocument(
        competitionCode,
        platformCode,
        formData
      );
      const docs = [...documents];
      docs.push(data);
      setSelectedFile(null);
      setDocumentType(types[0].code);
      setDescription(types[0].description);
      setDocuments(docs);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  async function deleteDocument(documentName) {
    try {
      setLoading(true);
      await deleteCompetitionDocument(
        competitionCode,
        platformCode,
        documentName
      );
      const docs = documents.filter((d) => d.name !== documentName);
      setDocuments(docs);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate hasBackButton titleId={'competitionDocument.TitleText'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-md-3 mb-5'}>
            <AlertError error={error} />
            <Form onSubmit={onSubmit}>
              <Form.Group controlId='platform'>
                <ImageUploader
                  buttonText={intl.formatMessage({
                    id: 'documents.insertDocumentText',
                  })}
                  onChange={(e) => onFileChange(e)}
                  singleImage={true}
                  imgExtension={[competitionDocumentExtensions]}
                  accept='*'
                  maxFileSize={1024 * 1024 * competitionDocumentSize}
                  label={
                    intl.formatMessage({
                      id: 'documents.labelSizeText',
                    }) +
                    competitionDocumentSize +
                    'Mb ' +
                    intl.formatMessage({ id: 'documents.labelTypeText' }) +
                    createAcceptedTypesLabel(competitionDocumentExtensions)
                  }
                  fileTypeError={intl.formatMessage({
                    id: 'documents.TypeErrorText',
                  })}
                  fileSizeError={intl.formatMessage({
                    id: 'documents.SizeErrorText',
                  })}
                />
              </Form.Group>
              <Form.Group controlId='type'>
                <Form.Label>
                  <FormattedMessage id='documents.typeText' />
                </Form.Label>
                <Form.Control
                  as='select'
                  name='type'
                  onChange={(e) => {
                    setDocumentType(e.target.value);
                    setDescription(
                      types.filter((t) => t.code == e.target.value)[0]
                        .description
                    );
                  }}
                >
                  {types.length &&
                    types.map((type) => (
                      <option value={type.code}>{type.description}</option>
                    ))}
                </Form.Control>
              </Form.Group>
              <button
                className='btn btn-outline-primary'
                type='submit'
                disabled={!selectedFile || false}
              >
                <FormattedMessage id='documents.upload' />
              </button>
            </Form>
          </div>

          <div className={'col-md-9 mb-5'}>
            <h5 className='mt-0 mb-4'>
              <FormattedMessage id='competition.documentsText' />
              {documents.length}
            </h5>

            <DocumentTable
              documents={documents}
              showDelete={hasPermission(
                'DELETE_COMPETITION_DOCUMENT',
                accessInfo
              )}
              onDelete={deleteDocument}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  getAccessInfo,
  saveInStorageAccessInfo,
} from '../../authentication/authenticationHelper';
import { ConfigObject } from '../../config';
import { useGlobalContext } from '../../context';
import { getNphToken, getUserPermissions } from '../../rest/authentication.api';

function NphAuthentication() {
  const { setAccessInfo, setGlobalLoading } = useGlobalContext();
  const history = useHistory();

  const { code } = useParams();

  const performLogin = async () => {
    try {
      const { data } = await getNphToken(code);
      data.refreshTs = data.expires_in * 1000 + Date.now();
      data.type = 'nph';
      const { data: permissions } = await getUserPermissions(
        data.access_token,
        'nph'
      );
      data.permissions = permissions;
      saveInStorageAccessInfo(data);
      setAccessInfo(getAccessInfo());
      setGlobalLoading(false);
      history.replace('/');
    } catch (error) {
      console.log(error);
      setGlobalLoading(false);
      window.location.replace(ConfigObject.get().baseUrl);
    }
  };

  useEffect(() => {
    if (!getAccessInfo()) {
      setGlobalLoading(true);
      performLogin();
    }
  }, []);

  return <div />;
}

export default NphAuthentication;

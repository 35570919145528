import React, { useState } from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { DRAFT } from '../../models/CompetitionState';
import { DocumentDescriptor } from '../../models/DocumentDescriptor';
import { AddDocumentTypeDialog } from '../documentType/AddDocumentTypeDialog';
import { EditDocumentTypeDialog } from '../documentType/EditDocumentTypeDialog';
import { SearchDocumentTypes } from '../documentType/SearchDocumentTypes';
import { ScrollToTop } from '../ScrollToTop';

export function CompetitionStep4Form({
  competition,
  values,
  setCompetition,
  setFieldValue,
  isSubmitting,
}) {
  const step = 4;
  const numberOfSteps = 4;

  if (!values.documentTypes) {
    values.documentTypes = [];
  }

  const [showAddDocumentType, setShowAddDocumentType] = useState(false);
  const handleCloseAddDocumentType = () => setShowAddDocumentType(false);
  const handleShowAddDocumentType = () => setShowAddDocumentType(true);

  const [showEditDocumentType, setShowEditDocumentType] = useState(false);
  const handleCloseEditDocumentType = () => setShowEditDocumentType(false);
  const handleShowEditDocumentType = () => setShowEditDocumentType(true);

  const [documentTypeToEdit, setDocumentTypeToEdit] = useState(null);

  const handleSelectedDocumentType = (selectedDocumentType) => {
    if (
      !values.documentTypes.find((x) => x.code === selectedDocumentType.code)
    ) {
      values.documentTypes.push(selectedDocumentType);
      setCompetition({ ...values });
    }
  };

  const showEditModal = (documentType) => {
    setDocumentTypeToEdit(documentType);
    handleShowEditDocumentType();
  };

  const handleEditedDocumentType = (documentType) => {
    const tempValues = { ...values };

    const editedDocumentTypes = tempValues.documentTypes.filter(
      (x) => x.code !== documentType.code
    );
    editedDocumentTypes.push(documentType);
    tempValues.documentTypes = editedDocumentTypes;
    setCompetition({ ...tempValues });
    setDocumentTypeToEdit(null);
    handleCloseEditDocumentType();
  };

  const handleRemove = (documentType) => {
    const tempValues = { ...values };
    tempValues.documentTypes = tempValues.documentTypes.filter(
      (x) => x.code !== documentType.code
    );

    setCompetition(tempValues);
  };

  const handleIsGeneral = (documentType, index) => {
    const tempValues = { ...values };

    const editedDocumentTypes = tempValues.documentTypes.filter(
      (x) => x.code !== documentType.code
    );
    documentType.general = !documentType.general;
    editedDocumentTypes.splice(index, 0, documentType);
    tempValues.documentTypes = editedDocumentTypes;
    setCompetition(tempValues);
  };

  const handleRequired = (documentType, index) => {
    const tempValues = { ...values };

    const editedDocumentTypes = tempValues.documentTypes.filter(
      (x) => x.code !== documentType.code
    );
    documentType.required = !documentType.required;
    editedDocumentTypes.splice(index, 0, documentType);
    tempValues.documentTypes = editedDocumentTypes;
    setCompetition(tempValues);
  };

  return (
    <div>
      <ScrollToTop />

      <h5 className='mb-4 pb-2 border-bottom'>
        <FormattedMessage
          id='all.steps'
          values={{ step: step, numberOfSteps: numberOfSteps }}
        />
        {' - '}
        <FormattedMessage id='competitionForm.title.documentTypes' />
      </h5>
      <div className='row'>
        <SearchDocumentTypes
          onClickDocumentType={handleSelectedDocumentType}
          descriptor={DocumentDescriptor.CANDIDATE}
        />
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <h5 className='mt-3 mb-2'>
            <FormattedMessage id='documentType.assigned' />
          </h5>
          <button
            type='button'
            className='btn btn-outline-secondary'
            onClick={handleShowAddDocumentType}
          >
            <FormattedMessage id='documentType.create.label' />
          </button>
          {values.documentTypes.length <= 0 && (
            <div className='my-4'>
              <p>
                <em>
                  <FormattedMessage id='documentType.empty' />
                </em>
              </p>
            </div>
          )}
          <ul className='results'>
            {values.documentTypes.length > 0 && (
              <div className='list-header'>
                <div className='row mt-4'>
                  <div className={'col-sm-6'}>
                    <FormattedMessage id='documentType.typeDocument' />
                  </div>
                  <div className={'col-sm-2 text-center'}>
                    <FormattedMessage id='all.general' />
                  </div>
                  <div className={'col-sm-2 text-center'}>
                    <FormattedMessage id='all.required' />
                  </div>
                </div>
              </div>
            )}
            {values.documentTypes.map((x, index) => {
              return (
                <li key={index}>
                  <div className='row my-4'>
                    <div className='col-sm-6 align-self-center'>
                      {x.description}
                    </div>
                    {x.general != null && (
                      <div className='col-sm-2 text-center'>
                        {x.descriptor === 'CANDIDATE' && (
                          <input
                            type='checkbox'
                            checked={x.general}
                            onClick={() => handleIsGeneral(x, index)}
                          />
                        )}
                      </div>
                    )}
                    {x.required != null && (
                      <div className='col-sm-2 text-center'>
                        {x.descriptor === 'CANDIDATE' && (
                          <input
                            type='checkbox'
                            checked={x.required}
                            onClick={() => handleRequired(x, index)}
                          />
                        )}
                      </div>
                    )}
                    <div className='col-sm-2 text-right'>
                      <button
                        type='button'
                        className={'btn btn-link p-0 pr-2'}
                        onClick={() => showEditModal(x)}
                      >
                        <FaPencilAlt />
                      </button>
                      <button
                        type='button'
                        className={'btn btn-link p-0 pr-2'}
                        onClick={() => handleRemove(x)}
                      >
                        <FaTrashAlt />
                      </button>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {showAddDocumentType && (
        <AddDocumentTypeDialog
          handleClose={handleCloseAddDocumentType}
          show={showAddDocumentType}
          onSave={handleSelectedDocumentType}
        />
      )}
      {showEditDocumentType && (
        <EditDocumentTypeDialog
          show={showEditDocumentType}
          handleClose={handleCloseEditDocumentType}
          onSave={handleEditedDocumentType}
          documentType={documentTypeToEdit}
        />
      )}
      <div className='form-actions'>
        <div className='mr-auto'>
          <button
            className='btn btn-link'
            type='button'
            onClick={(e) => setFieldValue('step', competition.step - 1)}
            disabled={isSubmitting}
          >
            <FormattedMessage id='all.previousButtonText' />
          </button>
        </div>
        <div className='ml-auto'>
          {competition.state === DRAFT && (
            <button
              className='btn btn-outline-primary mr-3'
              type='submit'
              onClick={(e) =>
                setFieldValue('submissionType', 'finalSubmitAndAddHabitation')
              }
              disabled={isSubmitting}
            >
              <FormattedMessage id='all.submitAndAddHabitationText' />
            </button>
          )}
          <button
            className='btn btn-primary'
            type='submit'
            onClick={(e) => setFieldValue('submissionType', 'finalSubmit')}
            disabled={isSubmitting}
          >
            <FormattedMessage id='all.submitButtonText' />
          </button>
        </div>
      </div>
    </div>
  );
}

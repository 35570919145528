import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { CandidatureState } from '../../../../models/CandidatureState';
import { MessageDirection } from '../../../../models/MessageDirection';
import { getCandidatureHistory } from '../../../../rest/candidatureuser';
import { Arrays } from '../../../../utils/Arrays';
import { handleError, isNotBusinessError } from '../../../../utils/handleError';

function CandidatureHistory() {
  const { externalId } = useParams();
  const { platformCode } = useParams();
  const { competitionCode } = useParams();

  const [loading, setLoading] = useState(true);
  const [candidatureHistory, setCandidatureHistory] = useState({});
  const [error, setError] = useState(null);

  const intl = useIntl();

  const fetchData = async () => {
    try {
      const { data: candidatureHistory } = await getCandidatureHistory(
        externalId
      );

      setCandidatureHistory(candidatureHistory);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate
      hasBackButton
      titleId='candidatureHistory.title'
      titleValues={{
        candidatureCode: candidatureHistory.candidatures[0].code,
        competitionCode: decodeURIComponent(competitionCode),
      }}
    >
      <div className={'container'}>
        <div className={'row accordion-panel'}>
          <div className={'col-lg-12'}>
            <AlertError error={error} />

            <Accordion
              key={0}
              className={'mb-4'}
              defaultActiveKey='candidature.history.changeStates'
            >
              <Card>
                <Card.Header className={'d-flex justify-content-between'}>
                  <Accordion.Toggle
                    as={Button}
                    variant='primary'
                    eventKey='candidature.history.changeStates'
                  >
                    <FormattedMessage id='candidature.history.changeStates' />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse
                  eventKey={'candidature.history.changeStates'}
                >
                  <Card.Body className='p-0'>
                    {candidatureHistory.candidatures.map((candidature) => (
                      <div className='form row accordion  pt-3 pb-3'>
                        <div className='col-lg-3'>
                          <label>
                            <FormattedMessage id='candidatureUser.candidatureStateText' />
                          </label>
                          <span>
                            {' '}
                            {intl.formatMessage({
                              id: 'CandidatureStateTitle.' + candidature.state,
                            })}
                          </span>
                        </div>
                        <div className='col-lg-3'>
                          <label>
                            <FormattedMessage id='candidatureUser.candidatureDateText' />
                          </label>
                          <span> {candidature.changeDate}</span>
                        </div>
                        {candidature.habitationCode && (
                          <div className='col-lg-4'>
                            <label>
                              <FormattedMessage id='candidature.history.habitation' />
                            </label>
                            <p>
                              <Link
                                to={`/programas/${encodeURIComponent(
                                  platformCode
                                )}/concursos/${encodeURIComponent(
                                  competitionCode
                                )}/alojamentos/${
                                  candidature.habitationExternalId
                                }`}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                {' '}
                                {candidature.habitationCode}
                              </Link>
                            </p>
                          </div>
                        )}

                        {candidature.position && (
                          <div className='col-lg-2'>
                            <label>
                              <FormattedMessage id='candidature.history.position' />
                            </label>
                            <span> {candidature.position}</span>
                          </div>
                        )}

                        {candidature.exludeReason && (
                          <div className='col-lg-6'>
                            <label>
                              <FormattedMessage
                                id={
                                  candidature.state ===
                                  CandidatureState.EXCLUDED
                                    ? 'candidature.history.exludeReason'
                                    : 'candidature.history.resignReason'
                                }
                              />
                            </label>
                            <span> {candidature.exludeReason}</span>
                          </div>
                        )}

                        {candidature.appointmentDate && (
                          <div className='col-lg-4'>
                            <label>
                              <FormattedMessage id='candidature.history.appointmentDate' />
                            </label>
                            <span> {candidature.appointmentDate}</span>
                          </div>
                        )}

                        {candidature.contestation && (
                          <div className='col-lg-6'>
                            <label>
                              <FormattedMessage id='candidature.history.contestation' />
                            </label>
                            <span> {candidature.contestation}</span>
                          </div>
                        )}

                        {candidature.documentSubmissionDeadline && (
                          <div className='col-lg-6'>
                            <label>
                              <FormattedMessage id='candidature.history.documentSubmissionDeadline' />
                            </label>
                            <span>
                              {' '}
                              {candidature.documentSubmissionDeadline}
                            </span>
                          </div>
                        )}

                        {candidature.askMoreTimeReason && (
                          <div className='col-lg-6'>
                            <label>
                              <FormattedMessage id='candidature.askMoreTimeReason' />
                            </label>
                            <span> {candidature.askMoreTimeReason}</span>
                          </div>
                        )}
                      </div>
                    ))}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              {Arrays.isNotEmpty(candidatureHistory.messages) && (
                <>
                  <Card>
                    <Card.Header className={'d-flex justify-content-between'}>
                      <Accordion.Toggle
                        as={Button}
                        variant='primary'
                        eventKey='candidature.history.messages'
                      >
                        <FormattedMessage id='candidature.history.messages' />
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse
                      eventKey={'candidature.history.messages'}
                    >
                      <Card.Body className='p-0'>
                        {candidatureHistory.messages.map((message) => (
                          <div className='form row accordion  pt-3 pb-3'>
                            <div className='col-lg-6'>
                              <label>
                                <FormattedMessage id='candidature.history.MessageDate' />
                              </label>
                              <span> {message.creationTs}</span>
                            </div>
                            <div className='col-lg-6'>
                              <label>
                                <FormattedMessage id='messages.toText' />
                              </label>
                              <span>
                                {message.direction ===
                                MessageDirection.TO_CANDIDATE ? (
                                  <FormattedMessage id='messages.toCandidateText' />
                                ) : (
                                  <FormattedMessage id='messages.fromCandidateText' />
                                )}
                              </span>
                            </div>
                            <div className='col-lg-12 mt-2'>
                              <label>
                                <FormattedMessage id='candidature.history.MessageTitle' />
                              </label>
                              <span> {message.title}</span>
                            </div>
                            <div className='col-lg-12 mt-2'>
                              <label>
                                <FormattedMessage id='candidature.history.MessageBody' />
                              </label>
                              <span> {parse(message.body)}</span>
                            </div>
                          </div>
                        ))}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </>
              )}
            </Accordion>
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default CandidatureHistory;

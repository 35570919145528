import React from 'react';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';

export function CompetitionDatesForm({
  touched,
  errors,
  competition,
  isNotEditable,
  setFieldValue,
}) {
  return (
    <>
      <div className='row'>
        <div className='col-md-6'>
          <DateTimeInputField
            name='publishDeadline'
            value={competition.publishDeadline}
            errorMessage={errors.publishDeadline}
            isInvalid={touched.publishDeadline && errors.publishDeadline}
            disabled={isNotEditable && isNotEditable('publishDeadline')}
            setFieldValue={setFieldValue}
            labelId='competitionForm.competitionPublishDeadlineText'
          ></DateTimeInputField>
        </div>
        <div className='col-md-6'>
          <DateTimeInputField
            name='startRegistrationDate'
            value={competition.startRegistrationDate}
            errorMessage={errors.startRegistrationDate}
            isInvalid={
              touched.startRegistrationDate && errors.startRegistrationDate
            }
            disabled={isNotEditable && isNotEditable('startRegistrationDate')}
            setFieldValue={setFieldValue}
            labelId='competitionForm.competitionStartRegistrationDateText'
          ></DateTimeInputField>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <DateTimeInputField
            name='endRegistrationDate'
            value={competition.endRegistrationDate}
            errorMessage={errors.endRegistrationDate}
            isInvalid={
              touched.endRegistrationDate && errors.endRegistrationDate
            }
            disabled={isNotEditable && isNotEditable('endRegistrationDate')}
            setFieldValue={setFieldValue}
            labelId='competitionForm.competitionEndRegistrationDateText'
          ></DateTimeInputField>
        </div>
        <div className='col-md-6'>
          <DateTimeInputField
            name='publicationDateAcceptedApps'
            value={competition.publicationDateAcceptedApps}
            errorMessage={errors.publicationDateAcceptedApps}
            isInvalid={
              touched.publicationDateAcceptedApps &&
              errors.publicationDateAcceptedApps
            }
            disabled={
              isNotEditable && isNotEditable('publicationDateAcceptedApps')
            }
            setFieldValue={setFieldValue}
            labelId='competitionForm.competitionPublicationDateAcceptedAppsText'
          ></DateTimeInputField>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <DateTimeInputField
            name='drawDate'
            value={competition.drawDate}
            errorMessage={errors.drawDate}
            isInvalid={touched.drawDate && errors.drawDate}
            disabled={isNotEditable && isNotEditable('drawDate')}
            setFieldValue={setFieldValue}
            labelId='competitionForm.competitionDrawDateText'
          ></DateTimeInputField>
        </div>
        <div className='col-md-6'>
          <DateTimeInputField
            name='publicationDateCompResult'
            value={competition.publicationDateCompResult}
            errorMessage={errors.publicationDateCompResult}
            isInvalid={
              touched.publicationDateCompResult &&
              errors.publicationDateCompResult
            }
            disabled={
              isNotEditable && isNotEditable('publicationDateCompResult')
            }
            setFieldValue={setFieldValue}
            labelId='competitionForm.competitionPublicationDateCompResultText'
          ></DateTimeInputField>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <DateTimeInputField
            name='conclusionDate'
            value={competition.conclusionDate}
            errorMessage={errors.conclusionDate}
            isInvalid={touched.conclusionDate && errors.conclusionDate}
            disabled={isNotEditable && isNotEditable('conclusionDate')}
            setFieldValue={setFieldValue}
            labelId='competitionForm.competitionConclusionDateText'
          ></DateTimeInputField>
        </div>
      </div>
    </>
  );
}

import parse from 'html-react-parser';
import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  TranslateMessageDirectionFilter,
  TranslateMessageSubject,
  TranslateMessageType
} from '../../utils/TranslateStates';

function MessageList({ messages }) {
  const intl = useIntl();
  return (
    <>
      {messages?.length > 0 ? (
        <table className='table'>
          <thead>
            <tr>
              <th>
                <label>
                  <FormattedMessage id='messages.titleText' />
                </label>
              </th>
              <th>
                <label>
                  <FormattedMessage id='messages.directionText' />
                </label>
              </th>
              <th>
                <label>
                  <FormattedMessage id='messages.subjectText' />
                </label>
              </th>
              <th>
                <label>
                  <FormattedMessage id='messages.typeText' />
                </label>
              </th>
              <th>
                <label>
                  <FormattedMessage id='messages.dateText' />
                </label>
              </th>
              <th>
                <label>
                  <FormattedMessage id='messages.competitionText' />
                </label>
              </th>
              <th>
                <label>
                  <FormattedMessage id='messages.regionText' />
                </label>
              </th>
            </tr>
          </thead>
          <tbody>
            {messages.map((message) => (
              <tr key={message.externalId}>
                <td>
                  <Link
                    to={`/perfil/mensagem/${message.externalId}`}
                    style={{
                      fontWeight:
                        message?.state === 'PENDING' ? 'bolder' : 'normal',
                    }}
                  >
                    {message.title ? parse(message.title) : 'No title'}
                  </Link>
                </td>
                <td> {TranslateMessageDirectionFilter(message.direction)} </td>
                <td>
                  <TranslateMessageSubject value={message.subject} />
                </td>
                <td>
                  <TranslateMessageType value={message.type} />
                </td>
                <td>{message.creationTs}</td>
                <td>{message.competitionNumber}</td>
                <td>
                  {message.region
                    ? intl.formatMessage({
                        id: `clarificationRequestForm.region_${message.region}`,
                      })
                    : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className='empty-state my-4'>
          <FaInfoCircle />
          <p>
            <FormattedMessage id='messages.noMessagesText' />
          </p>
        </div>
      )}
    </>
  );
}

export default MessageList;

import axios from 'axios';
import { apiAxios as authAxios } from '../axios.config';

export const startDraw = (competitionCode, platformCode, cadence) => {
  return authAxios.patch(
    `/api/platform/${platformCode}/competitions/${competitionCode}/startDraw`,
    cadence
  );
};

export const getDrawActualCandidatures = (competitionCode, platformCode) => {
  return axios.get(
    `/api/platform/${platformCode}/competitions/${competitionCode}/actualCandidatures`
  );
};

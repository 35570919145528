import React, { useEffect, useState } from 'react';
import { FaInfoCircle, FaSearch } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { hasPermission } from '../../../../authentication/authenticationHelper';
import { TextInputField } from '../../../../components/bootstrap/TextInputField';
import { CandidatureAllStatesFilterBar } from '../../../../components/candidature/CandidatureAllStatesFilterBar';
import { CandidaturesTable } from '../../../../components/candidature/CandidaturesTable';
import { ComponentLoading } from '../../../../components/ComponentLoading';
import Loading from '../../../../components/Loading';
import { PaginationComponent } from '../../../../components/PaginationComponent';
import { SubTemplate } from '../../../../components/SubTemplate';
import { useGlobalContext } from '../../../../context';
import { useQuery } from '../../../../hooks/useQuery';
import { CompetitionState } from '../../../../models/CompetitionState';
import {
  getAdmittedCandidaturesByCompetition,
  getCandidaturesByCompetition,
} from '../../../../rest/candidature';
import { getCompetitionByCompetitionCode } from '../../../../rest/competition';
import { handleError, isBusinessError } from '../../../../utils/handleError';
import { Strings } from '../../../../utils/Strings';

const getType = (query) => {
  return query && query.get('tipo');
};

const MAX_RESULTS = 15;
const DEFAULT_INITIAL_PAGE = 0;

export function ListCandidatures() {
  const { competitionCode, externalId, platformCode } = useParams();
  const query = useQuery();
  const intl = useIntl();
  const [selectedType, setSelectedType] = useState(getType(query));
  const [states, setStates] = useState([]);
  const { accessInfo } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(true);
  const [error, setError] = useState();
  const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

  const [candidatures, setCandidatures] = useState([]);
  const [competition, setCompetition] = useState();
  const [sortFilters, setSortFilters] = useState([]);
  const [inputFilter, setInputFilter] = useState('');

  useEffect(() => {
    const fetchData = async (index) => {
      try {
        const { data: competitionData } = await getCompetitionByCompetitionCode(
          competitionCode,
          platformCode
        );
        setCompetition(competitionData);
        if (hasPermission('LIST_CANDIDATURES', accessInfo)) {
          const { data: candidaturesData } = await getCandidaturesByCompetition(
            competitionCode,
            platformCode,
            index,
            MAX_RESULTS,
            selectedType,
            sortFilters,
            Strings.isBlank(inputFilter) ? null : inputFilter
          );
          setCandidatures(candidaturesData);
        } else {
          const { data: candidaturesData } =
            await getAdmittedCandidaturesByCompetition(
              competitionCode,
              platformCode,
              index,
              MAX_RESULTS
            );
          setCandidatures(candidaturesData);
        }

        setCurrentPage(index);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
        setSearching(false);
      }
    };
    setSearching(true);
    fetchData(currentPage);
  }, [selectedType, currentPage, externalId]);

  const fetchDataWithSortFilters = async (sortFilters) => {
    try {
      const { data: candidaturesData } = await getCandidaturesByCompetition(
        competitionCode,
        platformCode,
        currentPage,
        MAX_RESULTS,
        selectedType,
        sortFilters
      );
      setCandidatures(candidaturesData);
    } catch (error) {
      setError(error);
    }
  };

  const searchByNifOrCode = async () => {
    try {
      const { data: candidaturesData } = await getCandidaturesByCompetition(
        competitionCode,
        platformCode,
        0,
        MAX_RESULTS,
        selectedType,
        null,
        inputFilter
      );
      setCandidatures(candidaturesData);
      setCurrentPage(0);
    } catch (error) {
      setError(error);
    }
  };

  const filterCandidature = (type) => {
    setSelectedType(type);
  };

  const onPageChange = (pageNumber) => {
    if (candidatures.length > pageNumber) {
      setCurrentPage(pageNumber);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const handleInputFilterChange = (e) => {
    setInputFilter(e.target.value);
  };

  if (loading) {
    return <Loading />;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate
      hasBackButton
      title={`${intl.formatMessage({
        id: 'listCandidatures.competitionText',
      })} ${decodeURIComponent(competitionCode)}`}
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            <div>
              {hasPermission('LIST_CANDIDATURES', accessInfo) &&
                !competitionInSubmissionPhase(competition, platformCode) && (
                  <>
                    <div className='row px-3 mb-2'>
                      <div className='col-xs-4'>
                        <CandidatureAllStatesFilterBar
                          onFilter={filterCandidature}
                          states={states}
                          defaultFilter={selectedType}
                        />
                      </div>
                      <div className='col-xs-4'>
                        <div className='mt-4'>
                          <TextInputField
                            name='searchByNifOrCode'
                            value={inputFilter}
                            handleChange={handleInputFilterChange}
                            placeholder={intl.formatMessage({
                              id: 'listCandidatures.searchByNifOrCodeFilter.text',
                            })}
                          />
                        </div>
                      </div>
                      <div className='col-xs-4'>
                        <div className='mt-4 ml-3'>
                          <button
                            className={'btn btn-primary'}
                            onClick={searchByNifOrCode}
                            type='submit'
                          >
                            <FaSearch className={'mx-0'} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              {hasResults(candidatures) ? (
                <>
                  {searching ? (
                    <ComponentLoading />
                  ) : (
                    <>
                      <CandidaturesTable
                        listCandidaturesMetaData={candidatures}
                        fetchDataWithSortFilters={fetchDataWithSortFilters}
                        sortFilters={sortFilters}
                        setSortFilters={setSortFilters}
                      />
                      <PaginationComponent
                        dataPerPage={MAX_RESULTS}
                        totalData={candidatures.numberOfCandidatures}
                        onPageChange={onPageChange}
                        currentPage={currentPage}
                      />
                    </>
                  )}
                </>
              ) : (
                <div className='empty-state my-4'>
                  <FaInfoCircle />
                  <p>
                    <FormattedMessage id='candidatures.noCandidaturesText' />
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

const hasResults = (candidatures) => {
  return candidatures.candidatures.length > 0;
};

const competitionInSubmissionPhase = (competition, platformCode) => {
  return (
    (competition.state === CompetitionState.CANDIDATURES_SUBMISSION_PHASE ||
      competition.state === CompetitionState.APPROVED) &&
    platformCode === 'EAA'
  );
};

import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { ConfigObject } from '../config';
/**
 * @param  {string} ticket
 * @returns {W3CWebSocket}
 */
export const createConnection = (ticket) => {
  return new W3CWebSocket(
    `${ConfigObject.get().wsUrl}/api/notifications/${ticket}`
  );
};

/**
 * @param  {string} competitionCode
 * @returns {W3CWebSocket}
 */
export const createDrawConnection = (competitionCode, platformCode) => {
  return new W3CWebSocket(
    `${
      ConfigObject.get().wsUrl
    }/api/platform/${platformCode}/competitions/${competitionCode}/draw`
  );
};

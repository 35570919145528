import { Formik } from 'formik';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { EditorInputField } from '../bootstrap/EditorInputField';
import { IntInputField } from '../bootstrap/IntInputField';
import { TextInputField } from '../bootstrap/TextInputField';

/**
 * @param  {{faqDto:{platform?: string, question: string, answer: string, }, onSubmit }} props.faqDto
 */
function FaqForm({ faq, save }) {
  const intl = useIntl();
  const faqSchema = yup.object().shape({
    question: yup
      .string()
      .required(intl.formatMessage({ id: 'faqForm.question.required' })),
    answer: yup
      .string()
      .required(intl.formatMessage({ id: 'faqForm.answer.required' })),
    orderNumber: yup
      .number()
      .required(
        intl.formatMessage({
          id: 'faqForm.orderNumber.required',
        })
      )
      .min(
        1,
        intl.formatMessage({
          id: 'faqForm.error.minOrderNumber',
        })
      ),
  });

  return (
    <div>
      <Formik
        initialValues={faq}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={faqSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          save(values, setSubmitting, setErrors);
        }}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <IntInputField
              controlId='orderNumber'
              value={values.orderNumber}
              labelId='faqForm.orderNumberText'
              handleChange={handleChange}
              errorMessage={errors.orderNumber}
              isInvalid={errors.orderNumber}
            />

            <Form.Group controlId='platform'>
              <Form.Label>
                <FormattedMessage id='faqForm.platformText' />
              </Form.Label>
              <Form.Control
                as='select'
                name='platform'
                value={values.platform}
                onChange={handleChange}
                disabled
              >
                <option>{values.platform}</option>
              </Form.Control>
            </Form.Group>

            <TextInputField
              name='question'
              labelId='faqForm.questionText'
              value={values.question}
              handleChange={handleChange}
              isInvalid={touched.question && errors.question}
              errorMessage={errors.question}
            />

            <EditorInputField
              name='answer'
              labelId='faqForm.answerText'
              value={values.answer}
              handleChange={handleChange}
              isInvalid={touched.answer && errors.answer}
              errorMessage={errors.answer}
              placeholder={intl.formatMessage({ id: 'faqForm.answerText' })}
              hideOvelay={true}
            />

            <div className='form-actions'>
              <div className='mr-auto'>
                <Link
                  to={`/perguntas/${values.platform}`}
                  className='btn btn-link'
                >
                  <FormattedMessage id='all.backButtonText' />
                </Link>
              </div>
              <div className='ml-auto'>
                <Button variant='primary' type='submit' disabled={isSubmitting}>
                  <FormattedMessage id='all.submitButtonText' />
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default FaqForm;

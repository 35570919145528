import { apiAxios as axios } from '../axios.config';

export const deleteParameter = (code) => {
  const encondedCode = encodeURIComponent(code);
  return axios.delete(`api/parameter/${encondedCode}`);
};

export const getParameters = () => {
  return axios.get('api/parameter');
};

export const updateParameter = (parameterCode, parameter) => {
  return axios.put(`/api/parameter/${parameterCode}`, parameter);
};

export const getParameterByCode = (parameterCode) => {
  return axios.get(`/api/parameter/code/${parameterCode}`);
};

export const addParameter = (parameter) => {
  return axios.post('/api/parameter', parameter);
};

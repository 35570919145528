import axios from 'axios';
import { apiAxios as authAxios } from '../axios.config';

export const getHabitationDocument = (habitationExternalId) => {
  return axios.get(`/api/habitations/${habitationExternalId}/documents`);
};

export const addHabitationDocument = (habitationExternalId, formData) => {
  return authAxios.post(
    `/api/habitations/${habitationExternalId}/documents`,
    formData
  );
};

export const deleteHabitationDocument = (
  habitationExternalId,
  documentName
) => {
  return authAxios.delete(
    `/api/habitations/${habitationExternalId}/documents/${documentName}`
  );
};

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { updateDocumentType } from '../../rest/documentType';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import { AlertError } from '../bootstrap/AlertError';
import Loading from '../Loading';
import { DocumentTypeForm } from './DocumentTypeForm';

export function EditDocumentTypeDialog({
  show,
  handleClose,
  onSave,
  documentType,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updatePlatform = async (documentType) => {
    try {
      const { data } = await updateDocumentType(
        encodeURIComponent(documentType.code),
        documentType
      );
      setLoading(false);
      onSave(data);
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  };

  const handleClick = (documentType) => {
    setLoading(true);
    updatePlatform(documentType);
  };

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='documentType.edit' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <Loading />}
        {!loading && (
          <>
            <AlertError error={error} />
            <DocumentTypeForm
              docType={documentType}
              onSave={handleClick}
              onBack={handleClose}
              isUpdate={true}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

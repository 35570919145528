export const ValidateOptions = {
  YES: 'YES',
  NO: 'NO',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
  values: () => {
    return Object.keys(ValidateOptions)
      .filter((key) => key !== 'values')
      .map((key) => ValidateOptions[key]);
  },
};

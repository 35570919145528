import { useIntl } from 'react-intl';

export function TranslateBoolean({ value }) {
  const intl = useIntl();
  switch (value) {
    case true:
      return intl.formatMessage({
        id: 'all.yesText',
      });
    case false:
      return intl.formatMessage({
        id: 'all.noText',
      });
    default:
      return value;
  }
}

export const CandidatureState = {
  STAGING: 'STAGING',
  CREATED: 'CREATED',
  ASSIGNED: 'ASSIGNED',
  DOCUMENT_VALIDATION_INITIAL: 'DOCUMENT_VALIDATION_INITIAL',
  EXCLUSION_PROJECT: 'EXCLUSION_PROJECT',
  WAIT_CONTESTATION: 'WAIT_CONTESTATION',
  ASK_MORE_TIME: 'ASK_MORE_TIME',
  ANALYZE: 'ANALYZE',
  APPOINTMENT_BOOKING: 'APPOINTMENT_BOOKING',
  WAIT_APPOINTMENT: 'WAIT_APPOINTMENT',
  POS_APPOINTMENT: 'POS_APPOINTMENT',
  DOCUMENT_DELIVERY: 'DOCUMENT_DELIVERY',
  DOCUMENT_VALIDATION_FINAL: 'DOCUMENT_VALIDATION_FINAL',
  CHOOSE_ASSIGNED_HABITATIONS: 'CHOOSE_ASSIGNED_HABITATIONS',
  ASSIGNMENT_PROPOSAL: 'ASSIGNMENT_PROPOSAL',
  WAIT_OTHER_WITHDRAWALS: 'WAIT_OTHER_WITHDRAWALS',
  PROPOSAL: 'PROPOSAL',
  VALIDATE_OTHER_CONTRACTS: 'VALIDATE_OTHER_CONTRACTS',
  KEY_DELIVERY: 'KEY_DELIVERY',
  SUBSTITUTE: 'SUBSTITUTE',
  CONTRACT_ELIGIBLE_VALIDATION: 'CONTRACT_ELIGIBLE_VALIDATION',
  CONTRACT_CELEBRATION: 'CONTRACT_CELEBRATION',
  SUSPENDED: 'SUSPENDED',
  EXCLUDED: 'EXCLUDED',
  DELETED: 'DELETED',
  CLOSED: 'CLOSED',
  RESIGNATION: 'RESIGNATION',

  values: () => {
    return Object.keys(CandidatureState)
      .filter((key) => key !== 'values' && key !== 'getAvailableToSuspend')
      .map((key) => CandidatureState[key]);
  },

  getAvailableToSuspend: () => {
    return [
      'ASSIGNED',
      'WAIT_APPOINTMENT',
      'CHOOSE_ASSIGNED_HABITATIONS',
      'EXCLUSION_PROJECT',
    ];
  },
};

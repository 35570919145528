import React, { useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaEye, FaInfoCircle } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { PlatformType } from "../../models/PlatformType";
import { TranslateBoolean } from "../../utils/TranslateBoolean";
import { DecimalInputField } from "../bootstrap/DecimalInputField";
import { ScoreDetailsTable } from "./ScoreDetailsTable";

function ListCandidature({
  candidature,
  setCandidature,
  platformCode,
  hasMemberOnOtherCompetition,
  viewMode,
}) {
  const intl = useIntl();
  const [show, setShow] = useState(false);

  function isInArray(array) {
    return candidature.fieldsFixed.some((item) => item === array);
  }

  function handleShow() {
    setShow(true);
  }

  function handleClose() {
    setShow(false);
  }

  function canShowOtherInfo() {
    return (
      candidature.candidatureDto.documentSubmissionDeadline ||
      candidature.candidatureDto.appointmentDate ||
      candidature.candidatureDto.contestation ||
      candidature.candidatureDto.excludeReason ||
      candidature.candidatureDto.resignReason ||
      candidature.candidatureDto.askMoreTimeReason ||
      hasMemberOnOtherCompetition?.flag
    );
  }

  function handleIncomeChange(e, incomeType) {
    candidature.candidatureDto[incomeType] = e.target.value;
    setCandidature({ ...candidature });
  }

  function handleSingleParentFamilyChange(e) {
    candidature.candidatureDto.singleParentFamily = e.target.value;
    setCandidature({ ...candidature });
  }

  function hasPriorityCriteria(code) {
    return candidature.competitionCriterias.map((cc) => cc.code).includes(code);
  }

  function findPriorityCriteriaByCode(code) {
    return candidature.competitionCriterias.find((cc) => cc.code === code);
  }

  const singleParentFamily = candidature.candidatureDto.singleParentFamily;
  const drawPriority = candidature.candidatureDto.drawPriority;

  return (
    <div>
      <div className="row">
        {candidature.candidatureDto.competitionCode && (
          <div className="col-md-12">
            <div className="row">
              <div className={"col-md-4"} style={{ marginBottom: "1rem" }}>
                <label>
                  <FormattedMessage id="candidatureUser.competitionCodeText" />
                </label>
                <Link
                  className={"competition-to-print"}
                  to={`/programas/${encodeURIComponent(
                    candidature.candidatureDto.platformCode
                  )}/concursos/${encodeURIComponent(
                    candidature.candidatureDto.competitionCode
                  )}`}
                >
                  {candidature.candidatureDto.competitionCode}
                </Link>
              </div>
              {isInArray("code") && (
                <div className="col-md-4">
                  <label>
                    <FormattedMessage id="candidature.codeText" />
                  </label>
                  <p>{candidature.candidatureDto.code}</p>
                </div>
              )}
              {isInArray("candidature.candidatureEAA.requestNumber") && (
                <div className="col-md-4">
                  <label>
                    <FormattedMessage id="candidature.candidatureEAA.requestNumber" />
                  </label>
                  <p>{candidature.candidatureDto.code}</p>
                </div>
              )}
              {isInArray("candidatureEAA.registrationDate") && (
                <>
                  <div className="col-md-4">
                    <label>
                      <FormattedMessage id="candidature.candidatureEAA.requestDate" />
                    </label>
                    <p>
                      {
                        candidature.candidatureDto.candidatureEAA
                          .registrationDate
                      }
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        <div className="col-md-12">
          {isInArray("candidatureEAA.registrationDate") && (
            <>
              <div className="row">
                <div className="col-md-4">
                  <label>
                    <FormattedMessage id="candidature.candidatureEAA.numberYears" />
                  </label>
                  <p>
                    {candidature.candidatureDto.candidatureEAA.candidatureYears}
                  </p>
                </div>
                <div className="col-md-4">
                  <label>
                    <FormattedMessage id="candidature.candidatureEAA.typologies" />
                  </label>
                  <p>
                    {candidature.candidatureDto.minTypology.code +
                      " - " +
                      candidature.candidatureDto.maxTypology.code}
                  </p>
                </div>
                <div className="col-md-4">
                  <label>
                    <FormattedMessage id="candidature.candidatureEAA.unititledAgregate" />
                  </label>
                  <p>
                    {" "}
                    <TranslateBoolean
                      value={
                        candidature.candidatureDto.candidatureEAA
                          .unititledAgregate
                      }
                    />
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <label>
                    <FormattedMessage id="candidature.candidatureEAA.elementNumber" />
                  </label>
                  <p>
                    {candidature.candidatureDto.candidatureEAA.agregateElements}
                  </p>
                </div>
                <div className="col-md-4">
                  <label>
                    <FormattedMessage id="candidature.candidatureEAA.dependentsNumber" />
                  </label>
                  <p>
                    {candidature.candidatureDto.candidatureEAA.dependentsNumber}
                  </p>
                </div>
                <div className="col-md-4">
                  <label>
                    <FormattedMessage id="candidature.candidatureEAA.incapacityElementsNumber" />
                  </label>
                  <p>{candidature.candidatureDto.candidatureEAA.incElements}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <label>
                    <FormattedMessage id="candidature.candidatureEAA.over65Elements" />
                  </label>
                  <p>
                    {candidature.candidatureDto.candidatureEAA.over65Elements}
                  </p>
                </div>
                <div className="col-md-4">
                  <label>
                    <FormattedMessage id="candidature.candidatureEAA.residenceSituation" />
                  </label>
                  <p>
                    {
                      candidature.candidatureDto.candidatureEAA
                        .residencySituation.description
                    }
                  </p>
                </div>
                <div className="col-md-4">
                  <label>
                    <FormattedMessage id="candidature.candidatureEAA.liveHS" />
                  </label>
                  <p>
                    {" "}
                    <TranslateBoolean
                      value={
                        candidature.candidatureDto.candidatureMembers[0]
                          .candidatureMemberEAADto.applicantLivedHS
                      }
                    />
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8">
                  <label>
                    <FormattedMessage id="candidature.candidatureEAA.requestMotive" />
                  </label>
                  <p>
                    {
                      candidature.candidatureDto.candidatureEAA.requestMotive
                        .description
                    }
                  </p>
                </div>
              </div>
            </>
          )}
          <div className="row">
            {isInArray("goalCode") && (
              <div className="col-md-4">
                <label>
                  <FormattedMessage id="candidature.goalCodeText" />
                </label>
                <p>{candidature.candidatureDto.goal.description}</p>
              </div>
            )}
            {isInArray("modalityCode") && (
              <div className="col-md-4">
                <label>
                  <FormattedMessage id="candidature.modalityCodeText" />
                </label>
                <p>{candidature.candidatureDto.modality.name}</p>
              </div>
            )}
          </div>
          <div className="row">
            {isInArray("maxTypologyCode") && (
              <div className="col-md-4">
                <label>
                  <FormattedMessage id="candidature.minTypologyCodeText" />
                </label>
                <p>{candidature.candidatureDto.minTypology.code}</p>
              </div>
            )}
            {isInArray("maxTypologyCode") && (
              <div className="col-md-4">
                <label>
                  <FormattedMessage id="candidature.maxTypologyCodeText" />
                </label>
                <p>{candidature.candidatureDto.maxTypology.code}</p>
              </div>
            )}
          </div>
          <div className="row">
            {isInArray("minimumRent") && (
              <div className="col-md-4">
                <label>
                  <FormattedMessage id="candidature.minimumRentText" />
                </label>
                <p>{candidature.candidatureDto.minimumRent} €</p>
              </div>
            )}
            {isInArray("maximumRent") && (
              <div className="col-md-4">
                <label>
                  <FormattedMessage id="candidature.maximumRentText" />
                </label>
                <p>{candidature.candidatureDto.maximumRent} €</p>
              </div>
            )}

            {isInArray("representativeText") && (
              <div className="col-md-4">
                <label>
                  <FormattedMessage id="candidature.representativeText" />
                </label>
                <p>
                  <TranslateBoolean
                    value={candidature.candidatureDto.representative}
                  />
                </p>
              </div>
            )}
          </div>

          {platformCode === PlatformType.PAS &&
            candidature.competitionCriterias.length > 0 && (
              <>
                <legend>
                  <FormattedMessage id="priorityCriteria.title" />{" "}
                  {drawPriority !== null && (
                    <strong>
                      <FormattedMessage
                        id={`${
                          drawPriority
                            ? "candidature.priority"
                            : "candidature.noPriority"
                        }`}
                      />
                    </strong>
                  )}
                </legend>
                <div className="row align-items-end">
                  {hasPriorityCriteria("singleParentFamily") && (
                    <div className="col-md-4">
                    {viewMode ? (
                      <>
                        <label>
                          <FormattedMessage id="candidature.singleParentFamilyText" />
                        </label>
                        <p>
                          <TranslateBoolean
                            value={
                              singleParentFamily === "true" ||
                              singleParentFamily === true
                                ? true
                                : false
                            }
                          />
                        </p>
                      </>
                    ) : (
                      <Form.Group
                        controlId="singleParentFamily"
                        className="regular-radio"
                      >
                        <Form.Label>
                          <FormattedMessage id="candidature.singleParentFamilyText" />
                          <OverlayTrigger
                            overlay={
                              <Tooltip>
                                <FormattedMessage id="priorityCriteria.singleParentFamily.tooltip" />
                              </Tooltip>
                            }
                          >
                            <FaInfoCircle className="ml-1 pb-1" />
                          </OverlayTrigger>
                        </Form.Label>
                        <Form.Check
                          type="radio"
                          onChange={(e) => handleSingleParentFamilyChange(e)}
                          name="singleParentFamily"
                          value="true"
                          label={<FormattedMessage id={"all.yesText"} />}
                          checked={
                            singleParentFamily === "true" ||
                            singleParentFamily
                          }
                          disabled={viewMode}
                        />

                        <Form.Check
                          type="radio"
                          onChange={(e) => handleSingleParentFamilyChange(e)}
                          name="singleParentFamily"
                          value="false"
                          label={<FormattedMessage id={"all.noText"} />}
                          checked={
                            singleParentFamily === "false" ||
                            !singleParentFamily
                          }
                          disabled={viewMode}
                        />
                      </Form.Group>
                    )}
                  </div>
                  )}
                  {hasPriorityCriteria("incomeDrops") && (
                    <div className="col-8">
                      <div className="row">
                        <div className="col-md-12">
                          <label>
                            <FormattedMessage
                              id="candidature.incomeDrop"
                              values={{
                                incomeDrop:
                                  candidature.competitionCriterias.find(
                                    (cc) => cc.code === "incomeDrops"
                                  ).criteriaValue,
                              }}
                            />
                          </label>
                          <hr className="mt-0" />
                        </div>
                      </div>
                      <div className="row">
                      <>
                      <div className="col-md-6">
                        {viewMode ? (
                          <>
                            <label>
                              <FormattedMessage id="candidature.previousIncome" />
                            </label>
                            <p>{candidature.candidatureDto.previousIncome} €</p>
                          </>
                        ) : (
                          <DecimalInputField
                            name="lastYearAnnualIncome"
                            labelId="candidature.previousIncome"
                            value={candidature.candidatureDto.previousIncome}
                            defaultValue={0}
                            onChange={(e) =>
                              handleIncomeChange(e, "previousIncome")
                            }
                            isInvalid={
                              candidature.candidatureDto.previousIncome === ""
                            }
                            errorMessage={
                              <FormattedMessage id="errors.fieldRequired.text" />
                            }
                            disabled={viewMode}
                            hasOverlay={true}
                            tooltipMessage={intl.formatMessage(
                              { id: "candidature.previousIncome.tooltip" },
                              {
                                criteriaValue: `${
                                  findPriorityCriteriaByCode("incomeDrops")
                                    .criteriaValue
                                }%`,
                              }
                            )}
                            suffixLabel="€"
                          />
                        )}
                      </div>

                      <div className="col-md-6">
                        {viewMode ? (
                          <>
                            <label>
                              <FormattedMessage id="candidature.currentIncome" />
                            </label>
                            <p>{candidature.candidatureDto.currentIncome} €</p>
                          </>
                        ) : (
                          <DecimalInputField
                            name="lastYearAnnualIncome"
                            labelId="candidature.currentIncome"
                            value={candidature.candidatureDto.currentIncome}
                            defaultValue={0}
                            onChange={(e) =>
                              handleIncomeChange(e, "currentIncome")
                            }
                            isInvalid={
                              candidature.candidatureDto.currentIncome === ""
                            }
                            errorMessage={
                              <FormattedMessage id="errors.fieldRequired.text" />
                            }
                            disabled={viewMode}
                            hasOverlay={true}
                            tooltipMessage={intl.formatMessage(
                              { id: "candidature.currentIncome.tooltip" },
                              {
                                criteriaValue: `${
                                  findPriorityCriteriaByCode("incomeDrops")
                                    .criteriaValue
                                }%`,
                              }
                            )}
                            suffixLabel="€"
                          />
                        )}
                      </div>
                    </>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

          {candidature.candidatureDto.candidatureEAA?.counties.length > 0 && (
            <div>
              <legend className="mt-4 mb-2">
                <FormattedMessage id="candidature.candidatureEAA.counties" />
              </legend>
              <div className="row accordion-panel">
                <div className="col-md-12">
                  <Accordion className="mb-4">
                    <Card>
                      <Card.Header className={"d-flex justify-content-between"}>
                        <Accordion.Toggle
                          as={Button}
                          variant="primary"
                          eventKey={
                            candidature.candidatureDto.candidatureEAA.counties
                          }
                        >
                          <FormattedMessage id="candidature.candidatureEAA.countiesNumber" />
                          {
                            candidature.candidatureDto.candidatureEAA.counties
                              .length
                          }
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse
                        eventKey={
                          candidature.candidatureDto.candidatureEAA.counties
                        }
                      >
                        <Card.Body>
                          <div className="row">
                            {candidature.candidatureDto.candidatureEAA.counties.map(
                              (countie) => (
                                <div className="col-md-4 mt-1">
                                  {countie.description}
                                </div>
                              )
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </div>
            </div>
          )}
          {isInArray("candidatureMembers") && (
            <div>
              <legend className={"mt-4 mb-2"}>
                <FormattedMessage id="candidature.candidatureMembersText" />
                <strong>
                  {candidature.candidatureDto.candidatureMembers.length}
                </strong>
              </legend>

              <div className="row accordion-panel">
                <div className="col-md-12">
                  {candidature.candidatureDto.candidatureMembers != null &&
                    candidature.candidatureDto.candidatureMembers.map(
                      (candidatureMember, index) => (
                        <Accordion key={index} className={"mb-4"}>
                          <Card>
                            <Card.Header
                              className={"d-flex justify-content-between"}
                            >
                              <Accordion.Toggle
                                as={Button}
                                variant="primary"
                                eventKey={candidatureMember.name}
                              >
                                {candidature.candidatureDto.platformCode !=
                                  "EAA" || index != 0
                                  ? candidatureMember.name
                                  : candidatureMember.name +
                                    " (" +
                                    intl.formatMessage({
                                      id: "candidature.candidatureEAA.applicant",
                                    }) +
                                    ")"}
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse
                              eventKey={candidatureMember.name}
                            >
                              <Card.Body>
                                {candidature.candidatureDto.platformCode !==
                                "EAA" ? (
                                  <>
                                    <legend>
                                      <FormattedMessage id="candidature.candidatureMembersText.identificationMember" />
                                    </legend>
                                    <div className="row">
                                      {candidatureMember.identificationType !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.identificationTypeText" />
                                          </label>
                                          <p>
                                            {
                                              candidatureMember
                                                .identificationType.code
                                            }
                                          </p>
                                        </div>
                                      )}
                                      {candidatureMember.identificationNumber !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.identificationNumberText" />
                                          </label>
                                          <p>
                                            {
                                              candidatureMember.identificationNumber
                                            }
                                          </p>
                                        </div>
                                      )}
                                      {candidatureMember.identificationValidity !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.identificationValidityText" />
                                          </label>
                                          <p>
                                            {
                                              candidatureMember.identificationValidity
                                            }
                                          </p>
                                        </div>
                                      )}
                                    </div>

                                    <div className="row">
                                      {candidatureMember.birthDate != null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.birthDateText" />
                                          </label>
                                          <p>{candidatureMember.birthDate}</p>
                                        </div>
                                      )}
                                      {candidatureMember.nif != null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.nifText" />
                                          </label>
                                          <p>{candidatureMember.nif}</p>
                                        </div>
                                      )}
                                      {candidatureMember.email != null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.emailText" />
                                          </label>
                                          <p>{candidatureMember.email}</p>
                                        </div>
                                      )}
                                    </div>

                                    <div className="row">
                                      {candidatureMember.candidate != null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.candidateText" />
                                          </label>
                                          <p>
                                            <TranslateBoolean
                                              value={
                                                candidatureMember.candidate
                                              }
                                            />
                                          </p>
                                        </div>
                                      )}

                                      {candidatureMember.characterizationName !=
                                        null && (
                                        <div className="col-md-8">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.characterizationNameText" />
                                          </label>
                                          <p>
                                            {
                                              candidatureMember.characterizationName
                                            }
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                    <legend>
                                      <FormattedMessage id="candidature.candidatureMembersText.incomes" />
                                    </legend>
                                    <div className="row">
                                      {candidatureMember.candidateTypeName !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.candidateTypeNameText" />
                                          </label>
                                          <p>
                                            {
                                              candidatureMember.candidateTypeName
                                            }
                                          </p>
                                        </div>
                                      )}

                                      {candidatureMember.dependentWork !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.dependentWorkText" />
                                          </label>
                                          <p>
                                            {candidatureMember.dependentWork} €
                                          </p>
                                        </div>
                                      )}
                                      {candidatureMember.enterpriseIncome !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.enterpriseIncomeText" />
                                          </label>
                                          <p>
                                            {candidatureMember.enterpriseIncome}{" "}
                                            €
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                    <div className="row">
                                      {candidatureMember.capital != null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.capitalText" />
                                          </label>
                                          <p>{candidatureMember.capital} €</p>
                                        </div>
                                      )}

                                      {candidatureMember.property != null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.propertyText" />
                                          </label>
                                          <p>{candidatureMember.property} €</p>
                                        </div>
                                      )}

                                      {candidatureMember.equityIncrease !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.equityIncreaseText" />
                                          </label>
                                          <p>
                                            {candidatureMember.equityIncrease} €
                                          </p>
                                        </div>
                                      )}

                                      <div className="col-md-4"></div>
                                    </div>

                                    <div className="row">
                                      {candidatureMember.pension != null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.pensionText" />
                                          </label>
                                          <p>{candidatureMember.pension} €</p>
                                        </div>
                                      )}

                                      {candidatureMember.socialBenefits !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.socialBenefitsText" />
                                          </label>
                                          <p>
                                            {candidatureMember.socialBenefits} €
                                          </p>
                                        </div>
                                      )}

                                      {candidatureMember.grossAnnualIncome !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.grossAnnualIncomeText" />
                                          </label>
                                          <p>
                                            {
                                              candidatureMember.grossAnnualIncome
                                            }{" "}
                                            €
                                          </p>
                                        </div>
                                      )}
                                    </div>

                                    <div className="row">
                                      {candidatureMember.incomeModification !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.incomeModificationText" />
                                          </label>
                                          <p>
                                            <TranslateBoolean
                                              value={
                                                candidatureMember.incomeModification
                                              }
                                            />
                                          </p>
                                        </div>
                                      )}
                                      {candidatureMember.incomeModificationDate !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.incomeModificationDateText" />
                                          </label>
                                          <p>
                                            <TranslateBoolean
                                              value={
                                                candidatureMember.incomeModificationDate
                                              }
                                            />
                                          </p>
                                        </div>
                                      )}
                                      {candidatureMember.incomeModificationMonth !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.incomeModificationMonthText" />
                                          </label>
                                          <p>
                                            {
                                              candidatureMember.incomeModificationMonth
                                            }
                                          </p>
                                        </div>
                                      )}
                                      {candidatureMember.incomeTaxDeclaration !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.incomeTaxDeclarationText" />
                                          </label>
                                          <p>
                                            <TranslateBoolean
                                              value={
                                                candidatureMember.incomeTaxDeclaration
                                              }
                                            />
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                    <div className="row">
                                      {candidatureMember.guarantor != null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.guarantorText" />
                                          </label>
                                          <p>
                                            <TranslateBoolean
                                              value={
                                                candidatureMember.guarantor
                                              }
                                            />
                                          </p>
                                        </div>
                                      )}
                                      {candidatureMember.guarantorBirthDate !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.guarantorBirthDateText" />
                                          </label>
                                          <p>
                                            <TranslateBoolean
                                              value={
                                                candidatureMember.guarantorBirthDate
                                              }
                                            />
                                          </p>
                                        </div>
                                      )}
                                      {candidatureMember.guarantorIdentificationNumber !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.guarantorIdentificationNumberText" />
                                          </label>
                                          <p>
                                            {
                                              candidatureMember.guarantorIdentificationNumber
                                            }
                                          </p>
                                        </div>
                                      )}
                                      {candidatureMember.guarantorIdentificationType !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.guarantorIdentificationTypeText" />
                                          </label>
                                          <p>
                                            {
                                              candidatureMember.guarantorIdentificationType
                                            }
                                          </p>
                                        </div>
                                      )}
                                      {candidatureMember.guarantorMonthIncome !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.guarantorMonthIncomeText" />
                                          </label>
                                          <p>
                                            {
                                              candidatureMember.guarantorMonthIncome
                                            }
                                          </p>
                                        </div>
                                      )}
                                      {candidatureMember.guarantorName !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.guarantorNameText" />
                                          </label>
                                          <p>
                                            {candidatureMember.guarantorName}
                                          </p>
                                        </div>
                                      )}
                                      {candidatureMember.guarantorNif !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.guarantorNifText" />
                                          </label>
                                          <p>
                                            {candidatureMember.guarantorNif}
                                          </p>
                                        </div>
                                      )}

                                      <div className="col-md-4"></div>
                                    </div>
                                    <div className="row">
                                      {candidatureMember.tutorNif != null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.tutorNifText" />
                                          </label>
                                          <p>{candidatureMember.tutorNif}</p>
                                        </div>
                                      )}
                                      {candidatureMember.niss != null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.nissText" />
                                          </label>
                                          <p>{candidatureMember.niss}</p>
                                        </div>
                                      )}
                                      {candidatureMember.address !== null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.addressText" />
                                          </label>
                                          <p>{candidatureMember.address}</p>
                                        </div>
                                      )}
                                      <div className="col-md-4"></div>
                                    </div>
                                    <div className="row">
                                      {candidatureMember.independentWork !=
                                        null && (
                                        <div className="col-md-4">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.independentWorkText" />
                                          </label>
                                          <p>
                                            {candidatureMember.independentWork}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {candidatureMember.candidatureMemberEAADto
                                      .applicantRelationGrade.code != "PR" && (
                                      <div className="row">
                                        <div className="col-md-12">
                                          <label>
                                            <FormattedMessage id="candidature.candidatureMembersText.applicantRelationGrade" />
                                          </label>
                                          <p>
                                            {
                                              candidatureMember
                                                .candidatureMemberEAADto
                                                .applicantRelationGrade
                                                .description
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                    <div className="row">
                                      <div className="col-md-4">
                                        <label>
                                          <FormattedMessage id="candidature.candidatureMembersText.nifText" />
                                        </label>
                                        <p>{candidatureMember.nif}</p>
                                      </div>
                                      <div className="col-md-4">
                                        <label>
                                          <FormattedMessage id="candidature.candidatureMembersText.birthDateText" />
                                        </label>
                                        <p>{candidatureMember.birthDate}</p>
                                      </div>
                                      <div className="col-md-4">
                                        <label>
                                          <FormattedMessage id="candidature.candidatureMembersText.gender" />
                                        </label>
                                        <p>{candidatureMember.gender}</p>
                                      </div>
                                    </div>

                                    {candidatureMember.candidatureMemberEAADto
                                      .applicantRelationGrade.code === "PR" && (
                                      <>
                                        <div className="row">
                                          <div className="col-md-4">
                                            <label>
                                              <FormattedMessage id="candidature.candidatureMembersText.addressText" />
                                            </label>
                                            <p>
                                              {
                                                candidatureMember.address
                                                  .streetName
                                              }
                                            </p>
                                          </div>
                                          <div className="col-md-4">
                                            <label>
                                              <FormattedMessage id="candidature.candidatureMembersText.location" />
                                            </label>
                                            <p>
                                              {
                                                candidatureMember.address
                                                  .location
                                              }
                                            </p>
                                          </div>
                                          <div className="col-md-4">
                                            <label>
                                              <FormattedMessage id="candidature.candidatureMembersText.postalCode" />
                                            </label>
                                            <p>
                                              {
                                                candidatureMember.address
                                                  .postalCode
                                              }
                                            </p>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md-4">
                                            <label>
                                              <FormattedMessage id="candidature.candidatureMembersText.county" />
                                            </label>
                                            <p>
                                              {
                                                candidatureMember.address
                                                  .countyName
                                              }
                                            </p>
                                          </div>
                                          <div className="col-md-4">
                                            <label>
                                              <FormattedMessage id="candidature.candidatureMembersText.phone" />
                                            </label>
                                            <p>{candidatureMember.phone}</p>
                                          </div>
                                          <div className="col-md-4">
                                            <label>
                                              <FormattedMessage id="candidature.candidatureMembersText.email" />
                                            </label>
                                            <p>{candidatureMember.email}</p>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md-4">
                                            <label>
                                              <FormattedMessage id="candidature.candidatureMembersText.identificationType" />
                                            </label>
                                            <p>
                                              {
                                                candidatureMember
                                                  .identificationType.code
                                              }
                                            </p>
                                          </div>
                                          <div className="col-md-4">
                                            <label>
                                              <FormattedMessage id="candidature.candidatureMembersText.identificationNumber" />
                                            </label>
                                            <p>
                                              {
                                                candidatureMember.identificationNumber
                                              }
                                            </p>
                                          </div>
                                          <div className="col-md-4">
                                            <label>
                                              <FormattedMessage id="candidature.candidatureMembersText.identificationValidity" />
                                            </label>
                                            <p>
                                              {
                                                candidatureMember.identificationValidity
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </>
                                    )}

                                    <div className="row">
                                      <div className="col-md-4">
                                        <label>
                                          <FormattedMessage id="candidature.candidatureMembersText.applicantProfSituation" />
                                        </label>
                                        <p>
                                          {
                                            candidatureMember
                                              .candidatureMemberEAADto
                                              .applicantProfSituation
                                              .description
                                          }
                                        </p>
                                      </div>
                                      <div className="col-md-8">
                                        <label>
                                          <FormattedMessage id="candidature.candidatureMembersText.applicantDisability" />
                                        </label>
                                        <p>
                                          {" "}
                                          <TranslateBoolean
                                            value={
                                              candidatureMember
                                                .candidatureMemberEAADto
                                                .applicantDisability
                                            }
                                          />
                                        </p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-4">
                                        <label>
                                          <FormattedMessage id="candidature.candidatureMembersText.applicantIncomeType" />
                                        </label>
                                        <p>
                                          {
                                            candidatureMember
                                              .candidatureMemberEAADto
                                              .applicantIncomeType.description
                                          }
                                        </p>
                                      </div>
                                      <div className="col-md-8">
                                        <label>
                                          <FormattedMessage id="candidature.candidatureMembersText.applicantIncomeValue" />
                                        </label>
                                        <p>
                                          {" "}
                                          {
                                            candidatureMember
                                              .candidatureMemberEAADto
                                              .applicantIncomeValue
                                          }{" "}
                                          €
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      )
                    )}
                </div>
              </div>
            </div>
          )}
          {candidature.candidatureDto.candidatureEAA?.scoreDetails && (
            <>
              <button
                onClick={() => handleShow()}
                className={"btn btn-link mt-3 mb-2"}
              >
                <FaEye />
                <FormattedMessage id="seeScoreDetails.text" />
              </button>
              <ScoreDetailsTable
                candidature={candidature}
                show={show}
                handleClose={handleClose}
              />
            </>
          )}
          {canShowOtherInfo() && (
            <div>
              <legend className={"mt-4 mb-2"}>
                <FormattedMessage id="candidature.otherInfo" />
              </legend>
              <div className="row">
                {candidature.candidatureDto.documentSubmissionDeadline && (
                  <div className="col-md-4">
                    <label>
                      <FormattedMessage id="competitionForm.competitionDocumentSubmissionDeadlineText" />
                    </label>
                    <p>
                      {candidature.candidatureDto.documentSubmissionDeadline}
                    </p>
                  </div>
                )}
                {candidature.candidatureDto.appointmentDate && (
                  <div className="col-md-4">
                    <label>
                      <FormattedMessage id="bookAppointment.date" />
                    </label>
                    <p>{candidature.candidatureDto.appointmentDate}</p>
                  </div>
                )}
                {candidature.candidatureDto.contestation && (
                  <div className="col-md-12">
                    <label>
                      <FormattedMessage id="candidature.history.contestation" />
                    </label>
                    <p>{candidature.candidatureDto.contestation}</p>
                  </div>
                )}
                {candidature.candidatureDto.excludeReason && (
                  <div className="col-md-12">
                    <label>
                      <FormattedMessage id="candidature.excludeReason" />
                    </label>
                    <p>{candidature.candidatureDto.excludeReason}</p>
                  </div>
                )}
                {candidature.candidatureDto.resignReason && (
                  <div className="col-md-12">
                    <label>
                      <FormattedMessage id="candidature.resignReason" />
                    </label>
                    <p>{candidature.candidatureDto.resignReason}</p>
                  </div>
                )}
                {candidature.candidatureDto.askMoreTimeReason && (
                  <div className="col-md-12">
                    <label>
                      <FormattedMessage id="candidature.askMoreTimeReason" />
                    </label>
                    <p>{candidature.candidatureDto.askMoreTimeReason}</p>
                  </div>
                )}
                {hasMemberOnOtherCompetition?.flag && (
                  <div className="col-md-12">
                    <label>
                      <FormattedMessage id="candidature.otherCompetitions.alert" />
                    </label>
                    <p>
                      {" "}
                      <FormattedMessage id="candidature.otherCompetitions.yes" />
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ListCandidature;

import Keycloak from 'keycloak-js';
import {
  cleanAccessInfo,
  constructAccessInfoFromKeycloak,
  getAccessInfo,
  isCandidate,
  saveInStorageAccessInfo
} from './authentication/authenticationHelper';

export const KeycloakObject = (function () {
  let keycloak;

  function create(configs) {
    keycloak = new Keycloak(configs);
  }

  function get() {
    return keycloak;
  }

  const onEvent = (event, error) => {
    if (typeof error !== undefined) {
      switch (event) {
        case 'onAuthSuccess':
          break;
        case 'onTokenExpired':
          break;
        case 'onAuthLogout':
          cleanAccessInfo();
          break;
        case 'onAuthRefreshSuccess':
          break;
        default:
          break;
      }
    }
  };

  const onTokenChanged = (changedToken) => {
    let accessInfo = getAccessInfo();

    if (isCandidate(accessInfo)) {
      return;
    }

    if (changedToken && accessInfo) {
      let accessInfo = getAccessInfo();
      accessInfo = constructAccessInfoFromKeycloak(
        changedToken,
        accessInfo.permissions
      );
      saveInStorageAccessInfo(accessInfo);
    }
  };

  return {
    create: create,
    get: get,
    onEvent: onEvent,
    onTokenChanged: onTokenChanged,
  };
})();

import React from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { createExceptionErrorMessage } from '../../hooks/errorMessage';
import { validateCompetitionCode } from '../../rest/competition';
import { ScrollToTop } from '../ScrollToTop';
import { SelectBooleanField } from '../bootstrap/SelectBooleanField';
import { Arrays } from '../../utils/Arrays';
import { PlatformType } from '../../models/PlatformType';
import { TextInputField } from '../bootstrap/TextInputField';
import { FaInfoCircle } from 'react-icons/fa';

export function CompetitionStep1Form({
  touched,
  errors,
  competition,
  setCompetition,
  setFieldValue,
  handleChange,
  platform,
  isUpdate,
  validateForm,
  setError,
  step,
  numberOfSteps
}) {
  const intl = useIntl();

  const handleNextStep = async () => {
    const errors = await validateForm();

    if (!isUpdate) {
      const { data: check } = await validateCompetitionCode(
        encodeURIComponent(competition.code),
        platform.code
      );
      if (!check) {
        setError(
          createExceptionErrorMessage('CompetitionAlreadyExistsException', competition.code)
        );
        return;
      }
      setError(null);
    }
    if (Object.keys(errors).length === 0) {
      setFieldValue('step', competition.step + 1);
    }
  };

  function handlePriorityCriteriaChange(e, code) {
    if (e.target.value === 'false') {
      setCompetition({
        ...competition,
        priorityCriteriasChosen: competition.priorityCriteriasChosen.filter(
          (pc) => pc.code !== code
        )
      });
      return;
    }

    const priorityCriteria = findChosenPriorityCriteriaByCode(code);

    if (!priorityCriteria) {
      competition.priorityCriteriasChosen.push({ code: code, criteriaValue: '' });
      setCompetition({ ...competition });
    }
  }

  function handlePriorityCriteriaValueChange(e, code) {
    findChosenPriorityCriteriaByCode(code).criteriaValue = e.target.value;
    setCompetition({ ...competition });
  }

  function handleHasPriorityCriteriasChange(e) {
    if (e === 'false') {
      competition.priorityCriteriasChosen = [];
    }

    competition.hasPriorityCriterias = e;
    setCompetition({ ...competition });
  }

  function findChosenPriorityCriteriaByCode(code) {
    return competition.priorityCriteriasChosen.find((pc) => pc.code === code);
  }

  function findChosenPriorityCriteriaIndexByCode(code) {
    return competition.priorityCriteriasChosen.findIndex((pc) => pc.code === code);
  }

  function hasPriorityCriteria(code) {
    return findChosenPriorityCriteriaByCode(code) != null;
  }

  return (
    <div>
      <ScrollToTop />
      <h5 className='mb-4 pb-2 border-bottom'>
        <FormattedMessage id='all.steps' values={{ step: step, numberOfSteps: numberOfSteps }} />
        {' - '}
        <FormattedMessage id='competition.form.title.definitions' />
      </h5>
      <div className='row'>
        <div className='col-md-6'>
          <Form.Group controlId='competitionCode'>
            <Form.Label>
              <FormattedMessage id='competitionForm.competitionCodeText' />
            </Form.Label>
            <Form.Control
              type='text'
              name='code'
              value={competition.code}
              onChange={handleChange}
              isInvalid={touched.code && errors.code}
              disabled={isUpdate}
            />
            <Form.Control.Feedback type='invalid'>{errors.code}</Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className='col-md-6'>
          {platform.code && (
            <Form.Group controlId='platformIdentificationType'>
              <Form.Label>
                <FormattedMessage id='platformForm.typeText' />
              </Form.Label>
              <Form.Control
                as='select'
                name='platformCode'
                value={platform.code}
                onChange={handleChange}
                disabled>
                <option>{platform.code}</option>
              </Form.Control>
            </Form.Group>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <Form.Group controlId='type'>
            <Form.Label>
              <FormattedMessage id='competitionForm.competitionTypeText' />
            </Form.Label>
            <Form.Control
              as='select'
              name='type'
              value={competition.type}
              onChange={handleChange}
              isInvalid={touched.type && errors.type}>
              <option value='RAFFLE'>
                {intl.formatMessage({
                  id: 'competitionForm.competitionTypeRaffleText'
                })}
              </option>
              <option value='SERIALIZATION'>
                {intl.formatMessage({
                  id: 'competitionForm.competitionTypeSerializationText'
                })}
              </option>
            </Form.Control>
          </Form.Group>
        </div>
      </div>

      <Form.Group controlId='introductoryText'>
        <Form.Label>
          <FormattedMessage id='competitionForm.competitionIntroductoryText' />
        </Form.Label>
        <Form.Control
          as='textarea'
          rows={2}
          name='introductoryText'
          value={competition.introductoryText}
          onChange={handleChange}
          isInvalid={touched.introductoryText && errors.introductoryText}
        />
      </Form.Group>

      <Form.Group controlId='descriptiveText'>
        <Form.Label>
          <FormattedMessage id='competitionForm.competitionDescriptiveText' />
        </Form.Label>
        <Form.Control
          as='textarea'
          rows={3}
          name='descriptiveText'
          value={competition.descriptiveText}
          onChange={handleChange}
          isInvalid={touched.descriptiveText && errors.descriptiveText}
        />
      </Form.Group>

      {platform.code !== 'EAA' && (
        <>
          <div className='row'>
            <div className='col-md-6'>
              <Form.Group controlId='allowAccommodationChoice'>
                <Form.Label>
                  <FormattedMessage id='competitionForm.competitionAllowAccommodationChoiceText' />
                </Form.Label>
                <Form.Control
                  as='select'
                  name='allowAccommodationChoice'
                  value={competition.allowAccommodationChoice}
                  onChange={handleChange}
                  isInvalid={touched.allowAccommodationChoice && errors.allowAccommodationChoice}>
                  <option value='true'>
                    {intl.formatMessage({
                      id: 'all.yesText'
                    })}
                  </option>
                  <option value='false'>
                    {intl.formatMessage({
                      id: 'all.noText'
                    })}
                  </option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className='col-md-6'>
              <Form.Group controlId='allowPreferenceChoice'>
                <Form.Label>
                  <FormattedMessage id='competitionForm.competitionAllowPreferenceChoice' />
                </Form.Label>
                <Form.Control
                  as='select'
                  name='allowPreferenceChoice'
                  value={competition.allowPreferenceChoice}
                  onChange={handleChange}
                  isInvalid={touched.allowPreferenceChoice && errors.allowPreferenceChoice}>
                  <option value='true'>
                    {intl.formatMessage({
                      id: 'all.yesText'
                    })}
                  </option>
                  <option value='false'>
                    {intl.formatMessage({
                      id: 'all.noText'
                    })}
                  </option>
                </Form.Control>
              </Form.Group>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <Form.Group controlId='maxAccommodations'>
                <Form.Label>
                  <FormattedMessage id='competitionForm.competitionMaxAccommodationsText' />
                </Form.Label>
                <Form.Control
                  type='number'
                  name='maxAccommodations'
                  value={competition.maxAccommodations}
                  onChange={handleChange}
                  isInvalid={touched.maxAccommodations && errors.maxAccommodations}
                />
              </Form.Group>
            </div>

            {platform.code === PlatformType.PAS && (
              <div className='col-md-6'>
                <SelectBooleanField
                  name={'hasPriorityCriterias'}
                  labelId={'competitionForm.hasPriorityCriterias'}
                  value={competition.hasPriorityCriterias}
                  isInvalid={touched.hasPriorityCriterias && errors.hasPriorityCriterias}
                  onChange={(e) => handleHasPriorityCriteriasChange(e)}
                  nullOption={true}
                  errorMessage={<FormattedMessage id='errors.fieldRequired.text' />}
                />
              </div>
            )}
          </div>

          {Arrays.isNotEmpty(competition.priorityCriterias) &&
            competition.hasPriorityCriterias === 'true' &&
            platform.code === PlatformType.PAS && (
              <div className='mt-4'>
                <legend>
                  <FormattedMessage id='priorityCriteria.title' />
                </legend>
                {competition.priorityCriterias.map((pc, index) => (
                  <div className='row' key={index}>
                    <div className='col-md-6'>
                      <Form.Group controlId={pc.code} className='regular-radio'>
                        <Form.Label>
                          <FormattedMessage id={`priorityCriteria.${pc.code}`} />
                          {pc.code !== 'upToCertainAge' && (
                            <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  <FormattedMessage id={`priorityCriteria.${pc.code}.tooltip`} />
                                </Tooltip>
                              }>
                              <FaInfoCircle className='ml-1 pb-1' />
                            </OverlayTrigger>
                          )}
                        </Form.Label>

                        <Form.Check
                          type='radio'
                          onChange={(e) => handlePriorityCriteriaChange(e, pc.code)}
                          name={pc.code}
                          value='true'
                          label={<FormattedMessage id={'all.yesText'} />}
                          checked={hasPriorityCriteria(pc.code)}
                        />

                        <Form.Check
                          type='radio'
                          onChange={(e) => handlePriorityCriteriaChange(e, pc.code)}
                          name={pc.code}
                          value='false'
                          label={<FormattedMessage id={'all.noText'} />}
                          checked={!hasPriorityCriteria(pc.code)}
                        />
                      </Form.Group>
                    </div>

                    <div className='col-md-6'>
                      {hasPriorityCriteria(pc.code) && pc.code !== 'singleParentFamily' && (
                        <TextInputField
                          name={pc.code}
                          value={findChosenPriorityCriteriaByCode(pc.code).criteriaValue}
                          handleChange={(e) => handlePriorityCriteriaValueChange(e, pc.code)}
                          labelId='all.criteriaValue'
                          isInvalid={
                            errors.priorityCriteriasChosen?.[
                              findChosenPriorityCriteriaIndexByCode(pc.code)
                            ]?.criteriaValue
                              ? errors.priorityCriteriasChosen?.[
                                  findChosenPriorityCriteriaIndexByCode(pc.code)
                                ]?.criteriaValue
                              : false
                          }
                          errorMessage={
                            errors.priorityCriteriasChosen?.[
                              findChosenPriorityCriteriaIndexByCode(pc.code)
                            ]?.criteriaValue
                              ? errors.priorityCriteriasChosen?.[
                                  findChosenPriorityCriteriaIndexByCode(pc.code)
                                ]?.criteriaValue
                              : ''
                          }
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
        </>
      )}

      <div className='form-actions'>
        <div className='mr-auto'>
          <Link className='btn btn-link' to={`/programas/${platform.code}/concursos`}>
            <FormattedMessage id='all.backButtonText' />
          </Link>
        </div>
        <div className='ml-auto'>
          <button className='btn btn-primary' type='submit' onClick={(e) => handleNextStep()}>
            <FormattedMessage id='all.nextButtonText' />
          </button>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Redirect, useLocation } from 'react-router';
import { cleanAccessInfo } from '../../authentication/authenticationHelper';
import { useQuery } from '../../hooks/useQuery';

const isNphAuthentication = (query) => {
  return query && query.get('type') && query.get('type') === 'nph';
};

function Login(props) {
  cleanAccessInfo();

  let location = useLocation();

  const query = useQuery();

  let { from } = location.state || { from: '/' };

  if (isNphAuthentication(query)) {
    return (
      <Redirect
        to={{
          pathname: '/login/nph/' + query.get('code'),
          state: { from: from },
        }}
      />
    );
  } else {
    const path = from ? `/login/keycloak/?r=${from}` : `/login/keycloak`;
    return (
      <Redirect
        to={{
          pathname: path,
        }}
      />
    );
  }
}

export default Login;

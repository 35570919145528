import { Formik } from 'formik';
import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';
import { EditorInputField } from '../bootstrap/EditorInputField';

function DateSubmissionDocumentsModal({
  show,
  handleClose,
  onSubmit,
  descriptiveText,
  descriptiveTextId,
  actionNotification,
}) {
  const EMPTY_DRAW_REQUEST = {
    date: new Date(),
    title: actionNotification ? actionNotification.templateEmailTitle : '',
    body: actionNotification ? actionNotification.templateEmailBody : '',
  };

  let schema = yup.object().shape({
    date: yup
      .date()
      .min(new Date(), <FormattedMessage id='errors.publishDeadline.text' />),
  });
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='candidature.document.submission.extension.title' />
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={EMPTY_DRAW_REQUEST}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            onSubmit(values, setSubmitting, setErrors);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                {descriptiveText && (
                  <div>
                    <label>
                      <FormattedMessage id={descriptiveTextId} />
                    </label>
                    <p>{descriptiveText}</p>
                  </div>
                )}
                <Form.Group controlId='title'>
                  <Form.Label>
                    <FormattedMessage id='messages.titleText' />
                  </Form.Label>
                  <Form.Control
                    type='text'
                    name='title'
                    value={values.title}
                    onChange={handleChange}
                    isInvalid={touched.title && errors.title}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
                {!actionNotification && (
                  <Form.Group controlId='body'>
                    <Form.Label>
                      <FormattedMessage id='clarificationRequestForm.bodyText' />
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      name='body'
                      value={values.body}
                      onChange={handleChange}
                      isInvalid={touched.body && errors.body}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.body}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
                {actionNotification && (
                  <EditorInputField
                    name='body'
                    labelId={'clarificationRequestForm.bodyText'}
                    value={values.body}
                    handleChange={handleChange}
                    hideOvelay={true}
                  />
                )}
                <DateTimeInputField
                  name='date'
                  value={values.date}
                  errorMessage={errors.date}
                  isInvalid={touched.date && errors.date}
                  setFieldValue={setFieldValue}
                  labelId='documents.changeDateSubmission.text'
                ></DateTimeInputField>
              </Modal.Body>

              <Modal.Footer>
                <button className='btn btn-link mr-auto' onClick={handleClose}>
                  <FormattedMessage id='all.cancelText' />
                </button>

                <button
                  className={'btn btn-primary ml-auto'}
                  type='submit'
                  disabled={isSubmitting}
                >
                  <FormattedMessage id='candidature.document.submission.extension.button' />
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

export default DateSubmissionDocumentsModal;

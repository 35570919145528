import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Strings } from '../../utils/Strings';
import { TextAreaInputField } from './TextAreaInputField';

export function TextAreaDialog({
  show,
  onClose,
  onConfirm,
  titleId,
  inputLabelId,
  confirmButtonTextId,
  emptyTextErrorId,
  descriptiveText,
  descriptiveTextId,
}) {
  const [text, setText] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const intl = useIntl();

  const handleConfirm = () => {
    if (Strings.isBlank(text)) {
      setErrorMessage(intl.formatMessage({ id: emptyTextErrorId }));
    } else {
      onConfirm(text);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id={titleId} />
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Modal.Body>
            {descriptiveText && (
              <div>
                <label>
                  <FormattedMessage id={descriptiveTextId} />
                </label>
                <p>{descriptiveText}</p>
              </div>
            )}

            <TextAreaInputField
              labelId={inputLabelId}
              name='text'
              value={text}
              handleChange={(e) => {
                setErrorMessage(null);
                setText(e.target.value);
              }}
              errorMessage={errorMessage}
              isInvalid={errorMessage}
            />
          </Modal.Body>

          <Modal.Footer>
            <button
              className='btn btn-link mr-auto'
              onClick={onClose}
              type='button'
            >
              <FormattedMessage id='all.cancelText' />
            </button>

            <button
              className={'btn btn-primary ml-auto'}
              type='button'
              onClick={handleConfirm}
            >
              <FormattedMessage id={confirmButtonTextId} />
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

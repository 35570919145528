import axios from 'axios';
import { apiAxios as authAxios } from '../axios.config';

export const getPlatform = (platformCode) => {
  return axios.get(`/api/platform/${platformCode}`);
};

export const getPlatforms = () => {
  return axios.get(`/api/platform`);
};

export const getPlatformsForFaqs = () => {
  return axios.get('/api/platform?faqs=true');
};

export const addPlatform = (platform) => {
  return authAxios({
    method: 'post',
    url: 'api/platform',
    data: platform,
  });
};
export const updatePlatformAPI = (platformCode, platform) => {
  return authAxios.put(`/api/platform/${platformCode}`, platform);
};

export const getBuildingTypes = (platformCode) => {
  return axios.get(`/api/platform/${platformCode}/buildingTypes`);
};

export const getConservationStates = (platformCode) => {
  return axios.get(`/api/platform/${platformCode}/conservationStates`);
};

export const getEnergeticCertificates = (platformCode) => {
  return axios.get(`/api/platform/${platformCode}/energeticCertificates`);
};

export const getModalities = (platformCode) => {
  return axios.get(`/api/platform/${platformCode}/modalities`);
};

export const getFloorAccesses = (platformCode) => {
  return axios.get(`/api/platform/${platformCode}/floorsAccesses`);
};

export const getFurnitures = (platformCode) => {
  return axios.get(`/api/platform/${platformCode}/furnitures`);
};

export const getKitchenEquipments = (platformCode) => {
  return axios.get(`/api/platform/${platformCode}/kitchenEquipments`);
};

export const getPermitBuildingTypes = (platformCode) => {
  return axios.get(`/api/platform/${platformCode}/permitBuildingTypes`);
};

export const getCatalogs = (platformCode, type) => {
  return axios.get(`/api/platform/${platformCode}/${type}`);
};

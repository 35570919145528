import { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { hasPermission } from '../../authentication/authenticationHelper';
import { useGlobalContext } from '../../context';

export function CompetitionFilterBar({
  typologies,
  districts,
  counties,
  rentsFilter,
  onSubmit,
  onDistrictChange,
  onTabChange,
}) {
  //Filters
  const intl = useIntl();

  const [districtFilter, setDistricFilter] = useState('-1');
  const [countyFilter, setCountyFilter] = useState('-1');
  const [typologyFilter, setTypologyFilter] = useState('-1');
  const [rentFilter, setRentFilter] = useState('-1');
  const [selectedTab, setSelectedTab] = useState(1);

  const { accessInfo } = useGlobalContext();

  /**
   * @param  {React.ChangeEvent} e
   */
  const handleOnClick = (e) => {
    e.preventDefault();
    let districtF = districtFilter === '-1' ? null : districtFilter;
    let countyF = countyFilter === '-1' ? null : countyFilter;
    let typologyF = typologyFilter === '-1' ? null : typologyFilter;
    let maxRentF = null;
    let minRentF = null;

    if (rentFilter !== '-1') {
      maxRentF = rentFilter.max;
      minRentF = rentFilter.min;
    }
    let active = true;
    if (selectedTab === 2) {
      active = false;
    } else if (selectedTab === 3) {
      active = null;
    }

    onSubmit(districtF, countyF, typologyF, maxRentF, active, minRentF);
  };

  useEffect(() => {
    /**
     * @param  {React.ChangeEvent} e
     */
    const handleDistrictChange = () => {
      let active = true;
      if (selectedTab === 2) {
        active = false;
      } else if (selectedTab === 3) {
        active = null;
      }

      onDistrictChange(districtFilter, active);
      setDistricFilter(districtFilter);
      setCountyFilter('-1');
    };

    handleDistrictChange();
  }, [districtFilter]);

  const handleOnChangeTab = (selectedTab, active) => {
    setSelectedTab(selectedTab);
    setDistricFilter('-1');
    setCountyFilter('-1');
    setTypologyFilter('-1');
    setRentFilter('-1');
    onTabChange(active);
  };

  const handleRentFilter = (e) => {
    if (e.target.value === '-1') {
      setRentFilter('-1');
    } else {
      const rf = rentsFilter.find(
        (x) => x.id === Number.parseInt(e.target.value)
      );
      setRentFilter(rf);
    }
  };

  return (
    <div>
      <ul className={'nav nav-tabs'}>
        <li className={'nav-item'}>
          <button
            className={`nav-link ${
              selectedTab === 1 && 'active'
            } text-uppercase`}
            onClick={() => handleOnChangeTab(1, true)}
          >
            <FormattedMessage id='competitions.ongoingText' />
          </button>
        </li>
        <li className={'nav-item'}>
          <button
            className={`nav-link ${
              selectedTab === 2 && 'active'
            } text-uppercase`}
            onClick={() => handleOnChangeTab(2, false)}
          >
            <FormattedMessage id='competitions.closedText' />
          </button>
        </li>
        {hasPermission('GET_NOT_VISIBLE_FOR_CANDIDATE', accessInfo) && (
          <li className={'nav-item'}>
            <button
              className={`nav-link ${
                selectedTab === 3 && 'active'
              } text-uppercase`}
              onClick={() => handleOnChangeTab(3)}
            >
              <FormattedMessage id='competitions.backofficeText' />
            </button>
          </li>
        )}
      </ul>
      <form className={'form-inline mt-4'}>
        <div className={'form-group mr-3'}>
          <select
            className={'form-control rounded-0'}
            onChange={(e) => setDistricFilter(e.target.value)}
            value={districtFilter}
          >
            <option value={'-1'}>
              {intl.formatMessage({
                id: 'filters.districts',
              })}
            </option>
            {districts.map((district) => {
              return (
                <option key={district.code} value={district.code}>
                  {district.description}
                </option>
              );
            })}
          </select>
        </div>
        <div className={'form-group mr-3'}>
          <label htmlFor='exampleFormControlSelect2'></label>
          <select
            className={'form-control rounded-0'}
            onChange={(e) => setCountyFilter(e.target.value)}
            value={countyFilter}
          >
            <option value={'-1'}>
              {intl.formatMessage({
                id: 'filters.counties',
              })}
            </option>
            {counties.map((county) => {
              return (
                <option key={county.code} value={county.code}>
                  {county.description}
                </option>
              );
            })}
          </select>
        </div>
        <div className={'form-group mr-3'}>
          <select
            className={'form-control rounded-0'}
            onChange={(e) => setTypologyFilter(e.target.value)}
            value={
              typologyFilter === '-1' ? typologyFilter : typologyFilter.code
            }
          >
            <option value={'-1'}>
              {intl.formatMessage({
                id: 'filters.typologies',
              })}
            </option>
            {typologies.map((typology) => {
              return (
                <option key={typology.code} value={typology.code}>
                  {typology.description}
                </option>
              );
            })}
          </select>
        </div>
        {rentsFilter != null && (
          <div className={'form-group mr-3'}>
            <select
              className={'form-control rounded-0'}
              onChange={(e) => handleRentFilter(e)}
              value={rentFilter === '-1' ? rentFilter : rentFilter.id}
            >
              <option value={'-1'}>
                {intl.formatMessage({
                  id: 'filters.allRents',
                })}
              </option>
              {rentsFilter.map((rentInterval, index) => {
                return (
                  <option key={index} value={rentInterval.id}>
                    {rentInterval.max === 0
                      ? `Mais de ${rentInterval.min}`
                      : `Entre ${rentInterval.min} a ${rentInterval.max}`}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <div className={'form-group'}>
          <button
            className={'btn btn-primary'}
            onClick={handleOnClick}
            type='submit'
          >
            <FaSearch className={'mx-0'} />
          </button>
        </div>
      </form>
    </div>
  );
}

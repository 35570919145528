export const ConfigObject = (function () {
  var config = {
    GOV_URL: 'https://www.portugal.gov.pt/',
    IHRU_URL: 'http://www.ihru.pt/',
    DATE_TIME_PATTERN: 'yyyy-MM-dd HH:mm:ss',
    HOLIDAY_PATTERN: 'yyyy-MM-dd',
  };

  function save(configs) {
    config = { ...config, ...configs };
  }

  function get() {
    return config;
  }

  return {
    save: save,
    get: get,
  };
})();

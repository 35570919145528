import { Formik } from 'formik';
import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

function DialogMessageCandidature({
  showModalMessage,
  handleCloseMessageModal,
  handleSendMessage,
}) {
  const EMPTY_DRAW_REQUEST = {
    title: '',
    body: '',
  };
  const intl = useIntl();

  let schema = yup.object().shape({
    title: yup
      .string()
      .required(intl.formatMessage({ id: 'messages.emptyTitle' })),
    body: yup
      .string()
      .required(intl.formatMessage({ id: 'messages.emptyBody' })),
  });
  return (
    <div>
      <Modal show={showModalMessage} onHide={handleCloseMessageModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='all.reply' />
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={EMPTY_DRAW_REQUEST}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            handleSendMessage(values, setSubmitting, setErrors);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId='title'>
                  <Form.Label>
                    <FormattedMessage id='messages.titleText' />
                  </Form.Label>
                  <Form.Control
                    type='text'
                    name='title'
                    value={values.title}
                    onChange={handleChange}
                    isInvalid={touched.title && errors.title}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='body'>
                  <Form.Label>
                    <FormattedMessage id='clarificationRequestForm.bodyText' />
                  </Form.Label>
                  <Form.Control
                    as='textarea'
                    name='body'
                    value={values.body}
                    onChange={handleChange}
                    isInvalid={touched.body && errors.body}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.body}
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <button
                  className='btn btn-link mr-auto'
                  onClick={handleCloseMessageModal}
                >
                  <FormattedMessage id='all.cancelText' />
                </button>

                <button
                  className={'btn btn-primary ml-auto'}
                  type='submit'
                  disabled={isSubmitting}
                >
                  <FormattedMessage id='all.send' />
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

export default DialogMessageCandidature;

import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

export function StagingCandidaturesCheckboxesDialog({
  show,
  handleClose,
  fields,
  handleCheckBoxClick,
  handleCheckAll,
  isAllChecked,
}) {
  const intl = useIntl();

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='stagingCandidaturesCheckboxesDialog.title' />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='col-md-auto px-4'>
          {fields
            .filter(
              (field) => field.name !== 'id' && field.name !== 'numberEAA'
            )
            .map((field, index) => (
              <div className='row' key={index}>
                <div className='col-display-contents'>
                  <input
                    onChange={() => handleCheckBoxClick(field.name)}
                    checked={field.checked}
                    type='checkbox'
                  />
                </div>
                <div className='col-md-9'>
                  <label>
                    {intl.formatMessage({
                      id: `stagingCandidaturesTable.${field.name}`,
                    })}
                  </label>
                </div>
              </div>
            ))}
          <div className='row'>
            <div className='col-display-contents'>
              <input
                onChange={() => handleCheckAll()}
                checked={isAllChecked()}
                type='checkbox'
              />
            </div>
            <div className='col-md-9'>
              <label>
                {intl.formatMessage({
                  id: 'stagingCandidaturesTable.all',
                })}
              </label>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button className='btn btn-link mr-auto' onClick={handleClose}>
          <FormattedMessage id='all.backButtonText' />
        </button>
      </Modal.Footer>
    </Modal>
  );
}

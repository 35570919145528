import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import { AlertSuccess } from '../../../../components/bootstrap/AlertSuccess';
import { UploadDocumentType } from '../../../../components/candidature/UploadDocumentType';
import { AuthenticatedDownloadLink } from '../../../../components/document/AuthenticatedDownloadLink';
import { EmptyResults } from '../../../../components/EmptyResults';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { DocumentTypeCode } from '../../../../models/DocumentTypeCode';
import {
  addCandidatureDocument,
  deleteCandidatureDocument, excludeCandidature,
  getCandidatureByExternalId,
  searchCandidatureDocuments,
  sendAnalyzeOk,
} from '../../../../rest/candidatureuser';
import {
  FaTimes,
} from 'react-icons/fa';
import { getDocumentType } from '../../../../rest/documentType';
import { Arrays } from '../../../../utils/Arrays';
import { handleError, isNotBusinessError } from '../../../../utils/handleError';
import { candidatureDetailsLink } from '../../../../utils/LinkCreator';
import {hasPermission} from "../../../../authentication/authenticationHelper";
import {ExcludeDialog} from "../../../../components/candidature/ExcludeDialog";
import {Link} from "react-router-dom";
import {createCustomErrorMessage} from "../../../../hooks/errorMessage";

export function AnalyzeManagment() {
  const { externalId } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [candidature, setCandidature] = useState({});
  const [documents, setDocuments] = useState([]);
  const [showExcludeDialog, setShowExcludeDialog] = useState(false);
  const [finalDecisionDocumentType, setFinalDecisionDocumentType] = useState(
    {}
  );
  const history = useHistory();

  const intl = useIntl();

  useEffect(() => {
    async function fetchData() {
      try {
        const [
          candidatureResponse,
          finalDecisionDocumentTypeResponse,
          documentsResponse,
        ] = await Promise.all([
          getCandidatureByExternalId(externalId),
          getDocumentType(DocumentTypeCode.FINAL_DECISION),
          searchCandidatureDocuments(
            externalId,
            DocumentTypeCode.FINAL_DECISION
          ),
        ]);

        setCandidature(candidatureResponse.data);
        setDocuments(documentsResponse.data);
        setFinalDecisionDocumentType(finalDecisionDocumentTypeResponse.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error);
        setLoading(false);
      }
    }
    fetchData();
  }, [externalId]);


  const onClickExcludeCandidature = () => {
    if(documents.length==0){
      setError(
          createCustomErrorMessage(
              intl.formatMessage(
                  { id: 'validation.finalDocuments.needDocuments' },
                  { documents: '<br/>Decisão final' }
              )
          )
      );

    }else {
      setShowExcludeDialog(true);
    }

  };

  const handleCloseExcludeDialog = () => {
    setShowExcludeDialog(false);
  };





  const handleConfirmExcludeCandidature = async (reason) => {
    setShowExcludeDialog(false);
    setLoading(true);
    try {
      const { data } = await excludeCandidature(
          candidature.candidatureDto.externalId,
          reason
      );
      setCandidature(data);

      history.replace(
          candidatureDetailsLink(
              candidature.candidatureDto.platformCode,
              candidature.candidatureDto.competitionCode,
              candidature.candidatureDto.externalId
          )
      );

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const onFileSubmit = async (
    file,
    description,
    fileType,
    memberCode,
    setSubmitting,
    setErrors
  ) => {
    const formData = new FormData();
    formData.append('Attachment', file, file.name);
    formData.append('Description', description);
    formData.append('Type', fileType.code);
    setLoading(true);

    try {
      const addVerdictResponse = await addCandidatureDocument(
        externalId,
        formData
      );
      setDocuments([...documents, addVerdictResponse.data]);
      setSuccessMessage(
        intl.formatMessage(
          { id: 'document.success.add' },
          { docName: addVerdictResponse.data.name }
        )
      );
      setError(null);
    } catch (error) {
      setError(error);
      setSuccessMessage(null);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  const handleDeleteDocument = async (documentName) => {
    try {
      await deleteCandidatureDocument(externalId, documentName);
      setDocuments(documents.filter((doc) => doc.name !== documentName));
      setSuccessMessage(
        intl.formatMessage(
          { id: 'document.success.deleted' },
          { docName: documentName }
        )
      );
      setError(null);
    } catch (deleteDocumentError) {
      setError(deleteDocumentError);
      setSuccessMessage(null);
    }
  };

  const handleAnalyzeOk = async (e) => {
    setLoading(true);
    try {
      await sendAnalyzeOk(externalId);
      setError(null);
      history.replace(
        candidatureDetailsLink(
          candidature.candidatureDto.platformCode,
          candidature.candidatureDto.competitionCode,
          candidature.candidatureDto.externalId
        )
      );
    } catch (error) {
      setError(error);
      setSuccessMessage(null);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate
      hasBackButton
      titleId='analyzeOk.title'
      titleValues={{
        candidatureCode: candidature.candidatureDto.code,
        competitionCode: candidature.candidatureDto.competitionCode,
      }}
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            <AlertError error={error} />
            <AlertSuccess message={successMessage} />
            <legend className='mb-3'>
              <FormattedMessage id='all.submitDocuments' />
            </legend>

            <label>{finalDecisionDocumentType.description}</label>

            <div className='form bg-white pb-3 mb-4'>
              <UploadDocumentType
                onSubmit={onFileSubmit}
                fileType={finalDecisionDocumentType}
                memberCode={null}
              />

              {Arrays.isNotEmpty(documents) ? (
                <>
                  <div className='row mt-3'>
                    <div className='col-lg-10'>
                      <span className='font-small'>
                        <FormattedMessage id='documents.fileName.text' />
                      </span>
                    </div>
                  </div>

                  {documents.map((doc) => (
                    <div key={doc.name} className='border-top py-2'>
                      <div className='row'>
                        <div className='col-lg-10'>
                          <AuthenticatedDownloadLink
                            url={doc.path}
                            filename={doc.name}
                          >
                            {doc.name}
                          </AuthenticatedDownloadLink>
                        </div>
                        <div className='col-lg-2 text-right'>
                          <button
                            className='btn btn-link'
                            onClick={(e) => handleDeleteDocument(doc.name)}
                          >
                            <FormattedMessage id='all.deleteText' />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <EmptyResults />
              )}
            </div>

            <div className='form-actions'>

              <div className='mr-auto'>

                    <button
                        className={'btn btn-outline-primary'}
                        onClick={onClickExcludeCandidature}
                    >
                      <FaTimes />
                      <FormattedMessage id='candidature.exclude.button' />
                    </button>

              </div>


              <div className='ml-auto'>


                <button
                  className='btn btn-primary'
                  onClick={(e) => handleAnalyzeOk(e)}
                >
                  <FormattedMessage id='Candidature.action.validateOk' />
                </button>
              </div>
            </div>
          </div>
        </div>
        <ExcludeDialog
            show={showExcludeDialog}
            handleClose={handleCloseExcludeDialog}
            handleConfirm={handleConfirmExcludeCandidature}
            titleId='candidature.exclude.button'
            actionId='candidature.exclude.action'
            reasonId='messages.exclude.reason'
        />
      </div>
    </SubTemplate>
  );
}

import React, { useState } from 'react';
import { FaEye, FaRegWindowClose, FaSortDown, FaSortUp } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { Arrays } from '../../utils/Arrays';
import { EmptyResults } from '../EmptyResults';
import { StagingCandidaturesCheckboxesDialog } from './StagingCandidaturesCheckboxesDialog';

let DEFAULT_FIELDS = [
  { name: 'id', checked: true },
  { name: 'numberEAA', checked: true },
  { name: 'askMotiveId', checked: false },
  { name: 'motivePointsEAA', checked: false },
  { name: 'considerationMotiveEAA', checked: false },
  { name: 'designationEAA', checked: false },
  { name: 'designationIHRU', checked: false },
  { name: 'incapacityPoints', checked: false },
  { name: 'untitledPoints', checked: false },
  { name: 'over65Elements', checked: false },
  { name: 'over65Points', checked: false },
  { name: 'dependentsPoints', checked: false },
  { name: 'candidatureYearsPoints', checked: false },
  { name: 'incapacityElements', checked: false },
  { name: 'untitledAggregate', checked: false },
  { name: 'dependentsNumber', checked: false },
  { name: 'candidatureYearsNumber', checked: false },
  { name: 'totalPoints', checked: false },
  { name: 'incapacityTiebreaker', checked: false },
  { name: 'over65Tiebreaker', checked: false },
  { name: 'untitledTiebreaker', checked: false },
  { name: 'dependentsTiebreaker', checked: false },
  { name: 'stringTiebreaker', checked: false },
];

export function StagingCandidaturesTable({
  stagingCandidatures,
  fetchDataWithFilters,
  sortFilters,
  setSortFilters,
}) {
  const [showModal, setShowModal] = useState(false);

  const [fields, setFields] = useState(DEFAULT_FIELDS);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  function handleCheckBoxClick(fieldName) {
    const index = fields.findIndex((field) => field.name === fieldName);

    fields[index].checked = fields[index].checked ? false : true;

    setFields([...fields]);
  }

  function onSortFilterChange(type) {
    if (sortFilters.length > 0) {
      const index = sortFilters.findIndex((sf) => sf.type === type);

      if (sortFilters.includes(sortFilters[index])) {
        sortFilters[index].value = getSortFilterValue(sortFilters[index].value);
      } else {
        sortFilters.push({ type: type, value: 'ASC' });
      }

      setSortFilters([...sortFilters]);
    } else {
      sortFilters.push({ type: type, value: 'ASC' });
      setSortFilters([...sortFilters]);
    }

    fetchDataWithFilters();
  }

  function removeSortFilter(type) {
    const index = sortFilters.findIndex((sf) => sf.type === type);

    sortFilters.splice(index, 1);

    setSortFilters([...sortFilters]);

    fetchDataWithFilters();
  }

  function handleCheckAll() {
    if (isAllChecked()) {
      fields.forEach(
        (field, index) => (field.checked = index < 2 ? true : false)
      );
    } else {
      fields.forEach((field) => (field.checked = true));
    }
    setFields([...fields]);
  }

  function isChecked(fieldName) {
    const index = fields.findIndex((field) => field.name === fieldName);

    return fields[index].checked;
  }

  function isAllChecked() {
    let allChecked = true;

    fields.forEach((field) => {
      if (field.checked === false) {
        allChecked = false;
      }
    });
    return allChecked;
  }

  return (
    <div>
      <button
        onClick={() => handleShow()}
        className={'btn btn-outline-primary mb-3 mr-1'}
      >
        <FaEye />
        <FormattedMessage id='stagingCandidaturesCheckboxesDialog.title' />
      </button>
      <button onClick={() => handleCheckAll()} className={'btn btn-link mb-3'}>
        {isAllChecked() ? (
          <FormattedMessage id='all.hideAll' />
        ) : (
          <FormattedMessage id='all.showAll' />
        )}
      </button>

      <StagingCandidaturesCheckboxesDialog
        show={showModal}
        handleClose={handleClose}
        fields={fields}
        handleCheckBoxClick={handleCheckBoxClick}
        handleCheckAll={handleCheckAll}
        isAllChecked={isAllChecked}
      />
      <div className='row'>
        <div className='col-sm-12'>
          <div className='overflow-auto'>
            {Arrays.isNotEmpty(stagingCandidatures) ? (
              <table className='table'>
                <thead>
                  <tr>
                    {isChecked('id') && (
                      <th>
                        {Arrays.isNotEmpty(stagingCandidatures) && (
                          <label
                            className='d-inline pointer'
                            onClick={() => onSortFilterChange('id')}
                          >
                            <FormattedMessage id='stagingCandidaturesTable.id' />
                            {!isFilterEmpty(sortFilters, 'id') && (
                              <>
                                &nbsp;(
                                {getOrderFilterButton(sortFilters, 'id')})
                                &nbsp;
                              </>
                            )}
                          </label>
                        )}
                        {!isFilterEmpty(sortFilters, 'id') &&
                          getRemoveOrderFilterButton(removeSortFilter, 'id')}
                      </th>
                    )}
                    {isChecked('numberEAA') && (
                      <th>
                        {Arrays.isNotEmpty(stagingCandidatures) && (
                          <label
                            className='d-inline pointer'
                            onClick={() => onSortFilterChange('numberEAA')}
                          >
                            <FormattedMessage id='stagingCandidaturesTable.numberEAA' />
                            {!isFilterEmpty(sortFilters, 'numberEAA') && (
                              <>
                                &nbsp;(
                                {getOrderFilterButton(sortFilters, 'numberEAA')}
                                ) &nbsp;
                              </>
                            )}
                          </label>
                        )}
                        {!isFilterEmpty(sortFilters, 'numberEAA') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'numberEAA'
                          )}
                      </th>
                    )}
                    {isChecked('askMotiveId') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() => onSortFilterChange('askMotiveId')}
                        >
                          <FormattedMessage id='stagingCandidaturesTable.askMotiveId' />
                          {!isFilterEmpty(sortFilters, 'askMotiveId') && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(sortFilters, 'askMotiveId')}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'askMotiveId') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'askMotiveId'
                          )}
                      </th>
                    )}
                    {isChecked('motivePointsEAA') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() => onSortFilterChange('motivePointsEAA')}
                        >
                          <FormattedMessage id='stagingCandidaturesTable.motivePointsEAA' />
                          {!isFilterEmpty(sortFilters, 'motivePointsEAA') && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'motivePointsEAA'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'motivePointsEAA') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'motivePointsEAA'
                          )}
                      </th>
                    )}
                    {isChecked('considerationMotiveEAA') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() =>
                            onSortFilterChange('considerationMotiveEAA')
                          }
                        >
                          <FormattedMessage id='stagingCandidaturesTable.considerationMotiveEAA' />
                          {!isFilterEmpty(
                            sortFilters,
                            'considerationMotiveEAA'
                          ) && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'considerationMotiveEAA'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(
                          sortFilters,
                          'considerationMotiveEAA'
                        ) &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'considerationMotiveEAA'
                          )}
                      </th>
                    )}
                    {isChecked('designationEAA') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() => onSortFilterChange('designationEAA')}
                        >
                          <FormattedMessage id='stagingCandidaturesTable.designationEAA' />
                          {!isFilterEmpty(sortFilters, 'designationEAA') && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'designationEAA'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'designationEAA') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'designationEAA'
                          )}
                      </th>
                    )}
                    {isChecked('designationIHRU') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() => onSortFilterChange('designationIHRU')}
                        >
                          <FormattedMessage id='stagingCandidaturesTable.designationIHRU' />
                          {!isFilterEmpty(sortFilters, 'designationIHRU') && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'designationIHRU'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'designationIHRU') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'designationIHRU'
                          )}
                      </th>
                    )}
                    {isChecked('incapacityPoints') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() => onSortFilterChange('incapacityPoints')}
                        >
                          <FormattedMessage id='stagingCandidaturesTable.incapacityPoints' />
                          {!isFilterEmpty(sortFilters, 'incapacityPoints') && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'incapacityPoints'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'incapacityPoints') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'incapacityPoints'
                          )}
                      </th>
                    )}
                    {isChecked('untitledPoints') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() => onSortFilterChange('untitledPoints')}
                        >
                          <FormattedMessage id='stagingCandidaturesTable.untitledPoints' />
                          {!isFilterEmpty(sortFilters, 'untitledPoints') && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'untitledPoints'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'untitledPoints') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'untitledPoints'
                          )}
                      </th>
                    )}
                    {isChecked('over65Elements') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() => onSortFilterChange('over65Elements')}
                        >
                          <FormattedMessage id='stagingCandidaturesTable.over65Elements' />
                          {!isFilterEmpty(sortFilters, 'over65Elements') && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'over65Elements'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'over65Elements') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'over65Elements'
                          )}
                      </th>
                    )}
                    {isChecked('over65Points') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() => onSortFilterChange('over65Points')}
                        >
                          <FormattedMessage id='stagingCandidaturesTable.over65Points' />
                          {!isFilterEmpty(sortFilters, 'over65Points') && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'over65Points'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'over65Points') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'over65Points'
                          )}
                      </th>
                    )}
                    {isChecked('dependentsPoints') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() => onSortFilterChange('dependentsPoints')}
                        >
                          <FormattedMessage id='stagingCandidaturesTable.dependentsPoints' />
                          {!isFilterEmpty(sortFilters, 'dependentsPoints') && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'dependentsPoints'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'dependentsPoints') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'dependentsPoints'
                          )}
                      </th>
                    )}
                    {isChecked('candidatureYearsPoints') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() =>
                            onSortFilterChange('candidatureYearsPoints')
                          }
                        >
                          <FormattedMessage id='stagingCandidaturesTable.candidatureYearsPoints' />
                          {!isFilterEmpty(
                            sortFilters,
                            'candidatureYearsPoints'
                          ) && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'candidatureYearsPoints'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(
                          sortFilters,
                          'candidatureYearsPoints'
                        ) &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'candidatureYearsPoints'
                          )}
                      </th>
                    )}
                    {isChecked('incapacityElements') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() =>
                            onSortFilterChange('incapacityElements')
                          }
                        >
                          <FormattedMessage id='stagingCandidaturesTable.incapacityElements' />
                          {!isFilterEmpty(
                            sortFilters,
                            'incapacityElements'
                          ) && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'incapacityElements'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'incapacityElements') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'incapacityElements'
                          )}
                      </th>
                    )}
                    {isChecked('untitledAggregate') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() =>
                            onSortFilterChange('untitledAggregate')
                          }
                        >
                          <FormattedMessage id='stagingCandidaturesTable.untitledAggregate' />
                          {!isFilterEmpty(sortFilters, 'untitledAggregate') && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'untitledAggregate'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'untitledAggregate') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'untitledAggregate'
                          )}
                      </th>
                    )}
                    {isChecked('dependentsNumber') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() => onSortFilterChange('dependentsNumber')}
                        >
                          <FormattedMessage id='stagingCandidaturesTable.dependentsNumber' />
                          {!isFilterEmpty(sortFilters, 'dependentsNumber') && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'dependentsNumber'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'dependentsNumber') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'dependentsNumber'
                          )}
                      </th>
                    )}
                    {isChecked('candidatureYearsNumber') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() =>
                            onSortFilterChange('candidatureYearsNumber')
                          }
                        >
                          <FormattedMessage id='stagingCandidaturesTable.candidatureYearsNumber' />
                          {!isFilterEmpty(
                            sortFilters,
                            'candidatureYearsNumber'
                          ) && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'candidatureYearsNumber'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(
                          sortFilters,
                          'candidatureYearsNumber'
                        ) &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'candidatureYearsNumber'
                          )}
                      </th>
                    )}
                    {isChecked('totalPoints') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() => onSortFilterChange('totalPoints')}
                        >
                          <FormattedMessage id='stagingCandidaturesTable.totalPoints' />
                          {!isFilterEmpty(sortFilters, 'totalPoints') && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(sortFilters, 'totalPoints')}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'totalPoints') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'totalPoints'
                          )}
                      </th>
                    )}
                    {isChecked('incapacityTiebreaker') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() =>
                            onSortFilterChange('incapacityTiebreaker')
                          }
                        >
                          <FormattedMessage id='stagingCandidaturesTable.incapacityTiebreaker' />
                          {!isFilterEmpty(
                            sortFilters,
                            'incapacityTiebreaker'
                          ) && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'incapacityTiebreaker'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'incapacityTiebreaker') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'incapacityTiebreaker'
                          )}
                      </th>
                    )}
                    {isChecked('over65Tiebreaker') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() => onSortFilterChange('over65Tiebreaker')}
                        >
                          <FormattedMessage id='stagingCandidaturesTable.over65Tiebreaker' />
                          {!isFilterEmpty(sortFilters, 'over65Tiebreaker') && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'over65Tiebreaker'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'over65Tiebreaker') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'over65Tiebreaker'
                          )}
                      </th>
                    )}
                    {isChecked('untitledTiebreaker') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() =>
                            onSortFilterChange('untitledTiebreaker')
                          }
                        >
                          <FormattedMessage id='stagingCandidaturesTable.untitledTiebreaker' />
                          {!isFilterEmpty(
                            sortFilters,
                            'untitledTiebreaker'
                          ) && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'untitledTiebreaker'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'untitledTiebreaker') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'untitledTiebreaker'
                          )}
                      </th>
                    )}
                    {isChecked('dependentsTiebreaker') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() =>
                            onSortFilterChange('dependentsTiebreaker')
                          }
                        >
                          <FormattedMessage id='stagingCandidaturesTable.dependentsTiebreaker' />
                          {!isFilterEmpty(
                            sortFilters,
                            'dependentsTiebreaker'
                          ) && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'dependentsTiebreaker'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'dependentsTiebreaker') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'dependentsTiebreaker'
                          )}
                      </th>
                    )}
                    {isChecked('stringTiebreaker') && (
                      <th>
                        <label
                          className='d-inline pointer'
                          onClick={() => onSortFilterChange('stringTiebreaker')}
                        >
                          <FormattedMessage id='stagingCandidaturesTable.stringTiebreaker' />
                          {!isFilterEmpty(sortFilters, 'stringTiebreaker') && (
                            <>
                              &nbsp;(
                              {getOrderFilterButton(
                                sortFilters,
                                'stringTiebreaker'
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {!isFilterEmpty(sortFilters, 'stringTiebreaker') &&
                          getRemoveOrderFilterButton(
                            removeSortFilter,
                            'stringTiebreaker'
                          )}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {stagingCandidatures.map((candidature) => (
                    <tr key={candidature.id}>
                      {isChecked('id') && <td>{candidature.id}</td>}
                      {isChecked('numberEAA') && (
                        <td>{candidature.numberEAA}</td>
                      )}
                      {isChecked('askMotiveId') && (
                        <td>{candidature.askMotiveId}</td>
                      )}
                      {isChecked('motivePointsEAA') && (
                        <td>{candidature.motivePointsEAA}</td>
                      )}
                      {isChecked('considerationMotiveEAA') && (
                        <td>{candidature.considerationMotiveEAA}</td>
                      )}
                      {isChecked('designationEAA') && (
                        <td>{candidature.designationEAA}</td>
                      )}
                      {isChecked('designationIHRU') && (
                        <td>{candidature.designationIHRU}</td>
                      )}
                      {isChecked('incapacityPoints') && (
                        <td>{candidature.incapacityPoints}</td>
                      )}
                      {isChecked('untitledPoints') && (
                        <td>{candidature.untitledPoints}</td>
                      )}
                      {isChecked('over65Elements') && (
                        <td>{candidature.over65Elements}</td>
                      )}
                      {isChecked('over65Points') && (
                        <td>{candidature.over65Points}</td>
                      )}
                      {isChecked('dependentsPoints') && (
                        <td>{candidature.dependentsPoints}</td>
                      )}
                      {isChecked('candidatureYearsPoints') && (
                        <td>{candidature.candidatureYearsPoints}</td>
                      )}
                      {isChecked('incapacityElements') && (
                        <td>{candidature.incapacityElements}</td>
                      )}
                      {isChecked('untitledAggregate') && (
                        <td>{candidature.untitledAggregate}</td>
                      )}
                      {isChecked('dependentsNumber') && (
                        <td>{candidature.dependentsNumber}</td>
                      )}
                      {isChecked('candidatureYearsNumber') && (
                        <td>{candidature.candidatureYearsNumber}</td>
                      )}
                      {isChecked('totalPoints') && (
                        <td>{candidature.totalPoints}</td>
                      )}
                      {isChecked('incapacityTiebreaker') && (
                        <td>{candidature.incapacityTiebreaker}</td>
                      )}
                      {isChecked('over65Tiebreaker') && (
                        <td>{candidature.over65Tiebreaker}</td>
                      )}
                      {isChecked('untitledTiebreaker') && (
                        <td>{candidature.untitledTiebreaker}</td>
                      )}
                      {isChecked('dependentsTiebreaker') && (
                        <td>{candidature.dependentsTiebreaker}</td>
                      )}
                      {isChecked('stringTiebreaker') && (
                        <td>{candidature.stringTiebreaker}</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <EmptyResults />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function getSortFilterValue(value) {
  switch (value) {
    case '':
      return 'ASC';

    case 'ASC':
      return 'DESC';

    case 'DESC':
      return 'ASC';

    default:
      break;
  }
}

function isFilterEmpty(sortFilters, type) {
  return Arrays.isEmpty(sortFilters.filter((sf) => sf.type === type));
}

function getOrderFilterButton(sortFilters, type) {
  const index = sortFilters.findIndex((sf) => sf.type === type);

  const filter = sortFilters[index];

  if (filter.value === 'ASC') {
    return <FaSortUp />;
  } else if (filter.value === 'DESC') {
    return <FaSortDown />;
  } else {
    return <></>;
  }
}

function getRemoveOrderFilterButton(removeSortFilter, type) {
  return (
    <span onClick={() => removeSortFilter(type)} className='d-inline'>
      <FaRegWindowClose />
    </span>
  );
}

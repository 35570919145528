import { apiAxios as authAxios } from '../axios.config';

export const findAllStagingCandidatures = () => {
    return authAxios.get('/api/pointsRequest');
}

export const findAllStagingCandidaturesWithFilters = (index, size, searchFilters, sortFilters) => {
  return authAxios.get('/api/pointsRequest', {
    params: {
      index: index,
      size: size,
      districtId: searchFilters.districtId,
      countyId: searchFilters.countyId,
      minTypology: searchFilters.minTypology,
      maxTypology: searchFilters.maxTypology,
      minIncapacityElements: searchFilters.minIncapacityElements,
      maxIncapacityElements: searchFilters.maxIncapacityElements,
      minOver65Elements: searchFilters.minOver65Elements,
      maxOver65Elements: searchFilters.maxOver65Elements,
      untitledAggregate: searchFilters.untitledAggregate,
      minDependentsNumber: searchFilters.minDependentsNumber,
      maxDependentsNumber: searchFilters.maxDependentsNumber,
      sortFilters: sortFilters ? constructSortFiltersString(sortFilters) : '',
    },
  });
};

export const getMaxNumberOfColumn = (column) => {
  return authAxios.get(`/api/pointsRequest/maxNumber/${column}`);
}

function constructSortFiltersString(sortFilters) {
  let finalString = '';

  sortFilters.forEach((sf) => {
    finalString = finalString.concat(sf.type + ':' + sf.value + ',');
  });

  return finalString;
}
export const createAcceptedTypesLabel = (types) => {
  if (!types) {
    return '';
  }
  return types
    .split('|')
    .filter((v) => v)
    .reduce((v, ac) => ac + ', ' + v);
};
/**
 * @param  {String} types ex: '|extension1|extension2|'
 * @returns {Array} ex: ['extension1', 'extension2']
 */
export const getAcceptedExtensionsTypes = (types) => {
  if (!types) {
    return [];
  }
  return types.split('|').filter((v) => v);
};

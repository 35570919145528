import React, { useEffect, useState } from 'react';
import { FaEye, FaInfoCircle, FaPencilAlt, FaRegQuestionCircle } from 'react-icons/fa';
import { TiPlus } from 'react-icons/ti';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { hasPermission } from '../../../authentication/authenticationHelper';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { CompetitionFilterBar } from '../../../components/competition/CompetitionFilterBar';
import { CompetitionSearchResult } from '../../../components/competition/CompetitionSearchResult';
import { ComponentLoading } from '../../../components/ComponentLoading';
import HowItWorksDialog from '../../../components/HowItWorksDialog';
import Loading from '../../../components/Loading';
import { SubTemplate } from '../../../components/SubTemplate';
import { useGlobalContext } from '../../../context';
import '../../../css/custom.css';
import {
  getCompetitionsWithFilter,
  getCountyWithCompetitions,
  getDistrictWithCompetitions,
} from '../../../rest/competition';
import { getCatalogs, getPlatform } from '../../../rest/platform';
import { handleError, isNotBusinessError } from '../../../utils/handleError';

function PlatformCompetitions() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(true);
  const [platform, setPlatform] = useState(null);
  const [competitions, setCompetitions] = useState(null);

  const [counties, setCounties] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [typologies, setTypologies] = useState([]);
  const rentsFilter = [
    { id: 1, min: 0, max: 200 },
    { id: 2, min: 200, max: 400 },
    { id: 3, min: 400, max: 600 },
    { id: 4, min: 600, max: 800 },
    { id: 5, min: 800, max: 1000 },
    { id: 6, min: 1000, max: 1200 },
    { id: 7, min: 1200, max: 0 },
  ];
  const [show, setShow] = useState(false);

  const { accessInfo } = useGlobalContext();
  const { platformCode } = useParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchData = async (active) => {
    try {
      const { data: platform } = await getPlatform(platformCode);

      const { data: districts } = await getDistrictWithCompetitions(
        platformCode,
        active
      );
      const { data: typologies } = await getCatalogs(
        platformCode,
        'typologies'
      );
      const { data: competitions } = await getCompetitionsWithFilter(
        platformCode,
        null,
        null,
        null,
        null,
        active,
        null
      );

      setPlatform(platform);
      setDistricts(districts);
      setTypologies(typologies);
      setCompetitions(competitions);
      setSearchLoading(false);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  async function districtChange(districtCode, active) {
    try {
      const { data: counties } = await getCountyWithCompetitions(
        platformCode,
        districtCode,
        active
      );
      setCounties(counties);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  }

  const filterCompetitions = async (
    district,
    county,
    typology,
    maxRent,
    active,
    minRent
  ) => {
    setSearchLoading(true);
    try {
      const { data } = await getCompetitionsWithFilter(
        platformCode,
        district,
        county,
        typology,
        maxRent,
        active,
        minRent
      );

      setCompetitions(data);
      setSearchLoading(false);
    } catch (error) {
      console.log(error);
      setSearchLoading(false);
      setError(error);
    }
  };

  const onTabChange = (active) => {
    setSearchLoading(true);
    fetchData(active);
  };

  useEffect(() => {
    fetchData(true);
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate
      hasBackButton
      title={platform.name}
      subTitleId='competitions.competitionText'
    >
      <div className={'container'}>
        <div className={'row'}>
          <AlertError error={error} />
          <div className={'col-md-9 mb-5'}>
            <CompetitionFilterBar
              typologies={typologies}
              districts={districts}
              counties={counties}
              rentsFilter={platform.rentDefined ? rentsFilter : null}
              onSubmit={filterCompetitions}
              onDistrictChange={districtChange}
              onTabChange={onTabChange}
            />

            {searchLoading ? (
              <ComponentLoading />
            ) : (
              <CompetitionSearchResult
                competitions={competitions}
                platformCode={platformCode}
              />
            )}
          </div>

          <div className={'col-md-3 side-menu'}>
            <div className='mx-2 mb-4'>
              {accessInfo && hasPermission('CREATE_COMPETITION', accessInfo) && (
                <Link
                  to={`/programas/${platformCode}/concursos/adicionar`}
                  className={'btn btn-outline-primary mb-3'}
                >
                  <TiPlus />
                  <FormattedMessage id='competitions.addCompetitionText' />
                </Link>
              )}
              {accessInfo && hasPermission('UPDATE_PLATFORM', accessInfo) && (
                <Link
                  to={`/programas/${platformCode}/editar`}
                  className={'btn btn-outline-primary mb-3'}
                >
                  <FaPencilAlt />
                  <FormattedMessage id='competitions.editPlatformText' />
                </Link>
              )}
              {accessInfo && hasPermission('VIEW_STAGING_CANDIDATURES', accessInfo) && platformCode === 'EAA' && (
                <Link
                  to={`/programas/EAA/staging`}
                  className={'btn btn-outline-primary mb-3'}
                >
                  <FaEye />
                  Staging
                </Link>
              )}
            </div>
            <ul className={'nav flex-column border-left mx-2 my-3'}>
              <li className='nav-item'>
                <button
                  className={'nav-link btn btn-link'}
                  onClick={handleShow}
                >
                  <FaInfoCircle />
                  <FormattedMessage id='competitions.howItWorksText' />
                </button>
              </li>
              <li className={'nav-item'}>
                <Link to={`/perguntas/${platformCode}`} className={'nav-link'}>
                  <FaRegQuestionCircle />
                  <FormattedMessage id='competitions.faqsText' />
                </Link>
              </li>
            </ul>
          </div>
          <HowItWorksDialog
            handleClose={handleClose}
            show={show}
            platform={platform}
            showEditLink={hasPermission('UPDATE_PLATFORM', accessInfo)}
          />
        </div>
      </div>
    </SubTemplate>
  );
}
export default PlatformCompetitions;

import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export function DeleteDialog({
  show,
  handleClose,
  handleConfirmDelete,
  titleId,
  bodyId,
}) {
  if (!show) {
    return <></>;
  } else {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {titleId ? (
              <FormattedMessage id={titleId} />
            ) : (
              <FormattedMessage id='deleteDialog.deleteText' />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {bodyId ? (
            <FormattedMessage id={bodyId} />
          ) : (
            <FormattedMessage id='deleteDialog.deleteQuestionText' />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className={'btn btn-outline-primary'} onClick={handleClose}>
            <FormattedMessage id='all.cancelText' />
          </button>
          <button className={'btn btn-primary'} onClick={handleConfirmDelete}>
            <FormattedMessage id='all.deleteText' />
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

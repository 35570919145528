import { apiAxios as axios } from '../axios.config';
/**
 * @param  {string} code
 * @param  {Promise<{code: string, name: string, description?: string, platform?: string }>} =>{returnaxios.get(`/api/eligibilities/${code}`
 */
export const getEligibitityCondition = (code) => {
  return axios.get(`/api/eligibilities/${code}`);
};

/**
 * @param  {Promise<Array<{code: string, name: string, description?: string, platform?: string }>>} =>{returnaxios.get(`/api/eligibilities`
 */
export const getEligibitityConditions = () => {
  return axios.get(`/api/eligibilities`);
};

/**
 * @param  {{code: string, name: string, description?: string, platform?: string }} eligibilityCondition Eligibility Condition
 * @param  {} =>{returnaxios.post(`/api/eligibilities`
 */
export const addEligilityCondition = (eligibilityCondition) => {
  return axios.post(`/api/eligibilities`, eligibilityCondition);
};
/**
 * @param  {string} code
 * @param  {{code: string, name: string, description?: string, platform?: string }} eligibilityCondition
 * @param  {} =>{returnaxios.put(`/api/eligibilities/${code}`
 */
export const updateEligilityCondition = (code, eligibilityCondition) => {
  return axios.put(`/api/eligibilities/${code}`, eligibilityCondition);
};

/**
 * @param  {string} searchKeyWord
 * @returns {AxiosResponse<{code: string, name: string, description?: string, platform?: string }>} Results from the search
 */
export const getByKeyword = (searchKeyWord) => {
  return axios.get(`/api/eligibilities/search/${searchKeyWord}`);
};

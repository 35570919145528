import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { PlatformType } from '../models/PlatformType';

export const schema = (platform) =>
  yup.object().shape({
    step: yup.number(),
    code: yup
      .string()
      .matches(/^[\w\d_]+$/, 'Formato inválido')
      .required(<FormattedMessage id='errors.competitionCodeRequired.text' />),
    type: yup.string(),
    state: yup.string(),
    introductoryText: yup.string(),
    descriptiveText: yup.string(),
    allowAccommodationChoice: yup.string(),
    allowPreferenceChoice: yup.string(),
    hasPriorityCriterias: yup.mixed().when([], {
      is: () => platform.code === PlatformType.PAS,
      then: yup.boolean().required(<FormattedMessage id='errors.fieldRequired.text' />)
    }),
    maxAccommodations: yup.number(),
    publishDeadline: yup.mixed().when('step', {
      is: 2,
      then: yup.date().min(new Date(), <FormattedMessage id='errors.publishDeadline.text' />)
    }),
    startRegistrationDate: yup.mixed().when('step', {
      is: 2,
      then: yup
        .date()
        .min(
          yup.ref('publishDeadline'),
          <FormattedMessage id='errors.startRegistrationDate.text' />
        )
    }),
    endRegistrationDate: yup.mixed().when('step', {
      is: 2,
      then: yup
        .date()
        .min(
          yup.ref('startRegistrationDate'),
          <FormattedMessage id='errors.endRegistrationDate.text' />
        )
    }),
    publicationDateAcceptedApps: yup.mixed().when('step', {
      is: 2,
      then: yup
        .date()
        .min(
          yup.ref('endRegistrationDate'),
          <FormattedMessage id='errors.publicationDateAcceptedApps.text' />
        )
    }),
    drawDate: yup.mixed().when('step', {
      is: 2,
      then: yup
        .date()
        .min(yup.ref('publicationDateAcceptedApps'), <FormattedMessage id='errors.drawDate.text' />)
    }),
    publicationDateCompResult: yup.mixed().when('step', {
      is: 2,
      then: yup
        .date()
        .min(yup.ref('drawDate'), <FormattedMessage id='errors.publicationDateCompResult.text' />)
    }),
    conclusionDate: yup.mixed().when('step', {
      is: 2,
      then: yup
        .date()
        .min(
          yup.ref('publicationDateCompResult'),
          <FormattedMessage id='errors.conclusionDate.text' />
        )
    }),
    priorityCriteriasChosen: yup.array().of(
      yup.object().shape({
        code: yup.string(),
        criteriaValue: yup.mixed().when('code', {
          is: (code) => {
            return code === 'incomeDrops' || code === 'upToCertainAge';
          },
          then: yup.string().required(<FormattedMessage id='errors.fieldRequired.text' />)
        })
      })
    )
  });

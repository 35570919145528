import { ConfigObject } from '../config';
import { KeycloakObject } from '../keycloak';
import { refreshNphToken } from '../rest/authentication.api';

const LS_ACCESS_INFO_KEY = 'access_info';

export const getAccessInfo = () => {
  const accessInfoStr = localStorage.getItem(LS_ACCESS_INFO_KEY);
  return accessInfoStr ? JSON.parse(accessInfoStr) : null;
};

export const getAccessToken = () => {
  const accessInfo = getAccessInfo();
  if (accessInfo) {
    return accessInfo.access_token;
  }
  return null;
};

export const getPermissions = () => {
  const accessInfo = getAccessInfo();
  if (accessInfo) {
    return accessInfo.permissions;
  }
};

export const isTokenExpired = () => {
  const accessInfo = getAccessInfo();

  if (!accessInfo) {
    return true;
  }

  if (isCandidate(accessInfo)) {
    return accessInfo.refreshTs <= Date.now();
  } else if (
    accessInfo.type === 'keycloak' &&
    KeycloakObject.get().authenticated &&
    !KeycloakObject.get().isTokenExpired(1)
  ) {
    return false;
  }
  return true;
};

export const saveInStorageAccessInfo = (accessInfo) => {
  if (!accessInfo.refreshTs) {
    accessInfo.refreshTs = Date.now() + 1 * 60 * 1000;
  }

  localStorage.setItem(LS_ACCESS_INFO_KEY, JSON.stringify(accessInfo));
};

export const hasPermission = (permission, userInfo) => {
  if (!userInfo || !userInfo.permissions) {
    return false;
  }

  return userInfo.permissions.includes(permission);
};

export const hasAnyPermission = (permissions) => {
  const accessInfo = getAccessInfo();
  if (!accessInfo) {
    return false;
  }
  const { permissions: userPermissions } = accessInfo;
  if (!userPermissions || userPermissions.length <= 0) {
    return false;
  }

  if (!permissions || permissions.length <= 0) {
    return true;
  }

  return permissions.some((p) => {
    return hasPermission(p, accessInfo);
  });
};

export const refreshToken = async () => {
  const accessInfo = getAccessInfo();
  if (!accessInfo) {
    throw Error('Cannot refresh token, unexisting user');
  }
  if (isCandidate(accessInfo)) {
    return handleNphRefresh(accessInfo);
  } else {
    return handleKeycloakRefresh(accessInfo);
  }
};

export const logout = async () => {
  const accessInfo = getAccessInfo();
  if (accessInfo) {
    //Remove the access info from the local storage
    localStorage.removeItem(LS_ACCESS_INFO_KEY);

    if (accessInfo.type === 'keycloak') {
      await KeycloakObject.get().logout({
        redirectUri: ConfigObject.get().baseUrl,
      });
    } else if (isCandidate(accessInfo)) {
      window.location.replace(ConfigObject.get().nphLogout);
    }
  }
};

export const cleanAccessInfo = () => {
  localStorage.removeItem(LS_ACCESS_INFO_KEY);
};

const handleKeycloakRefresh = async () => {
  try {
    const keycloak = KeycloakObject.get();
    await keycloak.updateToken(60);
    constructAccessInfoFromKeycloak(keycloak);
    return getAccessInfo();
  } catch (error) {
    localStorage.removeItem(LS_ACCESS_INFO_KEY);
    throw error;
  }
};

const handleNphRefresh = async (accessInfo) => {
  try {
    const { data: refreshAccess } = await refreshNphToken(
      accessInfo.refresh_token
    );

    refreshAccess.refreshTs = refreshAccess.expires_in * 1000 + Date.now();
    saveInStorageAccessInfo({
      ...refreshAccess,
      type: 'nph',
      permissions: accessInfo.permissions,
    });
    return getAccessInfo();
  } catch (error) {
    console.log(error);
    localStorage.removeItem(LS_ACCESS_INFO_KEY);
    window.location.replace(ConfigObject.get().baseUrl);
    throw error;
  }
};

export const constructAccessInfoFromKeycloak = (keycloak, permissions) => {
  return {
    type: 'keycloak',
    access_token: keycloak.token,
    permissions: permissions,
    refresh_token: keycloak.refreshToken,
  };
};

export const isCandidate = (accessInfo) => {
  return accessInfo?.type === 'nph';
};

export const isIhruUser = (accessInfo) => {
  return accessInfo?.type === 'keycloak';
};

import axios from 'axios';

export const refreshNphToken = (refreshToken) => {
  const params = new URLSearchParams();
  params.append('refresh_token', refreshToken);

  return axios({
    method: 'post',
    url: '/api/auth/token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: params,
  });
};

export const getNphToken = (code) => {
  const params = new URLSearchParams();
  params.append('code', code);

  return axios({
    method: 'post',
    url: '/api/auth/token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: params,
  });
};

export const getUserPermissions = (accessToken, type) => {
  const config = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  if (type && type === 'nph') {
    config['tenant-id'] = 'tenant-a';
  }

  return axios({
    method: 'get',
    url: '/api/permissions/user',
    headers: config,
  });
};

import React from 'react';
import {
  FaBars,
  FaBell,
  FaCaretDown,
  FaRegUser,
  FaSignOutAlt,
} from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  hasPermission,
  isCandidate,
  logout,
} from '../authentication/authenticationHelper';
import { ConfigObject } from '../config';
import { useGlobalContext } from '../context';
import logo from '../img/logo-1.png';

function NavBar() {
  const { nphCodeUrl } = ConfigObject.get();

  const { accessInfo, webSocketClient, numberNotitification } =
    useGlobalContext();

  const handleLogout = () => {
    if (webSocketClient) {
      webSocketClient.close();
    }
    logout();
  };

  return (
    <>
      <a href='#content' className='skip-to-content'>
        <FormattedMessage id='topBar.skipToContent' />
      </a>
      <nav className={'navbar navbar-expand-md bg-white topbar border-bottom'}>
        <div className={'container'}>
          <Link to={'/'} className={'navbar-brand js-scroll-trigger mr-5'}>
            <img src={logo} alt='Logo' />
          </Link>
          <button
            data-toggle='collapse'
            data-target='#navbarsExampleDefault'
            aria-controls='navbarsExampleDefault'
            aria-expanded='false'
            className={'navbar-toggler text-primary'}
          >
            <FaBars />
          </button>
          <div className='collapse navbar-collapse' id='navbarsExampleDefault'>
            {accessInfo != null && accessInfo.type === 'keycloak' && (
              <div className={'navbar-nav-scroll'}>
                <ul className={'navbar-nav bd-navbar-nav'}>
                  {hasPermission('LIST_PLATFORMS', accessInfo) && (
                    <li className={'nav-item mx-3'}>
                      <Link className={'nav-link'} to='/programas'>
                        <FormattedMessage id='topBar.competitionText' />
                      </Link>
                    </li>
                  )}
                  {hasPermission('LIST_PARAMETERS', accessInfo) && (
                    <li className={'nav-item mx-3'}>
                      <Link className={'nav-link'} to='/parametros'>
                        <FormattedMessage id='parameter.list' />
                      </Link>
                    </li>
                  )}
                  {hasPermission('LIST_TEMPLATES', accessInfo) && (
                    <li className={'nav-item mx-3'}>
                      <Link className={'nav-link'} to='/templates'>
                        <FormattedMessage id='template.list' />
                      </Link>
                    </li>
                  )}
                  {hasPermission('GET_ROLES', accessInfo) && (
                    <li className={'nav-item mx-3'}>
                      <Link className={'nav-link'} to='/perfis'>
                        <FormattedMessage id='permissions.permissions' />
                      </Link>
                    </li>
                  )}
                  {hasPermission('GET_HOLIDAYS', accessInfo) && (
                    <li className={'nav-item mx-3'}>
                      <Link className={'nav-link'} to='/feriados'>
                        <FormattedMessage id='holidays.list' />
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            )}
            {isCandidate(accessInfo) && (
              <div className={'navbar-nav-scroll'}>
                <ul className={'navbar-nav bd-navbar-nav'}>
                  <li className={'nav-item mx-3'}>
                    <Link
                      className={'nav-link'}
                      to={`/perfil/candidaturasUtilizador`}
                    >
                      <FormattedMessage id='candidatureUser.candidatureText' />
                    </Link>
                  </li>
                </ul>
              </div>
            )}

            {/* Topbar Navbar */}
            {accessInfo ? (
              <ul className={'navbar-nav ml-auto'}>
                {isCandidate(accessInfo) && (
                  <li className={'nav-item mr-2 px-2 border-right'}>
                    <Link
                      to={`/perfil/mensagens`}
                      className='btn btn-link notification'
                    >
                      <FaBell className={'mr-1'} />
                      {numberNotitification > 0 && (
                        <span className='badge badge-light'>
                          {numberNotitification}
                        </span>
                      )}
                    </Link>
                  </li>
                )}
                {accessInfo.name && (
                  <li className='nav-item dropdown no-arrow mx-2 '>
                    <button
                      className='nav-link dropdown-toggle btn btn-link'
                      id='userDropdown'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='true'
                    >
                      {accessInfo.name}
                      <FaCaretDown className='ml-2' />
                    </button>
                    <div
                      className='dropdown-menu dropdown-menu-right shadow animated--grow-in'
                      aria-labelledby='userDropdown'
                    >
                      <Link className='dropdown-item' to={`/perfil`}>
                        <FaRegUser className={'mr-2 text-secondary'} />
                        <FormattedMessage id='user.profileText' />
                      </Link>
                      <div className='dropdown-divider' />
                      <button onClick={handleLogout} className='dropdown-item'>
                        <FaSignOutAlt className={'mr-2 text-secondary'} />
                        <FormattedMessage id='topBar.logoutButtonText' />
                      </button>
                    </div>
                  </li>
                )}
                {accessInfo.type === 'keycloak' && (
                  <>
                    {hasPermission('LIST_MESSAGES', accessInfo) && (
                      <li className={'nav-item mr-2 px-2 border-right'}>
                        <Link className={'nav-link'} to='/mensagens'>
                          <FaBell className={'mr-1'} />
                          {numberNotitification > 0 && (
                            <span className='badge badge-light'>
                              {numberNotitification}
                            </span>
                          )}
                        </Link>
                      </li>
                    )}
                    <li className={'nav-item'}>
                      <button
                        onClick={handleLogout}
                        className={'btn btn-link text-secondary'}
                      >
                        <FaSignOutAlt />
                        <FormattedMessage id='topBar.logoutButtonText' />
                      </button>
                    </li>
                  </>
                )}
              </ul>
            ) : (
              <ul className={'navbar-nav ml-auto'}>
                <li className={'nav-item'}>
                  <a
                    href={nphCodeUrl}
                    type='button'
                    className={'btn btn-outline-primary'}
                  >
                    <FaRegUser />
                    <FormattedMessage id='topBar.loginButtonText' />
                  </a>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;

import React from 'react';
import { FaCommentAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ConfigObject } from '../config';
import { useGlobalContext } from '../context';
import logoIHRU from '../img/Logo-IHRU.png';
import logoRP from '../img/Logo-RPortuguesa.png';

function Footer() {
  const { GOV_URL, IHRU_URL } = ConfigObject.get();
  const { accessInfo } = useGlobalContext();

  return (
    <footer>
      <div className={'bg-primary my-auto pb-4 pt-4'}>
        <div className={'container text-white'}>
          <div className='row'></div>
          <div className={'row'}>
            <div className={'col-lg-2 col-md-12'}>
              <h6 className={'mt-2 mb-2'}>
                <FormattedMessage id='footer.contacts' />
              </h6>
            </div>
            <div className={'col-md-8 border-left col-lg-5'}>
              <div className='d-flex flex-row pl-1'>
                <div className='p-2'>
                  <FaMapMarkerAlt />
                </div>
                <div>
                  <p className={'mb-0 text-uppercase'}>
                    <small>
                      <FormattedMessage id='footer.addressText' />
                    </small>
                  </p>
                  <p className='mb-0'>
                    <FormattedMessage id='footer.addressLisbon' />
                  </p>
                  <p>
                    <FormattedMessage id='footer.addressPorto' />
                  </p>
                </div>
              </div>
            </div>
            <div className={'col-md-4 col-lg-5 border-left'}>
              <div className='d-flex flex-row pl-1'>
                <div className='p-2'>
                  <FaCommentAlt />
                </div>
                <div>
                  <p className={'mb-0 text-uppercase'}>
                    <small>
                      <FormattedMessage id='footer.knowMore' />
                    </small>
                  </p>
                  <p>
                    <Link to={'/contactar'}>
                      <u>
                        <FormattedMessage id='footer.contactForm' />
                      </u>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'bg-black my-auto border-bottom'}>
        <div className={'container pb-5 pt-5'}>
          <div className={'row'}>
            <div className={'col-md-12 text-center'}>
              <a href={GOV_URL} target='blank' className={'mx-3'}>
                <img
                  style={{ height: '70px' }}
                  src={logoRP}
                  alt='logo república portuguesa'
                />
              </a>
              <a href={IHRU_URL} target='blank' className={'mx-3'}>
                <img
                  style={{ height: '70px' }}
                  src={logoIHRU}
                  alt='logo ihru'
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={'bg-black my-auto'}>
        <div className={'container pb-3 pt-4'}>
          <div className={'row'}>
            <div className={'col-md-6'}>
              {!accessInfo ? (
                <Link to={'/login'} className={'mr-4 text-uppercase'}>
                  <FormattedMessage id='footer.reservedAreaText' />
                </Link>
              ) : (
                <span></span>
              )}
              <a
                href='https://www.portaldahabitacao.pt/web/guest/avisos-legais'
                target='blank'
                className={'mr-4 text-uppercase'}
              >
                <FormattedMessage id='footer.legalText' />
              </a>
              <a
                href='https://www.portaldahabitacao.pt/web/guest/politica-de-privacidade'
                target='blank'
                className={'mr-4 text-uppercase'}
              >
                <FormattedMessage id='footer.privacyText' />
              </a>
            </div>
            <div className={'col-md-6 text-right'}>
              <p>
                <FormattedMessage id='footer.ccText' />
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import FaqForm from '../../../../components/faqs/FaqForm';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { addFaq } from '../../../../rest/faq';
import { getPlatform } from '../../../../rest/platform';
import { handleError, isNotBusinessError } from '../../../../utils/handleError';

function AddFaq() {
  const { platformCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [faq, setFaq] = useState({});

  const [submited, setSubmited] = useState(false);

  const fetchData = async () => {
    try {
      const { data: platform } = await getPlatform(platformCode);
      setFaq({
        platform: platform.code,
        answer: '',
        question: '',
      });
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const createFaq = async (faqToSave, setSubmitting, setErrors) => {
    setLoading(true);
    try {
      const { data } = await addFaq(faqToSave, platformCode);
      setSubmited(true);
      setErrors({});
      setSubmitting(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
      setSubmitting(false);
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  if (submited) {
    return <Redirect to={`/perguntas/${platformCode}`} />;
  }

  return (
    <SubTemplate hasBackButton titleId={'faqs.addFaqsText'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-8 offset-lg-2'}>
            <AlertError error={error} />
            <FaqForm faq={faq} setFaq={setFaq} save={createFaq} />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default AddFaq;

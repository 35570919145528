import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAccessInfo } from './authentication/authenticationHelper';
import { getTicket } from './rest/ticket';
import { createConnection } from './websockets/ws';

const AppContext = createContext();

const AppProvider = ({ children, config }) => {
  const [accessInfo, setAccessInfo] = useState(getAccessInfo());
  const [globalLoading, setGlobalLoading] = useState(false);
  const [webSocketClient, setWebSocketClient] = useState(null);
  const [numberNotitification, setNumberNotification] = useState(null);

  const fetchWS = async () => {
    if (accessInfo) {
      try {
        const { data: ticket } = await getTicket();
        const ws = createConnection(ticket);

        ws.onmessage = (message) => {
          setNumberNotification(message.data);
        };
        setWebSocketClient(ws);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchWS();
    return () => {
      if (webSocketClient) {
        webSocketClient.close();
      }
    };
  }, [accessInfo]);

  return (
    <AppContext.Provider
      value={{
        config,
        accessInfo,
        setAccessInfo,
        globalLoading,
        setGlobalLoading,
        webSocketClient,
        setWebSocketClient,
        numberNotitification,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider, useGlobalContext };

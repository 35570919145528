import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { updateEligilityCondition } from '../../rest/eligibilityCondition';
import { EligibilityConditionForm } from '../eligibilitycondition/EligibilityConditionForm';

function EditConditionDialog({ show, handleClose, onEdit, selectedCondition }) {
  const [condition, setCondition] = useState(selectedCondition);

  const [errors, setErrors] = useState({
    show: false,
    fields: [],
    system: null,
  });

  const updatePlatform = async () => {
    try {
      const { data } = await updateEligilityCondition(
        encodeURIComponent(condition.code),
        condition
      );

      onEdit(data);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        window.location.reload();
      }
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    updatePlatform();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='condition.add' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EligibilityConditionForm
          condition={condition}
          setCondition={setCondition}
          handleClick={handleClick}
          errors={errors}
          setErrors={setErrors}
          onBack={handleClose}
          isUpdate={true}
        />
      </Modal.Body>
    </Modal>
  );
}

export default EditConditionDialog;

import axios from 'axios';
import {
  getAccessInfo,
  isCandidate,
  refreshToken,
  saveInStorageAccessInfo,
} from './authentication/authenticationHelper';
import { ConfigObject } from './config';

export const apiAxios = axios.create();

//request interceptor to add the auth token header to requests
apiAxios.interceptors.request.use(
  (config) => {
    const accessInfo = getAccessInfo();
    if (accessInfo) {
      const { access_token } = accessInfo;
      if (access_token) {
        config.headers['Authorization'] = `Bearer ${access_token}`;
        if (isCandidate(accessInfo)) {
          config.headers['tenant-id'] = 'tenant-a';
        }
      }
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//response interceptor to refresh token on receiving token expired error
const interceptor = apiAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }
    axios.interceptors.response.eject(interceptor);

    console.log(error);
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await refreshToken();
        const accessInfo = getAccessInfo();
        if (!accessInfo) {
          return Promise.reject(error);
        }
        saveInStorageAccessInfo({
          ...accessInfo,
          permissions: accessInfo.permissions, //Do not get again the permissions, on get on login and logout
        });

        originalRequest.headers[
          'Authorization'
        ] = `Bearer ${accessInfo.access_token}`;
        if (isCandidate(accessInfo)) {
          originalRequest.headers['tenant-id'] = 'tenant-a';
        }
        return axios(originalRequest);
      } catch (error) {
        console.log('Could not refresh ', error);
        window.location.replace(ConfigObject.get().baseUrl);
      }
    }
    return Promise.reject(error);
  }
);

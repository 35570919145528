import React, { useState } from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { AddDocumentTypeDialog } from '../documentType/AddDocumentTypeDialog';
import { EditDocumentTypeDialog } from '../documentType/EditDocumentTypeDialog';
import { SearchDocumentTypes } from '../documentType/SearchDocumentTypes';

export function PlatformDocumentTypes({
  platform,
  setPlatform,
  step,
  numberOfSteps,
}) {
  const [showAddDocumentType, setShowAddDocumentType] = useState(false);
  const handleCloseAddDocumentType = () => setShowAddDocumentType(false);
  const handleShowAddDocumentType = () => setShowAddDocumentType(true);

  const [showEditDocumentType, setShowEditDocumentType] = useState(false);
  const handleCloseEditDocumentType = () => setShowEditDocumentType(false);
  const handleShowEditDocumentType = () => setShowEditDocumentType(true);

  const [documentTypeToEdit, setDocumentTypeToEdit] = useState(null);

  const showEditModal = (documentType) => {
    setDocumentTypeToEdit(documentType);
    handleShowEditDocumentType();
  };

  const handleRemove = (documentType) => {
    const tempPlatform = { ...platform };
    tempPlatform.documentTypes = tempPlatform.documentTypes.filter(
      (x) => x.code !== documentType.code
    );

    setPlatform(tempPlatform);
  };

  const handleEditedDocumentType = (documentType) => {
    const tempPlatform = { ...platform };

    const editedDocumentTypes = tempPlatform.documentTypes.filter(
      (x) => x.code !== documentType.code
    );
    editedDocumentTypes.push(documentType);
    tempPlatform.documentTypes = editedDocumentTypes;
    setPlatform({ ...tempPlatform });
    setDocumentTypeToEdit(null);
    handleCloseEditDocumentType();
  };

  const handleSelectedDocumentType = (documentType) => {
    const tempPlat = { ...platform };
    tempPlat.documentTypes = platform.documentTypes.filter(
      (x) => x.code !== documentType.code
    );

    tempPlat.documentTypes.push(documentType);
    setPlatform(tempPlat);
  };

  const handleIsGeneral = (documentType, index) => {
    const tempPlat = { ...platform };

    const editedDocumentTypes = tempPlat.documentTypes.filter(
      (x) => x.code !== documentType.code
    );
    documentType.general = !documentType.general;
    editedDocumentTypes.splice(index, 0, documentType);
    tempPlat.documentTypes = editedDocumentTypes;
    setPlatform(tempPlat);
  };

  const handleRequired = (documentType, index) => {
    const tempPlat = { ...platform };

    const editedDocumentTypes = tempPlat.documentTypes.filter(
      (x) => x.code !== documentType.code
    );
    documentType.required = !documentType.required;
    editedDocumentTypes.splice(index, 0, documentType);
    tempPlat.documentTypes = editedDocumentTypes;
    setPlatform(tempPlat);
  };

  return (
    <div>
      <h5 className='mb-4 pb-2 border-bottom'>
        <FormattedMessage
          id='all.steps'
          values={{ step: step, numberOfSteps: numberOfSteps }}
        />
        {' - '}
        <FormattedMessage id='platformForm.title.documentType' />
      </h5>
      <div className='row'>
        <SearchDocumentTypes onClickDocumentType={handleSelectedDocumentType} />
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <h5 className='mt-3 mb-2'>
            <FormattedMessage id='documentType.assigned' />
          </h5>
          <button
            className='btn btn-outline-secondary'
            onClick={handleShowAddDocumentType}
            type='button'
          >
            <FormattedMessage id='documentType.create.label' />
          </button>

          {platform.documentTypes.length <= 0 && (
            <div className='my-4'>
              <p>
                <em>
                  <FormattedMessage id='documentType.empty' />
                </em>
              </p>
            </div>
          )}
          <ul className='results'>
            {platform.documentTypes.length > 0 && (
              <div className='list-header'>
                <div className='row mt-4'>
                  <div className={'col-sm-6'}>
                    <FormattedMessage id='documentType.typeDocument' />
                  </div>
                  <div className={'col-sm-2 text-center'}>
                    <FormattedMessage id='all.general' />
                  </div>
                  <div className={'col-sm-2 text-center'}>
                    <FormattedMessage id='all.required' />
                  </div>
                </div>
              </div>
            )}
            {platform.documentTypes.map((x, index) => {
              return (
                <li key={index}>
                  <div className='row my-4'>
                    <div className='col-sm-6 align-self-center'>
                      {x.description}
                    </div>
                    {x.general != null && (
                      <div className='col-sm-2 text-center'>
                        {x.descriptor === 'CANDIDATE' && (
                          <input
                            type='checkbox'
                            checked={x.general}
                            onClick={() => handleIsGeneral(x, index)}
                          />
                        )}
                      </div>
                    )}
                    {x.required != null && (
                      <div className='col-sm-2 text-center'>
                        {x.descriptor === 'CANDIDATE' && (
                          <input
                            type='checkbox'
                            checked={x.required}
                            onClick={() => handleRequired(x, index)}
                          />
                        )}
                      </div>
                    )}
                    <div className='col-sm-2 text-right'>
                      <button
                        className={'btn btn-link p-0 pr-2'}
                        onClick={() => showEditModal(x)}
                        type='button'
                      >
                        <FaPencilAlt />
                      </button>

                      <button
                        className={'btn btn-link p-0 pr-2'}
                        onClick={() => handleRemove(x)}
                        type='button'
                      >
                        <FaTrashAlt />
                      </button>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {showAddDocumentType && (
        <AddDocumentTypeDialog
          handleClose={handleCloseAddDocumentType}
          show={showAddDocumentType}
          onSave={handleSelectedDocumentType}
        />
      )}
      {showEditDocumentType && (
        <EditDocumentTypeDialog
          show={showEditDocumentType}
          handleClose={handleCloseEditDocumentType}
          onSave={handleEditedDocumentType}
          documentType={documentTypeToEdit}
        />
      )}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { getPlatforms } from '../../../../rest/platform';

function EditPlatformsPage() {
  const [otherPlatforms, setOtherPlatforms] = useState(false);
  const [platforms, setPlatforms] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const { data: platforms } = await getPlatforms();
      setPlatforms(platforms);
      setLoading(false);
    } catch (error) {
      if (error.response?.status === 401) {
        window.location.reload();
      } else {
        setError(error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const setOtherPlatformsTrue = () => {
    setOtherPlatforms(true);
  };

  const setOtherPlatformsFalse = () => {
    setOtherPlatforms(false);
  };

  if (loading) {
    return <Loading></Loading>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <SubTemplate hasBackButton titleId={'backofficePlatform.platformText'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-md-9'}>
            {otherPlatforms ? (
              <ul className={'nav nav-tabs'}>
                <li className={'nav-item'}>
                  <button
                    className={'nav-link text-uppercase'}
                    onClick={setOtherPlatformsFalse}
                  >
                    <FormattedMessage id='all.textHabitational' />
                  </button>
                </li>
                <li className={'nav-item'}>
                  <button
                    className={'nav-link text-uppercase active'}
                    onClick={setOtherPlatformsTrue}
                  >
                    <FormattedMessage id='all.textOthers' />
                  </button>
                </li>
              </ul>
            ) : (
              <ul className={'nav nav-tabs'}>
                <li className={'nav-item'}>
                  <button
                    className={'nav-link active text-uppercase'}
                    onClick={setOtherPlatformsFalse}
                  >
                    <FormattedMessage id='all.textHabitational' />
                  </button>
                </li>
                <li className={'nav-item'}>
                  <button
                    className={'nav-link text-uppercase'}
                    onClick={setOtherPlatformsTrue}
                  >
                    <FormattedMessage id='all.textOthers' />
                  </button>
                </li>
              </ul>
            )}

            {!otherPlatforms ? (
              <div className={'row'}>
                <div className={'col-md-12'}>
                  <ul className={'results'}>
                    {platforms
                      .filter((platform) => platform.type === 'HABITATIONAL')
                      .map((platform) => (
                        <li key={platform.code}>
                          <div className={'row my-4'}>
                            <div className={'col-md-9'}>
                              <Link
                                to={`/programas/${platform.code}/concursos`}
                              >
                                {platform.name}
                              </Link>
                            </div>
                            <div className={'col-md-3 text-right'}>
                              <Link to={`/programas/${platform.code}/editar`}>
                                <div className={'text-secondary'}>
                                  <small>
                                    <FormattedMessage id='all.editButtonText' />
                                  </small>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            ) : (
              <div className={'row my-4'}>
                <div className={'col-md-12'}>
                  <ul className={'results'}>
                    {platforms
                      .filter((platform) => platform.type === 'OTHER')
                      .map((platform) => (
                        <li key={platform.code}>
                          <div className={'row my-4'}>
                            <div className={'col-md-9'}>
                              <Link
                                to={`/programas/${platform.code}/concursos`}
                              >
                                {platform.name}
                              </Link>
                            </div>
                            <div className={'col-md-3 text-right'}>
                              <Link to={`/programas/${platform.code}/editar`}>
                                <small>
                                  <FormattedMessage id='all.editButtonText' />
                                </small>
                              </Link>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default EditPlatformsPage;

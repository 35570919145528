import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export function FaqDeleteDialog({ faq, deleteFaq, handleClose, show }) {
  if (!show) {
    return <></>;
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='faqs.deleteFaqText' />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FormattedMessage id='faqs.deleteFaqQuestionText' />
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          <FormattedMessage id='all.cancelText' />
        </Button>

        <Button variant='primary' onClick={() => deleteFaq(faq.externalId)}>
          <FormattedMessage id='all.deleteText' />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import { EditCompetitionDatesForm } from '../../../../components/competition/EditCompetitionDatesForm';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import {
  changeCompetitionPartial,
  getAllowActions,
  getCompetitionByCompetitionCode,
} from '../../../../rest/competition';
import { handleError, isBusinessError } from '../../../../utils/handleError';

export function EditCompetitionDates() {
  const { platformCode } = useParams();
  const { competitionCode } = useParams();

  const [competition, setCompetition] = useState({});
  const [competitionDates, setCompetitionDates] = useState({});

  const [loading, setLoading] = useState(true);
  const [submited, setSubmited] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const { data: competitionData } = await getCompetitionByCompetitionCode(
        competitionCode,
        platformCode
      );

      const { data: allowActionsData } = await getAllowActions(
        competitionCode,
        platformCode
      );

      setCompetition(competitionData);
      setCompetitionDates(allowActionsData.datesCanBeChanged);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const update = async (competition, setSubmitting, setErrors) => {
    const updatedDates = competitionDates.map((element) => {
      return { fieldName: element, value: competition[element] };
    });

    try {
      await changeCompetitionPartial(
        competitionCode,
        platformCode,
        updatedDates
      );

      setSubmitting(false);
      setSubmited(true);
      setLoading(false);
    } catch (error) {
      setError(error);
      setSubmitting(false);
      setLoading(false);
    }
  };

  const onSubmit = (competition, setSubmitting, setErrors) => {
    setLoading(true);
    update(competition, setSubmitting, setErrors);
  };

  if (loading) {
    return <Loading />;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  if (submited) {
    return (
      <Redirect
        to={`/programas/${platformCode}/concursos/${competitionCode}`}
      />
    );
  }

  return (
    <SubTemplate titleId={'competitionDates.editText'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-8 offset-lg-2'}>
            <AlertError error={error} />
            <EditCompetitionDatesForm
              platformCode={platformCode}
              competition={competition}
              editableCompetitionDates={competitionDates}
              onSubmit={onSubmit}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { CompetitionState } from '../../models/CompetitionState';
import Annunnciation from './Annunciation';

export function CompetitionSearchResult({ competitions, platformCode }) {
  if (!competitions || competitions.length === 0) {
    return (
      <div className='empty-state my-4'>
        <FaInfoCircle />
        <p>
          <FormattedMessage id='competitions.noCompetitionsResult' />
        </p>
      </div>
    );
  }

  return (
    <div>
      <div className={'row'}>
        <div className={'col-md-12'}>
          <ul className={'results'}>
            {competitions.map(
              (competition, index) =>
                competition.state !== CompetitionState.DELETED && (
                  <Annunnciation
                    key={index}
                    competition={competition}
                    platformCode={platformCode}
                  />
                )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

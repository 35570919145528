import { apiAxios as authAxios } from '../axios.config';

export const getCompetitionDocuments = (competitionCode, platformCode) => {
  return authAxios.get(
    `/api/platform/${platformCode}/competitions/${competitionCode}/documents`
  );
};

export const getCompetitionDocumentsByDocType = (
  competitionCode,
  platformCode,
  documentTypeCode
) => {
  return authAxios.get(
    `/api/platform/${platformCode}/competitions/${competitionCode}/documents`,
    {
      params: { documentType: documentTypeCode },
    }
  );
};

export const addCompetitionDocument = (
  competitionCode,
  platformCode,
  formData
) => {
  return authAxios.post(
    `/api/platform/${platformCode}/competitions/${competitionCode}/documents`,
    formData
  );
};

export const deleteCompetitionDocument = (
  competitionCode,
  platformCode,
  documentName
) => {
  return authAxios.delete(
    `/api/platform/${platformCode}/competitions/${competitionCode}/documents/${documentName}`
  );
};

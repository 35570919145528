import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';

function AddCandidatureForm({
  certificateNumber,
  setCertificateNumber,
  onSubmit,
}) {
  const { competitionCode } = useParams();
  const { platformCode } = useParams();

  const handleChange = (e) => {
    setCertificateNumber(e.target.value);
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group controlId='competitionCode'>
        <Form.Label>
          <FormattedMessage id='habitationForm.competitionCode' />
        </Form.Label>
        <Form.Control
          type='text'
          name='competitionCode'
          value={decodeURIComponent(competitionCode)}
          disabled
        />
      </Form.Group>
      <Form.Group controlId='certificateNumber'>
        <Form.Label>
          <FormattedMessage id='addCandidature.certificateNumberText' />
        </Form.Label>
        <Form.Control
          type='text'
          name='certificateNumber'
          value={certificateNumber}
          onChange={handleChange}
        />
      </Form.Group>
      <div className='form-actions'>
        <div className='mr-auto'>
          <Link
            to={`/programas/${platformCode}/concursos/${competitionCode}`}
            className={'btn btn-link'}
          >
            <FormattedMessage id='all.backButtonText' />
          </Link>
        </div>
        <div className='ml-auto'>
          <button variant='primary' className='btn btn-primary' type='submit'>
            <FormattedMessage id='all.nextButtonText' />
          </button>
        </div>
      </div>
    </Form>
  );
}

export default AddCandidatureForm;

import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import ListCandidature from '../candidature/ListCandidature';
import { HabitationCardList } from '../habitation/HabitationCardList';
import { TermsAndConditions } from '../TermsAndConditions';

function CandidatureConfirmation({
  candidature,
  setCandidature,
  selectedHabitations,
  handleConfirmationBack,
  onSubmitCandidature,
  platformCode
}) {
  function order(a, b) {
    if (a.order > b.order) {
      return 1;
    } else if (a.order < b.order) {
      return -1;
    } else {
      return 0;
    }
  }

  const handleChangeValue = (acceptedConditions) => {
    setCandidature({
      ...candidature,
      candidatureDto: {
        ...candidature.candidatureDto,
        termsAndConditionsValues: acceptedConditions,
      },
    });
  };

  return (
    <>
      <div className={'form'}>
        <h5 className='mb-4 pb-2 border-bottom'>
          <FormattedMessage id='candidature.confirmationText' />
        </h5>
        <ListCandidature candidature={candidature} platformCode={platformCode} viewMode={true} />
        {candidature.options.canChoose && (
          <legend className={'mt-4 mb-2'}>
            <FormattedMessage id='candidature.numberHabitationsText' />
            {': '}
            <strong>{selectedHabitations.length}</strong>
          </legend>
        )}
        {selectedHabitations.sort(order).map((candidatureHabitation) => (
          <HabitationCardList
            key={candidatureHabitation.habitation.externalId}
            habitation={candidatureHabitation}
            view={true}
            showArrow={true}
          />
        ))}
      </div>
      <div className='mt-3 pl-3'>
        <TermsAndConditions value={candidature.termsAndConditions} setValue={handleChangeValue} platformCode={platformCode} />
      </div>
      <div className='form-actions'>
        <div className='mr-auto'>
          <Link onClick={handleConfirmationBack} className='btn btn-link'>
            <FormattedMessage id='all.backButtonText' />
          </Link>
        </div>
        <div className='ml-auto'>
          <Button variant='primary' onClick={() => onSubmitCandidature()}>
            <FormattedMessage id='all.submitButtonText' />
          </Button>
        </div>
      </div>
    </>
  );
}

export default CandidatureConfirmation;

import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ValidateOptions } from '../../models/ValidateOptions';

function DocumentsValidationAccording({
  required,
  documentType,
  handleChange,
  memberCode,
}) {
  const intl = useIntl();

  return (
    <Form className='radio-form'>
      <Form.Group controlId={documentType}>
        <Form.Check
          disabled={required}
          type={required ? 'hidden' : 'radio'}
          id={documentType + 'NOT_APPLICABLE' + memberCode}
          onClick={(e) => handleChange(e, documentType, memberCode)}
          name={documentType}
          value={ValidateOptions.NOT_APPLICABLE}
        />
        {!required && (
          <Form.Label>
            <FormattedMessage id={'all.notapplicableText'} />
          </Form.Label>
        )}
        <Form.Check
          type='radio'
          onClick={(e) => handleChange(e, documentType, memberCode)}
          id={documentType + 'YES' + memberCode}
          name={documentType}
          value={ValidateOptions.YES}
        />
        <Form.Label>
          <FormattedMessage id={'documents.according.text'} />
        </Form.Label>
        <Form.Check
          type='radio'
          id={documentType + 'NO' + memberCode}
          onClick={(e) => handleChange(e, documentType, memberCode)}
          name={documentType}
          value={ValidateOptions.NO}
        />
        <Form.Label>
          <FormattedMessage id={'documents.noAccording.text'} />
        </Form.Label>
      </Form.Group>
    </Form>
  );
}

export default DocumentsValidationAccording;

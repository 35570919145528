import { apiAxios as axios } from '../axios.config';

export const getDocumentType = (code) => {
  return axios.get(`/api/documentTypes/${code}`);
};

export const getDocumentTypes = (descriptor) => {
  return axios.get(
    `/api/documentTypes${descriptor ? '?descriptor=' + descriptor : ''}`
  );
};

export const getCandidatureDocumentTypes = (candidatureExternalId) => {
  return axios.get(`/api/candidature/${candidatureExternalId}/documentTypes`);
};

export const addDocumentType = (documentType) => {
  return axios.post(`/api/documentTypes`, documentType);
};

export const updateDocumentType = (code, documentType) => {
  return axios.put(`/api/documentTypes/${code}`, documentType);
};

export const getByKeyword = (searchKeyWord, descriptor) => {
  return axios.get(
    `/api/documentTypes/search/${searchKeyWord}${
      descriptor ? '?descriptor=' + descriptor : ''
    }`
  );
};

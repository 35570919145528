import React, { createRef } from 'react';
import { isCandidate } from '../../authentication/authenticationHelper';
import { useGlobalContext } from '../../context';

export function AuthenticatedDownloadLink({ url, filename, children }) {
  const link = createRef();

  const { accessInfo } = useGlobalContext();

  const handleAction = async () => {
    if (link.current.href) {
      return;
    }
    const headers = {};
    headers.Authorization = `Bearer ${accessInfo.access_token}`;
    if (isCandidate(accessInfo)) {
      headers['tenant-id'] = 'tenant-a';
    }

    const result = await fetch(url, { headers: headers });

    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);

    link.current.download = filename;
    link.current.href = href;

    link.current.click();
  };

  return (
    <>
      <a
        role='button'
        className='file-download'
        ref={link}
        onClick={handleAction}
      >
        {children}
      </a>
    </>
  );
}

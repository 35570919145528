export const Arrays = {
  /**
   * @param  {Array} arr
   */
  isEmpty: (arr) => {
    return checkEmpty(arr);
  },

  /**
   * @param  {Array} arr
   */
  isNotEmpty: (arr) => {
    return !checkEmpty(arr);
  },
};

function checkEmpty(arr) {
  return !arr || arr.length === 0;
}

import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { EmptyResults } from '../../../../components/EmptyResults';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { getDrawActualCandidatures } from '../../../../rest/draw';
import { handleError, isNotBusinessError } from '../../../../utils/handleError';

export function CompetitionSerialization() {
  const { platformCode } = useParams();
  const { competitionCode } = useParams();

  const intl = useIntl();

  const [drawnCandidatures, setDrawnCandidatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: drawnCandidatures } = await getDrawActualCandidatures(
          competitionCode,
          platformCode
        );
        setDrawnCandidatures(drawnCandidatures);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate
      hasBackButton
      title={`${intl.formatMessage({
        id: 'serialization.title.text',
      })} ${competitionCode}`}
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            {drawnCandidatures.length > 0 ? (
              <table className='table'>
                <thead>
                  <tr>
                    <th className='w-15'>
                      <label>
                        <FormattedMessage id='kafka.drawnCandidatures.orderNumber' />
                      </label>
                    </th>
                    <th className='w-35'>
                      <label>
                        <FormattedMessage id='kafka.drawnCandidatures.candidatureNumber' />
                      </label>
                    </th>
                    <th className='w-15'>
                      <label>
                        <FormattedMessage id='kafka.drawnCandidatures.housingSupportApplicationNumber' />
                      </label>
                    </th>
                    <th className='w-15'>
                      <label>
                        <FormattedMessage id='kafka.drawnCandidatures.drawnHabitationTypology' />
                      </label>
                    </th>
                    <th className='w-15'>
                      <label>
                        <FormattedMessage id='kafka.drawnCandidatures.drawnHabitation.serialization' />
                      </label>
                    </th>
                    <th className='w-15'>
                      <label>
                        <FormattedMessage id='scoreDetailsTable.score' />
                      </label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {drawnCandidatures.map((drawnCandidature, index) => (
                    <DrawnCandidatureRow
                      key={index}
                      drawnCandidature={drawnCandidature}
                    />
                  ))}
                </tbody>
              </table>
            ) : (
              <EmptyResults />
            )}
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

function DrawnCandidatureRow({ drawnCandidature }) {
  return (
    <tr>
      <td>{drawnCandidature.position}</td>
      <td>{drawnCandidature.code}</td>
      <td>
        {drawnCandidature.code} - {drawnCandidature.competitionCode}
      </td>
      <td>
        {getDrawnedHabitationTypologyMessage(
          drawnCandidature.drawnHabitationTypology
        )}
      </td>
      <td>{getDrawnedHabitationMessage(drawnCandidature.habitationCode)}</td>
      <td>{drawnCandidature.totalPoints}</td>
    </tr>
  );
}

function getDrawnedHabitationMessage(habitationCode) {
  return habitationCode ? (
    <FormattedMessage
      id='kafka.drawnCandidatures.drawnedHabitation'
      values={{
        habitationCode: habitationCode,
      }}
    />
  ) : (
    <FormattedMessage id='all.noText' />
  );
}

function getDrawnedHabitationTypologyMessage(drawnedHabitationTypology) {
  return drawnedHabitationTypology ? (
    drawnedHabitationTypology
  ) : (
    <FormattedMessage id='kafka.drawnCandidatures.notApplicableMessage' />
  );
}

import React, { useState } from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { AddConditionDialog } from '../condition/AddConditionDialog';
import EditConditionDialog from '../condition/EditConditionDialog';
import SearchConditions from '../condition/SearchConditions';

export function PlatformFormConditions({
  platform,
  setPlatform,
  step,
  numberOfSteps,
}) {
  const [showAddCondition, setShowAddCondition] = useState(false);
  const handleCloseAddCondition = () => setShowAddCondition(false);
  const handleShowAddCondition = () => setShowAddCondition(true);

  const [showEditCondition, setShowEditCondition] = useState(false);
  const handleCloseEditCondition = () => setShowEditCondition(false);
  const handleShowEditCondition = () => setShowEditCondition(true);

  const [conditionToEdit, setConditionToEdit] = useState(null);

  const showEditModal = (condition) => {
    setConditionToEdit(condition);
    handleShowEditCondition();
  };

  const handleRemove = (condition) => {
    const tempPlatform = { ...platform };
    tempPlatform.eligibilityConditions =
      tempPlatform.eligibilityConditions.filter(
        (x) => x.code !== condition.code
      );

    setPlatform(tempPlatform);
  };

  const handleEditedCondition = (condition) => {
    const tempPlatform = { ...platform };

    const editedEligibilityConditions =
      tempPlatform.eligibilityConditions.filter(
        (x) => x.code !== condition.code
      );
    editedEligibilityConditions.push(condition);
    tempPlatform.eligibilityConditions = editedEligibilityConditions;
    setPlatform({ ...tempPlatform });
    setConditionToEdit(null);
    handleCloseEditCondition();
  };

  const handleSelectedCondition = (condition) => {
    const tempPlat = { ...platform };
    tempPlat.eligibilityConditions = platform.eligibilityConditions.filter(
      (x) => x.code !== condition.code
    );

    tempPlat.eligibilityConditions.push(condition);
    setPlatform(tempPlat);
  };

  return (
    <div>
      <h5 className='mb-4 pb-2 border-bottom'>
        <FormattedMessage
          id='all.steps'
          values={{ step: step, numberOfSteps: numberOfSteps }}
        />
        {' - '}
        <FormattedMessage id='platformForm.title.eligibility.condition' />
      </h5>
      <div className='row'>
        <SearchConditions onClickCondition={handleSelectedCondition} />
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <h5 className='mt-3 mb-2'>
            <FormattedMessage id='platformForm.assigned.conditions' />
          </h5>
          <button
            className='btn btn-outline-secondary'
            onClick={handleShowAddCondition}
            type='button'
          >
            <FormattedMessage id='condition.create.label' />
          </button>
          {platform.eligibilityConditions.length <= 0 && (
            <div className='my-4'>
              <p>
                <em>
                  <FormattedMessage id='condition.empty' />
                </em>
              </p>
            </div>
          )}
          <ul className='results'>
            {platform.eligibilityConditions.map((x, index) => {
              return (
                <li key={index}>
                  <div className='row my-4'>
                    <div className='col-sm-9 align-self-center'>{x.name}</div>
                    <div className='col-sm-3 text-right'>
                      <button
                        className={'btn btn-link ml-3'}
                        onClick={() => showEditModal(x)}
                        type='button'
                      >
                        <FaPencilAlt />
                      </button>
                      <button
                        className={'btn btn-link ml-3'}
                        onClick={() => handleRemove(x)}
                        type='button'
                      >
                        <FaTrashAlt />
                      </button>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {showAddCondition && (
        <AddConditionDialog
          platformCode={platform.code}
          handleClose={handleCloseAddCondition}
          show={showAddCondition}
          onCreated={handleSelectedCondition}
        />
      )}
      {showEditCondition && (
        <EditConditionDialog
          handleClose={handleCloseEditCondition}
          onEdit={handleEditedCondition}
          show={showEditCondition}
          selectedCondition={conditionToEdit}
        />
      )}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { TiPlus } from 'react-icons/ti';
import { FormattedMessage } from 'react-intl';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import { TextInputField } from '../../../../components/bootstrap/TextInputField';
import { EmptyResults } from '../../../../components/EmptyResults';
import { HolidaysDialog } from '../../../../components/holidays/HolidaysDialog';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import {
  createHoliday,
  deleteHoliday,
  getHolidays,
  updateHoliday,
} from '../../../../rest/holidays';
import { Arrays } from '../../../../utils/Arrays';
import { handleError, isNotBusinessError } from '../../../../utils/handleError';

const CURRENT_YEAR = '2021';
const YEAR_VALIDATION_REGEX = '^[0-9]{4}$';

export function ListHolidays() {
  const [year, setYear] = useState(CURRENT_YEAR);
  const [yearErrorMessage, setYearErrorMessage] = useState(null);
  const [holidays, setHolidays] = useState({});
  const [selectedHoliday, setSelectedHoliday] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [update, setUpdate] = useState(false);

  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    fetchData();
  }, [year]);

  const fetchData = async () => {
    setError(null);
    try {
      if (year.match(YEAR_VALIDATION_REGEX)) {
        const { data } = await getHolidays(year);
        setHolidays(data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (
    holidayData,
    externalId,
    setSubmitting,
    isUpdate
  ) => {
    isUpdate
      ? changeHoliday(holidayData, externalId, setSubmitting)
      : addHoliday(holidayData, setSubmitting);
  };

  const handleDelete = async (externalId) => {
    setLoading(true);

    try {
      const { data: deletedHoliday } = await deleteHoliday(externalId);
      setHolidays(
        holidays.filter(
          (holiday) => holiday.externalId !== deletedHoliday.externalId
        )
      );
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const addHoliday = async (holidayData, setSubmitting) => {
    setLoading(true);

    try {
      await createHoliday(holidayData);
      fetchData();
      setSubmitting(false);
    } catch (error) {
      setError(error);
    } finally {
      setShowDialog(false);
      setLoading(false);
    }
  };

  const changeHoliday = async (holidayData, externalId, setSubmitting) => {
    setLoading(true);

    try {
      const { data: updatedHoliday } = await updateHoliday(
        externalId,
        holidayData
      );

      const oldHoliday = holidays.find(
        (holiday) => holiday.externalId === updatedHoliday.externalId
      );

      const oldHolidayIndex = holidays.indexOf(oldHoliday);

      holidays[oldHolidayIndex] = updatedHoliday;

      setHolidays([...holidays]);

      setSubmitting(false);
    } catch (error) {
      setError(error);
    } finally {
      setShowDialog(false);
      setLoading(false);
    }
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleEditClick = (holiday) => {
    setUpdate(true);
    setSelectedHoliday(holiday);
    setShowDialog(true);
  };

  const handleAddClick = () => {
    if (!year.match(YEAR_VALIDATION_REGEX)) {
      setYear(CURRENT_YEAR);
      setYearErrorMessage(null);
    }

    setUpdate(false);
    setShowDialog(true);
  };

  const handleYearChange = (e) => {
    if (!e.target.value.match(YEAR_VALIDATION_REGEX)) {
      setYearErrorMessage(<FormattedMessage id='errors.invalidYear' />);
    } else {
      setYearErrorMessage(null);
    }
    setYear(e.target.value);
  };

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate hasBackButton titleId={'holidays.list'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-md-9'}>
            <AlertError error={error} />
            <div className='col-md-2 px-0'>
              <TextInputField
                name='year'
                labelId='holidays.year'
                value={year}
                handleChange={handleYearChange}
                isInvalid={yearErrorMessage}
                errorMessage={yearErrorMessage}
              />
            </div>
            <br />

            {Arrays.isNotEmpty(holidays) ? (
              <ul className={'results'}>
                {holidays.map((holiday) => (
                  <li key={holiday.externalId}>
                    <div className={'row my-3'}>
                      <div className={'col-md-8'}>
                        <strong>{holiday.holidayDate}</strong> ·{' '}
                        {holiday.description}
                      </div>

                      <div className={'col-md-4 text-right'}>
                        <span>
                          <button
                            onClick={() => handleDelete(holiday.externalId)}
                            className={'btn btn-link mr-3'}
                          >
                            <FaTrashAlt />
                          </button>
                        </span>

                        <button
                          className='btn btn-link'
                          onClick={() => handleEditClick(holiday)}
                        >
                          <FaEdit />
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <EmptyResults />
            )}
          </div>

          {showDialog && update && (
            <HolidaysDialog
              show={showDialog}
              handleClose={handleDialogClose}
              handleSubmit={handleSubmit}
              holiday={selectedHoliday}
              isUpdate={true}
            />
          )}

          {showDialog && !update && (
            <HolidaysDialog
              show={showDialog}
              handleClose={handleDialogClose}
              handleSubmit={handleSubmit}
              isUpdate={false}
            />
          )}

          <div className='col-md-3'>
            <div className='mx-2 mb-4'>
              <button
                className={'btn btn-outline-primary mb-3'}
                onClick={() => handleAddClick()}
              >
                <TiPlus aria-hidden='true' />
                <FormattedMessage id='holidays.add' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

import axios from 'axios';
import { apiAxios as authAxios } from '../axios.config';

/**
 * Rest service to add an image to a habitation
 *
 * @param  {string} habitationExternalId External Id of the habitation
 * @return  {Promise<Response<ImageMetadataDto>>} => Response
 */
export const getHabitationImage = (habitationExternalId) => {
  return axios.get(`/api/habitations/${habitationExternalId}/images`);
};

/**
 * @param  {string} habitationExternalId External Id of the habitation
 * @param  {Image} formData
 */
export const addHabitationImage = (habitationExternalId, formData) => {
  return authAxios.post(
    `/api/habitations/${habitationExternalId}/images`,
    formData
  );
};

/**
 * @param  {string} habitationExternalId External Id of the habitation
 * @param  {string} imageName Name of the image
 * @param  {} =>{returnaxios.delete(`/api/habitations/${habitationExternalId}/images/${imageName}`
 */
export const deleteHabitationImage = (habitationExternalId, imageName) => {
  return authAxios.delete(
    `/api/habitations/${habitationExternalId}/images/${imageName}`
  );
};

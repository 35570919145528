import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

function ValidationChangeDateSubmission({
  show,
  handleClose,
  onClick,
  onClickSubmit,
  onClickChangeDate,
}) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='documents.validation.button' />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FormattedMessage id='documents.invalidSubmissionText.text' />
      </Modal.Body>

      <Modal.Footer>
        <button className='btn btn-link mr-auto' onClick={handleClose}>
          <FormattedMessage id='all.cancelText' />
        </button>
        <button
          className='btn btn-outline-primary'
          onClick={(e) => onClickChangeDate(e)}
        >
          <FormattedMessage id='documents.changeDate.text' />
        </button>
        <button className='btn btn-primary' onClick={(e) => onClickSubmit(e)}>
          <FormattedMessage id='all.submitButtonText' />
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ValidationChangeDateSubmission;

import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loading from './components/Loading';
import { ScrollToTop } from './components/ScrollToTop';
import { Template } from './components/Template';
import { CompetitionDraw } from './kafka/CompetitionDraw';
import { AddActionNotification } from './pages/protected/backoffice/actionNotification/AddActionNotification';
import { EditActionNotification } from './pages/protected/backoffice/actionNotification/EditActionNotification';
import { ListActionNotifications } from './pages/protected/backoffice/actionNotification/ListActionNotifications';
import { AnalyzeManagment } from './pages/protected/backoffice/candidature/AnalyzeManagment';
import { CelebrationContractManagment } from './pages/protected/backoffice/candidature/CelebrationContractManagment';
import { ContestationManagment } from './pages/protected/backoffice/candidature/ContestationManagment';
import DocumentsValidation from './pages/protected/backoffice/candidature/DocumentsValidation';
import { ListCandidatures } from './pages/protected/backoffice/candidature/ListCandidatures';
import { PosAppointment } from './pages/protected/backoffice/candidature/PosAppointment';
import { VerdictDocumentsManagment } from './pages/protected/backoffice/candidature/VerdictDocumentsManagment';
import AddCompetition from './pages/protected/backoffice/competition/AddCompetition';
import { CompetitionDocumentsManagment } from './pages/protected/backoffice/competition/CompetitionDocumentsManagment';
import { EditCompetition } from './pages/protected/backoffice/competition/EditCompetition';
import { EditCompetitionDates } from './pages/protected/backoffice/competition/EditCompetitionDates';
import AddFaq from './pages/protected/backoffice/faqs/AddFaq';
import EditFaq from './pages/protected/backoffice/faqs/EditFaq';
import { AddEaaHabitation } from './pages/protected/backoffice/habitation/AddEaaHabitation';
import AddHabitation from './pages/protected/backoffice/habitation/AddHabitation';
import AddHabitationDocuments from './pages/protected/backoffice/habitation/AddHabitationDocuments';
import AddHabitationImage from './pages/protected/backoffice/habitation/AddHabitationImage';
import AddPaaHabitation from './pages/protected/backoffice/habitation/AddPaaHabitation';
import EditHabitation from './pages/protected/backoffice/habitation/EditHabitation';
import Habitation from './pages/protected/backoffice/habitation/Habitation';
import { ListHolidays } from './pages/protected/backoffice/holidays/ListHolidays';
import { ListMessages } from './pages/protected/backoffice/messages/ListMessages';
import AddParameter from './pages/protected/backoffice/parameters/AddParameter';
import EditParameter from './pages/protected/backoffice/parameters/EditParameter';
import ListParameters from './pages/protected/backoffice/parameters/ListParameters';
import { Permissions } from './pages/protected/backoffice/permissions/Permissions';
import AddPlatform from './pages/protected/backoffice/platform/AddPlatform';
import EditPlatform from './pages/protected/backoffice/platform/EditPlatform';
import ListPlatformBackoffice from './pages/protected/backoffice/platform/ListPlatformBackoffice';
import { ViewStagingCandidatures } from './pages/protected/backoffice/platform/ViewStagingCandidatures';
import AddCandidature from './pages/protected/frontoffice/candidature/AddCandidature';
import CandidatureDocuments from './pages/protected/frontoffice/candidature/CandidatureDocuments';
import CandidatureFinalDocuments from './pages/protected/frontoffice/candidature/CandidatureFinalDocuments';
import CandidatureHistory from './pages/protected/frontoffice/candidature/CandidatureHistory';
import CandidaturesUser from './pages/protected/frontoffice/candidature/CandidaturesUser';
import CandidatureValidateFinalDocuments from './pages/protected/frontoffice/candidature/CandidatureValidateFinalDocuments';
import MessageDetails from './pages/protected/frontoffice/messages/MessageDetails';
import MessagesNotification from './pages/protected/frontoffice/messages/MessagesNotification';
import UserProfile from './pages/protected/frontoffice/UserProfile';
import { CompetitionSerialization } from './pages/protected/shared/candidature/CompetitionSerialization';
import ViewUserCandidature from './pages/protected/shared/candidature/ViewUserCandidature';
import { ClarificationRequest } from './pages/public/clarification/ClarificationRequest';
import CompetitionHabitations from './pages/public/competition/CompetitionHabitations';
import CompetitionHabitationsEAA from './pages/public/competition/CompetitionHabitationsEAA';
import { CandidatureDocumentsSearch } from './pages/public/document/CandidatureDocuments';
import { CompetitionDocuments } from './pages/public/document/CompetitionDocuments';
import Faqs from './pages/public/faqs/Faqs';
import Home from './pages/public/Home';
import PlatformCompetitions from './pages/public/platform/PlatformCompetitions';
import KeycloakAuthentication from './pages/security/KeycloakAuthentication';
import Login from './pages/security/Login';
import NphAuthentication from './pages/security/NphAuthentication';
import { PrivateRoute } from './PrivateRoute';

function AppRouter() {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return <Loading complex />;
  }

  return (
    <Router>
      <Template>
        <ScrollToTop />
        <Switch>
          <Route
            path='/programas/:platformCode/concursos/:competitionCode/sorteio'
            component={CompetitionDraw}
          />

          <Route
            path='/programas/:platformCode/concursos/:competitionCode/seriacao'
            component={CompetitionSerialization}
          />

          <PrivateRoute
            permissions={['ADD_HABITATION']}
            path='/programas/EAA/concursos/:competitionCode/alojamentos/pre/adicionar'
          >
            <AddEaaHabitation />
          </PrivateRoute>
          <PrivateRoute
            permissions={['ADD_HABITATION']}
            path='/programas/:platformCode/concursos/:competitionCode/alojamentos/pre/adicionar'
          >
            <AddPaaHabitation />
          </PrivateRoute>
          <PrivateRoute
            exact
            permissions={['CONSULT_HABITATION']}
            path='/programas/:platformCode/concursos/:competitionCode/alojamentos/adicionar'
          >
            <AddHabitation />
          </PrivateRoute>
          <PrivateRoute
            permissions={['EDIT_HABITATION']}
            path='/programas/:platformCode/concursos/:competitionCode/alojamentos/:habitationExternalId/editar'
          >
            <EditHabitation />
          </PrivateRoute>

          <Route path='/programas/:platformCode/concursos/:competitionCode/alojamentos/:habitationExternalId'>
            <Habitation />
          </Route>
          <PrivateRoute
            permissions={['ADD_COMPETITION_DOCUMENT']}
            path='/programas/:platformCode/concursos/:competitionCode/documentos/editar'
          >
            <CompetitionDocumentsManagment />
          </PrivateRoute>
          <Route path='/programas/:platformCode/concursos/:competitionCode/documentos'>
            <CompetitionDocuments />
          </Route>
          <PrivateRoute
            permissions={['EDIT_COMPETITION']}
            path='/programas/:platformCode/concursos/:competitionCode/editar'
          >
            <EditCompetition />
          </PrivateRoute>
          <PrivateRoute
            permissions={['EDIT_COMPETITION']}
            path='/programas/:platformCode/concursos/:competitionCode/datas/editar'
          >
            <EditCompetitionDates />
          </PrivateRoute>
          <PrivateRoute
            permissions={['LIST_CANDIDATURES']}
            path='/programas/:platformCode/concursos/:competitionCode/candidaturas'
          >
            <ListCandidatures />
          </PrivateRoute>
          <PrivateRoute
            exact
            permissions={['CREATE_COMPETITION']}
            path='/programas/:platformCode/concursos/adicionar'
          >
            <AddCompetition />
          </PrivateRoute>
          <PrivateRoute
            permissions={['PARTICIPATE']}
            path='/programas/:platformCode/concursos/:competitionCode/candidatar'
          >
            <AddCandidature />
          </PrivateRoute>

          <PrivateRoute
            permissions={['DOCUMENT_VALIDATION_FINAL']}
            path='/programas/:platformCode/concursos/:competitionCode/candidatura/:externalId/documentosFinais/validar'
          >
            <CandidatureValidateFinalDocuments />
          </PrivateRoute>

          <PrivateRoute
            permissions={['USER_DETAILS']}
            path='/programas/:platformCode/concursos/:competitionCode/candidatura/:externalId/documentosFinais'
          >
            <CandidatureFinalDocuments />
          </PrivateRoute>

          <PrivateRoute
            permissions={['USER_DETAILS']}
            path='/programas/:platformCode/concursos/:competitionCode/candidatura/:externalId/documentos/search'
          >
            <CandidatureDocumentsSearch />
          </PrivateRoute>

          <PrivateRoute
            permissions={['USER_DETAILS']}
            path='/programas/:platformCode/concursos/:competitionCode/candidatura/:externalId/documentos'
          >
            <CandidatureDocuments />
          </PrivateRoute>

          <PrivateRoute
            permissions={['GET_CANDIDATURE_HISTORY']}
            path='/programas/:platformCode/concursos/:competitionCode/candidatura/:externalId/history'
          >
            <CandidatureHistory />
          </PrivateRoute>

          <PrivateRoute
            permissions={['DOCUMENT_VALIDATION_INIT']}
            path='/programas/:platformCode/concursos/:competitionCode/candidatura/:externalId/validarDocumentos'
          >
            <DocumentsValidation />
          </PrivateRoute>

          <PrivateRoute
            permissions={['CONTEST']}
            path='/programas/:platformCode/concursos/:competitionCode/candidatura/:externalId/contestar'
          >
            <ContestationManagment />
          </PrivateRoute>

          <PrivateRoute
            permissions={['USER_DETAILS']}
            path='/programas/:platformCode/concursos/:competitionCode/candidatura/:externalId'
          >
            <ViewUserCandidature />
          </PrivateRoute>

          <PrivateRoute path='/programas/:platformCode/concursos/:competitionCode/candidaturasAdmitidas'>
            <ListCandidatures />
          </PrivateRoute>
          <Route
            path='/programas/EAA/concursos/:competitionCode'
            component={CompetitionHabitationsEAA}
          ></Route>
          <Route
            path='/programas/:platformCode/concursos/:competitionCode'
            component={CompetitionHabitations}
          ></Route>

          <PrivateRoute
            permissions={['VIEW_STAGING_CANDIDATURES']}
            path='/programas/EAA/staging'
          >
            <ViewStagingCandidatures />
          </PrivateRoute>

          <Route
            path='/programas/:platformCode/concursos'
            component={PlatformCompetitions}
          />
          <PrivateRoute
            exact
            path='/programas/adicionar'
            permissions={['CREATE_PLATFORM']}
          >
            <AddPlatform />
          </PrivateRoute>
          <PrivateRoute
            path='/programas/:platformCode/editar'
            permissions={['UPDATE_PLATFORM']}
          >
            <EditPlatform />
          </PrivateRoute>

          <PrivateRoute path='/programas' permissions={['LIST_PLATFORMS']}>
            <ListPlatformBackoffice />
          </PrivateRoute>

          <Route path='/perguntas/:platformCode' component={Faqs}></Route>
          <PrivateRoute
            path='/adicionarFaq/:platformCode'
            permissions={['CREATE_FAQ']}
          >
            <AddFaq />
          </PrivateRoute>
          <PrivateRoute
            path='/editarFaq/:externalId'
            permissions={['UPDATE_FAQ']}
          >
            <EditFaq />
          </PrivateRoute>

          <PrivateRoute
            permissions={['CREATE_PARAMETER']}
            path='/parametros/adicionar'
          >
            <AddParameter />
          </PrivateRoute>
          <PrivateRoute
            permissions={['UPDATE_PARAMETER']}
            path='/parametros/:parameterCode/editar'
          >
            <EditParameter />
          </PrivateRoute>
          <PrivateRoute permissions={['LIST_PARAMETERS']} path='/parametros'>
            <ListParameters />
          </PrivateRoute>

          <PrivateRoute permissions={['LIST_TEMPLATES']} path='/templates'>
            <ListActionNotifications />
          </PrivateRoute>

          <PrivateRoute
            permissions={['CREATE_TEMPLATE']}
            path='/notificacoes/adicionar'
          >
            <AddActionNotification />
          </PrivateRoute>

          <PrivateRoute
            permissions={['EDIT_TEMPLATE']}
            path='/notificacoes/:actionNotificationCode/editar'
          >
            <EditActionNotification />
          </PrivateRoute>
          <PrivateRoute
            exact
            permissions={['UPDATE_PERMISSION']}
            path='/perfis'
          >
            <Permissions />
          </PrivateRoute>
          <PrivateRoute
            permissions={['GET_USER_MESSAGE']}
            path='/perfil/mensagens'
            component={MessagesNotification}
          ></PrivateRoute>

          <PrivateRoute
            permissions={['GET_MESSAGE']}
            path='/perfil/mensagem/:externalId'
            component={MessageDetails}
          ></PrivateRoute>

          <PrivateRoute
            permissions={['GET_CANDIDATURE_FROM_PLATFORM']}
            path='/perfil/candidaturasUtilizador'
          >
            <CandidaturesUser />
          </PrivateRoute>

          <PrivateRoute
            permissions={['WAIT_CONTESTATION']}
            path='/candidatura/:externalId/veredito'
          >
            <VerdictDocumentsManagment />
          </PrivateRoute>

          <PrivateRoute
            permissions={['CELEBRATE_CONTRACT']}
            path='/candidatura/:externalId/celebrar'
          >
            <CelebrationContractManagment />
          </PrivateRoute>

          <PrivateRoute
            permissions={['FINALIZE_VISIT']}
            path='/candidatura/:externalId/posvisita'
          >
            <PosAppointment />
          </PrivateRoute>

          <PrivateRoute
            permissions={['ANALYZE_CANDIDATURE_OK']}
            path='/candidatura/:externalId/analisar'
          >
            <AnalyzeManagment />
          </PrivateRoute>

          <PrivateRoute
            permissions={['CONTEST']}
            path='/candidatura/:externalId/contestar'
          >
            <ContestationManagment />
          </PrivateRoute>

          <PrivateRoute permissions={['USER_DETAILS']} path='/perfil'>
            <UserProfile />
          </PrivateRoute>

          <PrivateRoute
            permissions={['ADD_HABITATION_IMAGE']}
            path='/adicionarImagemHabitacao/:habitationExternalId'
          >
            <AddHabitationImage />
          </PrivateRoute>
          <PrivateRoute
            permissions={['ADD_HABITATION_DOCUMENT']}
            path='/adicionarDocumentoHabitacao/:habitationExternalId'
          >
            <AddHabitationDocuments />
          </PrivateRoute>

          <PrivateRoute
            permissions={['CREATE_CANDIDATURE']}
            path='/adicionarCandidatura/:competitionCode/adicionarHabitacao'
            component={NphAuthentication}
          ></PrivateRoute>

          <PrivateRoute permissions={['LIST_MESSAGES']} path='/mensagens'>
            <ListMessages />
          </PrivateRoute>

          <PrivateRoute permissions={['GET_HOLIDAYS']} path='/feriados'>
            <ListHolidays />
          </PrivateRoute>

          <Route path='/login/nph/:code' component={NphAuthentication} />
          <Route path='/login/keycloak'>
            <KeycloakAuthentication />
          </Route>
          <Route exact path='/login' component={Login}></Route>
          <Route path='/contactar' component={ClarificationRequest} />
          <Route exact path='/' component={Home} />
          <Route path='*' component={Home} />
        </Switch>
      </Template>
    </Router>
  );
}

export default AppRouter;

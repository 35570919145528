import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { ScrollToTop } from '../ScrollToTop';

export function AlertSuccess({ message }) {
  const [messageObject, setMessageObject] = useState({ show: false });

  useEffect(() => {
    setMessageObject({ show: true, message: message });
  }, [message]);

  if (!messageObject.show || !messageObject.message) {
    return <></>;
  }

  return (
    <>
      <ScrollToTop />
      <Alert
        dismissible
        onClose={() =>
          setMessageObject({
            show: false,
          })
        }
        variant='success'
      >
        {messageObject.message}
      </Alert>
    </>
  );
}

import React from 'react';
import { useIntl } from 'react-intl';
import { CandidatureState } from '../../models/CandidatureState';

export function CandidatureDetailsTitle({ candidature }) {
  const intl = useIntl();

  return (
    <>
      {intl.formatMessage(
        { id: 'candidature.candidatureTitle' },
        {
          state: intl.formatMessage({
            id: 'CandidatureStateTitle.' + candidature.candidatureDto.state,
          }),
          date: candidature.candidatureDto.stateModifiedTs,
        }
      )}

      <span>
        {intl.formatMessage(
          {
            id: 'candidature.codeAndCompetitionCode',
          },

          {
            code: candidature.candidatureDto.code,

            competitionCode: candidature.candidatureDto.competitionCode,
          }
        )}
      </span>
      <br />
      <small>
        <span
          style={{
            color:
              candidature.candidatureDto.state === CandidatureState.DELETED
                ? 'red'
                : 'black',
          }}
        >
          {intl.formatMessage(
            { id: 'candidature.stateAndDate' },
            {
              state: intl.formatMessage({
                id: 'CandidatureStateTitle.' + candidature.candidatureDto.state,
              }),
              date: candidature.candidatureDto.stateModifiedTs,
            }
          )}
        </span>
      </small>
    </>
  );
}

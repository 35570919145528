import { apiAxios as authAxios } from '../axios.config';

export const editHabitationToCompetition = (
  habitationExternalId,
  habitation
) => {
  return authAxios.put(
    `/api/habitations/${habitationExternalId}`,
    habitation.habitation
  );
};

export const getHabitationByExternalId = (habitationExternalId) => {
  return authAxios.get(`/api/habitations/${habitationExternalId}`);
};

export const deleteHabitationToCompetition = (habitationExternalId) => {
  return authAxios.delete(`/api/habitations/${habitationExternalId}`);
};

export const deleteHabitationsToCompetition = (habitationExternalIds) => {
  return authAxios.delete(`/api/habitations`, {
    data: habitationExternalIds,
  });
};

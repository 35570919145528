import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ScrollToTop } from '../ScrollToTop';
import { CompetitionDatesForm } from './CompetitionDatesForm';

export function CompetitionStep2Form({
  touched,
  errors,
  competition,
  setFieldValue,
  validateForm,
  step,
  numberOfSteps,
}) {
  const handleNextStep = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setFieldValue('step', competition.step + 1);
    }
  };

  return (
    <div>
      <ScrollToTop />
      <h5 className='mb-4 pb-2 border-bottom'>
        <FormattedMessage
          id='all.steps'
          values={{ step: step, numberOfSteps: numberOfSteps }}
        />
        {' - '}
        <FormattedMessage id='competition.form.title.calendar' />
      </h5>
      <CompetitionDatesForm
        touched={touched}
        errors={errors}
        competition={competition}
        setFieldValue={setFieldValue}
      />
      <div className='form-actions'>
        <div className='mr-auto'>
          <button
            className='btn btn-link'
            type='button'
            onClick={(e) => setFieldValue('step', competition.step - 1)}
          >
            <FormattedMessage id='all.previousButtonText' />
          </button>
        </div>
        <div className='ml-auto'>
          <button
            className='btn btn-primary'
            type='submit'
            onClick={(e) => handleNextStep()}
          >
            <FormattedMessage id='all.nextButtonText' />
          </button>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import { useGlobalContext } from '../context';
import Loading from './Loading';

export function Template({ children }) {
  const { globalLoading } = useGlobalContext();
  if (globalLoading) {
    return <Loading complex />;
  }
  return (
    <div>
      <div id='wrapper'>
        <NavBar />
        <div id='content-wrapper' className={'d-flex flex-column'}>
          <div id='content'>{children}</div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

import axios from 'axios';
import { apiAxios as authAxios } from '../axios.config';

export const addFaq = (faq, platformCode) => {
  return authAxios.post(`/api/faq/${platformCode}`, faq);
};

export const deleteFaqAPI = (externalId) => {
  return authAxios.delete(`/api/faq/${externalId}`);
};

export const getFaqsForPlatform = (platformCode) => {
  return axios.get(`/api/faq/list/${platformCode}`);
};

export const updateFaq = (externalId, faq) => {
  return authAxios.put(`/api/faq/${externalId}`, faq);
};

export const getFaq = (externalId) => {
  return authAxios.get(`/api/faq/${externalId}`);
};

import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { TiPlus } from 'react-icons/ti';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { hasPermission } from '../../../authentication/authenticationHelper';
import { ComponentLoading } from '../../../components/ComponentLoading';
import { FaqDeleteDialog } from '../../../components/faqs/FaqDeleteDialog';
import { FaqRow } from '../../../components/faqs/FaqRow';
import Loading from '../../../components/Loading';
import { SubTemplate } from '../../../components/SubTemplate';
import { useGlobalContext } from '../../../context';
import { deleteFaqAPI, getFaqsForPlatform } from '../../../rest/faq';
import { getPlatforms } from '../../../rest/platform';
import { handleError, isNotBusinessError } from '../../../utils/handleError';

function Faqs() {
  const { platformCode } = useParams();

  const { accessInfo } = useGlobalContext();

  const [platforms, setPlatforms] = useState();
  const [platformFaqs, setPlatformFaqs] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [faqToDelete, setFaqToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(true);
  const [error, setError] = useState(null);

  //Dialog stuff
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        let responsePlatforms;
        const response = await getPlatforms();
        responsePlatforms = response.data;
        setPlatforms(responsePlatforms);
        setSelectedPlatform(platformCode);
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setSearching(false);
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [platformCode]);

  useEffect(() => {
    const fetchBySelectedPlatform = async () => {
      try {
        setSearching(true);
        const { data: platformFaqs } = await getFaqsForPlatform(
          selectedPlatform
        );
        setPlatformFaqs(platformFaqs);
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setSearching(false);
      }
    };

    if (!selectedPlatform) {
      return;
    }
    fetchBySelectedPlatform();
  }, [selectedPlatform]);

  async function deleteFaq(externalId) {
    try {
      setLoading(true);
      await deleteFaqAPI(externalId);
      const notDeletedFaqs = platformFaqs.filter(
        (faq) => faq.externalId !== externalId
      );
      setPlatformFaqs(notDeletedFaqs);
      handleClose();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  }

  const onClickDelete = (faq) => {
    setFaqToDelete(faq);
    handleShow();
  };

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate hasBackButton titleId='faqs.faqText'>
      <div className={'container faqs'}>
        <div className='row'>
          {hasPermission('CREATE_FAQ', accessInfo) && (
            <div className={'col-md-3'}>
              <Nav className='flex-column'>
                {platforms != null &&
                  platforms.map((platform) => {
                    return (
                      <Nav.Link
                        key={platform.code}
                        className={
                          platform.code === selectedPlatform && 'active'
                        }
                        onClick={() => setSelectedPlatform(platform.code)}
                      >
                        {platform.name}
                      </Nav.Link>
                    );
                  })}
              </Nav>
            </div>
          )}
          <div
            className={
              hasPermission('CREATE_FAQ', accessInfo) ? 'col-md-6' : 'col-md-12'
            }
          >
            {searching ? (
              <ComponentLoading />
            ) : (
              <ListFaqs
                faqs={platformFaqs}
                onClickDelete={onClickDelete}
                accessInfo={accessInfo}
                faqToDelete={faqToDelete}
                deleteFaq={deleteFaq}
                handleClose={handleClose}
                show={show}
              />
            )}
          </div>
          <div className={'col-md-3'}>
            <div className='mx-2 mb-4'>
              {hasPermission('CREATE_FAQ', accessInfo) && (
                <Link
                  to={`/adicionarFaq/${selectedPlatform}`}
                  className={'btn btn-outline-primary mb-3'}
                >
                  <TiPlus />
                  <FormattedMessage id='faqs.addFaqText' />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

function ListFaqs({
  faqs,
  onClickDelete,
  accessInfo,
  faqToDelete,
  deleteFaq,
  handleClose,
  show,
}) {
  if (!faqs || faqs.length === 0) {
    return (
      <div className='empty-state'>
        <FaInfoCircle />
        <p>
          <FormattedMessage id='all.noData' />
        </p>
      </div>
    );
  }

  return (
    <span>
      {faqs.map((faq) => {
        return (
          <FaqRow
            key={faq.externalId}
            faq={faq}
            onClickDelete={onClickDelete}
            accessInfo={accessInfo}
          />
        );
      })}
      <FaqDeleteDialog
        faq={faqToDelete}
        deleteFaq={deleteFaq}
        handleClose={handleClose}
        show={show}
      />
    </span>
  );
}

export default Faqs;

import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export function TextInputField({
  name,
  value,
  handleChange,
  labelId,
  label,
  errorMessage,
  isInvalid,
  controlId,
  disabled,
  placeholder,
}) {

  return (
    <Form.Group controlId={controlId || name}>
      <Form.Label>
        {labelId && <FormattedMessage id={labelId} />}
        {label && label}
      </Form.Label>
      <Form.Control
        type='text'
        name={name}
        value={value}
        onChange={handleChange}
        isInvalid={isInvalid}
        disabled={disabled}
        placeholder={placeholder}
      />
      {errorMessage && (
        <Form.Control.Feedback type='invalid'>
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

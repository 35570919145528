import dateFormat from 'date-fns/format';
import { Formik } from 'formik';
import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import { ConfigObject } from '../../config';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';

export function BookAppointmentDialog({ show, handleClose, handleConfirm }) {
  const pattern = ConfigObject.get().DATE_TIME_PATTERN;

  const EMPTY_DRAW_REQUEST = {
    date: dateFormat(new Date(), pattern),
  };
  const intl = useIntl();

  let schema = yup.object().shape({
    date: yup
      .date()
      .required(intl.formatMessage({ id: 'bookAppointment.emptyDate' }))
      .min(
        new Date(),
        <FormattedMessage id='bookAppointment.dateIsInThePast' />
      ),
  });
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='candidature.book.appointment' />
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={EMPTY_DRAW_REQUEST}
          validationSchema={schema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            handleConfirm(values, setSubmitting, setErrors);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <DateTimeInputField
                  name='date'
                  value={values.date}
                  errorMessage={errors.date}
                  setFieldValue={setFieldValue}
                  isInvalid={errors.date && touched.date}
                  labelId={'bookAppointment.date'}
                />
              </Modal.Body>

              <Modal.Footer>
                <button
                  className='btn btn-link mr-auto'
                  onClick={handleClose}
                  type='button'
                >
                  <FormattedMessage id='all.cancelText' />
                </button>

                <button
                  className={'btn btn-primary ml-auto'}
                  type='submit'
                  disabled={isSubmitting}
                >
                  <FormattedMessage id='candidature.book.appointment' />
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

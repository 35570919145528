import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export function ValidateOtherContractsDialog({ show, handleClose, handleConfirm }) {
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='candidature.dialog.validateOtherContracts.text' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id='candidature.dialog.validateOtherContracts.question' />
        </Modal.Body>

        <Modal.Footer>
          <button
            className='btn btn-link mr-auto'
            onClick={handleClose}
            type='button'
          >
            <FormattedMessage id='all.cancelText' />
          </button>

          <button
            className={'btn btn-primary'}
            type='submit'
            onClick={() => handleConfirm({ flag: true })}
          >
            <FormattedMessage id='all.yesText' />
          </button>

          <button
            className={'btn btn-primary'}
            type='submit'
            onClick={() => handleConfirm({ flag: false })}
          >
            <FormattedMessage id='all.noText' />
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

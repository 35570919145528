import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { addEligilityCondition } from '../../rest/eligibilityCondition';
import { AlertError } from '../bootstrap/AlertError';
import { EligibilityConditionForm } from '../eligibilitycondition/EligibilityConditionForm';

export function AddConditionDialog({ show, handleClose, onCreated }) {
  const intl = useIntl();

  const [condition, setCondition] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    show: false,
    fields: [],
    system: null,
  });

  const createCondition = async () => {
    try {
      const { data } = await addEligilityCondition(condition);
      setLoading(false);
      onCreated(data);
      handleClose();
    } catch (error) {
      console.log(error);
      setErrors({ show: true, fields: [], system: error });
      setLoading(false);
      if (error.response.status === 401) {
        window.location.reload();
      }
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    const errorsArr = [];
    if (!condition.code || condition.code.length <= 0) {
      errorsArr.push({
        id: 'code',
        message: intl.formatMessage({
          id: 'errors.conditionDialogCodeRequired.text',
        }),
      });
    }
    if (!condition.name || condition.name.length <= 0) {
      errorsArr.push({
        id: 'name',
        message: intl.formatMessage({
          id: 'errors.conditionDialogNameRequired.text',
        }),
      });
    }
    if (errorsArr.length <= 0) {
      setLoading(true);
      createCondition();
    } else {
      setErrors({ show: true, fields: errorsArr, system: [] });
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='condition.add' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertError error={errors.system} />
        <EligibilityConditionForm
          condition={condition}
          setCondition={setCondition}
          handleClick={handleClick}
          errors={errors}
          setErrors={setErrors}
          onBack={handleClose}
        />
      </Modal.Body>
    </Modal>
  );
}

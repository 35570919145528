import React, { useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {
  FaAngleLeft,
  FaAngleRight,
  FaFileImage,
  FaInfoCircle,
  FaPencilAlt,
  FaPrint,
  FaRegFileAlt,
  FaRegImage,
  FaRegQuestionCircle,
  FaTrashAlt,
} from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  hasPermission,
  isIhruUser,
} from '../../../../authentication/authenticationHelper';
import { AlertSuccess } from '../../../../components/bootstrap/AlertSuccess';
import { DeleteHabitationDialog } from '../../../../components/habitation/DeleteHabitationDialog';
import { DisplayableHabitationDetails } from '../../../../components/habitation/DisplayableHabitationDetails';
import HowItWorksDialog from '../../../../components/HowItWorksDialog';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { useGlobalContext } from '../../../../context';
import logo_print from '../../../../img/logo_print.png';
import {
  APPROVED,
  CompetitionState,
  DRAFT,
} from '../../../../models/CompetitionState';
import { getCompetitionByCompetitionCode } from '../../../../rest/competition';
import {
  deleteHabitationToCompetition,
  getHabitationByExternalId,
} from '../../../../rest/habitation';
import { getPlatform } from '../../../../rest/platform';
function Habitation() {
  const { habitationExternalId } = useParams();
  const { platformCode } = useParams();
  const { competitionCode } = useParams();

  const { accessInfo } = useGlobalContext();

  const intl = useIntl();

  const habitationSubmited = localStorage.getItem('habitationSubmited');

  const [habitation, setHabitation] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [competition, setCompetition] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false);
  const [show, setShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showDeleteHabitationDialog, setShowDeleteHabitationDialog] =
    useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchInitialData = async () => {
    try {
      const { data: habitationData } = await getHabitationByExternalId(
        habitationExternalId
      );
      const { data: platform } = await getPlatform(platformCode);
      const { data: competitionData } = await getCompetitionByCompetitionCode(
        competitionCode,
        platformCode
      );
      setHabitation(habitationData);
      setPlatform(platform);
      setCompetition(competitionData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(true);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteHabitationToCompetition(habitationExternalId);
      setIsDeleted(true);
    } catch (error) {
      setError(error);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    fetchInitialData();
    getSuccessMessage();
  }, []);

  const getSuccessMessage = () => {
    if (habitationSubmited) {
      localStorage.removeItem('habitationSubmited');
      setSuccessMessage(
        <FormattedMessage id='habitation.habitationSubmited.text' />
      );
    } else {
      return null;
    }
  };

  if (error) {
    return <div>{error.message}</div>;
  }

  if (loading) {
    return <Loading />;
  }

  if (isDeleted) {
    return (
      <Redirect
        to={`/programas/${
          habitation.habitation.platform
        }/concursos/${encodeURIComponent(
          habitation.habitation.competitionCode
        )}`}
      />
    );
  }

  const renderPrevButton = ({ isDisabled }) => {
    return (
      <span style={{ opacity: isDisabled ? '0.5' : 1 }}>
        <FaAngleLeft />
      </span>
    );
  };

  const renderNextButton = ({ isDisabled }) => {
    return (
      <span style={{ opacity: isDisabled ? '0.5' : 1 }}>
        <FaAngleRight />
      </span>
    );
  };

  const plgpCode = habitation.habitation.fractionCodePLGP
    ? intl.formatMessage({ id: 'plgp.internalCode' }) +
      ': ' +
      habitation.habitation.fractionCodePLGP
    : '';
  return (
    <SubTemplate
      hasBackButton
      forceClass={'no-print'}
      title={`${intl.formatMessage({ id: 'habitationDetails.text' })} ${
        habitation.habitation.orderNumber
      } - ${habitation.habitation.code}      ${plgpCode}`}
    >
      <div className='container'>
        <div className='row print-only mt-4 align-top'>
          <div className={'col-50'}>
            <img alt='Logo IHRU' src={logo_print} className={'mb-5 header-habitation'} />
          </div>
          <div className={'col-50 text-right'}>
            <h3>
              {`${intl.formatMessage({ id: 'habitationDetails.text' })} ${
                habitation.habitation.orderNumber
              } - ${habitation.habitation.code}       ${plgpCode}`}
            </h3>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-9 display-info'>
            <AlertSuccess message={successMessage} />
            <div className='row mb-4'>
              {platformCode != 'EAA' && (
                <div className='col-md-12'>
                  {habitation.images.length ? (
                    <AliceCarousel
                      renderPrevButton={renderPrevButton}
                      renderNextButton={renderNextButton}
                    >
                      {habitation.images.map((image) => (
                        <div
                          key={image.name}
                          className='habitation-img'
                          style={{
                            backgroundImage: `url(${image.path})`,
                          }}
                        ></div>
                      ))}
                    </AliceCarousel>
                  ) : (
                    <div className='empty-state'>
                      <FaFileImage />
                      <p>
                        <FormattedMessage id='habitationForm.noImages' />
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <DisplayableHabitationDetails
                  habitation={habitation.habitation}
                  displayFields={habitation.displayFields}
                />
              </div>
            </div>
            {platformCode != 'EAA' && (
              <div className='row no-print'>
                <div className='col-md-12 '>
                  <legend>
                    <FormattedMessage id='documents.text' />
                  </legend>
                  {habitation.documents.length ? (
                    habitation.documents.map((document) => (
                      <p key={document.name} className='mb-3 mt-3'>
                        <a href={document.path}>{document.description}</a>
                      </p>
                    ))
                  ) : (
                    <div className='mb-3 mt-3'>
                      <p>
                        <FormattedMessage id='habitationForm.noDocuments' />
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
            {habitation.candidaturesSubstitute.length > 0 &&
              isIhruUser(accessInfo) && (
                <div className='row mb-4 no-print'>
                  <div className='col-md-12 '>
                    <legend>
                      <FormattedMessage id='habitationForm.candidaturesSubstitute' />
                    </legend>

                    <div className='row'>
                      {habitation.candidaturesSubstitute.map(
                        (substitute, index) => (
                          <div key={index} className='col-md-3'>
                            {index + 1 + ' --> '}
                            <Link
                              to={`/programas/${encodeURIComponent(
                                habitation.habitation.platform
                              )}/concursos/${encodeURIComponent(
                                habitation.habitation.competitionCode
                              )}/candidatura/${substitute.externalId}`}
                            >
                              {substitute.code}
                            </Link>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>

          <div className={'col-md-3 side-menu'}>
            <div className='mx-2 mb-4'>
              {accessInfo &&
                hasPermission('ADD_HABITATION_DOCUMENT', accessInfo) &&
                platformCode != 'EAA' &&
                competition.state === DRAFT && (
                  <Link
                    to={`/adicionarDocumentoHabitacao/${habitation.habitation.externalId}`}
                    className={'btn btn-outline-primary mb-3'}
                  >
                    <FaRegFileAlt />
                    <FormattedMessage id='habitation.addDocumentsText' />
                  </Link>
                )}
              {accessInfo &&
                hasPermission('ADD_HABITATION_IMAGE', accessInfo) &&
                platformCode != 'EAA' &&
                competition.state === DRAFT && (
                  <Link
                    to={`/adicionarImagemHabitacao/${habitation.habitation.externalId}`}
                    className={'btn btn-outline-primary mb-3'}
                  >
                    <FaRegImage />
                    <FormattedMessage id='all.editImageText' />
                  </Link>
                )}
              {accessInfo &&
                hasPermission('ADD_HABITATION', accessInfo) &&
                ((platformCode != 'EAA' && competition.state === DRAFT) ||
                  (platformCode == 'EAA' &&
                    competition.state === APPROVED)) && (
                  <Link
                    to={`/programas/${encodeURIComponent(
                      habitation.habitation.platform
                    )}/concursos/${encodeURIComponent(
                      habitation.habitation.competitionCode
                    )}/alojamentos/${habitation.habitation.externalId}/editar`}
                    className={'btn btn-outline-primary mb-3'}
                  >
                    <FaPencilAlt />
                    <FormattedMessage id='all.editHabitationText' />
                  </Link>
                )}
              {accessInfo &&
                hasPermission('DELETE_HABITATION', accessInfo) &&
                competition.state === DRAFT && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={handleDelete}
                  >
                    <FaTrashAlt />
                    <FormattedMessage id='all.deleteHabitationText' />
                  </button>
                )}
              {isIhruUser(accessInfo) &&
                hasPermission('DELETE_HABITATION', accessInfo) &&
                CompetitionState.getActives().includes(competition.state) &&
                habitation.habitation.stateCode === 'AVAILABLE' && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => setShowDeleteHabitationDialog(true)}
                  >
                    <FaTrashAlt />
                    <FormattedMessage id='all.deleteHabitationText' />
                  </button>
                )}
              <button
                className={'btn btn-outline-primary mb-3'}
                onClick={handlePrint}
              >
                <FaPrint />
                <FormattedMessage id='habitation.print' />
              </button>
            </div>

            <ul className={'nav flex-column border-left mx-2 my-3'}>
              <li className='nav-item'>
                <button
                  className={'nav-link btn btn-link'}
                  onClick={handleShow}
                >
                  <FaInfoCircle />
                  <FormattedMessage id='competitions.howItWorksText' />
                </button>
              </li>
              <li className={'nav-item'}>
                <Link className={'nav-link'} to={`/perguntas/${platformCode}`}>
                  <FaRegQuestionCircle />
                  <FormattedMessage id='competitionHabitation.faqsText' />
                </Link>
              </li>
            </ul>
          </div>
          <HowItWorksDialog
            handleClose={handleClose}
            show={show}
            platform={platform}
            showEditLink={hasPermission('UPDATE_PLATFORM', accessInfo)}
          />
          <DeleteHabitationDialog
            show={showDeleteHabitationDialog}
            handleClose={() => setShowDeleteHabitationDialog(false)}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </SubTemplate>
  );
}

export default Habitation;

export const Strings = {
  /**
   * @param  {String} str
   */
  isBlank: (str) => {
    return checkBlank(str);
  },
};

function checkBlank(str) {
  return !str || /^\s*$/.test(str);
}

import parse from 'html-react-parser';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { EmptyResults } from './EmptyResults';

function HowItWorksDialog({ handleClose, show, platform, showEditLink }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id='competitions.howItWorksText' />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {platform.howItWorks ? parse(platform.howItWorks) : <EmptyResults />}
      </Modal.Body>

      <Modal.Footer>
        {showEditLink && (
          <Link
            className='btn btn-link mr-auto'
            to={`/programas/${encodeURIComponent(platform.code)}/editar`}
          >
            <FormattedMessage id='all.editButtonText' />
          </Link>
        )}

        <button
          className='btn btn-outline-primary ml-auto '
          onClick={handleClose}
        >
          <FormattedMessage id='all.closeText' />
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default HowItWorksDialog;

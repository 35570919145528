import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import ListCandidature from '../candidature/ListCandidature';
import { PlatformType } from '../../models/PlatformType';

function ViewCandidature({
  candidature,
  setCandidature,
  platformCode,
  handleAfter,
  handleBack,
  onSubmitCandidature
}) {
  function handleNextStep() {
    if (platformCode === PlatformType.PAS) {
      const hasIncomeDropsCriteria = candidature.competitionCriterias
        .map((cc) => cc.code)
        .includes('incomeDrops');

      const emptyPreviousIncome = candidature.candidatureDto.previousIncome === '';
      const emptyCurrentIncome = candidature.candidatureDto.currentIncome === '';

      if (hasIncomeDropsCriteria && (emptyPreviousIncome || emptyCurrentIncome)) {
        return;
      }
    }

    handleAfter();
  }

  return (
    <>
      <div className={'form'}>
        <h5 className='mb-4 pb-2 border-bottom'>
          <FormattedMessage
            id={`${
              platformCode === PlatformType.PAS
                ? 'candidature.pas.step1of5Text'
                : 'candidature.step1of3Text'
            }`}
          />
        </h5>
        <p className='mb-4'>
          <FormattedMessage id='candidature.confirmationFamilyText' />
        </p>
        <ListCandidature
          candidature={candidature}
          setCandidature={setCandidature}
          platformCode={platformCode}
        />
      </div>
      <div className='form-actions'>
        <div className='mr-auto'>
          <Link onClick={handleBack} className='btn btn-link'>
            <FormattedMessage id='all.backButtonText' />
          </Link>
        </div>
        <div className='ml-auto'>
          {!candidature.options.canChoose ? (
            <Button variant='primary' onClick={() => onSubmitCandidature()}>
              <FormattedMessage id='all.submitButtonText' />
            </Button>
          ) : (
            <Button variant='primary' onClick={() => handleNextStep()}>
              <FormattedMessage id='all.nextButtonText' />
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default ViewCandidature;

import React from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

const showBackButton = (history, hasBackButton) => {
  return hasBackButton && history.location.key;
};

export function SubTemplate({
  hasBackButton,
  titleId,
  title,
  subTitleId,
  subTitle,
  children,
  forceRedirect,
  forceClass,
  titleValues,
}) {
  const history = useHistory();

  const handleBackClick = () => {
    if (forceRedirect) {
      history.replace(forceRedirect);
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <div className={`container page-title ${forceClass ? forceClass : ''}`}>
        <div className={'row'}>
          <div className={'col-md-12'}>
            {showBackButton(history, hasBackButton) && (
              <button className={'back-button'} onClick={handleBackClick}>
                <FaAngleLeft />
              </button>
            )}

            <h1>
              {titleId ? (
                <FormattedMessage id={titleId} values={titleValues} />
              ) : (
                <span>{title}</span>
              )}
            </h1>
            {subTitleId && (
              <p>
                <FormattedMessage id={subTitleId} />
              </p>
            )}
            {subTitle && <p>{subTitle}</p>}
          </div>
        </div>
      </div>
      {children}
    </>
  );
}

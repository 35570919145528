import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import { AlertSuccess } from '../../../../components/bootstrap/AlertSuccess';
import { UploadDocumentType } from '../../../../components/candidature/UploadDocumentType';
import { AuthenticatedDownloadLink } from '../../../../components/document/AuthenticatedDownloadLink';
import { EmptyResults } from '../../../../components/EmptyResults';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { createCustomErrorMessage } from '../../../../hooks/errorMessage';
import { DocumentTypeCode } from '../../../../models/DocumentTypeCode';
import {
  addCandidatureDocument,
  deleteCandidatureDocument,
  getCandidatureByExternalId,
  searchCandidatureDocuments,
  sendVisitDocument,
} from '../../../../rest/candidatureuser';
import { getDocumentType } from '../../../../rest/documentType';
import { Arrays } from '../../../../utils/Arrays';
import { handleError, isNotBusinessError } from '../../../../utils/handleError';
import { candidatureDetailsLink } from '../../../../utils/LinkCreator';

export function PosAppointment() {
  const { externalId } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [candidature, setCandidature] = useState({});
  const [documentsContract, setDocumentsContract] = useState([]);
  const [contractDocumentType, setContractDocumentType] = useState({});
  const [documentsCard, setDocumentsCard] = useState([]);
  const [cardDocumentType, setCardDocumentType] = useState({});
  const history = useHistory();

  const intl = useIntl();

  useEffect(() => {
    async function fetchData() {
      try {
        const [
          candidatureResponse,
          contractDocumentTypeResponse,
          documentsContractResponse,
          cardDocumentTypeResponse,
          documentsCardResponse,
        ] = await Promise.all([
          getCandidatureByExternalId(externalId),
          getDocumentType(DocumentTypeCode.MIN_CONTR_ARRE),
          searchCandidatureDocuments(
            externalId,
            DocumentTypeCode.MIN_CONTR_ARRE
          ),
          getDocumentType(DocumentTypeCode.FI_ALOJ_ASSIN),
          searchCandidatureDocuments(
            externalId,
            DocumentTypeCode.FI_ALOJ_ASSIN
          ),
        ]);

        setCandidature(candidatureResponse.data);
        setDocumentsContract(documentsContractResponse.data);
        setContractDocumentType(contractDocumentTypeResponse.data);
        setCardDocumentType(cardDocumentTypeResponse.data);
        setDocumentsCard(documentsCardResponse.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error);
        setLoading(false);
      }
    }
    fetchData();
  }, [externalId]);

  const onFileSubmit = async (
    file,
    description,
    fileType,
    memberCode,
    setSubmitting,
    setErrors
  ) => {
    setSuccessMessage(null);
    const formData = new FormData();
    formData.append('Attachment', file, file.name);
    formData.append('Description', description);
    formData.append('Type', fileType.code);
    setLoading(true);

    try {
      const addDocumentResponse = await addCandidatureDocument(
        externalId,
        formData
      );
      if (fileType.code === DocumentTypeCode.MIN_CONTR_ARRE) {
        setDocumentsContract([...documentsContract, addDocumentResponse.data]);
      } else {
        setDocumentsCard([...documentsCard, addDocumentResponse.data]);
      }
      setSuccessMessage(
        intl.formatMessage(
          { id: 'document.success.add' },
          { docName: addDocumentResponse.data.name }
        )
      );
      setError(null);
    } catch (error) {
      setError(error);
      setSuccessMessage(null);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  const handleDeleteDocument = async (documentName, documentType) => {
    try {
      await deleteCandidatureDocument(externalId, documentName);
      if (documentType.code === DocumentTypeCode.MIN_CONTR_ARRE) {
        setDocumentsContract(
          documentsContract.filter((doc) => doc.name !== documentName)
        );
      } else {
        setDocumentsCard(
          documentsCard.filter((doc) => doc.name !== documentName)
        );
      }

      setSuccessMessage(
        intl.formatMessage(
          { id: 'document.success.deleted' },
          { docName: documentName }
        )
      );
      setError(null);
    } catch (deleteDocumentError) {
      setError(deleteDocumentError);
      setSuccessMessage(null);
    }
  };

  const handlePosAppointment = async (e) => {
    setLoading(true);
    setSuccessMessage(null);
    try {
      if (documentsContract.length === 0 || documentsCard.length === 0) {
        setError(
          createCustomErrorMessage(
            intl.formatMessage({
              id: 'Candidature.ContractCelebration.needsAllDocuments',
            })
          )
        );
      } else {
        await sendVisitDocument(externalId);
        setError(null);
        history.replace(
          candidatureDetailsLink(
            candidature.candidatureDto.platformCode,
            candidature.candidatureDto.competitionCode,
            candidature.candidatureDto.externalId
          )
        );
      }
    } catch (error) {
      setError(error);
      setSuccessMessage(null);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate
      hasBackButton
      titleId='delieveryDocuments.posAppointment.title'
      titleValues={{
        candidatureCode: candidature.candidatureDto.code,
        competitionCode: candidature.candidatureDto.competitionCode,
      }}
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            <AlertError error={error} />
            <AlertSuccess message={successMessage} />
            <legend className='mb-3'>
              <FormattedMessage id='all.submitDocuments' />
            </legend>

            <label>{contractDocumentType.description}</label>

            <div className='form bg-white pb-3 mb-4'>
              <UploadDocumentType
                onSubmit={onFileSubmit}
                fileType={contractDocumentType}
                memberCode={null}
              />

              {Arrays.isNotEmpty(documentsContract) ? (
                <>
                  <div className='row mt-3'>
                    <div className='col-lg-10'>
                      <span className='font-small'>
                        <FormattedMessage id='documents.fileName.text' />
                      </span>
                    </div>
                  </div>

                  {documentsContract.map((doc) => (
                    <div key={doc.name} className='border-top py-2'>
                      <div className='row'>
                        <div className='col-lg-10'>
                          <AuthenticatedDownloadLink
                            url={doc.path}
                            filename={doc.name}
                          >
                            {doc.name}
                          </AuthenticatedDownloadLink>
                        </div>
                        <div className='col-lg-2 text-right'>
                          <button
                            className='btn btn-link'
                            onClick={(e) =>
                              handleDeleteDocument(
                                doc.name,
                                contractDocumentType
                              )
                            }
                          >
                            <FormattedMessage id='all.deleteText' />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <EmptyResults />
              )}
            </div>

            <label>{cardDocumentType.description}</label>

            <div className='form bg-white pb-3 mb-4'>
              <UploadDocumentType
                onSubmit={onFileSubmit}
                fileType={cardDocumentType}
                memberCode={null}
              />

              {Arrays.isNotEmpty(documentsCard) ? (
                <>
                  <div className='row mt-3'>
                    <div className='col-lg-10'>
                      <span className='font-small'>
                        <FormattedMessage id='documents.fileName.text' />
                      </span>
                    </div>
                  </div>

                  {documentsCard.map((doc) => (
                    <div key={doc.name} className='border-top py-2'>
                      <div className='row'>
                        <div className='col-lg-10'>
                          <AuthenticatedDownloadLink
                            url={doc.path}
                            filename={doc.name}
                          >
                            {doc.name}
                          </AuthenticatedDownloadLink>
                        </div>
                        <div className='col-lg-2 text-right'>
                          <button
                            className='btn btn-link'
                            onClick={(e) =>
                              handleDeleteDocument(doc.name, cardDocumentType)
                            }
                          >
                            <FormattedMessage id='all.deleteText' />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <EmptyResults />
              )}
            </div>

            <div className='form-actions'>
              <div className='ml-auto'>
                <button
                  className='btn btn-primary'
                  onClick={(e) => handlePosAppointment(e)}
                >
                  <FormattedMessage id='all.submitButtonText' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

import parse from 'html-react-parser';
import React from 'react';
import { Link } from 'react-router-dom';

function PlatformCard({ platform }) {
  return (
    <div className={'col-lg-8 pb-3 mb-4'}>
      <div className={'card'}>
        <div className={'card-header text-center'}>
          <Link to={`/programas/${platform.code}/concursos`}>
            {platform.name}
          </Link>
        </div>
        <div className={'card-body'}>
          {platform.description
            ? parse(platform.description)
            : platform.description}
        </div>
      </div>
    </div>
  );
}

export default PlatformCard;

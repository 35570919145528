import React, { useEffect } from 'react';
import logo from '../img/logo-1.png';

function Loading({ complex }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!complex) {
    return (
      <div className='loading'>
        <div className='loader' />
      </div>
    );
  }
  return (
    <div>
      <div id='wrapper'>
        <LoadingNav />
        <div id='content-wrapper' className={'d-flex flex-column'}>
          <div id='content'>
            <div className='loading'>
              <div className='loader' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function LoadingNav() {
  return (
    <nav
      className={
        'navbar navbar-expand navbar-light bg-white topbar static-top border-bottom'
      }
    >
      <div className={'container'}>
        {/* Sidebar Toggle (Topbar) */}
        <button
          id='sidebarToggleTop'
          className={'btn btn-link d-md-none rounded-circle mr-3'}
        ></button>

        <a href={'/'} className={'navbar-brand js-scroll-trigger mr-5'}>
          <img src={logo} alt='Logo' />
        </a>
      </div>
    </nav>
  );
}

export default Loading;

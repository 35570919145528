import dateFormat from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Redirect, useParams } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import CompetitionForm from '../../../../components/competition/CompetitionForm';
import { ConfigObject } from '../../../../config';
import { DocumentDescriptor } from '../../../../models/DocumentDescriptor';
import { createCompetition } from '../../../../rest/competition';
import { getPlatform } from '../../../../rest/platform';
import { handleError, isBusinessError } from '../../../../utils/handleError';

function AddCompetition() {
  const { platformCode } = useParams();

  const pattern = ConfigObject.get().DATE_TIME_PATTERN;

  const INITIAL_FORM_DATA = {
    step: 1,
    platformCode: platformCode,
    code: '',
    type: 'RAFFLE',
    state: 'DRAFT',
    introductoryText: '',
    descriptiveText: '',
    allowAccommodationChoice: 'TRUE',
    allowPreferenceChoice: 'TRUE',
    maxAccommodations: -1,
    publishDeadline: dateFormat(new Date(), pattern),
    startRegistrationDate: dateFormat(new Date(), pattern),
    endRegistrationDate: dateFormat(new Date(), pattern),
    conclusionDate: dateFormat(new Date(), pattern),
    publicationDateAcceptedApps: dateFormat(new Date(), pattern),
    publicationDateCompResult: dateFormat(new Date(), pattern),
    drawDate: dateFormat(new Date(), pattern),
    eligibilities: [],
    hasPriorityCriterias: '-1',
    priorityCriteriasChosen: []
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submited, setSubmited] = useState(false);
  const [submitedAndAddHabitation, setSubmitedAndAddHabitation] = useState(false);

  const [platform, setPlatform] = useState(null);
  const [competition, setCompetition] = useState(INITIAL_FORM_DATA);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await getPlatform(platformCode);
        setCompetition({
          ...competition,
          eligibilities: data.eligibilityConditions,
          documentTypes: data.documentTypes.filter(
            (doc) => doc.descriptor === DocumentDescriptor.CANDIDATE
          ),
          priorityCriterias: data.priorityCriterias
        });
        setPlatform(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const create = async (type, competition, setSubmitting, setErrors) => {
    competition.step = null;
    competition.submissionType = null;

    try {
      if (!competition.maxAccommodations) {
        competition.maxAccommodations = -1;
      }

      if (competition.hasPriorityCriterias === 'true') {
        competition.priorityCriterias = competition.priorityCriteriasChosen;
      }

      await createCompetition(competition);

      setCompetition(competition);
      setSubmitting(false);

      if (type === 'finalSubmitAndAddHabitation') {
        setSubmitedAndAddHabitation(true);
      } else {
        setSubmited(true);
      }
      setLoading(false);
    } catch (error) {
      setSubmitting(false);
      setError(error);
      setLoading(false);
    }
  };

  const onSubmit = (type, competition, setSubmitting, setErrors) => {
    setLoading(true);
    create(type, competition, setSubmitting, setErrors);
  };

  if (loading) {
    return <Loading></Loading>;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  if (submited) {
    return (
      <Redirect
        to={`/programas/${platformCode}/concursos/${encodeURIComponent(competition.code)}`}
      />
    );
  }

  if (submitedAndAddHabitation) {
    return (
      <Redirect
        to={`/programas/${platformCode}/concursos/${encodeURIComponent(
          competition.code
        )}/alojamentos/pre/adicionar`}
      />
    );
  }

  return (
    <SubTemplate titleId={'competitions.addCompetitionText'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-8 offset-lg-2'}>
            <AlertError error={error} />
            <CompetitionForm
              platform={platform}
              competition={competition}
              onSubmit={onSubmit}
              isUpdate={false}
              setError={setError}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default AddCompetition;

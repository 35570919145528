import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import { StagingCandidaturesFilterArea } from '../../../../components/candidature/StagingCandidaturesFilterArea';
import { StagingCandidaturesTable } from '../../../../components/candidature/StagingCandidaturesTable';
import Loading from '../../../../components/Loading';
import { PaginationComponent } from '../../../../components/PaginationComponent';
import { SubTemplate } from '../../../../components/SubTemplate';
import {
  getCountiesByEAACandidatures,
  getDistrictsByEAACandidatures,
} from '../../../../rest/competition';
import { getCatalogs } from '../../../../rest/platform';
import {
  findAllStagingCandidaturesWithFilters,
  getMaxNumberOfColumn,
} from '../../../../rest/pointsRequest';
import { handleError, isBusinessError } from '../../../../utils/handleError';

const MAX_RESULTS = 15;
const DEFAULT_INITIAL_PAGE = 0;

export function ViewStagingCandidatures() {
  const [stagingCandidaturesDto, setStagingCandidaturesDto] = useState([]);
  const [typologies, setTypologies] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [counties, setCounties] = useState([]);
  const [maxNumberOfIncapacityElements, setMaxNumberOfIncapacityElements] =
    useState(null);
  const [maxNumberOfDependents, setMaxNumberOfDependents] = useState(null);
  const [maxNumberOfOver65Elements, setMaxNumberOfOver65Elements] =
    useState(null);
  const [sortFilters, setSortFilters] = useState([]);
  const [defaultSearchFilters, setDefaultSearchFilters] = useState({
    districtId: null,
    countyId: null,
    minTypology: null,
    maxTypology: null,
    minIncapacityNumber: null,
    maxIncapacityNumber: null,
    minOver65Elements: null,
    maxOver65Elements: null,
    untitledAggregate: null,
    minDependentsNumber: null,
    maxDependentsNumber: null,
  });

  const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async (index) => {
    try {
      const [
        { data: stagingCandidaturesDto },
        { data: typologies },
        { data: districts },
        { data: maxNumberOfIncapacityElements },
        { data: maxNumberOfDependents },
        { data: maxNumberOfOver65Elements },
      ] = await Promise.all([
        findAllStagingCandidaturesWithFilters(
          index,
          MAX_RESULTS,
          defaultSearchFilters,
          sortFilters
        ),
        getCatalogs('EAA', 'typologies'),
        getDistrictsByEAACandidatures(),
        getMaxNumberOfColumn('incapacityElements'),
        getMaxNumberOfColumn('dependentsNumber'),
        getMaxNumberOfColumn('over65Elements'),
      ]);
      setStagingCandidaturesDto(stagingCandidaturesDto);
      setTypologies(typologies);
      setDistricts(districts);
      setMaxNumberOfIncapacityElements(maxNumberOfIncapacityElements);
      setMaxNumberOfDependents(maxNumberOfDependents);
      setMaxNumberOfOver65Elements(maxNumberOfOver65Elements);
      setCurrentPage(index);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const fetchDataWithFilters = async (filterValues) => {
    try {
      const { data: stagingCandidaturesDto } =
        await findAllStagingCandidaturesWithFilters(
          currentPage,
          MAX_RESULTS,
          filterValues ? filterValues : defaultSearchFilters,
          sortFilters
        );
      setStagingCandidaturesDto(stagingCandidaturesDto);
    } catch (error) {
      setError(error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  async function handleDistrictChange(districtCode) {
    try {
      const { data: counties } = await getCountiesByEAACandidatures(
        districtCode
      );
      setCounties(counties);
    } catch (error) {
      setError(error);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate
      hasBackButton
      title={<FormattedMessage id='stagingCandidatures.title' />}
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            <AlertError error={error} />
            <StagingCandidaturesFilterArea
              districts={districts}
              counties={counties}
              typologies={typologies}
              maxNumberOfIncapacityElements={maxNumberOfIncapacityElements}
              maxNumberOfDependents={maxNumberOfDependents}
              maxNumberOfOver65Elements={maxNumberOfOver65Elements}
              onDistrictChange={handleDistrictChange}
              fetchDataWithFilters={fetchDataWithFilters}
            />
            <StagingCandidaturesTable
              stagingCandidatures={stagingCandidaturesDto.stagingCandidatures}
              sortFilters={sortFilters}
              setSortFilters={setSortFilters}
              fetchDataWithFilters={fetchDataWithFilters}
            />
            <PaginationComponent
              dataPerPage={MAX_RESULTS}
              totalData={
                stagingCandidaturesDto.totalNumberOfStagingCandidatures
              }
              onPageChange={handlePageChange}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

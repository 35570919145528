import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const DEFAULT_VALUES = {
  districtId: null,
  countyId: null,
  parishId: null,
  typology: null,
  singleFamilyHouse: null,
};

export function AddEaaHabitationForm({
  submitted,
  selectOne,
  selectAll,
  onSubmit,
  onSearch,
  competitionCode,
  districts,
  counties,
  parishes,
  typologies,
  onDistrictChange,
  onCountyChange,
  results,
}) {
  const intl = useIntl();

  const [values, setValues] = useState(DEFAULT_VALUES);

  function handleFilterValueChange(e, filterName) {
    if (filterName === 'districtId') {
      onDistrictChange(e.target.value);
    }

    if (filterName === 'countyId') {
      onCountyChange(e.target.value);
    }

    values[filterName] = e.target.value === '1' ? null : e.target.value;
    setValues({ ...values });
  }

  return (
    <div>
      <Form onSubmit={onSubmit}>
        <div className='row'>
          <div className='col-md-12'>
            <Form.Group controlId='competitionCode'>
              <Form.Label>
                <FormattedMessage id='habitationForm.competitionCode' />
              </Form.Label>
              <Form.Control
                type='text'
                name='competitionCode'
                value={competitionCode}
                disabled
              />
            </Form.Group>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <Form.Group controlId='district'>
              <Form.Label>
                <FormattedMessage id='habitationForm.habitationAddressDistrictNameText' />
              </Form.Label>
              <Form.Control
                as='select'
                name='district'
                onChange={(e) => handleFilterValueChange(e, 'districtId')}
                value={values.districtId}
              >
                <option value='-1'>
                  {intl.formatMessage({ id: 'all.chooseOne' })}
                </option>

                {districts.map((district) => (
                  <option key={district.code} value={district.code}>
                    {district.description}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className='col-md-6'>
            <Form.Group controlId='county'>
              <Form.Label>
                <FormattedMessage id='habitationForm.habitationAddressCountyNameText' />
              </Form.Label>
              <Form.Control
                as='select'
                name='county'
                onChange={(e) => handleFilterValueChange(e, 'countyId')}
                value={values.countyId}
              >
                <option value='-1'>
                  {intl.formatMessage({ id: 'all.chooseOne' })}
                </option>

                {counties.map((county) => (
                  <option key={county.code} value={county.code}>
                    {county.description}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className='col-md-6'>
            <Form.Group controlId='parish'>
              <Form.Label>
                <FormattedMessage id='habitationForm.habitationAddressParishNameText' />
              </Form.Label>
              <Form.Control
                as='select'
                name='parish'
                onChange={(e) => handleFilterValueChange(e, 'parishId')}
                value={values.parishId}
              >
                <option value='-1'>
                  {intl.formatMessage({ id: 'all.chooseOne' })}
                </option>

                {parishes.map((parish) => (
                  <option key={parish.code} value={parish.code}>
                    {parish.description}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className='col-md-3'>
            <Form.Group controlId='typology'>
              <Form.Label>
                <FormattedMessage id='habitationForm.typologyText' />
              </Form.Label>
              <Form.Control
                as='select'
                name='typology'
                onChange={(e) => handleFilterValueChange(e, 'typology')}
                value={values.typology}
              >
                <option value='-1'>
                  {intl.formatMessage({ id: 'all.chooseOne' })}
                </option>

                {typologies.map((typology) => (
                  <option key={typology.code} value={typology.code}>
                    {typology.description}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className='col-md-3'>
            <Form.Group controlId='singleFamilyHouse'>
              <Form.Label>
                <FormattedMessage id='EAAHabitationForm.singleFamilyHouse' />
              </Form.Label>
              <Form.Control
                as='select'
                name='singleFamilyHouse'
                onChange={(e) => handleFilterValueChange(e, 'singleFamilyHouse')}
                value={values.singleFamilyHouse}
              >
                <option value='-1'>
                  {intl.formatMessage({ id: 'all.chooseOne' })}
                </option>

                <option value='true'>
                  {intl.formatMessage({ id: 'all.yesText' })}
                </option>
                <option value='false'>
                  {intl.formatMessage({ id: 'all.noText' })}
                </option>
              </Form.Control>
            </Form.Group>
          </div>
        </div>
        <div className='form-actions'>
          <div className='ml-auto'>
            <button
              className='btn btn-primary'
              onClick={(e) => onSearch(values, e)}
              type='button'
            >
              <FormattedMessage id='all.searchButtonText' />
            </button>
          </div>
        </div>

        <legend className={'mt-4 mb-2'}>
          <FormattedMessage id='searchHabitation.title' />
        </legend>

        {results?.length > 0 ? (
          <table className='table mt-5'>
            <thead>
              <tr>
                <th>
                  <input type='checkbox' onChange={(e) => selectAll(e)} />
                </th>
                <th>
                  <label>
                    <FormattedMessage id='searchHabitation.codePLGP' />
                  </label>
                </th>
                <th>
                  <label>
                    <FormattedMessage id='searchHabitation.parish' />
                  </label>
                </th>
                <th>
                  <label>
                    <FormattedMessage id='searchHabitation.typology' />
                  </label>
                </th>
                <th>
                  <label>
                    <FormattedMessage id='searchHabitation.area' />
                  </label>
                </th>
              </tr>
            </thead>
            <tbody>
              {results.map((result, index) => (
                <tr>
                  <td>
                    <input
                      type='checkbox'
                      checked={results[index].selected}
                      value={results[index].selected}
                      onChange={(e) => selectOne(e, index)}
                    />
                  </td>
                  <td>{result.codePLGP}</td>
                  <td>
                    {result.districtName} : {result.countyName} :{' '}
                    {result.parishName}{' '}
                  </td>
                  <td>{result.typology}</td>
                  <td>{result.buildingArea} m2</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className='empty-state my-4'>
            <FaInfoCircle />
            <p>
              <FormattedMessage id='info.noResult' />
            </p>
          </div>
        )}

        <div className='form-actions'>
          <div className='mr-auto'>
            <Link
              to={`/programas/EAA/concursos/${encodeURIComponent(
                competitionCode
              )}`}
              variant='secondary'
            >
              <FormattedMessage id='all.backButtonText' />
            </Link>
          </div>
          <div className='ml-auto'>
            <button
              className='btn btn-primary'
              type='submit'
              disabled={
                (results.length === 0 ||
                  results.filter((result) => result.selected).length === 0) &&
                submitted === false
              }
            >
              <FormattedMessage id='all.submitButtonText' />
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

import { Formik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FaUpload } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";
import { ConfigObject } from "../../config";

export function UploadDocumentType({
  onSubmit,
  fileType,
  memberCode,
  description,
  acceptExtensions,
}) {
  const { candidatureDocumentExtensions, candidatureDocumentSize } =
    ConfigObject.get();
  const intl = useIntl();
  const hiddenFileInput = React.useRef(null);
  const [fileName, setFileName] = useState("");

  const EMPTY_DRAW_REQUEST = {
    file: null,
    description: description || fileType.description,
  };

  let schema = yup.object().shape({
    file: yup.mixed().required(
      intl.formatMessage({
        id: "documents.noFile",
      })
    ),
  });

  const handleClick = (e) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };

  return (
    <Formik
      initialValues={EMPTY_DRAW_REQUEST}
      validationSchema={schema}
      validate={(values) => {
        const errors = {};

        if (values.file != null) {
          var extensions = candidatureDocumentExtensions
            .substring(1, candidatureDocumentExtensions.length - 1)
            .replaceAll("|", ",");
          var fileName = values.file.name;
          var extension =
            fileName.substring(
              fileName.lastIndexOf(".") + 1,
              fileName.length
            ) || fileName;
        }
        if (
          values.file &&
          values.file.size / 1024 / 1024 >= candidatureDocumentSize
        ) {
          errors.file = intl.formatMessage({
            id: "documents.SizeErrorText",
          });
          errors.fileShow = true;
        }
        if (fileName && !extensions.includes(extension)) {
          errors.file = intl.formatMessage({
            id: "documents.TypeErrorText",
          });
          errors.fileShow = true;
        }
        if (Object.keys(errors).length === 0) {
          setFileName(fileName);
        }
        return errors;
      }}
      onSubmit={(
        values,
        { setSubmitting, setErrors, setFieldValue, setTouched }
      ) => {
        onSubmit(
          values.file,
          values.description,
          fileType,
          memberCode,
          setSubmitting,
          setErrors,
          setFileName,
          setFieldValue,
          setTouched
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form className="upload-custom" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-10">
              <Form.Group controlId="file">
                <Form.Control
                  type="file"
                  name="file"
                  ref={hiddenFileInput}
                  onChange={(event) => {
                    setFieldValue("file", event.target.files[0]);
                  }}
                  isInvalid={errors.file && (errors.fileShow || touched.file)}
                  accept={acceptExtensions ?? ""}
                />
                <div className="input-group">
                  <div
                    className="form-control cursor-pointer"
                    onClick={handleClick}
                  >
                    {fileName}
                  </div>
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-primary"
                      onClick={handleClick}
                    >
                      <FaUpload />
                    </button>
                  </div>
                </div>
                <Form.Control.Feedback type="invalid">
                  {errors.file}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-lg-2">
              <button
                className="btn btn-outline-primary btn-block"
                type="submit"
                disabled={isSubmitting}
              >
                <FormattedMessage id="documents.otherUpload" />
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

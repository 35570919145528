import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { TiPlus } from 'react-icons/ti';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { DeleteDialog } from '../../../../components/bootstrap/DeleteDialog';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { deleteParameter, getParameters } from '../../../../rest/parameter';

function ListParameters() {
  const [parameters, setParameters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [toDelete, setToDelete] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await getParameters();
        setParameters(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const deleteParam = async (code) => {
    try {
      const { data: deletedParameter } = await deleteParameter(code);
      setParameters(parameters.filter((p) => p.code !== deletedParameter.code));
      setLoading(false);
    } catch (error) {
      if (error.response?.status === 401) {
        window.location.reload();
      } else {
        console.log(error);
        setError(error);
        setLoading(false);
      }
    }
  };

  const handleOnClickDelete = (parameter) => {
    setToDelete(parameter);
    handleShow();
  };

  const handleConfirmDelete = () => {
    deleteParam(toDelete.code);
    handleClose();
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <div>Error {error.message}</div>;
  }

  return (
    <SubTemplate hasBackButton titleId={'parameter.manage'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-md-9'}>
            <ul className={'results'}>
              {parameters.map((parameter) => (
                <li key={parameter.code}>
                  <div className={'row my-3'}>
                    <div className={'col-md-8'}>{parameter.name}</div>

                    <div className={'col-md-4 text-right'}>
                      {!parameter.systemOperational && (
                        <span>
                          <button
                            onClick={() => handleOnClickDelete(parameter)}
                            className={'btn btn-link mr-3'}
                          >
                            <FaTrashAlt />
                          </button>
                        </span>
                      )}
                      <Link to={`/parametros/${parameter.code}/editar`}>
                        <FaEdit />
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
              <DeleteDialog
                show={show}
                handleClose={handleClose}
                handleConfirmDelete={handleConfirmDelete}
              />
            </ul>
          </div>
          <div className='col-md-3'>
            <div className='mx-2 mb-4'>
              <Link
                to={`/parametros/adicionar`}
                className={'btn btn-outline-primary mb-3'}
              >
                <TiPlus aria-hidden='true' />
                <FormattedMessage id='parameter.add' />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default ListParameters;

import React, { useState } from "react";
import { FaRegQuestionCircle } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, Redirect, useParams } from "react-router-dom";
import Loading from "../../../../components/Loading";
import { SubTemplate } from "../../../../components/SubTemplate";
import { AlertError } from "../../../../components/bootstrap/AlertError";
import AddCandidatureForm from "../../../../components/candidature/AddCandidatureForm";
import CandidatureConfirmation from "../../../../components/candidature/CandidatureConfirmation";
import CandidatureHabitations from "../../../../components/candidature/CandidatureHabitations";
import CandidatureOrderHabitation from "../../../../components/candidature/CandidatureOrderHabitation";
import ViewCandidature from "../../../../components/candidature/ViewCandidature";
import { createCustomErrorMessage } from "../../../../hooks/errorMessage";
import { PlatformType } from "../../../../models/PlatformType";
import {
  addCandidature,
  getCandidatureCompetition,
} from "../../../../rest/candidatureuser";
import { handleError, isBusinessError } from "../../../../utils/handleError";
import CandidatureFormSubmitDocuments from "./CandidatureFormSubmitDocuments";
import { Arrays } from "../../../../utils/Arrays";

function AddCandidature() {
  const { competitionCode } = useParams();
  const { platformCode } = useParams();

  const [loading, setLoading] = useState(false);
  const [certificateNumber, setCertificateNumber] = useState(null);
  const [candidature, setCandidature] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [habitationsCandidature, setHabitationsCandidature] = useState([]);
  const [selectedHabitations, setSelectedHabitations] = useState([]);
  const [candidatureDocuments, setCandidatureDocuments] = useState({});
  const [submited, setSubmited] = useState(false);
  const [error, setError] = useState();
  const [step, setStep] = useState(1);
  const intl = useIntl();

  function createMultiPartWithFilesAndJsonObject(
    files,
    object,
    objectFormParamName
  ) {
    const formData = createMultiPart(files);
    formData.append(objectFormParamName, JSON.stringify(object));
    return formData;
  }

  function createMultiPart(files) {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("Attachment", file.content);
      formData.append("Description", file.description);
      formData.append("Type", file.type);
      formData.append("MemberNif", file.memberNif ?? "");
      formData.append("Size", file.content.size);
    });
    return formData;
  }

  const createCandidatureCertificate = async () => {
    try {
      const { data: candidature } = await getCandidatureCompetition(
        competitionCode,
        platformCode,
        certificateNumber
      );

      if (!candidature.options.canChoose) {
        setStep(5);
      } else {
        handleAfter();
      }

      setCandidature(candidature);
      setHabitationsCandidature(candidature.options.habitationsToSelect);

      if (platformCode === PlatformType.PAS) {
        setCandidatureDocuments(candidature.candidatureDocumentsDto);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const createCandidature = async (newCandidature) => {
    try {
      let formData = new FormData();
      if (Arrays.isNotEmpty(documents)) {
        formData = createMultiPartWithFilesAndJsonObject(
          documents,
          newCandidature,
          "CandidatureFromPlatform"
        );
      } else {
        formData.append(
          "CandidatureFromPlatform",
          JSON.stringify(newCandidature)
        );
      }

      const { data: candidature } = await addCandidature(
        competitionCode,
        platformCode,
        formData
      );
      setCandidature(candidature);
      setLoading(false);
      setSubmited(true);
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!certificateNumber) {
      setError(
        createCustomErrorMessage(
          intl.formatMessage({
            id: "candidatureForm.error.emptyCertificateNumber",
          })
        )
      );
    } else {
      setError(null);
      setLoading(true);
      createCandidatureCertificate();
    }
  };

  const onSubmitCandidature = () => {
    if (candidature.candidatureDto.termsAndConditionsValues?.every((terms => terms.value))) {
      setLoading(true);

      const newCandidature = {
        ...candidature,
        options: { ...candidature.options },
      };
      newCandidature.options.habitationsToSelect = selectedHabitations;
      createCandidature(newCandidature);
    } else {
      setError(
        createCustomErrorMessage(
          intl.formatMessage({
            id: "exceptions.TermsConditionNotAcceptedException",
          })
        )
      );
    }
  };

  const handleHabitationClick = (habitation) => {
    if (
      (!habitation.selected &&
        candidature.options.habitationToChoose >=
          selectedHabitations.length + 1) ||
      habitation.selected ||
      candidature.options.habitationToChoose === -1
    ) {
      setError(false);
      habitation.selected = !habitation.selected;
      let tempSelectedHabitation = [...selectedHabitations];
      if (habitation.selected) {
        tempSelectedHabitation.push(habitation);
      } else {
        tempSelectedHabitation = selectedHabitations.filter(
          (x) => x.habitation.externalId !== habitation.habitation.externalId
        );
      }
      setSelectedHabitations(tempSelectedHabitation);
      setHabitationsCandidature([...habitationsCandidature]);
    } else {
      setError(error);
    }
  };

  const handleBack = () => {
    if (step === 1) {
      return;
    } else {
      setStep(step - 1);
    }
  };

  const handleConfirmationBack = () => {
    if (!candidature.options.preference) {
      setStep(3);
    } else if (!candidature.options.canChoose) {
      setStep(2);
    } else {
      setStep(step - 1);
    }
  };

  const handleAfter = () => {
    if (
      ((platformCode === PlatformType.PAA && step > 2) ||
        (platformCode === PlatformType.PAS && step === 4)) &&
      selectedHabitations.length < 1
    ) {
      setError(
        createCustomErrorMessage(
          intl.formatMessage({ id: "errors.selectOneHabitation.text" })
        )
      );
    } else {
      setStep(step + 1);
    }
  };

  const handleSubmited = () => {
    setStep(platformCode === PlatformType.PAS ? 6 : 5);
  };

  const getByPlatformFromOriginalStep = (originalStep) => {
    if (platformCode === PlatformType.PAS) {
      return (originalStep += 1);
    } else {
      return originalStep;
    }
  };

  if (submited) {
    return (
      <Redirect
        to={`/programas/${encodeURIComponent(
          platformCode
        )}/concursos/${encodeURIComponent(competitionCode)}/candidatura/${
          candidature.candidatureDto.externalId
        }?submission=true`}
      />
    );
  }

  if (loading) {
    return <Loading />;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate hasBackButton titleId="addCandidature.candidatureTitle">
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col-md-9 mb-5"}>
            <AlertError error={error} />
            {step === 1 && (
              <AddCandidatureForm
                certificateNumber={certificateNumber}
                setCertificateNumber={setCertificateNumber}
                onSubmit={onSubmit}
              />
            )}
            {step === 2 && (
              <ViewCandidature
                candidature={candidature}
                setCandidature={setCandidature}
                platformCode={platformCode}
                onSubmit={onSubmit}
                handleAfter={handleAfter}
                handleBack={handleBack}
                onSubmitCandidature={onSubmitCandidature}
              />
            )}
            {step === 3 && platformCode === PlatformType.PAS && (
              <CandidatureFormSubmitDocuments
                candidatureDocuments={candidatureDocuments}
                handleAfter={handleAfter}
                handleBack={handleBack}
                documents={documents}
                setDocuments={setDocuments}
                error={error}
                setError={setError}
              />
            )}
            {step === getByPlatformFromOriginalStep(3) && (
              <CandidatureHabitations
                candidature={candidature}
                handleAfter={handleAfter}
                handleBack={handleBack}
                handleSubmited={handleSubmited}
                habitationsCandidature={habitationsCandidature}
                handleHabitationClick={handleHabitationClick}
                platformCode={platformCode}
              />
            )}
            {step === getByPlatformFromOriginalStep(4) && (
              <CandidatureOrderHabitation
                selectedHabitations={selectedHabitations}
                handleBack={handleBack}
                handleSubmited={handleSubmited}
                platformCode={platformCode}
              />
            )}
            {step === getByPlatformFromOriginalStep(5) && (
              <CandidatureConfirmation
                candidature={candidature}
                setCandidature={setCandidature}
                selectedHabitations={selectedHabitations}
                handleConfirmationBack={handleConfirmationBack}
                onSubmitCandidature={onSubmitCandidature}
                platformCode={platformCode}
              />
            )}
          </div>
          <div className={"col-md-3 side-menu"}>
            <ul className={"nav flex-column border-left mx-2 my-3"}>
              <li className={"nav-item"}>
                <Link
                  to={`/perguntas/${platformCode}`}
                  className={"nav-link"}
                  target={"blank"}
                >
                  <FaRegQuestionCircle />
                  <FormattedMessage id="competitions.faqsText" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default AddCandidature;

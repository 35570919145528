import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { EditorInputField } from '../bootstrap/EditorInputField';

function PlatformFormDetails({
  platform,
  handleChange,
  isUpdate,
  numberOfSteps,
  step,
}) {
  const intl = useIntl();

  return (
    <>
      <h5 className='mb-4 pb-2 border-bottom'>
        <FormattedMessage
          id='all.steps'
          values={{ step: step, numberOfSteps: numberOfSteps }}
        />
        {' - '}
        <FormattedMessage id='platformForm.title.platformDefinitions' />
      </h5>
      <div className='row'>
        <div className='col-md-12'>
          {platform.externalId && (
            <Form.Group controlId='externalId'>
              <Form.Label>
                <FormattedMessage id='platformForm.externalIdText' />
              </Form.Label>
              <Form.Control
                type='text'
                name='externalId'
                value={platform.externalId}
                onChange={handleChange}
                disabled
              />
            </Form.Group>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <Form.Group controlId='type'>
            <Form.Label>
              <FormattedMessage id='platformForm.typeText' />
            </Form.Label>
            <Form.Control
              as='select'
              name='type'
              value={platform.type}
              onChange={handleChange}
            >
              <option value='HABITATIONAL'>
                {intl.formatMessage({
                  id: 'all.textHabitational',
                })}
              </option>
              <option value='OTHER'>
                {intl.formatMessage({
                  id: 'all.textOthers',
                })}
              </option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className='col-md-6'>
          {isUpdate ? (
            <Form.Group controlId='code'>
              <Form.Label>
                <FormattedMessage id='platformForm.codeText' />
              </Form.Label>
              <Form.Control
                type='text'
                name='code'
                value={platform.code}
                onChange={handleChange}
                disabled
              />
            </Form.Group>
          ) : (
            <Form.Group controlId='code'>
              <Form.Label>
                <FormattedMessage id='platformForm.codeText' />
              </Form.Label>
              <Form.Control
                type='text'
                name='code'
                value={platform.code}
                onChange={handleChange}
              />
            </Form.Group>
          )}
        </div>
      </div>

      <Form.Group controlId='name'>
        <Form.Label>
          <FormattedMessage id='platformForm.nameText' />
        </Form.Label>
        <Form.Control
          type='text'
          name='name'
          value={platform.name}
          onChange={handleChange}
        />
      </Form.Group>

      <EditorInputField
        name='description'
        labelId='platformForm.descriptionText'
        value={platform.description}
        handleChange={handleChange}
        hideOvelay={true}
      />

      <EditorInputField
        name='howItWorks'
        labelId='platformForm.howItWorksText'
        value={platform.howItWorks}
        handleChange={handleChange}
        hideOvelay={true}
      />

      <EditorInputField
        name='introductoryText'
        labelId='platformForm.introductoryTextText'
        value={platform.introductoryText}
        handleChange={handleChange}
        hideOvelay={true}
      />

      <EditorInputField
        name='descriptiveText'
        labelId='platformForm.descriptiveTextText'
        value={platform.descriptiveText}
        handleChange={handleChange}
        hideOvelay={true}
      />
    </>
  );
}

export default PlatformFormDetails;

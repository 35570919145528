import { Formik } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { schema } from '../../validationSchemas/CompetitionSchema';
import { CompetitionStep1Form } from './CompetitionFormStep1';
import { CompetitionStep2Form } from './CompetitionFormStep2';
import { CompetitionStep3Form } from './CompetitionFormStep3';
import { CompetitionStep4Form } from './CompetitionFormStep4';

const NUMBER_OF_STEPS = 4;

const renderStep = (props) => {
  switch (props.values.step) {
    case 1:
      return (
        <CompetitionStep1Form
          {...props}
          competition={props.values}
          setCompetition={props.setValues}
          numberOfSteps={NUMBER_OF_STEPS}
          step={1}
        />
      );
    case 2:
      return (
        <CompetitionStep2Form
          {...props}
          competition={props.values}
          numberOfSteps={NUMBER_OF_STEPS}
          step={2}
        />
      );
    case 3:
      return (
        <CompetitionStep3Form
          {...props}
          competition={props.values}
          setCompetition={props.setValues}
          numberOfSteps={NUMBER_OF_STEPS}
          step={3}
        />
      );
    case 4:
      return (
        <CompetitionStep4Form
          {...props}
          competition={props.values}
          setCompetition={props.setValues}
          numberOfSteps={NUMBER_OF_STEPS}
          step={4}
        />
      );
    default:
      return (
        <CompetitionStep1Form
          {...props}
          competition={props.values}
          numberOfSteps={NUMBER_OF_STEPS}
          step={1}
        />
      );
  }
};

function CompetitionForm({ competition, platform, onSubmit, isUpdate, setError }) {
  competition.step = 1;

  return (
    <div>
      <Formik
        initialValues={competition}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema(platform)}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          if (values.submissionType) {
            onSubmit(values.submissionType, values, setSubmitting, setErrors);
          } else {
            setSubmitting(false);
          }
        }}>
        {(formProps) => (
          <Form onSubmit={formProps.handleSubmit}>
            {renderStep({
              isUpdate,
              platform,
              setError,
              ...formProps
            })}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CompetitionForm;

import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import PlatformForm from '../../../../components/platform/PlatformForm';
import { SubTemplate } from '../../../../components/SubTemplate';
import { addPlatform } from '../../../../rest/platform';
import { handleError, isBusinessError } from '../../../../utils/handleError';

function AddPlatform() {
  const [platform, setPlatform] = useState({
    type: 'HABITATIONAL',
    textType: 'TEXT',
    eligibilityConditions: [],
    documentTypes: [],
  });
  const [submited, setSubmited] = useState(false);
  const [error, setError] = useState(null);

  const createPlatform = async () => {
    try {
      await addPlatform(platform);

      setSubmited(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    createPlatform();
  };

  if (submited) {
    return <Redirect to={`/programas/${platform.code}/concursos`} />;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate hasBackButton titleId='platform.addPlatform'>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-8 offset-lg-2'}>
            <PlatformForm
              platform={platform}
              setPlatform={setPlatform}
              onSubmit={onSubmit}
              setError={setError}
              error={error}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default AddPlatform;

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { ComponentLoading } from '../../../components/ComponentLoading';
import { DocumentFilterBar } from '../../../components/document/DocumentFilterBar';
import { DocumentTable } from '../../../components/document/DocumentTable';
import Loading from '../../../components/Loading';
import { SubTemplate } from '../../../components/SubTemplate';
import { useQuery } from '../../../hooks/useQuery';
import { DocumentDescriptor } from '../../../models/DocumentDescriptor';
import { getCompetitionDocumentsByDocType } from '../../../rest/competitiondocument';
import { getDocumentTypes } from '../../../rest/documentType';
import { handleError, isNotBusinessError } from '../../../utils/handleError';

const getType = (query) => {
  return query && query.get('tipo');
};

export function CompetitionDocuments() {
  const query = useQuery();
  const { competitionCode } = useParams();
  const { platformCode } = useParams();
  const [selectedType, setSelectedType] = useState(getType(query));
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(true);
  const [error, setError] = useState(null);
  const [documentTypes, setDocumentTypes] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    const fetchDocumentTypes = async () => {
      try {
        const { data } = await getDocumentTypes(DocumentDescriptor.COMPETITION);
        setDocumentTypes(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchDocumentTypes();
  }, []);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const { data } = await getCompetitionDocumentsByDocType(
          competitionCode,
          platformCode,
          selectedType
        );
        setDocuments(data);
      } catch (error) {
        setError(error);
      } finally {
        setSearching(false);
      }
    };
    setSearching(true);
    fetchDocuments();
  }, [selectedType, competitionCode]);

  const filterDocuments = (type) => {
    setSelectedType(type);
  };

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate
      hasBackButton
      title={intl.formatMessage(
        { id: 'competiton.documents.page' },
        { competitionCode: decodeURIComponent(competitionCode) }
      )}
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-9'}>
            <AlertError error={error} />
            <DocumentFilterBar
              keyword={null}
              parentExternalId={null}
              onFilter={filterDocuments}
              documentTypes={documentTypes}
              defaultFilter={selectedType}
            />
            {searching ? (
              <ComponentLoading />
            ) : (
              <DocumentTable documents={documents} />
            )}
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export const DocumentTypeCode = {
  NOTICE: 'NOTICE',
  GEN: 'GEN',
  LIST: 'LIST',
  CONTESTATION: 'CONTESTATION',
  SIGNED_CONTRACT: 'SIGNED_CONTRACT',
  CERT_REG_VALID: 'CERT_REG_VALID',
  VERDICT: 'VERDICT',
  FINAL_DECISION: 'FINAL_DECISION',
  COMPR_SEG_LOCADO: 'COMPR_SEG_LOCADO',
  DECL_DISP_SEGURO: 'DECL_DISP_SEGURO',
  COMP_CAUCAO: 'COMP_CAUCAO',
  COMP_SEG_RENDI: 'COMP_SEG_RENDI',
  MIN_CONTR_ARRE: 'MIN_CONTR_ARRE',
  FI_ALOJ_ASSIN: 'FI_ALOJ_ASSIN',

  values: () => {
    return Object.keys(DocumentTypeCode)
      .filter((key) => key !== 'values')
      .map((key) => DocumentTypeCode[key]);
  },
};

import parse from 'html-react-parser';
import React from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { hasPermission } from '../../authentication/authenticationHelper';

export function FaqRow({ faq, accessInfo, onClickDelete }) {
  return (
    <div className={'mb-3 accordion-panel'}>
      <Accordion>
        <Card>
          <Card.Header className={'d-flex justify-content-between'}>
            <Accordion.Toggle
              as={Button}
              variant='primary'
              eventKey={faq.externalId}
            >
              {faq.question ? parse(faq.question) : faq.question}
            </Accordion.Toggle>
            <div>
              {hasPermission('UPDATE_FAQ', accessInfo) && (
                <Link
                  to={`/editarFaq/${faq.externalId}`}
                  className={'btn btn-primary'}
                >
                  <FaPencilAlt />
                </Link>
              )}
              {hasPermission('DELETE_FAQ', accessInfo) && (
                <Button
                  onClick={() => onClickDelete(faq)}
                  className={'btn btn-primary'}
                >
                  <FaTrashAlt />
                </Button>
              )}
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey={faq.externalId}>
            <Card.Body>{faq.answer ? parse(faq.answer) : faq.answer}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

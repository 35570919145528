import { apiAxios as authAxios } from '../axios.config';

export const createHoliday = (holidayData) => {
  return authAxios.post(`/api/holiday`, holidayData);
};

export const updateHoliday = (externalId, holidayData) => {
  return authAxios.put(`/api/holiday/${externalId}`, holidayData);
};

export const getHoliday = (externalId) => {
  return authAxios.get(`/api/holiday/${externalId}`);
};

export const getHolidays = (year) => {
  return authAxios.get(`/api/holiday/list/${year}`);
};

export const deleteHoliday = (externalId) => {
  return authAxios.delete(`/api/holiday/${externalId}`);
};

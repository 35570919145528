import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import AddHabitationForm from '../../../../components/habitation/AddHabitationForm';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { addHabitationToCompetition } from '../../../../rest/competition';
import { getCatalogs } from '../../../../rest/platform';
import { handleError, isBusinessError } from '../../../../utils/handleError';

function Habitation() {
  const location = useLocation();
  const { platformCode } = useParams();
  const { competitionCode } = useParams();

  const habitationData = JSON.parse(localStorage.getItem('habitationData'));

  const history = useHistory();

  const [data, setData] = useState(null);
  const [catalogs, setCatalogs] = useState(null);
  const [submited, setSubmited] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [images, setImages] = useState(false);

  useEffect(() => {
    setData(habitationData);

    async function fetchCatalogs() {
      try {
        const [
          buildingTypesResponse,
          conservationStatesResponse,
          modalitiesResponse,
          floorsAccessesResponse,
          furnituresResponse,
          kitchenEquipmentsResponse,
          permitBuildingTypesResponse,
        ] = await Promise.all([
          getCatalogs(platformCode, 'buildingTypes'),
          getCatalogs(platformCode, 'conservationStates'),
          getCatalogs(platformCode, 'modalities'),
          getCatalogs(platformCode, 'floorsAccesses'),
          getCatalogs(platformCode, 'furnitures'),
          getCatalogs(platformCode, 'kitchenEquipments'),
          getCatalogs(platformCode, 'permitBuildingTypes'),
        ]);

        const catalogsData = {
          buildingTypes: buildingTypesResponse.data,
          conservationStates: conservationStatesResponse.data,
          modalities: modalitiesResponse.data,
          floorsAccesses: floorsAccessesResponse.data,
          furnitures: furnituresResponse.data,
          kitchenEquipments: kitchenEquipmentsResponse.data,
          permitBuildingTypes: permitBuildingTypesResponse.data,
        };

        setCatalogs(catalogsData);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error);
        setLoading(false);
      }
    }

    fetchCatalogs();
  }, []);

  const addHabitation = async (nextPage, habitation) => {
    setLoading(true);
    try {
      data.habitation = habitation;
      const { data: newHab } = await addHabitationToCompetition(
        competitionCode,
        platformCode,
        data.habitation
      );

      setData({ ...data, habitation: newHab[0] });
      setImages(nextPage);
      setSubmited(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  };

  const onSubmit = (habitation) => {
    setLoading(true);
    addHabitation(false, habitation);
  };

  const onSubmitAndNextPage = (habitation) => {
    setLoading(true);
    addHabitation(true, habitation);
  };

  const handleBackClick = () => {
    history.goBack();
  };

  if (loading) {
    return <Loading />;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error, location.pathname);
  }

  if (submited) {
    localStorage.setItem('habitationSubmited', true);
    if (images) {
      return (
        <Redirect
          to={`/adicionarImagemHabitacao/${data.habitation.externalId}`}
        ></Redirect>
      );
    } else {
      const encodedPlatformCode = encodeURIComponent(data.habitation.platform);
      const encodedCompetitionCode = encodeURIComponent(
        data.habitation.competitionCode
      );
      const habitationExternalId = data.habitation.externalId;
      return (
        <Redirect
          to={`/programas/${encodedPlatformCode}/concursos/${encodedCompetitionCode}/alojamentos/${habitationExternalId}`}
        />
      );
    }
  }

  return (
    <SubTemplate hasBackButton titleId={'addHabitation.insertText'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-md-10 offset-md-1'}>
            <AlertError error={error} />

            <AddHabitationForm
              data={data}
              setData={setData}
              onSubmit={onSubmit}
              onSubmitAndNextPage={onSubmitAndNextPage}
              catalogs={catalogs}
              handleBackClick={handleBackClick}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default Habitation;

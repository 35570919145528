import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { EditorInputField } from '../bootstrap/EditorInputField';

export function CandidatureDecisionDialog({
  show,
  handleClose,
  handleConfirm,
  isSubstituteScoresHigher,
  actionNotification,
  setActionNotification,
}) {
  function handleChange(e) {
    actionNotification.templateEmailBody = e.target.value;
    setActionNotification({ ...actionNotification });
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='candidature.dialog.candidatureDecision.question' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditorInputField
            name='body'
            value={actionNotification.templateEmailBody}
            handleChange={(e) => handleChange(e)}
            hideOvelay={true}
          />
        </Modal.Body>

        <Modal.Footer>
          <button
            className='btn btn-link mr-auto'
            onClick={handleClose}
            type='button'
          >
            <FormattedMessage id='all.cancelText' />
          </button>

          {!isSubstituteScoresHigher && (
            <button
              className={'btn btn-primary'}
              type='submit'
              onClick={() => handleConfirm({ flag: true })}
            >
              <FormattedMessage id='all.yesText' />
            </button>
          )}

          <button
            className={'btn btn-primary'}
            type='submit'
            onClick={() => handleConfirm({ flag: false })}
          >
            <FormattedMessage id='all.noText' />
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

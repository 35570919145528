import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Redirect, useHistory, useLocation, useParams } from 'react-router';
import { HabitationForm } from '../../../../components/habitation/HabitationForm';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { useMessageObjectCreator } from '../../../../hooks/errorMessage';
import {
  editHabitationToCompetition,
  getHabitationByExternalId,
} from '../../../../rest/habitation';
import { getCatalogs } from '../../../../rest/platform';
import { handleError, isBusinessError } from '../../../../utils/handleError';

function EditHabitation() {
  const location = useLocation();
  const { habitationExternalId } = useParams();
  const [data, setData] = useState(null);
  const [catalogs, setCatalogs] = useState(null);
  const [submited, setSubmited] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState({
    show: false,
    type: null,
    message: null,
  });

  const history = useHistory();

  const creator = useMessageObjectCreator();

  const fetchData = async () => {
    try {
      const { data: habitation } = await getHabitationByExternalId(
        habitationExternalId
      );

      const { data: buildingTypes } = await getCatalogs(
        habitation.habitation.platform,
        'buildingTypes'
      );

      const { data: conservationStates } = await getCatalogs(
        habitation.habitation.platform,
        'conservationStates'
      );

      const { data: modalities } = await getCatalogs(
        habitation.habitation.platform,
        'modalities'
      );

      const { data: floorsAccesses } = await getCatalogs(
        habitation.habitation.platform,
        'floorsAccesses'
      );

      const { data: furnitures } = await getCatalogs(
        habitation.habitation.platform,
        'furnitures'
      );

      const { data: kitchenEquipments } = await getCatalogs(
        habitation.habitation.platform,
        'kitchenEquipments'
      );

      const { data: permitBuildingTypes } = await getCatalogs(
        habitation.habitation.platform,
        'permitBuildingTypes'
      );

      const { data: energeticCertificates } = await getCatalogs(
        habitation.habitation.platform,
        'energeticCertificates'
      );

      const catalogsData = {
        buildingTypes,
        conservationStates,
        modalities,
        floorsAccesses,
        furnitures,
        kitchenEquipments,
        permitBuildingTypes,
        energeticCertificates,
      };

      setCatalogs(catalogsData);
      setData(habitation);
      console.log(catalogsData);
      console.log(habitation);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setMessage(creator.create(error));
      setError(error);
      setLoading(false);
    }
  };

  const editHabitation = async (habitation) => {
    try {
      data.habitation = habitation;
      await editHabitationToCompetition(habitationExternalId, data);
      setSubmited(true);
      setLoading(false);
    } catch (error) {
      setMessage(creator.create(error));
      console.log(error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = (habitation, setSubmitting, setErrors) => {
    setLoading(true);
    editHabitation(habitation, setSubmitting, setErrors);
  };

  const goBack = () => {
    history.goBack();
  };

  if (loading) {
    return <Loading />;
  }

  if (submited) {
    const encodedPlatformCode = encodeURIComponent(data.habitation.platform);
    const encodedCompetitionCode = encodeURIComponent(
      data.habitation.competitionCode
    );
    const habitationExternalId = data.habitation.externalId;
    return (
      <Redirect
        to={`/programas/${encodedPlatformCode}/concursos/${encodedCompetitionCode}/alojamentos/${habitationExternalId}`}
      />
    );
  }

  if (error && !isBusinessError(error)) {
    return handleError(error, location.pathname);
  }

  return (
    <SubTemplate hasBackButton titleId={'editHabitation.insertText'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-md-10 offset-md-1'}>
            {message.show && (
              <Alert variant={message.type}>{message.message}</Alert>
            )}
            <HabitationForm
              habitation={data.habitation}
              isUpdate={true}
              catalogs={catalogs}
              onSubmit={onSubmit}
              goBack={goBack}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default EditHabitation;

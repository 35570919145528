import { Formik } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { createSchema } from '../../validationSchemas/CompetitionDateSchema';
import { CompetitionDatesForm } from './CompetitionDatesForm';
export function EditCompetitionDatesForm({
  platformCode,
  competition,
  editableCompetitionDates,
  onSubmit,
}) {
  const isNotEditable = (fieldName) => {
    return !editableCompetitionDates.includes(fieldName);
  };

  return (
    <Formik
      initialValues={competition}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={createSchema(editableCompetitionDates)}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        onSubmit(values, setSubmitting, setErrors);
      }}
    >
      {({ values, errors, touched, setFieldValue, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <CompetitionDatesForm
            touched={touched}
            errors={errors}
            competition={values}
            isNotEditable={isNotEditable}
            setFieldValue={setFieldValue}
          />
          <div className='form-actions'>
            <div className='mr-auto'>
              <Link
                to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                  competition.code
                )}`}
                variant='secondary'
              >
                <FormattedMessage id='all.backButtonText' />
              </Link>
            </div>
            <div className='ml-auto'>
              <button
                className='btn btn-primary mr-3'
                variant='primary'
                type='submit'
              >
                <FormattedMessage id='all.submitButtonText' />
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

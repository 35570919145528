import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import PlatformForm from '../../../../components/platform/PlatformForm';
import { SubTemplate } from '../../../../components/SubTemplate';
import { getPlatform, updatePlatformAPI } from '../../../../rest/platform';
import { handleError, isBusinessError } from '../../../../utils/handleError';

function EditPlatform() {
  const [platform, setPlatform] = useState();
  const [submited, setSubmited] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { platformCode } = useParams();

  useEffect(() => {
    async function fetchPlatform() {
      try {
        const { data: platform } = await getPlatform(platformCode);
        if (!platform.eligibilityConditions) {
          platform.eligibilityConditions = [];
        }
        setPlatform(platform);
        setLoading(false);
      } catch (error) {
        if (error.response?.status === 401) {
          window.location.reload();
        } else {
          console.log(error);
          setLoading(true);
        }
      }
    }
    fetchPlatform();
  }, [setPlatform, setLoading, platformCode]);

  const updatePlatform = async () => {
    try {
      await updatePlatformAPI(platformCode, platform);
      setLoading(false);
      setSubmited(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updatePlatform();
  };

  if (submited) {
    return <Redirect to={`/programas/${platform.code}/concursos`} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate hasBackButton titleId='editPlatform.editPlatformText'>
      <div className={'container'}>
        <div className={'row'}>
          {platform != null && (
            <div className={'col-lg-8 offset-lg-2'}>
              <PlatformForm
                platform={platform}
                setPlatform={setPlatform}
                onSubmit={onSubmit}
                isUpdate={true}
                setError={setError}
                error={error}
              />
            </div>
          )}
        </div>
      </div>
    </SubTemplate>
  );
}

export default EditPlatform;

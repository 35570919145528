import { Formik } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import { DecimalInputField } from '../bootstrap/DecimalInputField';
import { IntInputField } from '../bootstrap/IntInputField';
import { SelectBooleanField } from '../bootstrap/SelectBooleanField';
import { SelectCatalogField } from '../bootstrap/SelectCatalogField';
export function HabitationForm({
  habitation,
  isUpdate,
  catalogs,
  onSubmit,
  onSubmitAndNextPage,
  goBack,
}) {
  const intl = useIntl();

  const handleSubmitting = (values) => {
    if (typeof values.habitationAddress?.longitude === 'string') {
      values.habitationAddress.longitude = null;
    }
    if (typeof values.habitationAddress?.latitude === 'string') {
      values.habitationAddress.latitude = null;
    }

    if (values.nextPage) {
      onSubmitAndNextPage(values);
    } else {
      onSubmit(values);
    }
  };

  if (!habitation.rentToApply) {
    habitation.rentToApply = 0;
  }

  let schema =
    habitation.platform != 'EAA'
      ? yup.object({
          rentToApply: yup
            .number()
            .required('Por favor introduza a renda')
            .min(
              0,
              intl.formatMessage({
                id: 'habitationForm.error.minRentToApply',
              })
            )
            .max(
              habitation.maxRent,
              intl.formatMessage(
                {
                  id: 'habitationForm.error.maxExcededRentToApply',
                },
                { maxRent: habitation.maxRent }
              )
            ),
          habitationAddress: yup.object({
            longitude: yup
              .number()
              .max(
                180.0,
                intl.formatMessage({
                  id: 'habitationForm.error.rangeLongitude',
                })
              )
              .min(
                -180.0,
                intl.formatMessage({
                  id: 'habitationForm.error.rangeLongitude',
                })
              )
              .nullable(true),
            latitude: yup
              .number()
              .max(
                90.0,
                intl.formatMessage({ id: 'habitationForm.error.rangeLatitude' })
              )
              .min(
                -90.0,
                intl.formatMessage({ id: 'habitationForm.error.rangeLatitude' })
              )
              .nullable(true),
          }),
          orderNumber: yup
            .number()
            .required('Por favor introduza um número de ordem')
            .min(
              1,
              intl.formatMessage({
                id: 'habitationForm.error.minOrderNumber',
              })
            ),
        })
      : yup.object({
          orderNumber: yup
            .number()
            .required('Por favor introduza um número de ordem')
            .min(
              1,
              intl.formatMessage({
                id: 'habitationForm.error.minOrderNumber',
              })
            ),
        });

  return (
    <Formik
      initialValues={habitation}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        handleSubmitting(values, setSubmitting, setErrors);
      }}
    >
      {({
        values: habitation,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <div>
            {!isUpdate && (
              <h5 className='mb-4 pb-2 border-bottom'>
                <FormattedMessage id='habitation.step2of2.title' />
              </h5>
            )}
            <div className='row'>
              <div className='col-lg-4 col-md-6'>
                <IntInputField
                  controlId='orderNumber'
                  value={habitation.orderNumber}
                  labelId='habitationForm.orderNumberText'
                  handleChange={handleChange}
                  errorMessage={errors.orderNumber}
                  isInvalid={errors.orderNumber}
                />
              </div>
            </div>
            {habitation.platform != 'EAA' && (
              <>
                <legend>
                  <FormattedMessage id='habitationForm.generalData.text' />
                </legend>
                <div className='row'>
                  <div className='col-lg-4 col-md-6'>
                    <Form.Group controlId='representative'>
                      <Form.Label>
                        <FormattedMessage id='habitationForm.representativeText' />
                      </Form.Label>
                      <Form.Control
                        as='select'
                        name='representative'
                        value={habitation.representative}
                        onChange={handleChange}
                      >
                        <option value='true'>
                          {intl.formatMessage({ id: 'all.yesText' })}
                        </option>
                        <option value='false'>
                          {intl.formatMessage({ id: 'all.noText' })}
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </div>

                  <div className='col-lg-4 col-md-6'>
                    <DecimalInputField
                      name='rentToApply'
                      labelId='habitationForm.rentToApplyText'
                      value={habitation.rentToApply}
                      onChange={handleChange}
                      isInvalid={touched.rentToApply && errors.rentToApply}
                      errorMessage={errors.rentToApply}
                    />
                  </div>
                  <div className='col-lg-4 col-md-6'>
                    <IntInputField
                      controlId='numberOfBathroom'
                      value={habitation.numberOfBathroom}
                      labelId='habitationForm.numberOfBathroomText'
                      handleChange={handleChange}
                    />
                  </div>
                  <div className='col-lg-4 col-md-6'>
                    <Form.Group controlId='fractionCodePLGP'>
                      <Form.Label>
                        <FormattedMessage id='habitationForm.fractionCodePLGPText' />
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='fractionCodePLGP'
                        value={habitation.fractionCodePLGP}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>

                  <div className='col-lg-4 col-md-6'>
                    <Form.Group controlId='fraction'>
                      <Form.Label>
                        <FormattedMessage id='habitationForm.fractionText' />
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='fraction'
                        value={habitation.fraction}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                </div>

                <legend>
                  <FormattedMessage id='habitationForm.areas.text' />
                </legend>
                <div className='row'>
                  <div className='col-lg-4 col-md-6'>
                    <Form.Group controlId='fractionAreaPct'>
                      <Form.Label>
                        <FormattedMessage id='habitationForm.fractionAreaPctText' />
                      </Form.Label>
                      <Form.Control
                        type='number'
                        name='fractionAreaPct'
                        value={habitation.fractionAreaPct}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>

                  <div className='col-lg-4 col-md-6'>
                    <Form.Group controlId='fractionIntegratedLandArea'>
                      <Form.Label>
                        <FormattedMessage id='habitationForm.fractionIntegratedLandAreaText' />
                      </Form.Label>
                      <Form.Control
                        type='number'
                        name='fractionIntegratedLandArea'
                        value={habitation.fractionIntegratedLandArea}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div className='col-lg-4 col-md-6'>
                    <Form.Group controlId='fractionsIntegratedLandArea'>
                      <Form.Label>
                        <FormattedMessage id='habitationForm.fractionsIntegratedLandAreaText' />
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='fractionsIntegratedLandArea'
                        value={habitation.fractionsIntegratedLandArea}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>

                  <div className='col-lg-4 col-md-6'>
                    <Form.Group controlId='bedroomArea'>
                      <Form.Label>
                        <FormattedMessage id='habitationForm.bedroomAreaText' />
                      </Form.Label>
                      <Form.Control
                        type='number'
                        name='bedroomArea'
                        value={habitation.bedroomArea}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div className='col-lg-4 col-md-6'>
                    <Form.Group controlId='totalBedroomsArea'>
                      <Form.Label>
                        <FormattedMessage id='habitationForm.totalBedroomsAreaText' />
                      </Form.Label>
                      <Form.Control
                        type='number'
                        name='totalBedroomsArea'
                        value={habitation.totalBedroomsArea}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>

                  <div className='col-lg-4 col-md-6'>
                    <Form.Group controlId='buildingArea'>
                      <Form.Label>
                        <FormattedMessage id='habitationForm.buildingAreaText' />
                      </Form.Label>
                      <Form.Control
                        type='number'
                        name='buildingArea'
                        value={habitation.buildingArea}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div className='col-lg-4 col-md-6'>
                    <Form.Group controlId='totalLandArea'>
                      <Form.Label>
                        <FormattedMessage id='habitationForm.totalLandAreaText' />
                      </Form.Label>
                      <Form.Control
                        type='number'
                        name='totalLandArea'
                        value={habitation.totalLandArea}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                </div>

                <legend>
                  <FormattedMessage id='habitationForm.location' />
                </legend>
                <div className='row'>
                  <div className='col-lg-4 col-md-6'>
                    <DecimalInputField
                      name='habitationAddress.latitude'
                      value={habitation.habitationAddress.latitude}
                      onChange={handleChange}
                      labelId='habitationForm.habitationAddressLatitudeText'
                      isInvalid={
                        touched.habitationAddress?.latitude &&
                        errors.habitationAddress?.latitude
                      }
                      errorMessage={errors.habitationAddress?.latitude}
                    />
                  </div>
                  <div className='col-lg-4 col-md-6'>
                    <DecimalInputField
                      name='habitationAddress.longitude'
                      value={habitation.habitationAddress.longitude}
                      onChange={handleChange}
                      labelId='habitationForm.habitationAddressLongitudeText'
                      isInvalid={
                        touched.habitationAddress?.longitude &&
                        errors.habitationAddress?.longitude
                      }
                      errorMessage={errors.habitationAddress?.longitude}
                    />
                  </div>
                </div>

                <legend>
                  <FormattedMessage id='habitationForm.catalogs.text' />
                </legend>
                <div className='row'>
                  <div className='col-lg-4 col-md-6'>
                    <SelectCatalogField
                      name='conservationState'
                      labelId='habitationForm.conservationStateText'
                      setFieldValue={setFieldValue}
                      catalogs={catalogs.conservationStates}
                      value={habitation.conservationState}
                      nullOption={true}
                    />
                  </div>

                  <div className='col-lg-4 col-md-6'>
                    <SelectCatalogField
                      name='modality'
                      labelId='habitationForm.modalityText'
                      setFieldValue={setFieldValue}
                      catalogs={catalogs.modalities}
                      value={habitation.modality}
                      nullOption={true}
                    />
                  </div>

                  <div className='col-lg-4 col-md-6'>
                    <SelectCatalogField
                      name='permitBuildingType'
                      labelId='habitationForm.permitBuildingTypeText'
                      setFieldValue={setFieldValue}
                      catalogs={catalogs.permitBuildingTypes}
                      value={habitation.permitBuildingType}
                      nullOption={true}
                    />
                  </div>

                  <div className='col-lg-4 col-md-6'>
                    <SelectCatalogField
                      name='floorAccess'
                      labelId='habitationForm.floorAccessText'
                      setFieldValue={setFieldValue}
                      catalogs={catalogs.floorsAccesses}
                      value={habitation.floorAccess}
                      nullOption={true}
                    />
                  </div>
                  <div className='col-lg-4 col-md-6'>
                    <SelectCatalogField
                      name='kitchenEquipment'
                      labelId='habitationForm.kitchenEquipmentText'
                      setFieldValue={setFieldValue}
                      catalogs={catalogs.kitchenEquipments}
                      value={habitation.kitchenEquipment}
                      nullOption={true}
                    />
                  </div>
                  <div className='col-lg-4 col-md-6'>
                    <SelectCatalogField
                      name='furniture'
                      labelId='habitationForm.furnitureText'
                      setFieldValue={setFieldValue}
                      catalogs={catalogs.furnitures}
                      value={habitation.furniture}
                      nullOption={true}
                    />
                  </div>
                  <div className='col-lg-4 col-md-6'>
                    <SelectBooleanField
                      name='clothesline'
                      labelId='habitationForm.clotheslineText'
                      setFieldValue={setFieldValue}
                      value={habitation.clothesline}
                      nullOption={true}
                    />
                  </div>

                  <div className='col-lg-4 col-md-6'>
                    <SelectBooleanField
                      name='stairAccess'
                      labelId='habitationForm.stairAccessText'
                      setFieldValue={setFieldValue}
                      value={habitation.stairAccess}
                      nullOption={true}
                    />
                  </div>
                  {isUpdate && (
                    <div className='col-lg-4 col-md-6'>
                      <SelectCatalogField
                        name='energeticCertificate'
                        labelId='habitationForm.energeticCertificate'
                        setFieldValue={setFieldValue}
                        catalogs={catalogs.energeticCertificates}
                        value={habitation.energeticCertificate}
                        nullOption={true}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {isUpdate && (
            <div className='form-actions'>
              <div className='mr-auto'>
                <button
                  disabled={isSubmitting}
                  className='btn btn-link'
                  type='button'
                  onClick={goBack}
                >
                  <FormattedMessage id='all.backButtonText' />
                </button>
              </div>
              <div className='ml-auto'>
                <button
                  disabled={isSubmitting}
                  className='btn btn-primary'
                  type='submit'
                >
                  <FormattedMessage id='all.saveChanges' />
                </button>
              </div>
            </div>
          )}
          {!isUpdate && (
            <div className='form-actions'>
              <div className='mr-auto'>
                <button
                  className='btn btn-link'
                  type='button'
                  disabled={isSubmitting}
                  onClick={goBack}
                >
                  <FormattedMessage id='all.previousButtonText' />
                </button>
              </div>
              <div className='ml-auto'>
                <button
                  className='btn btn-outline-primary mr-3'
                  disabled={isSubmitting}
                  onClick={(e) => setFieldValue('nextPage', true)}
                  type='submit'
                >
                  <FormattedMessage id='habitationForm.submitAndAddImages' />
                </button>
                <button
                  disabled={isSubmitting}
                  className='btn btn-primary'
                  type='submit'
                >
                  <FormattedMessage id='all.submitButtonText' />
                </button>
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}

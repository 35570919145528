import React, { useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import Loading from '../../../../components/Loading';
import { PermissionDialog } from '../../../../components/permission/PermissionDialog';
import { SubTemplate } from '../../../../components/SubTemplate';
import {
  getAllRoles,
  getPermission,
  getPermissions,
  updatePermission,
} from '../../../../rest/permission';
import {
  handleError,
  isBusinessError,
  isConstraintViolationException,
} from '../../../../utils/handleError';

/**
 * @param  {Object} newPermission
 * @param  {Array<Object>} permissions
 */
const replacePermission = (newPermission, permissions) => {
  const oldPermission = permissions.find((p) => p.code === newPermission.code);

  if (!oldPermission) {
    permissions.push(newPermission);
  } else {
    const index = permissions.indexOf(oldPermission);
    permissions[index] = newPermission;
  }
};

export function Permissions() {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  const intl = useIntl();

  const fetchData = async () => {
    try {
      const { data: roles } = await getAllRoles();
      const { data: permissions } = await getPermissions();
      setRoles(roles);
      setPermissions(permissions);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const savePermission = async (permission) => {
    try {
      const { data: updatedPermission } = await updatePermission(
        permission.code,
        permission
      );

      replacePermission(updatedPermission, permissions);

      setPermissions([...permissions]);
      setSelectedPermission(null);
      setShowDialog(false);
      setLoading(false);
    } catch (error) {
      if (isConstraintViolationException(error)) {
        const vioDescription = error.response.data.violations.find(
          (vio) => vio.path === 'description'
        );
        if (vioDescription) {
          setError({
            response: {
              status: 400,
              data: {
                exception: 'ForceException',
                message: intl.formatMessage(
                  {
                    id: 'permissionForm.error.blankDescription',
                  },
                  { value: permission.code }
                ),
              },
            },
          });
          const { data: dbPermission } = await getPermission(permission.code);
          replacePermission(dbPermission, permissions);
          setPermissions([...permissions]);
        }
      } else {
        setError(error);
      }
      setShowDialog(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onPermissionUpdate = (permission) => {
    setLoading(true);
    setError(null);
    savePermission(permission);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleChange = (role, permission) => {
    if (permission.roles.includes(role)) {
      permission.roles = permission.roles.filter((x) => x !== role);
    } else {
      permission.roles.push(role);
    }
    setError(null);
    savePermission(permission);
  };

  const onPermissionEditClick = (permission) => {
    setSelectedPermission(permission);
    setShowDialog(true);
  };

  if (loading) {
    return <Loading />;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate hasBackButton titleId={'permissions.title'}>
      <div className={'container'}>
        <div className={'row'}>
          <AlertError error={error} />

          <Table striped className='permissions'>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id='permissions.permission' />
                </th>
                {roles.map((role) => {
                  return (
                    <th key={role} className='text-center'>
                      {role}
                    </th>
                  );
                })}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {permissions.map((permission) => {
                return (
                  <tr>
                    <td>
                      <div>{permission.code}</div>
                      <div className='permission-description'>
                        {permission.description}
                      </div>
                    </td>
                    {roles.map((role) => {
                      return (
                        <td key={role} className='text-center'>
                          <Form.Group controlId={role}>
                            <Form.Check
                              type='checkbox'
                              checked={permission.roles.includes(role)}
                              onChange={() => handleChange(role, permission)}
                            />
                          </Form.Group>
                        </td>
                      );
                    })}
                    <td className='text-center'>
                      <button
                        className='btn btn-link'
                        onClick={() => onPermissionEditClick(permission)}
                      >
                        <FaEdit />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {showDialog && (
            <PermissionDialog
              show={showDialog}
              handleClose={handleDialogClose}
              onUpdate={onPermissionUpdate}
              permission={selectedPermission}
            />
          )}
        </div>
      </div>
    </SubTemplate>
  );
}

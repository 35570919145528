import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  getAccessInfo,
  hasPermission,
  isTokenExpired,
  logout,
  refreshToken,
} from './authentication/authenticationHelper';
import Loading from './components/Loading';
import { ConfigObject } from './config';
import { useGlobalContext } from './context';

export function PrivateRoute({ children, permissions, ...rest }) {
  const { accessInfo, setAccessInfo } = useGlobalContext();

  const hasPermissionsToAccess = (permissions) => {
    if (!permissions) {
      return false;
    }

    if (accessInfo) {
      const userPermissions = accessInfo.permissions;
      if (userPermissions) {
        return permissions.some((p) => {
          return hasPermission(p, accessInfo);
        });
      }
    }
    return false;
  };

  useEffect(() => {
    if (isTokenExpired()) {
      refreshToken()
        .then(() => {
          setAccessInfo(getAccessInfo());
        })
        .catch(() => {
          logout();
          window.location.replace(ConfigObject.get().baseUrl);
        });
    }
  }, [children]);

  if (isTokenExpired()) {
    return <Loading></Loading>;
  }

  return (
    <Route
      {...rest}
      render={() => {
        return hasPermissionsToAccess(permissions) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        );
      }}
    />
  );
}

import axios from 'axios';
import { apiAxios as authAxios } from '../axios.config';

/**
 * @param  {{title:String, body: String, emailAddressFrom: String, personName: String, mobile: String}} message
 * @returns {AxiosResponse<String>}
 */
export const sendPublicMessage = (message) => {
  return axios.post('api/messages', message);
};

export const getUserMessages = (
  keyword,
  type,
  direction,
  state,
  platform,
  parentExternalId,
  competitionNumber,
  region,
  index,
  size
) => {
  let queryParams = constructQueryParams(
    keyword,
    type,
    direction,
    state,
    platform,
    parentExternalId,
    competitionNumber,
    region,
    index,
    size
  );
  return authAxios.get(`/api/messages/user${queryParams}`);
};

export const getSubjects = () => {
  return axios.get('api/messages/subjects');
};

export const getMessage = (externalId) => {
  return authAxios.get(`/api/messages/${externalId}`);
};

export const getSubjectsMessages = () => {
  return authAxios.get(`/api/messages/subjects`);
};

export const getTypesMessages = () => {
  return authAxios.get(`/api/messages/types`);
};

export const getStatesMessages = () => {
  return authAxios.get(`/api/messages/states`);
};

export const getDirectionsMessages = () => {
  return authAxios.get(`/api/messages/directions`);
};

export const getAdminMessages = (
  nif,
  keyword,
  type,
  direction,
  state,
  platform,
  parentExternalId,
  competitionNumber,
  region,
  index,
  size
) => {
  return authAxios.get(`/api/messages/admin`, {
    params: {
      nif: nif,
      keyword: keyword,
      type: type,
      direction: direction,
      state: state,
      platform: platform,
      parentExternalId: parentExternalId,
      competitionNumber: competitionNumber,
      region: region,
      index: index,
      size: size,
    },
  });
};

const constructQueryParams = (
  keyword,
  type,
  direction,
  state,
  subject,
  parentExternalId,
  competitionNumber,
  region,
  index,
  size
) => {
  let queryParams = '?';
  if (keyword) {
    queryParams = queryParams + 'keyword=' + keyword;
  }
  if (type) {
    queryParams =
      queryParams.length > 1
        ? queryParams + '&type=' + type
        : queryParams + 'type=' + type;
  }
  if (direction) {
    queryParams =
      queryParams.length > 1
        ? queryParams + '&direction=' + direction
        : queryParams + 'direction=' + direction;
  }
  if (state) {
    queryParams =
      queryParams.length > 1
        ? queryParams + '&state=' + state
        : queryParams + 'state=' + state;
  }
  if (subject) {
    queryParams =
      queryParams.length > 1
        ? queryParams + '&subject=' + subject
        : queryParams + 'subject=' + subject;
  }
  if (parentExternalId) {
    queryParams =
      queryParams.length > 1
        ? queryParams + '&parentExternalId=' + parentExternalId
        : queryParams + 'parentExternalId=' + parentExternalId;
  }
  if (competitionNumber) {
    queryParams =
      queryParams.length > 1
        ? queryParams + '&competitionNumber=' + competitionNumber
        : queryParams + 'competitionNumber=' + competitionNumber;
  }
  if (region) {
    queryParams =
      queryParams.length > 1
        ? queryParams + '&region=' + region
        : queryParams + 'region=' + region;
  }

  if (index !== null) {
    queryParams =
      queryParams.length > 1
        ? queryParams + '&index=' + index
        : queryParams + 'index=' + index;
  }
  if (size) {
    queryParams =
      queryParams.length > 1
        ? queryParams + '&size=' + size
        : queryParams + 'size=' + size;
  }
  if (queryParams.length <= 0) {
    return '';
  }
  return queryParams;
};

/**
 * @param  {String} parentExternalId
 * @param  {{title: string, body: string}} replyMessage
 * @returns  {Promise<AxiosResponse<{}>>} created message
 */
export const replyMessage = (parentExternalId, replyMessage) => {
  return authAxios.post(`/api/messages/${parentExternalId}`, replyMessage);
};

/**
 * @param  {String} platformCode not encoded platform code
 * @param  {String} competitionCode not encoded competition code
 * @param  {String} candidatureExternalId external id of the candidature
 * @returns {String} link to candidature details
 */
export const candidatureDetailsLink = (
  platformCode,
  competitionCode,
  candidatureExternalId
) => {
  const encodedPlatformCode = encodeURIComponent(platformCode);
  const encodedCompetitionCode = encodeURIComponent(competitionCode);
  const encodedCandidatureExternalId = encodeURIComponent(
    candidatureExternalId
  );

  return `/programas/${encodedPlatformCode}/concursos/${encodedCompetitionCode}/candidatura/${encodedCandidatureExternalId}`;
};

/**
 * @param  {String} candidatureExternalId external id of the candidature
 * @returns {String} link to the verdict page
 */
export const verdictManagerLink = (candidatureExternalId) => {
  const encodedCandidatureExternalId = encodeURIComponent(
    candidatureExternalId
  );

  return `/candidatura/${encodedCandidatureExternalId}/veredito`;
};

export const celebrateManagerLink = (candidatureExternalId) => {
  const encodedCandidatureExternalId = encodeURIComponent(
    candidatureExternalId
  );

  return `/candidatura/${encodedCandidatureExternalId}/celebrar`;
};

export const analyzeOkLink = (candidatureExternalId) => {
  const encodedCandidatureExternalId = encodeURIComponent(
    candidatureExternalId
  );

  return `/candidatura/${encodedCandidatureExternalId}/analisar`;
};

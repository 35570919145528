import React from 'react';
import { Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

export function DecimalInputField({
  name,
  labelId,
  label,
  onChange,
  value,
  errorMessage,
  isInvalid,
  defaultValue,
  hasOverlay,
  overlayLabelId,
  disabled,
  suffixLabel,
  tooltipMessage
}) {
  return (
    <Form.Group controlId={name}>
      <Form.Label>
        {labelId && <FormattedMessage id={labelId} />}
        {label && label}
        {hasOverlay && (
          <OverlayTrigger
            overlay={
              <Tooltip>
                {overlayLabelId && <FormattedMessage id={overlayLabelId} />}
                {tooltipMessage &&  tooltipMessage}
              </Tooltip>
            }>
            <FaInfoCircle className='ml-1 pb-1' />
          </OverlayTrigger>
        )}
      </Form.Label>
      <InputGroup>
        <Form.Control
          className={`${suffixLabel && 'inputSuffix'}`}
          type='number'
          name={name}
          value={value}
          onChange={onChange}
          isInvalid={isInvalid}
          defaultValue={defaultValue && defaultValue}
          disabled={disabled}
        />
        {suffixLabel && <InputGroup.Text>{suffixLabel}</InputGroup.Text>}
      </InputGroup>
      {errorMessage && (
        <Form.Control.Feedback type='invalid' className={`${isInvalid && 'd-block'}`}>
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

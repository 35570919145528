import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

export function PaginationComponent({
  dataPerPage,
  totalData,
  onPageChange,
  currentPage,
}) {
  let items = [];

  if (Math.ceil(totalData / dataPerPage) > 1) {
    if (currentPage != 0) {
      items.push(<Pagination.First onClick={() => onPageChange(0)} />);
      items.push(
        <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} />
      );
    }
    if (currentPage == 1 || currentPage == 2) {
      items.push(
        <Pagination.Item onClick={() => onPageChange(0)} key={0}>
          {1}
        </Pagination.Item>
      );
    }
    if (currentPage - 2 > 0)
      items.push(
        <Pagination.Item
          onClick={() => onPageChange(currentPage - 2)}
          key={currentPage - 2}
        >
          {currentPage - 1}
        </Pagination.Item>
      );
    if (currentPage - 1 > 0)
      items.push(
        <Pagination.Item
          onClick={() => onPageChange(currentPage - 1)}
          key={currentPage - 1}
        >
          {currentPage}
        </Pagination.Item>
      );
    items.push(
      <Pagination.Item
        onClick={() => onPageChange(currentPage)}
        key={currentPage}
        active={true}
      >
        {currentPage + 1}
      </Pagination.Item>
    );
    if (currentPage + 1 < Math.ceil(totalData / dataPerPage))
      items.push(
        <Pagination.Item
          onClick={() => onPageChange(currentPage + 1)}
          key={currentPage + 1}
        >
          {currentPage + 2}
        </Pagination.Item>
      );
    if (currentPage + 2 < Math.ceil(totalData / dataPerPage))
      items.push(
        <Pagination.Item
          onClick={() => onPageChange(currentPage + 2)}
          key={currentPage + 2}
        >
          {currentPage + 3}
        </Pagination.Item>
      );
    if (currentPage !== Math.ceil(totalData / dataPerPage) - 1) {
      items.push(
        <Pagination.Next onClick={() => onPageChange(currentPage + 1)} />
      );
      items.push(
        <Pagination.Last
          onClick={() => onPageChange(Math.ceil(totalData / dataPerPage) - 1)}
        />
      );
    }
  }

  return (
    <div className='row'>
      <div className='col-md-4'>&nbsp;</div>
      <div className='col-md-4 text-center'>
        <Pagination size='sm'>{items}</Pagination>
      </div>
      <div className='col-md-4 text-right'>
        <h7>Total: {totalData} resultados.</h7>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ScrollToTop } from '../../components/ScrollToTop';
import { PlatformDocumentTypes } from './PlatformDocumentTypes';
import { PlatformFormConditions } from './PlatformFormConditions';
import PlatformFormDetails from './PlatformFormDetails';

const NUMBER_OF_STEPS = 3;

function PlatformForm({
  platform,
  setPlatform,
  onSubmit,
  isUpdate,
  error,
  setError,
}) {
  const handleChange = (e) => {
    const plat = { ...platform };
    plat[e.target.name] = e.target.value;
    setPlatform(plat);
  };

  const [step, setStep] = useState(1);

  const handleNext = () => {
    if (step + 1 > NUMBER_OF_STEPS) {
      return;
    }
    setStep(step + 1);
  };

  const handlePrevious = () => {
    if (step - 1 < 1) {
      return;
    }
    setStep(step - 1);
  };

  return (
    <Form onSubmit={onSubmit}>
      {step === 1 && (
        <>
          <ScrollToTop />
          <PlatformFormDetails
            platform={platform}
            handleChange={handleChange}
            isUpdate={isUpdate}
            numberOfSteps={NUMBER_OF_STEPS}
            step={1}
          />
        </>
      )}
      {step === 2 && (
        <>
          <ScrollToTop />
          <PlatformFormConditions
            platform={platform}
            setPlatform={setPlatform}
            numberOfSteps={NUMBER_OF_STEPS}
            step={2}
          />
        </>
      )}
      {step === 3 && (
        <>
          <ScrollToTop />
          <PlatformDocumentTypes
            platform={platform}
            setPlatform={setPlatform}
            numberOfSteps={NUMBER_OF_STEPS}
            step={3}
          />
        </>
      )}

      {step === 1 && (
        <div className='form-actions'>
          <div className='mr-auto'>
            {platform && platform.code ? (
              <Link
                className='btn btn-link'
                to={`/programas/${platform.code}/concursos`}
              >
                <FormattedMessage id='all.backButtonText' />
              </Link>
            ) : (
              <Link className='btn btn-link' to={`/programas`}>
                <FormattedMessage id='all.backButtonText' />
              </Link>
            )}
          </div>
          <div className='ml-auto'>
            <Button type='button' onClick={handleNext}>
              <FormattedMessage id='all.nextButtonText' />
            </Button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className='form-actions'>
          <div className='mr-auto'>
            <button
              className='btn btn-link'
              type='button'
              onClick={handlePrevious}
            >
              <FormattedMessage id='all.backButtonText' />
            </button>
          </div>
          <div className='ml-auto'>
            <Button type='button' onClick={handleNext}>
              <FormattedMessage id='all.nextButtonText' />
            </Button>
          </div>
        </div>
      )}
      {step === 3 && (
        <div className='form-actions'>
          <div className='mr-auto'>
            <button
              className='btn btn-link'
              type='button'
              onClick={handlePrevious}
            >
              <FormattedMessage id='all.backButtonText' />
            </button>
          </div>
          <div className='ml-auto'>
            <button
              className='btn btn-primary'
              type='submit'
              onClick={(e) => onSubmit(e)}
            >
              <FormattedMessage id='all.submitButtonText' />
            </button>
          </div>
        </div>
      )}
    </Form>
  );
}

export default PlatformForm;

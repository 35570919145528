import dateFormat from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import {
  FaEye,
  FaInfoCircle,
  FaPencilAlt,
  FaRegFileAlt,
  FaRegQuestionCircle,
  FaRegWindowClose,
  FaSortDown,
  FaSortUp,
  FaTrashAlt,
} from 'react-icons/fa';
import { TiPlus } from 'react-icons/ti';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import {
  hasPermission,
  isCandidate,
  isIhruUser,
} from '../../../authentication/authenticationHelper';
import { AlertError } from '../../../components/bootstrap/AlertError';
import DrawDialog from '../../../components/draw/DrawDialog';
import HowItWorksDialog from '../../../components/HowItWorksDialog';
import Loading from '../../../components/Loading';
import { SubTemplate } from '../../../components/SubTemplate';
import { ConfigObject } from '../../../config';
import { useGlobalContext } from '../../../context';
import '../../../css/custom.css';
import {
  CLOSED,
  CompetitionState,
  DRAFT,
  DRAW,
  DRAWN_CANDIDATURES_TREATMENT_PHASE,
} from '../../../models/CompetitionState';
import { CompetitionType } from '../../../models/CompetitionType';
import { DocumentTypeCode } from '../../../models/DocumentTypeCode';
import { getCandidature } from '../../../rest/candidatureuser';
import {
  changeCompetitionPartial,
  getAllowActions,
  getCompetitionHabitations,
} from '../../../rest/competition';
import { startDraw } from '../../../rest/draw';
import {
  deleteHabitationsToCompetition,
  editHabitationToCompetition,
} from '../../../rest/habitation';
import { getPlatform } from '../../../rest/platform';
import { startSerialization } from '../../../rest/serialization';
import { handleError, isBusinessError } from '../../../utils/handleError';

function CompetitionHabitationsEAA() {
  const { competitionCode } = useParams();
  const platformCode = 'EAA';
  const { accessInfo } = useGlobalContext();
  const { nphCodeUrl } = ConfigObject.get();

  const [competitionHabitations, setCompetitionHabitations] = useState(null);
  const [orderFilter, setOrderFilter] = useState([
    { type: 'countyName', value: 'ASC' },
    { type: 'typology', value: 'DESC' },
    { type: 'buildingArea', value: 'DESC' },
  ]);
  const [candidature, setCandidature] = useState(null);
  const [orderNumberChanged, setOrderNumberChanged] = useState(false);
  const [platform, setPlatform] = useState(null);
  const [existCandidature, setExistCandidature] = useState(false);
  const [allowActions, setAllowActions] = useState(null);

  const [loading, setLoading] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [drawStart, setDrawStart] = useState(false);
  const [serializationStart, setSerializationStart] = useState(false);
  const [showModalDraw, setShowModalDraw] = useState(false);

  const handleClose = () => setShow(false);
  const handleCloseDrawModal = () => setShowModalDraw(false);
  const handleShow = () => setShow(true);

  const intl = useIntl();
  const history = useHistory();

  const pattern = ConfigObject.get().DATE_TIME_PATTERN;

  useEffect(() => {
    async function fetchData() {
      try {
        const [
          competitionHabitationsResponse,
          platformResponse,
          allowActionsResponse,
        ] = await Promise.all([
          getCompetitionHabitations(competitionCode, platformCode),
          getPlatform(platformCode),
          getAllowActions(competitionCode, platformCode),
        ]);

        setCompetitionHabitations(competitionHabitationsResponse.data);

        setPlatform(platformResponse.data);
        setAllowActions(allowActionsResponse.data);

        if (isCandidate(accessInfo)) {
          const { data: candidatures } = await getCandidature(
            competitionCode,
            platformCode
          );
          setCandidature(candidatures);
          setExistCandidature(true);
        }
        setLoading(false);
        setOrderNumberChanged(false);
      } catch (error) {
        if (isCandidate(accessInfo) && error.response?.status === 400) {
          setExistCandidature(false);
        } else {
          setError(error);
        }
        console.log(error);
        setLoading(false);
      }
    }

    fetchData();
  }, [orderNumberChanged]);

  async function selectAll(e) {
    let temp = { ...competitionHabitations };
    temp.habitations.map((result) => (result.selected = e.target.checked));
    setCompetitionHabitations(temp);
  }

  async function selectOne(e, index) {
    let temp = { ...competitionHabitations };

    temp.habitations[index].selected = e.target.checked;
    setCompetitionHabitations(temp);
  }

  const handleKeypress = async (e) => {
    const characterCode = e.key;
    if (characterCode === 'Backspace') return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const handleDelete = async () => {
    try {
      await deleteHabitationsToCompetition(
        competitionHabitations.habitations
          .filter((h) => h.selected)
          .map((h) => h.externalId)
      );
      window.location.reload();

      setIsDeleted(true);
    } catch (error) {
      setError(error);
    }
  };

  const handleStateChange = async (e, state) => {
    setLoading(true);

    e.preventDefault();

    const body = [{ fieldName: 'state', value: state }];

    try {
      await changeCompetitionPartial(competitionCode, platformCode, body);
      const { data: allowActionsData } = await getAllowActions(
        competitionCode,
        platformCode
      );
      competitionHabitations.state = state;
      setAllowActions(allowActionsData);
      setCompetitionHabitations({ ...competitionHabitations });
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const handleStartDraw = async (cadence, setSubmitting, setErrors) => {
    setLoading(true);
    try {
      await startDraw(competitionCode, platformCode, cadence);
      setLoading(false);
      setDrawStart(true);
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setSubmitting(false);
    }
  };

  const initSerialization = async () => {
    setLoading(true);
    try {
      await startSerialization(competitionCode, platformCode);
      setSerializationStart(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (drawStart) {
    history.replace(
      `/programas/${platformCode}/concursos/${encodeURIComponent(
        competitionHabitations.code
      )}/sorteio`
    );
  }

  if (serializationStart) {
    history.replace(
      `/programas/${platformCode}/concursos/${encodeURIComponent(
        competitionHabitations.code
      )}/seriacao`
    );
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  const handleOrder = async (event, habitation) => {
    if (event.target.value.length == 0) return;

    setLoading(true);
    try {
      habitation.orderNumber = event.target.value;
      const habitationAux = {
        habitation: {
          orderNumber: event.target.value,
        },
      };

      await editHabitationToCompetition(habitation.externalId, habitationAux);
      setLoading(false);
      setOrderNumberChanged(true);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  let compareObjectsWithFilters = (a, b, filter) => {
    if (a[eval('filter[0].type')] < b[eval('filter[0].type')]) {
      return filter[0].value === 'DESC' ? 1 : -1;
    }

    if (a[eval('filter[0].type')] > b[eval('filter[0].type')]) {
      return filter[0].value === 'DESC' ? -1 : 1;
    }
    if (filter.length == 1) {
      return 0;
    }
    filter.shift();

    return compareObjectsWithFilters(a, b, filter);
  };

  const removeOrder = (type) => {
    let orderFilterAux = orderFilter.filter((f) => f.type !== type);
    setOrderFilter(orderFilterAux);

    let competitionAUX = { ...competitionHabitations };
    competitionAUX.habitations.sort((a, b) => {
      return compareObjects(a.orderNumber, b.orderNumber);
    });

    if (orderFilterAux.length !== 0) {
      competitionAUX.habitations.sort((a, b) => {
        return compareObjectsWithFilters(a, b, [...orderFilterAux]);
      });
    }
    setCompetitionHabitations({ ...competitionAUX });
  };

  const compareObjects = (a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const changeOrder = (type) => {
    let orderFilterAux = [...orderFilter];
    if (orderFilter.length > 0) {
      let filter = orderFilter.filter((h) => h.type == type);

      if (filter.length === 0) {
        orderFilterAux.push({ type: type, value: 'ASC' });
        setOrderFilter([...orderFilterAux]);
      } else {
        orderFilterAux = orderFilter.map((f) =>
          f.type == type
            ? {
                type: f.type,
                value: filter[0].value === 'DESC' ? 'ASC' : 'DESC',
              }
            : { type: f.type, value: f.value }
        );
        setOrderFilter(orderFilterAux);
      }
    } else {
      orderFilterAux.push({ type: type, value: 'ASC' });

      setOrderFilter(orderFilterAux);
    }
    let competitionAUX = { ...competitionHabitations };
    competitionAUX.habitations.sort((a, b) => {
      return compareObjects(a.orderNumber, b.orderNumber);
    });

    if (orderFilterAux.length !== 0) {
      competitionAUX.habitations.sort((a, b) => {
        return compareObjectsWithFilters(a, b, [...orderFilterAux]);
      });
    }
    setCompetitionHabitations({ ...competitionAUX });
  };

  return (
    <SubTemplate
      hasBackButton
      title={`${intl.formatMessage({
        id: 'competitionHabitation.competition',
      })} ${competitionHabitations.code} - ${intl.formatMessage({
        id: `competitionState.${competitionHabitations.state}`,
      })}`}
    >
      <div className={'container'}>
        <AlertError error={error} />
        <div className={'row'}>
          <div className={'col-md-9 mb-5'}>
            <p className={'text-secondary'}>
              <FormattedMessage id='competitionHabitation.datesStartEntText' />
              <strong>{competitionHabitations.startRegistrationDate}</strong>
              <FormattedMessage id='all.toText' />
              <strong> {competitionHabitations.endRegistrationDate}</strong>
            </p>
            {competitionHabitations.habitations.length > 0 ? (
              <div>
                <hr className={'my-4'} />
                <h6 className={'text-uppercase mb-4'}>
                  {competitionHabitations.habitations.length}
                  <FormattedMessage id='competitionHabitation.habitationInCompetitionsText' />
                </h6>

                <table className='table'>
                  <thead>
                    <tr>
                      {competitionHabitations.habitations[0].codePLGP &&
                        hasPermission('EDIT_COMPETITION', accessInfo) &&
                        competitionHabitations.state === 'DRAFT' && (
                          <th>
                            <input
                              type='checkbox'
                              onChange={(e) => selectAll(e)}
                            />
                          </th>
                        )}
                      <th className={'col-lg-1'}>
                        <FormattedMessage id='habitationForm.orderNumberText' />
                      </th>

                      {competitionHabitations.habitations[0].codePLGP && (
                        <th>
                          <label>
                            <FormattedMessage id='searchHabitation.codePLGP' />
                          </label>
                        </th>
                      )}
                      <th className='w-15'>
                        <label>
                          <FormattedMessage id='searchHabitation.code' />
                        </label>
                      </th>

                      <th>
                        <label
                          onClick={() => changeOrder('countyName')}
                          className='d-inline pointer'
                        >
                          <FormattedMessage id='searchHabitation.county' />
                          {orderFilter.filter((t) => t.type === 'countyName')
                            .length > 0 && (
                            <>
                              &nbsp;(
                              {orderFilter.filter(
                                (t) => t.type === 'countyName'
                              )[0]?.value === 'ASC' ? (
                                <FaSortUp />
                              ) : (
                                <FaSortDown />
                              )}
                              )&nbsp;
                            </>
                          )}
                        </label>
                        {orderFilter.filter((t) => t.type === 'countyName')
                          .length > 0 && (
                          <span
                            onClick={() => removeOrder('countyName')}
                            className='d-inline'
                          >
                            <FaRegWindowClose />
                          </span>
                        )}
                      </th>
                      <th>
                        <label
                          onClick={() => changeOrder('typology')}
                          className='d-inline pointer'
                        >
                          <FormattedMessage id='searchHabitation.typology' />
                          {orderFilter.filter((t) => t.type === 'typology')
                            .length > 0 && (
                            <>
                              {' '}
                              &nbsp;(
                              {orderFilter.filter(
                                (t) => t.type === 'typology'
                              )[0]?.value === 'ASC' ? (
                                <FaSortUp />
                              ) : (
                                <FaSortDown />
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>

                        {orderFilter.filter((t) => t.type === 'typology')
                          .length > 0 && (
                          <span
                            onClick={() => removeOrder('typology')}
                            className='d-inline'
                          >
                            <FaRegWindowClose />
                          </span>
                        )}
                      </th>
                      <th>
                        <label
                          onClick={() => changeOrder('buildingArea')}
                          className='d-inline pointer'
                        >
                          <FormattedMessage id='searchHabitation.area' />
                          {orderFilter.filter((t) => t.type === 'buildingArea')
                            .length > 0 && (
                            <>
                              {' '}
                              &nbsp;(
                              {orderFilter.filter(
                                (t) => t.type === 'buildingArea'
                              )[0]?.value === 'ASC' ? (
                                <FaSortUp />
                              ) : (
                                <FaSortDown />
                              )}
                              ) &nbsp;
                            </>
                          )}
                        </label>
                        {orderFilter.filter((t) => t.type === 'buildingArea')
                          .length > 0 && (
                          <span
                            onClick={() => removeOrder('buildingArea')}
                            className='d-inline'
                          >
                            <FaRegWindowClose />
                          </span>
                        )}
                      </th>
                      {competitionHabitations.habitations[0].codePLGP && (
                        <th>
                          <label>
                            <FormattedMessage id='searchHabitation.date' />
                          </label>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {competitionHabitations.habitations.map(
                      (habitation, index) => (
                        <tr key={habitation.externalId}>
                          {habitation.codePLGP &&
                            hasPermission('EDIT_COMPETITION', accessInfo) &&
                            competitionHabitations.state === 'DRAFT' && (
                              <td>
                                <input
                                  type='checkbox'
                                  value={habitation.selected}
                                  checked={habitation.selected}
                                  onChange={(e) => selectOne(e, index)}
                                />
                              </td>
                            )}
                          {hasPermission('EDIT_COMPETITION', accessInfo) &&
                          competitionHabitations.state === 'APPROVED' ? (
                            <td key={habitation.orderNumber}>
                              <input
                                className={'form-control pl-2 pr-2 text-center'}
                                defaultValue={habitation.orderNumber || ''}
                                type='number'
                                onBlur={(event) =>
                                  handleOrder(event, habitation)
                                }
                                onKeyDown={(e) => handleKeypress(e)}
                                min='1'
                              />
                            </td>
                          ) : (
                            <td>{habitation.orderNumber}</td>
                          )}
                          {habitation.codePLGP && (
                            <td>{habitation.codePLGP}</td>
                          )}
                          <td>
                            <Link
                              to={`/programas/EAA/concursos/${encodeURIComponent(
                                habitation.competitionCode
                              )}/alojamentos/${habitation.externalId}`}
                            >
                              {habitation.code}
                            </Link>
                          </td>
                          <td
                            title={
                              habitation.districtName +
                              ' : ' +
                              habitation.countyName
                            }
                          >
                            {habitation.countyName}
                          </td>
                          <td>{habitation.typology}</td>
                          <td>{habitation.buildingArea} m2</td>
                          {habitation.codePLGP && (
                            <td>{habitation.modificationDate}</td>
                          )}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className='empty-state my-4'>
                <FaInfoCircle />
                <p>
                  <FormattedMessage id='competitionHabitation.addHabitationCTA' />
                  <br />
                  <strong>
                    <FormattedMessage id='annunciations.competition' />{' '}
                    {competitionHabitations.code}
                  </strong>
                </p>
              </div>
            )}
          </div>
          <div className={'col-md-3 side-menu'}>
            <div className='mx-2 mb-4'>
              {hasPermission('CHANGE_COMPETITION', accessInfo) &&
                allowActions.states.length > 0 && (
                  <div className='border-bottom mb-3'>
                    {allowActions.states.map((state, index) => (
                      <button
                        key={index}
                        className={
                          'btn btn-outline-primary mb-3 text-uppercase'
                        }
                        onClick={(e) => handleStateChange(e, state)}
                      >
                        <FormattedMessage
                          id={`competitionState.changeTo${state}`}
                        />
                      </button>
                    ))}
                    {hasPermission('START_DRAW', accessInfo) &&
                      competitionHabitations.state ===
                        'PUBLICATION_ACCEPTED_CANDIDATURES' &&
                      competitionHabitations.drawDate <
                        dateFormat(new Date(), pattern) &&
                      allowActions.canInitDraw && (
                        <button
                          className={
                            'btn btn-outline-primary mb-3 text-uppercase'
                          }
                          onClick={() =>
                            competitionHabitations.type ===
                            CompetitionType.RAFFLE
                              ? setShowModalDraw(true)
                              : initSerialization()
                          }
                        >
                          <FormattedMessage
                            id={
                              competitionHabitations.type ===
                              CompetitionType.RAFFLE
                                ? `competitionState.changeToDRAW`
                                : 'competitionState.changeToSERIALIZATION'
                            }
                          />
                        </button>
                      )}
                  </div>
                )}

              {hasPermission('DELETE_HABITATION', accessInfo) &&
                competitionHabitations.state === 'DRAFT' &&
                !isDeleted &&
                competitionHabitations.habitations.filter((h) => h.selected)
                  .length > 0 && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={handleDelete}
                  >
                    <FaTrashAlt />
                    <FormattedMessage id='all.deleteHabitationText' />
                  </button>
                )}
              {hasPermission('ADD_HABITATION', accessInfo) &&
                competitionHabitations.state === DRAFT && (
                  <Link
                    to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                      competitionHabitations.code
                    )}/alojamentos/pre/adicionar`}
                    className={'btn btn-outline-primary mb-3'}
                  >
                    <TiPlus />
                    <FormattedMessage id='addHabitation.insertText' />
                  </Link>
                )}
              {hasPermission('EDIT_COMPETITION', accessInfo) &&
                competitionHabitations.state === DRAFT && (
                  <Link
                    to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                      competitionHabitations.code
                    )}/editar`}
                    className={'btn btn-outline-primary mb-3'}
                  >
                    <FaPencilAlt />
                    <FormattedMessage id='competitions.editCompetitionText' />
                  </Link>
                )}
              {hasPermission('CHANGE_COMPETITION', accessInfo) &&
                competitionHabitations.state !== DRAFT &&
                allowActions.datesCanBeChanged.length > 0 && (
                  <Link
                    to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                      competitionHabitations.code
                    )}/datas/editar`}
                    className={'btn btn-outline-primary mb-3'}
                  >
                    <FaPencilAlt />
                    <FormattedMessage id='competitionDates.editText' />
                  </Link>
                )}
              {hasPermission('ADD_COMPETITION_DOCUMENT', accessInfo) &&
                (competitionHabitations.state === DRAFT ||
                  CompetitionState.getActives().includes(
                    competitionHabitations.state
                  )) && (
                  <Link
                    to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                      competitionHabitations.code
                    )}/documentos/editar`}
                    className={'btn btn-outline-primary mb-3'}
                  >
                    <FaRegFileAlt />
                    <FormattedMessage id='competitionForm.addDocuments' />
                  </Link>
                )}
              {existCandidature && (
                <div className='mb-4'>
                  <Link
                    to={`/programas/${encodeURIComponent(
                      platformCode
                    )}/concursos/${encodeURIComponent(
                      competitionCode
                    )}/candidatura/${candidature.candidatureDto.externalId}`}
                    className={'btn btn-primary mb-3'}
                  >
                    <FaEye />
                    <FormattedMessage id='candidature.seeCandidatureText' />
                  </Link>
                </div>
              )}
              {hasPermission('LIST_CANDIDATURES', accessInfo) && (
                <Link
                  to={`/programas/${platformCode}/concursos/${competitionCode}/candidaturas`}
                  className={'btn btn-primary mb-3'}
                >
                  <FaEye />
                  <FormattedMessage id='candidature.seeListOfCandidaturesText' />
                </Link>
              )}
              <Link
                to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                  competitionHabitations.code
                )}/documentos?tipo=${DocumentTypeCode.NOTICE}`}
                className={'btn btn-outline-primary mb-3'}
              >
                <FaRegFileAlt />
                <FormattedMessage id='notice.button.text' />
              </Link>
              {competitionHabitations.state === DRAW && (
                <Link
                  to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                    competitionHabitations.code
                  )}/sorteio`}
                  className={'btn btn-primary mb-3'}
                >
                  <FaEye />
                  <FormattedMessage id='draw.button.text' />
                </Link>
              )}
              {competitionHabitations.state === CLOSED ||
                (competitionHabitations.state ===
                  DRAWN_CANDIDATURES_TREATMENT_PHASE &&
                  isIhruUser(accessInfo) &&
                  hasPermission('START_DRAW', accessInfo) && (
                    <Link
                      to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                        competitionHabitations.code
                      )}/${
                          (competitionHabitations.type === 'PAA'  || competitionHabitations.type === 'PAS')
                          ? 'sorteio'
                          : 'seriacao'
                      }`}
                      className={'btn btn-primary mb-3'}
                    >
                      <FaEye />
                      <FormattedMessage id='drawResultas.button.text' />
                    </Link>
                  ))}
              {allowActions.canShowLists && (
                <Link
                  to={`/programas/${platformCode}/concursos/${encodeURIComponent(
                    competitionHabitations.code
                  )}/documentos?tipo=${DocumentTypeCode.LIST}`}
                  className={'btn btn-outline-primary mb-3'}
                >
                  <FaRegFileAlt />
                  <FormattedMessage id='list.button.text' />
                </Link>
              )}
            </div>

            <ul className={'nav flex-column border-left mx-2 my-3'}>
              <li className='nav-item'>
                <button
                  className={'nav-link btn btn-link'}
                  onClick={handleShow}
                >
                  <FaInfoCircle />
                  <FormattedMessage id='competitions.howItWorksText' />
                </button>
              </li>
              <li className={'nav-item'}>
                <Link to={`/perguntas/${platformCode}`} className={'nav-link'}>
                  <FaRegQuestionCircle />
                  <FormattedMessage id='competitionHabitation.faqsText' />
                </Link>
              </li>
            </ul>
          </div>
          <HowItWorksDialog
            handleClose={handleClose}
            show={show}
            platform={platform}
            showEditLink={hasPermission('UPDATE_PLATFORM', accessInfo)}
          />
          <DrawDialog
            handleCloseDrawModal={handleCloseDrawModal}
            showModalDraw={showModalDraw}
            handleStartDraw={handleStartDraw}
          />
        </div>
      </div>
    </SubTemplate>
  );
}

export default CompetitionHabitationsEAA;

import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import { AlertCandidateDocumentSubmissionModal } from '../../../../components/candidature/AlertCandidateDocumentSubmissionModal';
import { UploadDocumentType } from '../../../../components/candidature/UploadDocumentType';
import { AuthenticatedDownloadLink } from '../../../../components/document/AuthenticatedDownloadLink';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import {
  addCandidatureDocument,
  addCandidatureDocumentAll,
  addCandidatureMemberDocument,
  deleteCandidatureDocument,
  deleteCandidatureMemberDocument,
  getCandidatureDocument,
} from '../../../../rest/candidatureuser';
import { Arrays } from '../../../../utils/Arrays';
import { handleError, isNotBusinessError } from '../../../../utils/handleError';

function CandidatureDocuments() {
  const { externalId } = useParams();
  const { platformCode } = useParams();
  const { competitionCode } = useParams();

  const [loading, setLoading] = useState(true);
  const [isSubmited, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [documentsMember, setMemeberDocuments] = useState({});
  const [documents, setDocuments] = useState({});
  const [showAlerDocumentSubmissionModal, setShowAlerDocumentSubmissionModal] =
    useState(true);

  const history = useHistory();

  const fetchData = async () => {
    try {
      const { data: documents } = await getCandidatureDocument(externalId);

      setDocuments(documents);
      setMemeberDocuments(documents.memberDocuments);
      setShowAlerDocumentSubmissionModal(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmitGeneralDocuments = (file, description, fileType) => {
    const formData = new FormData();
    formData.append('Attachment', file, file.name);
    formData.append('Description', description);
    formData.append('Type', fileType);
    addDocumentGeneral(formData);
  };
  async function addDocumentGeneral(formData) {
    try {
      setLoading(true);
      await addCandidatureDocument(externalId, formData);
      fetchData();
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  async function deleteDocumentGeneral(e, documentName) {
    e.preventDefault();
    try {
      setLoading(true);
      await deleteCandidatureDocument(externalId, documentName);
      fetchData();
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  const onSubmitMemberDocuments = (
    file,
    description,
    fileType,
    memberCode,
    setSubmitting
  ) => {
    const formData = new FormData();
    formData.append('Attachment', file, file.name);
    formData.append('Description', description);
    formData.append('Type', fileType);
    addDocumentMember(formData, memberCode, setSubmitting);
  };

  async function addDocumentMember(formData, memberCode, setSubmitting) {
    try {
      setLoading(true);
      setError(null);
      await addCandidatureMemberDocument(externalId, memberCode, formData);
      fetchData();
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  async function deleteDocumentMember(e, documentName, memberCode) {
    e.preventDefault();
    try {
      setLoading(true);
      await deleteCandidatureMemberDocument(
        externalId,
        memberCode,
        documentName
      );
      fetchData();
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  async function submitAllDocuments(e) {
    e.preventDefault();
    try {
      setLoading(true);
      await addCandidatureDocumentAll(externalId);
      setLoading(false);
      setSubmitted(true);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  if (isSubmited) {
    history.replace(
      `/programas/${platformCode}/concursos/${competitionCode}/candidatura/${externalId}?docSubmited=true`
    );
  }

  return (
    <SubTemplate
      hasBackButton
      titleId={'documents.candidature.text'}
      titleValues={{
        candidatureCode: documents.candidatureCode,
        competitionCode: decodeURIComponent(competitionCode),
      }}
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            <AlertError error={error} />
            <legend className='mb-3'>
              <FormattedMessage id='all.submitDocuments' />
            </legend>
            {documents.generalDocuments.map((documentType) => (
              <>
                <label>
                  {documentType.description}
                  {documentType.required && (
                    <span className={'required'}>*</span>
                  )}
                </label>
                <div className='form bg-white pb-3 mb-4'>
                  <UploadDocumentType
                    onSubmit={onSubmitGeneralDocuments}
                    fileType={documentType.code}
                    description={documentType.description}
                    memberCode={null}
                  />
                  {!Arrays.isEmpty(documentType.documents) && (
                    <div className='row mt-3'>
                      <div className='col-lg-10'>
                        <span className='font-small'>
                          <FormattedMessage id='documents.fileName.text' />
                        </span>
                      </div>
                    </div>
                  )}
                  {!Arrays.isEmpty(documentType.documents) &&
                    documentType.documents.map((docs) => (
                      <>
                        <div className='border-top py-2'>
                          <div className='row'>
                            <div className='col-lg-10'>
                              <AuthenticatedDownloadLink
                                url={docs.path}
                                filename={docs.name}
                              >
                                {docs.name}
                              </AuthenticatedDownloadLink>
                            </div>
                            <div className='col-lg-2 text-right'>
                              <button
                                className='btn btn-link'
                                onClick={(e) =>
                                  deleteDocumentGeneral(e, docs.name)
                                }
                              >
                                <FormattedMessage id='all.deleteText' />
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </>
            ))}

            {documentsMember.map((memberDocument) => (
              <>
                {Arrays.isNotEmpty(memberDocument.documentTypes) && (
                  <legend className='mb-3 mt-5'>
                    <FormattedMessage id='documents.candidature.memberDocument' />{' '}
                    {memberDocument.name}
                  </legend>
                )}
                {memberDocument.documentTypes.map((documentType) => (
                  <>
                    <label>
                      {documentType.description}
                      {documentType.required && (
                        <span className={'required'}>*</span>
                      )}
                    </label>
                    <div className='form bg-white pb-3 mb-4'>
                      <UploadDocumentType
                        onSubmit={onSubmitMemberDocuments}
                        fileType={documentType.code}
                        description={documentType.description}
                        memberCode={memberDocument.code}
                      />
                      {!Arrays.isEmpty(documentType.documents) && (
                        <div className='row mt-3'>
                          <div className='col-lg-10'>
                            <span className='font-small'>
                              <FormattedMessage id='documents.fileName.text' />
                            </span>
                          </div>
                        </div>
                      )}
                      {!Arrays.isEmpty(documentType.documents) &&
                        documentType.documents.map((document) => (
                          <>
                            <div className='border-top py-2'>
                              <div className='row'>
                                <div className='col-lg-10'>
                                  <AuthenticatedDownloadLink
                                    url={document.path}
                                    filename={document.name}
                                  >
                                    {document.name}
                                  </AuthenticatedDownloadLink>
                                </div>
                                <div className='col-lg-2 text-right'>
                                  <button
                                    className='btn btn-link'
                                    onClick={(e) =>
                                      deleteDocumentMember(
                                        e,
                                        document.name,
                                        memberDocument.code
                                      )
                                    }
                                  >
                                    <FormattedMessage id='all.deleteText' />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </>
                ))}
              </>
            ))}
            <div className='form-actions'>
              <div className='ml-auto'>
                <button
                  className='btn btn-primary'
                  onClick={(e) => submitAllDocuments(e)}
                >
                  <FormattedMessage id='all.submitButtonText' />
                </button>
              </div>
            </div>
          </div>
        </div>
        <AlertCandidateDocumentSubmissionModal
          show={showAlerDocumentSubmissionModal}
          handleClose={() => setShowAlerDocumentSubmissionModal(false)}
        />
      </div>
    </SubTemplate>
  );
}

export default CandidatureDocuments;

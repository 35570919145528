import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import App from './App';
import './css/custom.css';
import './css/fonts/Nunito-Bold.ttf';
import './css/fonts/Nunito-Italic.ttf';
import './css/fonts/Nunito-Regular.ttf';
import pt from './translations/pt.json';

const locale = navigator.language;
const language = pt;

export const languageString = 'pt';

ReactDOM.render(
  <IntlProvider locale={locale} messages={language}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </IntlProvider>,
  document.getElementById('root')
);

import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import { UploadDocumentType } from '../../../../components/candidature/UploadDocumentType';
import { AuthenticatedDownloadLink } from '../../../../components/document/AuthenticatedDownloadLink';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { createCustomErrorMessage } from '../../../../hooks/errorMessage';
import { DocumentTypeCode } from '../../../../models/DocumentTypeCode';
import {
  addCandidatureDocument,
  addFinalDocumentsToCandidature,
  changeInsuranceOption,
  deleteCandidatureDocument,
  deliverLaterInsurance,
  getCanDeliveryLater,
  getFinalDocuments,
} from '../../../../rest/candidatureuser';
import { Arrays } from '../../../../utils/Arrays';
import { handleError, isNotBusinessError } from '../../../../utils/handleError';

function getDocumentTypesByOption(documentTypes, option) {
  switch (option) {
    case 1:
      return documentTypes.filter(
        (documentType) => documentType.code === 'COMPR_SEG_LOCADO'
      );

    case 2:
      return documentTypes.filter(
        (documentType) =>
          documentType.code === 'DECL_DISP_SEGURO' ||
          documentType.code === 'COMP_CAUCAO'
      );

    default:
      return documentTypes.filter(
        (documentType) => documentType.code === 'COMP_SEG_RENDI'
      );
  }
}

function CandidatureFinalDocuments() {
  const { externalId, platformCode, competitionCode } = useParams();

  const [loading, setLoading] = useState(true);
  const [isSubmited, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [finalDocuments, setFinalDocuments] = useState({});
  const [option, setOption] = useState(null);
  const [selectedTab, setSelectedTab] = useState(1);
  const [canDeliveryLater, setCanDeliveryLater] = useState(false);
  const [deliveryLater, setDeliveryLater] = useState(false);
  const history = useHistory();

  const intl = useIntl();

  const fetchData = async () => {
    setError(null);
    try {
      const [documentsResponse, canDeliveryLaterResponse] = await Promise.all([
        getFinalDocuments(externalId),
        getCanDeliveryLater(externalId),
      ]);

      setFinalDocuments(documentsResponse.data);
      setOption(documentsResponse.data.insuranceOption);
      setCanDeliveryLater(canDeliveryLaterResponse.data.flag);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function validateDocuments(option, documents, setError) {
    let validations = [];
    let documentsNeeded = [];

    if (finalDocuments.insuranceOption == 'OPTION1') {
      documentsNeeded.push(DocumentTypeCode.COMPR_SEG_LOCADO);
    } else {
      documentsNeeded.push(DocumentTypeCode.DECL_DISP_SEGURO);
      documentsNeeded.push(DocumentTypeCode.COMP_CAUCAO);
    }
    documentsNeeded.push(DocumentTypeCode.COMP_SEG_RENDI);

    documentsNeeded.forEach((dN) => {
      if (
        finalDocuments.documentTypes.filter((dt) => dt.code === dN)[0].documents
          .length === 0
      ) {
        validations.push(
          intl.formatMessage({ id: 'validation.documents.' + dN })
        );
      }
    });

    if (validations.length === 0) {
      return true;
    }

    setError(
      createCustomErrorMessage(
        intl.formatMessage(
          { id: 'validation.finalDocuments.needDocuments' },
          { documents: '<br/>' + validations.join('<br/>') }
        )
      )
    );
  }

  const handleOnChangeTab = (selectedTab, selectedOption) => {
    setError(null);
    setSelectedTab(selectedTab);
    setOption(selectedOption);
    changeInsuranceOption(externalId, { insuranceOption: selectedOption });

    setFinalDocuments({ ...finalDocuments, insuranceOption: selectedOption });
  };

  const onSubmitFinalDocuments = (file, description, fileType) => {
    const formData = new FormData();
    formData.append('Attachment', file, file.name);
    formData.append('Description', description);
    formData.append('Type', fileType);
    addFinalDocument(formData);
  };

  async function addFinalDocument(formData) {
    try {
      setLoading(true);
      await addCandidatureDocument(externalId, formData);
      fetchData();
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  async function deleteDocumentGeneral(e, documentName) {
    e.preventDefault();
    try {
      setLoading(true);
      await deleteCandidatureDocument(externalId, documentName);
      fetchData();
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  async function submitAllDocuments(e) {
    e.preventDefault();
    try {
      setDeliveryLater(false);
      setLoading(true);

      const documentsAreValidated = validateDocuments(
        finalDocuments.insuranceOption,
        finalDocuments,
        setError
      );

      if (documentsAreValidated) {
        await addFinalDocumentsToCandidature(externalId);
        setSubmitted(true);
      }

      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }
  async function submitDeliveryLater(e) {
    e.preventDefault();
    try {
      setDeliveryLater(true);
      setDeliveryLater(true);
      setLoading(true);
      await deliverLaterInsurance(externalId);
      setSubmitted(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  if (isSubmited) {
    let message = deliveryLater ? 'docSubmitLater=true' : 'docSubmited=true';
    history.replace(
      `/programas/${platformCode}/concursos/${competitionCode}/candidatura/${externalId}?${message}`
    );
  }
  return (
    <SubTemplate
      hasBackButton
      titleId={'documents.candidature.text'}
      titleValues={{
        candidatureCode: finalDocuments.candidatureCode,
        competitionCode: decodeURIComponent(competitionCode),
      }}
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            <AlertError error={error} />
            <label className='mb-3'>
              <FormattedMessage id='paaInsurance.text' />
            </label>

            <legend className='mb-3'>
              <FormattedMessage id='candidature.finalDocuments.income.local.type' />
            </legend>

            <ul className={'nav nav-tabs'}>
              <li className={'nav-item'}>
                <button
                  className={`nav-link ${
                    selectedTab === 1 && 'active'
                  } text-uppercase`}
                  onClick={() => handleOnChangeTab(1, 'OPTION1')}
                >
                  <FormattedMessage id='paaInsurance.option1.text' />
                </button>
              </li>
              <li className={'nav-item'}>
                <button
                  className={`nav-link ${
                    selectedTab === 2 && 'active'
                  } text-uppercase`}
                  onClick={() => handleOnChangeTab(2, 'OPTION2')}
                >
                  <FormattedMessage id='paaInsurance.option2.text' />
                </button>
              </li>
            </ul>

            {option === 'OPTION1' ? (
              <div className='form bg-white pb-3 mb-4'>
                <label>
                  {
                    getDocumentTypesByOption(finalDocuments.documentTypes, 1)[0]
                      .description
                  }
                </label>
                <UploadDocumentType
                  onSubmit={onSubmitFinalDocuments}
                  fileType={
                    getDocumentTypesByOption(finalDocuments.documentTypes, 1)[0]
                      .code
                  }
                  description={
                    getDocumentTypesByOption(finalDocuments.documentTypes, 1)[0]
                      .description
                  }
                  memberCode={null}
                />

                <div>
                  {!Arrays.isEmpty(
                    getDocumentTypesByOption(finalDocuments.documentTypes, 1)[0]
                      .documents
                  ) && (
                    <div className='row mt-3'>
                      <div className='col-lg-10'>
                        <span className='font-small'>
                          <FormattedMessage id='documents.fileName.text' />
                        </span>
                      </div>
                    </div>
                  )}
                  {!Arrays.isEmpty(
                    getDocumentTypesByOption(finalDocuments.documentTypes, 1)[0]
                      .documents
                  ) &&
                    getDocumentTypesByOption(
                      finalDocuments.documentTypes,
                      1
                    )[0].documents.map((document) => (
                      <>
                        <div className='border-top py-2'>
                          <div className='row'>
                            <div className='col-lg-10'>
                              <AuthenticatedDownloadLink
                                url={document.path}
                                filename={document.name}
                              >
                                {document.name}
                              </AuthenticatedDownloadLink>
                            </div>
                            <div className='col-lg-2 text-right'>
                              <button
                                className='btn btn-link'
                                onClick={(e) =>
                                  deleteDocumentGeneral(e, document.name)
                                }
                              >
                                <FormattedMessage id='all.deleteText' />
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            ) : (
              <div>
                {getDocumentTypesByOption(finalDocuments.documentTypes, 2).map(
                  (documentType, index) => (
                    <div key={index} className='form bg-white pb-3 mb-4'>
                      <label>{documentType.description}</label>
                      <UploadDocumentType
                        onSubmit={onSubmitFinalDocuments}
                        fileType={documentType.code}
                        description={documentType.description}
                        memberCode={null}
                      />

                      <div>
                        {!Arrays.isEmpty(documentType.documents) && (
                          <div className='row mt-3'>
                            <div className='col-lg-10'>
                              <span className='font-small'>
                                <FormattedMessage id='documents.fileName.text' />
                              </span>
                            </div>
                          </div>
                        )}
                        {!Arrays.isEmpty(documentType.documents) &&
                          documentType.documents.map((document) => (
                            <>
                              <div className='border-top py-2'>
                                <div className='row'>
                                  <div className='col-lg-10'>
                                    <AuthenticatedDownloadLink
                                      url={document.path}
                                      filename={document.name}
                                    >
                                      {document.name}
                                    </AuthenticatedDownloadLink>
                                  </div>
                                  <div className='col-lg-2 text-right'>
                                    <button
                                      className='btn btn-link'
                                      onClick={(e) =>
                                        deleteDocumentGeneral(e, document.name)
                                      }
                                    >
                                      <FormattedMessage id='all.deleteText' />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}

            <legend className='mb-3 mt-5'>
              <FormattedMessage id='candidature.finalDocuments.income.insurance.type' />
            </legend>
            <div className='form bg-white pb-3 mb-4'>
              <label>
                {
                  getDocumentTypesByOption(finalDocuments.documentTypes)[0]
                    .description
                }
              </label>
              <UploadDocumentType
                onSubmit={onSubmitFinalDocuments}
                fileType={
                  getDocumentTypesByOption(finalDocuments.documentTypes)[0].code
                }
                description={
                  getDocumentTypesByOption(finalDocuments.documentTypes)[0]
                    .description
                }
                memberCode={null}
              />

              <div>
                {!Arrays.isEmpty(
                  getDocumentTypesByOption(finalDocuments.documentTypes)[0]
                    .documents
                ) && (
                  <div className='row mt-3'>
                    <div className='col-lg-10'>
                      <span className='font-small'>
                        <FormattedMessage id='documents.fileName.text' />
                      </span>
                    </div>
                  </div>
                )}
                {!Arrays.isEmpty(
                  getDocumentTypesByOption(finalDocuments.documentTypes)[0]
                    .documents
                ) &&
                  getDocumentTypesByOption(
                    finalDocuments.documentTypes
                  )[0].documents.map((document) => (
                    <>
                      <div className='border-top py-2'>
                        <div className='row'>
                          <div className='col-lg-10'>
                            <AuthenticatedDownloadLink
                              url={document.path}
                              filename={document.name}
                            >
                              {document.name}
                            </AuthenticatedDownloadLink>
                          </div>
                          <div className='col-lg-2 text-right'>
                            <button
                              className='btn btn-link'
                              onClick={(e) =>
                                deleteDocumentGeneral(e, document.name)
                              }
                            >
                              <FormattedMessage id='all.deleteText' />
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
            <div className='form-actions'>
              {canDeliveryLater && (
                <div className='mr-5 ml-auto'>
                  <button
                    className='btn btn-primary'
                    onClick={(e) => submitDeliveryLater(e)}
                  >
                    <FormattedMessage id='validation.finalDocuments.deliveryLater' />
                  </button>
                </div>
              )}

              <div className={canDeliveryLater ? '' : 'ml-auto'}>
                <button
                  className='btn btn-primary'
                  onClick={(e) => submitAllDocuments(e)}
                >
                  <FormattedMessage id='all.submitButtonText' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default CandidatureFinalDocuments;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import ListCandidature from '../candidature/ListCandidature';
import { HabitationCardList } from '../habitation/HabitationCardList';

export function ListCandidatureDetails({
  candidature,
  hasMemberOnOtherCompetition,
  platformCode,
  viewMode
}) {
  function order(a, b) {
    if (a.order > b.order) {
      return 1;
    } else if (a.order < b.order) {
      return -1;
    } else {
      return 0;
    }
  }

  return (
    <div className={'form'}>
      <ListCandidature
        candidature={candidature}
        platformCode={platformCode}
        viewMode={viewMode}
        hasMemberOnOtherCompetition={hasMemberOnOtherCompetition}
      />
      {candidature != null && candidature.options.canChoose && (
        <div>
          <legend className={'mt-4 mb-2'}>
            <FormattedMessage id='candidature.numberHabitationsText' />
            {': '}
            <strong>{candidature.options.habitationsToSelect.length}</strong>
          </legend>
          {candidature.options.habitationsToSelect
            .sort(order)
            .map((candidatureHabitation) => {
              return (
                <HabitationCardList
                  key={candidatureHabitation.habitation.externalId}
                  habitation={candidatureHabitation}
                  view={true}
                />
              );
            })}
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import { AddEaaHabitationForm } from '../../../../components/habitation/AddEaaHabitationForm';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import {
    addHabitationToCompetitionEAA,
    getCountyEAA,
    getDistrictEAA,
    getParishEAA,
    searchHabitationToCompetitionEAA
} from '../../../../rest/competition';
import { getCatalogs } from '../../../../rest/platform';
import { handleError, isBusinessError } from '../../../../utils/handleError';

export function AddEaaHabitation() {
  const { competitionCode } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submited, setSubmited] = useState(false);

  const [districts, setDistricts] = useState([]);
  const [counties, setCounties] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [results, setResults] = useState([]);
  const [fractionTypes, setFractionTypes] = useState([]);
  const [typologies, setTypologies] = useState(null);

  const fetchData = async (active) => {
    try {
      let [{ data: districts }, { data: typologies }] = await Promise.all([
        await getDistrictEAA(competitionCode),
        await getCatalogs('EAA', 'typologies'),
      ]);
      setDistricts(districts);
      setTypologies(typologies);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(true);
  }, []);

  async function handleDistrictChange(districtCode) {
    try {
      const { data: counties } = await getCountyEAA(
        districtCode,
        competitionCode
      );
      setCounties(counties);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  }

  async function handleCountyChange(countyCode) {
    try {
      const { data: parishes } = await getParishEAA(
        countyCode,
        competitionCode
      );
      setParishes(parishes);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  }

  async function addHabitation(codes) {
    try {
      setLoading(true);
      await addHabitationToCompetitionEAA(competitionCode, codes);
      setSubmited(true);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function selectAll(e) {
    let temp = [...results];
    temp.map((result) => (result.selected = e.target.checked));
    setResults(temp);
  }

  async function selectOne(e, index) {
    let temp = [...results];
    temp[index].selected = e.target.checked;
    setResults(temp);
  }

  async function searchHabitation(values, e) {
    e.preventDefault();

    console.log(values);

    try {
      //setLoading(true);
      const habitationDto = {
        typology: { code: values.typology },
        singleFamilyHouse: values.singleFamilyHouse,
        habitationAddress: {
          districtCode: values.districtId,
          countyCode: values.countyId,
          parishCode: values.parishId,
        },
      };
      const { data: results } = await searchHabitationToCompetitionEAA(
        competitionCode,
        habitationDto
      );
      setResults(results);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      //setLoading(false);
    }
  }

  async function handleSubmit(values) {
    setSubmited(true);
    const codes = results
      .filter((result) => result.selected)
      .map((result) => result.codePLGP);

    await addHabitation(codes);
    setSubmited(false);
  }

  if (loading) {
    return <Loading />;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  if (submited) {
    return (
      <Redirect
        to={`/programas/EAA/concursos/${encodeURIComponent(competitionCode)}`}
      />
    );
  }

  return (
    <SubTemplate hasBackButton titleId={'habitationForm.title'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            <AlertError error={error} />
            <AddEaaHabitationForm
              submitted={submited}
              selectOne={selectOne}
              selectAll={selectAll}
              onSubmit={handleSubmit}
              onSearch={searchHabitation}
              competitionCode={competitionCode}
              fractionTypes={fractionTypes}
              districts={districts}
              counties={counties}
              parishes={parishes}
              typologies={typologies}
              onDistrictChange={handleDistrictChange}
              onCountyChange={handleCountyChange}
              results={results}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

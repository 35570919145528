import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { HabitationCardList } from '../habitation/HabitationCardList';
import { PlatformType } from '../../models/PlatformType';

function CandidatureHabitations({
  candidature,
  handleAfter,
  handleBack,
  handleSubmited,
  habitationsCandidature,
  handleHabitationClick,
  platformCode
}) {
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);

  return (
    <>
      <div className={'form'}>
        <h5 className='mb-4 pb-2 border-bottom'>
          <FormattedMessage
            id={`${
              platformCode === PlatformType.PAS
                ? 'candidature.pas.step3of5Text'
                : 'candidature.step2of3Text'
            }`}
          />
        </h5>
        <p className='mb-4'>
          <FormattedMessage id='candidature.chooseHabitationText' />
        </p>
        {error && show && (
          <Alert variant={'danger'} onClose={() => setShow(false)} dismissible>
            <FormattedMessage id='candidature.manyHabitationsText' />
          </Alert>
        )}
        {habitationsCandidature.map((habitation) => (
          <HabitationCardList
            key={habitation.habitation.externalId}
            habitation={habitation}
            handleHabitationClick={handleHabitationClick}
            order={false}
          />
        ))}
      </div>
      <div className='form-actions'>
        <div className='mr-auto'>
          <Link onClick={handleBack} className='btn btn-link'>
            <FormattedMessage id='all.backButtonText' />
          </Link>
        </div>
        <div className='ml-auto'>
          {!candidature.options.preference ? (
            <Button variant='primary' onClick={handleSubmited}>
              <FormattedMessage id='all.nextButtonText' />
            </Button>
          ) : (
            <Button variant='primary' onClick={handleAfter}>
              <FormattedMessage id='all.nextButtonText' />
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default CandidatureHabitations;

import { apiAxios as authAxios } from '../axios.config';

/**
 * Get all roles from the system
 *
 * @returns {Promise<import('axios').AxiosResponse<Array<String>>>} List of roles
 */
export const getAllRoles = () => {
  return authAxios.get('/api/permissions/roles');
};

/**
 * Get the details of a permission
 *
 * @param  {String} permissionCode unique code of the permission
 * @returns {Promise<import('axios').AxiosResponse<{code: string, roles: Array<String>, description: String, zoneLimitation: boolean}>>} Permission details
 */
export const getPermission = (permissionCode) => {
  return authAxios.get(`/api/permissions/permission/${permissionCode}`);
};

/**
 * Get the details of a permission
 *
 * @returns {Promise<import('axios').AxiosResponse<Array<{code: string, roles: Array<String>, description: String, zoneLimitation: boolean}>>>} Permission details
 */
export const getPermissions = () => {
  return authAxios.get(`/api/permissions`);
};

/**
 * Get the details of a permission
 *
 * @param  {String} permissionCode unique code of the permission
 * @param  {{roles: Array<String>, description: String, zoneLimitation: boolean}} permission details
 * @returns {Promise<import('axios').AxiosResponse<{code: string, roles: Array<String>, description: String, zoneLimitation: boolean}>>} Permission details
 */
export const updatePermission = (permissionCode, permission) => {
  return authAxios.put(
    `/api/permissions/permission/${permissionCode}`,
    permission
  );
};

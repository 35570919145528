import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import Loading from '../../../../components/Loading';
import ParameterForm from '../../../../components/parameters/ParameterForm';
import { SubTemplate } from '../../../../components/SubTemplate';
import {
  getParameterByCode,
  updateParameter,
} from '../../../../rest/parameter';

function EditParameter() {
  const [parameter, setParameter] = useState();
  const [loading, setLoading] = useState(true);
  const [submited, setSubmited] = useState(false);
  const [setError] = useState(null);

  const { parameterCode } = useParams();

  const fetchParam = async () => {
    try {
      const { data } = await getParameterByCode(parameterCode);
      setParameter(data);
      setLoading(false);
    } catch (error) {
      if (error.response?.status === 401) {
        window.location.reload();
      } else {
        setError(error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchParam();
  }, []);

  const editParam = async () => {
    try {
      await updateParameter(parameterCode, parameter);
      setSubmited(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    editParam();
  };

  if (submited) {
    return <Redirect to='/parametros' />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <SubTemplate hasBackButton titleId={'parameter.edit'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-8 offset-lg-2'}>
            <ParameterForm
              parameter={parameter}
              setParameter={setParameter}
              onSubmit={onSubmit}
              isUpdate={true}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default EditParameter;

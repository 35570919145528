import { apiAxios as axios } from '../axios.config';

export const getActionNotificationsMetaData = () => {
  return axios.get('api/actionNotifications/list');
};

export const deleteActionNotification = (code) => {
  return axios.delete(`api/actionNotifications/${code}`);
};

export const getActionNotification = (code) => {
  return axios.get(`api/actionNotifications/${code}`);
};

export const updateActionNotification = (code, actionNotification) => {
  return axios.put(`api/actionNotifications/${code}`, actionNotification);
};

export const createActionNotification = (actionNotification) => {
  return axios.post('api/actionNotifications', actionNotification);
};

export const getActionNotificationByCandidatureState = (state, competitionType) => {
  return axios.get(`api/actionNotifications/candidature/${state}/${competitionType}`)
}

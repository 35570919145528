import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

export function SelectCatalogField({
  name,
  labelId,
  label,
  catalogs,
  isInvalid,
  errorMessage,
  setFieldValue,
  value,
  nullOption,
}) {
  const intl = useIntl();

  const handleDescription = (catalog) => {
    const description = catalog.name || catalog.description;

    return description.length > 40 ? catalog.code : description;
  };

  useEffect(() => {
    if (!nullOption && !value) {
      setFieldValue(name, catalogs[0]);
    }
  }, []);

  return (
    <Form.Group controlId={name}>
      <Form.Label>
        {labelId && <FormattedMessage id={labelId} />}
        {label && label}
      </Form.Label>
      <Form.Control
        as='select'
        name={name}
        onChange={(e) =>
          setFieldValue(
            name,
            catalogs.find((x) => e.target.value === x.code)
          )
        }
        value={value?.code}
        isInvalid={isInvalid}
      >
        {nullOption && (
          <option>{intl.formatMessage({ id: 'all.chooseOne' })}</option>
        )}
        {catalogs.map((catalog) => (
          <option key={catalog.code} value={catalog.code}>
            {handleDescription(catalog)}
          </option>
        ))}
      </Form.Control>
      {errorMessage && (
        <Form.Control.Feedback type='invalid'>
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DisplayableHabitationDetails } from '../habitation/DisplayableHabitationDetails';
import { HabitationForm } from './HabitationForm';

function AddHabitationForm({
  data,
  catalogs,
  onSubmit,
  onSubmitAndNextPage,
  handleBackClick,
}) {
  const [step, setStep] = useState(1);

  const goBack = () => {
    if (step === 1) {
      return;
    } else {
      setStep(step - 1);
    }
  };

  const handleAfter = () => {
    if (step === 2) {
      return;
    } else {
      setStep(step + 1);
    }
  };

  return (
    <div>
      {step === 1 && (
        <div className='form'>
          <h5 className='mb-4 pb-2 border-bottom'>
            <FormattedMessage id='habitation.step1of2.title' />
          </h5>
          <DisplayableHabitationDetails
            habitation={data.habitation}
            displayFields={data.fieldsFixed}
          />
          <div className='form-actions'>
            <div className='mr-auto'>
              <button className='btn btn-link' onClick={handleBackClick}>
                <FormattedMessage id='all.backButtonText' />
              </button>
            </div>
            <div className='ml-auto'>
              <button
                className='btn btn-primary'
                type='button'
                onClick={handleAfter}
              >
                <FormattedMessage id='all.nextButtonText' />
              </button>
            </div>
          </div>
        </div>
      )}
      {step === 2 && (
        <HabitationForm
          catalogs={catalogs}
          habitation={data.habitation}
          isUpdate={false}
          onSubmit={onSubmit}
          onSubmitAndNextPage={onSubmitAndNextPage}
          goBack={goBack}
        />
      )}
    </div>
  );
}

export default AddHabitationForm;

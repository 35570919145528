import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { ActionNotificationForm } from '../../../../components/actionNotification/ActionNotificationForm';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { createActionNotification } from '../../../../rest/templates';
import { handleError, isBusinessError } from '../../../../utils/handleError';

export function AddActionNotification() {
  const INITIAL_FORM_DATA = {
    code: '',
    description: '',
    systemOperational: false,
    templateEmailTitle: '',
    templateEmailBody: '',
    templateInternalNoteTitle: '',
    templateInternalNoteBody: '',
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [submited, setSubmited] = useState(false);

  const [actionNotification, setActionNotification] =
    useState(INITIAL_FORM_DATA);

  const create = async (actionNotification, setSubmitting, setErrors) => {
    try {
      await createActionNotification(actionNotification);

      setActionNotification(actionNotification);
      setSubmitting(false);
      setSubmited(true);
      setLoading(false);
    } catch (error) {
      setSubmitting(false);
      setError(error);
      setLoading(false);
    }
  };

  const onSubmit = (actionNotification, setSubmitting, setErrors) => {
    setLoading(true);
    create(actionNotification, setSubmitting, setErrors);
  };

  if (loading) {
    return <Loading />;
  }

  if (error && !isBusinessError(error)) {
    return handleError(error);
  }

  if (submited) {
    return <Redirect to={'/templates'} />;
  }

  return (
    <SubTemplate hasBackButton titleId={'template.add'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-8 offset-lg-2'}>
            <AlertError error={error} />
            <ActionNotificationForm
              actionNotification={actionNotification}
              isUpdate={false}
              onSubmit={onSubmit}
            />
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export function CandidatureFilterBar({ onSubmit }) {
  const intl = useIntl();

  const [activeFilter, setActiveFilter] = useState(true);

  useEffect(() => {
    let activeF = activeFilter === '-1' ? null : activeFilter;

    onSubmit(activeF);
  }, [activeFilter]);

  const handleOnChange = (e) => {
    e.preventDefault();

    setActiveFilter(e.target.value);
  };

  return (
    <form className={'p-0 border-0 mt-4'}>
      <div className={'form-inline'}>
        <div className={'form-group  big-input mr-3'}>
          <select
            className={'form-control rounded-0'}
            onChange={handleOnChange}
            value={activeFilter}
          >
            <option value={'-1'}>
              {intl.formatMessage({ id: 'filters.allCandidatures' })}
            </option>
            <option value={true}>
              {' '}
              {intl.formatMessage({ id: 'filters.activeCandidatures' })}
            </option>
            <option value={false}>
              {' '}
              {intl.formatMessage({ id: 'filters.deletedCandidatures' })}
            </option>
          </select>
        </div>
      </div>
    </form>
  );
}

import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { FaCaretDown, FaSearch } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { MessageDirection } from '../../models/MessageDirection';
import { MessageRegion } from '../../models/MessageRegion';
import { MessageState } from '../../models/MessageState';
import { MessageType } from '../../models/MessageType';
import { SubjectType } from '../../models/SubjectType';
import {
  TranslateMessageDirectionFilter,
  TranslateMessageStateFilter,
  TranslateMessageTypeFilter
} from '../../utils/TranslateStates';

function MessageFilterBar({
  isAdmin,
  defaultDirectionFilter,
  platforms,
  onSubmit,
}) {
  const intl = useIntl();

  const [nifFilter, setNifFilter] = useState('');
  const [keywordFilter, setKeywordFilter] = useState('');
  const [directionFilter, setDirectionFilter] = useState(
    defaultDirectionFilter
  );
  const [typeFilter, setTypeFilter] = useState(MessageType.INTERNAL);
  const [stateFilter, setStateFilter] = useState('-1');
  const [platformFilter, setPlatformFilter] = useState('-1');
  const [competitionFilter, setCompetitionFilter] = useState('');
  const [regionFilter, setRegionFilter] = useState('-1');

  const handleOnClick = (e) => {
    e.preventDefault();
    let typeF = typeFilter === '-1' ? null : typeFilter;
    let directionF = directionFilter === '-1' ? null : directionFilter;
    let stateF = stateFilter === '-1' ? null : stateFilter;
    let platformF = platformFilter === '-1' ? null : platformFilter;
    let regionF = regionFilter === '-1' ? null : regionFilter;

    let filters = {
      nif: isAdmin ? nifFilter : null,
      keyword: keywordFilter,
      type: typeF,
      direction: directionF,
      state: stateF,
      platform: platformF,
      parentExternalId: null,
      competitionNumber: competitionFilter,
      region: regionF,
    };

    onSubmit(filters, 0);
  };

  return (
    <form className={'p-0 border-0 mt-4'}>
      <div className={'form-inline'}>
        <div className={'form-group big-input mr-3'}>
          <input
            type='text'
            className={'form-control  rounded-0'}
            onChange={(e) => setKeywordFilter(e.target.value)}
            placeholder={intl.formatMessage({
              id: 'messages.keyword',
            })}
          />
        </div>

        <div className={'form-group  big-input mr-3'}>
          <select
            className={'form-control rounded-0'}
            onChange={(e) => setTypeFilter(e.target.value)}
            value={typeFilter}
          >
            <option value={'-1'}>
              {intl.formatMessage({
                id: 'messages.allTypeText',
              })}
            </option>
            {MessageType.values().map((type) => {
              return (
                <option key={type} value={type}>
                  {TranslateMessageTypeFilter(type)}
                </option>
              );
            })}
          </select>
        </div>
        <div className={'form-group big-input mr-3'}>
          <select
            className={'form-control  rounded-0'}
            onChange={(e) => setDirectionFilter(e.target.value)}
            value={directionFilter}
          >
            <option value={'-1'}>
              {intl.formatMessage({
                id: 'messages.allToText',
              })}
            </option>
            {MessageDirection.values().map((direction) => {
              return (
                <option key={direction} value={direction}>
                  {TranslateMessageDirectionFilter(direction)}
                </option>
              );
            })}
          </select>
        </div>

        <div className={'form-group'}>
          <button
            className={'btn btn-primary'}
            onClick={handleOnClick}
            type='submit'
          >
            <FaSearch className={'mx-0'} />
          </button>
        </div>
      </div>
      <div>
        <Accordion className={'mb-3'}>
          <Accordion.Toggle className={'btn-accordion mt-1 mb-2'} eventKey={1}>
            <FormattedMessage id='all.advancedSearch' />
            <FaCaretDown className='ml-2' />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={1}>
            <div className={'form-inline'}>
              {isAdmin && (
                <div className={'form-group big-input mr-3'}>
                  <input
                    type='text'
                    className={'form-control rounded-0'}
                    onChange={(e) => setNifFilter(e.target.value)}
                    placeholder={intl.formatMessage({
                      id: 'messages.nif',
                    })}
                  />
                </div>
              )}
              <div className={'form-group mr-3'}>
                <select
                  className={'form-control rounded-0'}
                  onChange={(e) => setStateFilter(e.target.value)}
                  value={stateFilter}
                >
                  <option value={'-1'}>
                    {intl.formatMessage({
                      id: 'messages.allStateText',
                    })}
                  </option>
                  {MessageState.values().map((state) => {
                    return (
                      <option key={state} value={state}>
                        {TranslateMessageStateFilter(state)}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className={'form-group mr-3'}>
                <select
                  className={'form-control rounded-0'}
                  onChange={(e) => setPlatformFilter(e.target.value)}
                >
                  <option value={'-1'}>
                    {intl.formatMessage({
                      id: 'messages.programSubjectText',
                    })}
                  </option>
                  {platforms.map((platform) => (
                    <option key={platform.code} value={platform.code}>
                      {platform.code}
                    </option>
                  ))}
                  <option value={SubjectType.GENERAL}>Geral</option>
                </select>
              </div>
              <div className={'form-group big-input mr-3'}>
                <input
                  type='text'
                  className={'form-control  rounded-0'}
                  onChange={(e) => setCompetitionFilter(e.target.value)}
                  placeholder={intl.formatMessage({
                    id: 'messages.competitionNumber',
                  })}
                />
              </div>
              <div className={'form-group big-input mr-3'}>
                <select
                  className={'form-control  rounded-0'}
                  onChange={(e) => setRegionFilter(e.target.value)}
                  value={regionFilter}
                >
                  <option value={'-1'}>
                    {intl.formatMessage({
                      id: 'messages.region',
                    })}
                  </option>
                  {MessageRegion.values().map((region) => {
                    return (
                      <option key={region} value={region}>
                        {intl.formatMessage({
                          id: `clarificationRequestForm.region_${region}`,
                        })}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </Accordion.Collapse>
        </Accordion>
      </div>
    </form>
  );
}

export default MessageFilterBar;

import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const convertToInteger = (value) => {
  if (value) {
    return parseInt(value);
  }
  return value;
};

export function IntInputField({
  controlId,
  labelId,
  label,
  name,
  handleChange,
  value,
  errorMessage,
  isInvalid,
}) {
  const onChange = (e) => {
    e.currentTarget.value = e.currentTarget.value.replace(/\D/g, '');
    e.currentTarget.value = convertToInteger(e.currentTarget.value);
    handleChange(e);
  };

  return (
    <Form.Group controlId={controlId}>
      <Form.Label>
        {labelId && <FormattedMessage id={labelId} />}
        {label && label}
      </Form.Label>
      <Form.Control
        type='text'
        name={name}
        value={value}
        onChange={onChange}
        isInvalid={isInvalid}
      />
      {errorMessage && (
        <Form.Control.Feedback type='invalid'>
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

export default IntInputField;

import React from 'react';
import { FaRegWindowClose, FaSortDown, FaSortUp } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { hasPermission } from '../../authentication/authenticationHelper';
import { useGlobalContext } from '../../context';
import { CandidatureState } from '../../models/CandidatureState';
import { Arrays } from '../../utils/Arrays';
import { candidatureDetailsLink } from '../../utils/LinkCreator';
import { TranslateCandidatureState } from '../../utils/TranslateStates';

export function CandidaturesTable({
  listCandidaturesMetaData,
  fetchDataWithSortFilters,
  sortFilters,
  setSortFilters,
}) {
  const { accessInfo } = useGlobalContext();
  const platformCode = listCandidaturesMetaData.platformCode;

  function onSortFilterChange(type) {
    if (sortFilters.length > 0) {
      const index = sortFilters.findIndex((sf) => sf.type === type);

      if (sortFilters.includes(sortFilters[index])) {
        sortFilters[index].value = getSortFilterValue(sortFilters[index].value);
      } else {
        sortFilters.push({ type: type, value: 'ASC' });
      }

      setSortFilters([...sortFilters]);
    } else {
      sortFilters.push({ type: type, value: 'ASC' });
      setSortFilters([...sortFilters]);
    }

    fetchDataWithSortFilters(sortFilters);
  }

  function removeSortFilter(type) {
    const index = sortFilters.findIndex((sf) => sf.type === type);

    sortFilters.splice(index, 1);

    setSortFilters([...sortFilters]);

    fetchDataWithSortFilters(sortFilters);
  }

  return (
    <table className='table'>
      <thead>
        <tr>
          <th>
            <label
              className='d-inline pointer'
              onClick={() => onSortFilterChange('code')}
            >
              <FormattedMessage
                id={`candidatures${
                  platformCode === 'EAA' ? platformCode : ''
                }.codeText`}
              />
              {!isFilterEmpty(sortFilters, 'code') && (
                <>
                  &nbsp;(
                  {getOrderFilterButton(sortFilters, 'code')}) &nbsp;
                </>
              )}
            </label>
            {!isFilterEmpty(sortFilters, 'code') &&
              getRemoveOrderFilterButton(removeSortFilter, 'code')}
          </th>
          <th>
            <label
              className='d-inline pointer'
              onClick={() => onSortFilterChange('name')}
            >
              <FormattedMessage id='candidatures.candidateHolderText' />
              {!isFilterEmpty(sortFilters, 'name') && (
                <>
                  &nbsp;(
                  {getOrderFilterButton(sortFilters, 'name')}) &nbsp;
                </>
              )}
            </label>
            {!isFilterEmpty(sortFilters, 'name') &&
              getRemoveOrderFilterButton(removeSortFilter, 'name')}
          </th>
          {platformCode === 'EAA' && (
            <th>
              <label
                className='d-inline pointer'
                onClick={() => onSortFilterChange('registrationDate')}
              >
                <FormattedMessage id='candidatures.requestDate' />
                {!isFilterEmpty(sortFilters, 'registrationDate') && (
                  <>
                    &nbsp;(
                    {getOrderFilterButton(sortFilters, 'registrationDate')})
                    &nbsp;
                  </>
                )}
              </label>
              {!isFilterEmpty(sortFilters, 'registrationDate') &&
                getRemoveOrderFilterButton(
                  removeSortFilter,
                  'registrationDate'
                )}
            </th>
          )}
          <th>
            <label>
              <FormattedMessage
                id={`candidatureUser${
                  platformCode === 'EAA' ? platformCode : ''
                }.candidatureStateText`}
              />
            </label>
          </th>
          {!isInStagingState(listCandidaturesMetaData.candidatures[0]) && (
            <th>
              <label
                className='d-inline pointer'
                onClick={() => onSortFilterChange('changeDate')}
              >
                <FormattedMessage id='candidatureUser.candidatureDateText' />
                {!isFilterEmpty(sortFilters, 'changeDate') && (
                  <>
                    &nbsp;({getOrderFilterButton(sortFilters, 'changeDate')})
                    &nbsp;
                  </>
                )}
              </label>
              {!isFilterEmpty(sortFilters, 'changeDate') &&
                getRemoveOrderFilterButton(removeSortFilter, 'changeDate')}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {listCandidaturesMetaData.candidatures.map((candidature) => (
          <tr key={candidature.externalId}>
            <td>
              {hasPermission('GET_CANDIDATURE', accessInfo) ? (
                <Link
                  to={candidatureDetailsLink(
                    listCandidaturesMetaData.platformCode,
                    listCandidaturesMetaData.competitionCode,
                    candidature.externalId
                  )}
                >
                  {candidature.code}_{listCandidaturesMetaData.competitionCode}
                </Link>
              ) : (
                candidature.code
              )}
            </td>
            <td>{candidature.candidateHolder?.name}</td>
            {platformCode === 'EAA' && <td>{candidature.registrationDate}</td>}
            <td>
              <TranslateCandidatureState state={candidature.state} />
            </td>
            {!isInStagingState(listCandidaturesMetaData.candidatures[0]) && (
              <td>{candidature.changeDate}</td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function isInStagingState(candidature) {
  return candidature.state === CandidatureState.STAGING;
}

function getSortFilterValue(value) {
  switch (value) {
    case '':
      return 'ASC';

    case 'ASC':
      return 'DESC';

    case 'DESC':
      return 'ASC';

    default:
      break;
  }
}

function isFilterEmpty(sortFilters, type) {
  return Arrays.isEmpty(sortFilters.filter((sf) => sf.type === type));
}

function getOrderFilterButton(sortFilters, type) {
  const index = sortFilters.findIndex((sf) => sf.type === type);

  const filter = sortFilters[index];

  if (filter.value === 'ASC') {
    return <FaSortUp />;
  } else if (filter.value === 'DESC') {
    return <FaSortDown />;
  } else {
    return <></>;
  }
}

function getRemoveOrderFilterButton(removeSortFilter, type) {
  return (
    <span onClick={() => removeSortFilter(type)} className='d-inline'>
      <FaRegWindowClose />
    </span>
  );
}

import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import DocumentsValidationAccording from '../../../../components/candidature/DocumentsValidationAccording';
import { AuthenticatedDownloadLink } from '../../../../components/document/AuthenticatedDownloadLink';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { createCustomErrorMessage } from '../../../../hooks/errorMessage';
import { DocumentTypeCode } from '../../../../models/DocumentTypeCode';
import {
  getFinalDocuments,
  validateFinalDocuments
} from '../../../../rest/candidatureuser';
import { Arrays } from '../../../../utils/Arrays';
import { handleError, isNotBusinessError } from '../../../../utils/handleError';

function getDocumentTypesByOption(documentTypes, option) {
  switch (option) {
    case 1:
      return documentTypes.filter(
        (documentType) => documentType.code === 'COMPR_SEG_LOCADO'
      );

    case 2:
      return documentTypes.filter(
        (documentType) =>
          documentType.code === 'DECL_DISP_SEGURO' ||
          documentType.code === 'COMP_CAUCAO'
      );

    default:
      return documentTypes.filter(
        (documentType) => documentType.code === 'COMP_SEG_RENDI'
      );
  }
}

function CandidatureValidateFinalDocuments() {
  const { externalId, platformCode, competitionCode } = useParams();

  const [loading, setLoading] = useState(true);
  const [isSubmited, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [finalDocuments, setFinalDocuments] = useState({});
  const [option, setOption] = useState(null);
  const history = useHistory();

  const intl = useIntl();

  const fetchData = async () => {
    setError(null);
    try {
      const [documentsResponse] = await Promise.all([
        getFinalDocuments(externalId),
      ]);

      setFinalDocuments(documentsResponse.data);
      setOption(documentsResponse.data.insuranceOption);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function validateDocuments(option, documents, setError) {
    let validations = [];
    let documentsNeeded = [];

    if (finalDocuments.insuranceOption == 'OPTION1') {
      documentsNeeded.push(DocumentTypeCode.COMPR_SEG_LOCADO);
    } else {
      documentsNeeded.push(DocumentTypeCode.DECL_DISP_SEGURO);
      documentsNeeded.push(DocumentTypeCode.COMP_CAUCAO);
    }
    documentsNeeded.push(DocumentTypeCode.COMP_SEG_RENDI);

    documentsNeeded.forEach((dN) => {
      if (
        finalDocuments.documentTypes.filter((dt) => dt.code === dN)[0]
          .validation !== 'YES'
      ) {
        validations.push(
          intl.formatMessage({ id: 'validation.documents.' + dN })
        );
      }
    });

    if (validations.length === 0) {
      return true;
    }

    setError(
      createCustomErrorMessage(
        intl.formatMessage(
          { id: 'validation.finalDocuments.needBeValid' },
          { documents: '<br/>' + validations.join('<br/>') }
        )
      )
    );
  }

  async function submitAllDocuments(e) {
    e.preventDefault();
    try {
      setLoading(true);

      const documentsAreValidated = validateDocuments(
        finalDocuments.insuranceOption,
        finalDocuments,
        setError
      );

      if (documentsAreValidated) {
        await validateFinalDocuments(externalId, finalDocuments);
        setSubmitted(true);
      }

      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  if (isSubmited) {
    history.replace(
      `/programas/${platformCode}/concursos/${encodeURIComponent(
        competitionCode
      )}/candidatura/${externalId}`
    );
  }

  const handleChangeGeneral = (e, documentType) => {
    const docValidation = finalDocuments.documentTypes.find(
      (docType) => docType.code === documentType
    );

    docValidation.validation = e.target.value;

    setFinalDocuments({ ...finalDocuments });
  };

  return (
    <SubTemplate
      hasBackButton
      titleId={'documents.validation.text'}
      titleValues={{
        candidatureCode: finalDocuments.candidatureCode,
        competitionCode: decodeURIComponent(competitionCode),
      }}
    >
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            <AlertError error={error} />
            <label className='mb-3'>
              <FormattedMessage id='paaInsurance.text' />
            </label>

            <legend className='mb-3'>
              <FormattedMessage id='candidature.finalDocuments.income.local.type' />
            </legend>

            {option === 'OPTION1' ? (
              <div>
                <div className='row'>
                  <div className='col-md-6'>
                    <label>
                      {
                        getDocumentTypesByOption(
                          finalDocuments.documentTypes,
                          1
                        )[0].description
                      }
                    </label>
                  </div>
                  <div className='col-md-6 text-right'>
                    <DocumentsValidationAccording
                      required={true}
                      documentType={
                        getDocumentTypesByOption(
                          finalDocuments.documentTypes,
                          1
                        )[0].code
                      }
                      handleChange={handleChangeGeneral}
                      memberCode={null}
                    />
                  </div>
                </div>

                <div className='form bg-white pb-3 mb-4'>
                  {!Arrays.isEmpty(
                    getDocumentTypesByOption(finalDocuments.documentTypes, 1)[0]
                      .documents
                  ) && (
                    <div className='row mt-3'>
                      <div className='col-lg-10'>
                        <span className='font-small'>
                          <FormattedMessage id='documents.fileName.text' />
                        </span>
                      </div>
                    </div>
                  )}
                  {!Arrays.isEmpty(
                    getDocumentTypesByOption(finalDocuments.documentTypes, 1)[0]
                      .documents
                  ) &&
                    getDocumentTypesByOption(
                      finalDocuments.documentTypes,
                      1
                    )[0].documents.map((document) => (
                      <div key={document.name} className='border-top py-2'>
                        <div className='row'>
                          <div className='col-lg-10'>
                            <AuthenticatedDownloadLink
                              url={document.path}
                              filename={document.name}
                            >
                              {document.name}
                            </AuthenticatedDownloadLink>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div>
                {getDocumentTypesByOption(finalDocuments.documentTypes, 2).map(
                  (documentType, index) => (
                    <div key={index}>
                      <div className='row'>
                        <div className='col-md-6'>
                          <label>{documentType.description}</label>
                        </div>
                        <div className='col-md-6 text-right'>
                          <DocumentsValidationAccording
                            required={true}
                            documentType={documentType.code}
                            handleChange={handleChangeGeneral}
                            memberCode={null}
                          />
                        </div>
                      </div>

                      <div className='form bg-white pb-3 mb-4'>
                        {!Arrays.isEmpty(documentType.documents) && (
                          <div className='row mt-3'>
                            <div className='col-lg-10'>
                              <span className='font-small'>
                                <FormattedMessage id='documents.fileName.text' />
                              </span>
                            </div>
                          </div>
                        )}
                        {!Arrays.isEmpty(documentType.documents) &&
                          documentType.documents.map((document) => (
                            <div
                              key={document.name}
                              className='border-top py-2'
                            >
                              <div className='row'>
                                <div className='col-lg-10'>
                                  <AuthenticatedDownloadLink
                                    url={document.path}
                                    filename={document.name}
                                  >
                                    {document.name}
                                  </AuthenticatedDownloadLink>
                                </div>
                                <div className='col-lg-2 text-right'></div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}

            <legend className='mb-3 mt-5'>
              <FormattedMessage id='candidature.finalDocuments.income.insurance.type' />
            </legend>
            <div>
              <div className='row'>
                <div className='col-md-6'>
                  <label>
                    {
                      getDocumentTypesByOption(finalDocuments.documentTypes)[0]
                        .description
                    }
                  </label>
                </div>

                <div className='col-md-6 text-right'>
                  <DocumentsValidationAccording
                    required={true}
                    documentType={
                      getDocumentTypesByOption(finalDocuments.documentTypes)[0]
                        .code
                    }
                    handleChange={handleChangeGeneral}
                    memberCode={null}
                  />
                </div>
              </div>
              <div className='form bg-white pb-3 mb-4'>
                {!Arrays.isEmpty(
                  getDocumentTypesByOption(finalDocuments.documentTypes)[0]
                    .documents
                ) && (
                  <div className='row'>
                    <div className='col-lg-10'>
                      <span className='font-small'>
                        <FormattedMessage id='documents.fileName.text' />
                      </span>
                    </div>
                  </div>
                )}
                {!Arrays.isEmpty(
                  getDocumentTypesByOption(finalDocuments.documentTypes)[0]
                    .documents
                ) &&
                  getDocumentTypesByOption(
                    finalDocuments.documentTypes
                  )[0].documents.map((document) => (
                    <div key={document.name} className='border-top py-2'>
                      <div className='row'>
                        <div className='col-lg-10'>
                          <AuthenticatedDownloadLink
                            url={document.path}
                            filename={document.name}
                          >
                            {document.name}
                          </AuthenticatedDownloadLink>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className='form-actions'>
              <div className='ml-auto'>
                <button
                  className='btn btn-primary'
                  onClick={(e) => submitAllDocuments(e)}
                >
                  <FormattedMessage id='all.submitButtonText' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SubTemplate>
  );
}

export default CandidatureValidateFinalDocuments;

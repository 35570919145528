import axios from 'axios';
import { apiAxios as authAxios } from '../axios.config';

export const getCandidatureUser = (active) => {
  return authAxios.get('api/candidature/loggedUser', {
    params: { active: active },
  });
};

export const getCandidatureCompetition = (
  competition,
  platformCode,
  candidatureNumber
) => {
  return authAxios.get(
    `api/platform/${platformCode}/competitions/${competition}/candidature/${candidatureNumber.replaceAll(
      '/',
      ''
    )}`
  );
};

export const addCandidature = (competitionCode, platformCode, candidature) => {
  return authAxios.post(
    `api/platform/${platformCode}/competitions/${competitionCode}/candidature`,
    candidature
  );
};

export const getCandidature = (competitionCode, platformCode) => {
  return authAxios.get(
    `/api/platform/${platformCode}/competitions/${competitionCode}/candidature`
  );
};

export const getCandidatureDocument = (candidatureExternalId) => {
  return authAxios.get(`/api/candidature/${candidatureExternalId}/documents`);
};

export const getCandidatureHistory = (candidatureExternalId) => {
  return authAxios.get(`/api/candidature/${candidatureExternalId}/history`);
};

export const getCandidatureByExternalId = (candidatureExternalId) => {
  return authAxios.get(`/api/candidature/${candidatureExternalId}`);
};

export const deleteCandidatureDocument = (candidatureExternalId, fileName) => {
  return authAxios.delete(
    `/api/candidature/${candidatureExternalId}/documents/${fileName}`
  );
};

export const deleteCandidature = (candidatureExternalId) => {
  return authAxios.delete(`/api/candidature/${candidatureExternalId}`);
};

export const addCandidatureDocument = (candidatureExternalId, formData) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/documents`,
    formData
  );
};

export const getCandidatureMemberDocument = (
  candidatureExternalId,
  memberCode
) => {
  return authAxios.get(
    `/api/candidature/${candidatureExternalId}/members/${memberCode}/documents`
  );
};

export const addCandidatureMemberDocument = (
  candidatureExternalId,
  memberCode,
  formData
) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/members/${memberCode}/documents`,
    formData
  );
};

export const deleteCandidatureMemberDocument = (
  candidatureExternalId,
  memberCode,
  fileName
) => {
  return authAxios.delete(
    `/api/candidature/${candidatureExternalId}/members/${memberCode}/documents/${fileName}`
  );
};

export const addCandidatureDocumentAll = (externalId) => {
  return authAxios.put(`/api/candidature/${externalId}/submitDocuments`);
};

export const addFinalDocumentsToCandidature = (externalId) => {
  return authAxios.put(`/api/candidature/${externalId}/submitFinalDocuments`);
};

export const deliverLaterInsurance = (externalId) => {
  return authAxios.post(`/api/candidature/${externalId}/deliveryLater`);
};

export const sendMessageCandidature = (candidatureExternalId, formData) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/sendMessage`,
    formData
  );
};

export const validateDocumentsCandidature = (
  candidatureExternalId,
  formData
) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/validateDocuments`,
    formData
  );
};

export const giveMoreTime = (candidatureExternalId, formData) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/giveMoreTime`,
    formData
  );
};

export const bookAppointment = (candidatureExternalId, bookAppointmentBody) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/appointment`,
    bookAppointmentBody
  );
};

export const deliveryKeys = (candidatureExternalId) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/deliveryKeys`
  );
};

export const askDocumentsAgain = (
  candidatureExternalId,
  askDocumentsAgainBody
) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/returnToSubmitFinalDocuments`,
    askDocumentsAgainBody
  );
};

export const getCanExclude = (candidatureExternalId) => {
  return axios.get(`/api/candidature/${candidatureExternalId}/canExclude`);
};

export const getCanResign = (candidatureExternalId) => {
  return axios.get(`/api/candidature/${candidatureExternalId}/canResign`);
};

export const getHasMemberOnOtherCompetition = (candidatureExternalId) => {
  return axios.get(
    `/api/candidature/${candidatureExternalId}/hasMemberOnOtherCompetition`
  );
};

export const excludeCandidature = (candidatureExternalId, reason) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/exclude`,
    reason
  );
};

export const resignCandidature = (candidatureExternalId, reason) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/resign`,
    reason
  );
};

export const validateElegibilityContractCandidature = (
  candidatureExternalId,
  answer
) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/validateContractsActives`,
    answer
  );
};

export const getFinalDocuments = (candidatureExternalId) => {
  return authAxios.get(
    `/api/candidature/${candidatureExternalId}/finalDocuments`
  );
};

export const validateFinalDocuments = (
  candidatureExternalId,
  finalDocuments
) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/validateFinalDocuments`,
    finalDocuments
  );
};

export const getCanDeliveryLater = (candidatureExternalId) => {
  return authAxios.get(
    `/api/candidature/${candidatureExternalId}/canDeliveryLater`
  );
};

export const changeInsuranceOption = (candidatureExternalId, option) => {
  return authAxios.put(
    `/api/candidature/${candidatureExternalId}/changeInsuranceOption`,
    option
  );
};

/**
 * Search documents for a candidature
 *
 * @param  {String} candidatureExternalId
 * @param  {String} documentTypeCode
 * @param  {String} nif
 * @returns  {Promise<AxiosResponse<{name: String, description: String, type: String, path: String, creationDate: String}>>}
 */
export const searchCandidatureDocuments = (
  candidatureExternalId,
  documentTypeCode,
  nif
) => {
  return authAxios.get(
    `/api/candidature/${candidatureExternalId}/documents/search`,
    {
      params: {
        nif: nif,
        type: documentTypeCode,
      },
    }
  );
};

/**
 * @param  {String} candidatureExternalId
 * @returns {Promise<AxiosResponse<>>}
 */
export const sendToWaitContestation = (candidatureExternalId) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/sendToWaitContestation`
  );
};

export const celebrateContract = (candidatureExternalId) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/celebrateContract`
  );
};

export const sendVisitDocument = (candidatureExternalId) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/sendVisitDocument`
  );
};

export const sendAnalyzeOk = (candidatureExternalId) => {
  return authAxios.post(`/api/candidature/${candidatureExternalId}/analyzeOk`);
};

export const sendContestation = (candidatureExternalId, contestation) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/contestation`,
    contestation
  );
};

/**
 * @param  {String} candidatureExternalId
 * @param  {String} reason
 * @returns {Promise<AxiosResponse<CandidatureFromPlatform>>}
 */
export const askMoreTime = (candidatureExternalId, reason) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/askMoreTime`,
    reason
  );
};

/**
 * @param  {String} candidatureExternalId
 * @param  {String} reason
 * @returns {Promise<AxiosResponse<CandidatureFromPlatform>>}
 */
export const rejectExtensionDeadline = (candidatureExternalId, reason) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/rejectExtensionDeadline`,
    reason
  );
};

/**
 * @param  {String} candidatureExternalId
 * @returns {Promise<AxiosResponse<>>}
 */
export const getReasonAskTime = (candidatureExternalId) => {
  return authAxios.get(
    `/api/candidature/${candidatureExternalId}/reasonAskTime`
  );
};

export const confirmChosenHabitation = (
  candidatureExternalId,
  optionChosen
) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/confirmChosenHabitation`,
    optionChosen
  );
};

export const confirmWithdrawal = (candidatureExternalId, optionChosen) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/confirmWithdrawal`,
    optionChosen
  );
};

export const authorizeCandidature = (candidatureExternalId, dataToSend) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/authorizeCandidature`,
    dataToSend
  );
};

export const finalizeAnalysis = (candidatureExternalId, scoreDetails) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/finalizeAnalysis`,
    scoreDetails
  );
};

export const confirmFinalDecision = (candidatureExternalId, body) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/confirmFinalDecision`,
    body
  );
};

export const validateOtherContracts = (candidatureExternalId, optionChosen) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/validateOtherContracts`,
    optionChosen
  );
};

export const confirmRegisteredContract = (candidatureExternalId, body) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/confirmRegisteredContract`,
    body
  );
};

export const rescheduleContractCelebrationAndKeyDelivery = (
  candidatureExternalId,
  body
) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/rescheduleContractCelebrationAndKeyDelivery`,
    body
  );
};

export const suspendCandidature = (candidatureExternalId) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/suspendCandidature`
  );
};

export const getCandidatureScores = (candidatureExternalId) => {
  return authAxios.get(`/api/candidature/${candidatureExternalId}/scores`);
};

export const changeCandidatureState = (candidatureExternalId, body) => {
  return authAxios.post(
    `/api/candidature/${candidatureExternalId}/changeCandidatureState`,
    body
  );
};

import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

const isEditable = (name, editableFields) => {
  return editableFields.includes(name);
};

const createDateValidation = (validation, name, editableFields) => {
  return isEditable(name, editableFields) ? validation : yup.date();
};

const createMinValidation = (name, fieldTranslation) => {
  return yup
    .date()
    .min(
      yup.ref(name),
      <FormattedMessage
        id='validation.dateBefore.text'
        values={{ value: fieldTranslation }}
      />
    );
};

export const createSchema = (editableFields) => {
  const publishDeadline = createDateValidation(
    yup
      .date()
      .min(
        new Date(),
        <FormattedMessage
          id='validation.dateBefore.text'
          values={{ value: <FormattedMessage id='validation.dateNow.text' /> }}
        />
      ),
    'publishDeadline',
    editableFields
  );

  return yup.object().shape({
    publishDeadline,
    startRegistrationDate: createDateValidation(
      createMinValidation(
        'publishDeadline',
        <FormattedMessage id='competitionForm.competitionPublishDeadlineText' />
      ),
      'startRegistrationDate',
      editableFields
    ),

    endRegistrationDate: createDateValidation(
      createMinValidation(
        'startRegistrationDate',
        <FormattedMessage id='competitionForm.competitionStartRegistrationDateText' />
      ),
      'endRegistrationDate',
      editableFields
    ),
    publicationDateAcceptedApps: createDateValidation(
      createMinValidation(
        'endRegistrationDate',
        <FormattedMessage id='competitionForm.competitionEndRegistrationDateText' />
      ),
      'publicationDateAcceptedApps',
      editableFields
    ),

    drawDate: createDateValidation(
      createMinValidation(
        'publicationDateAcceptedApps',
        <FormattedMessage id='competitionForm.competitionPublicationDateAcceptedAppsText' />
      ),
      'drawDate',
      editableFields
    ),

    publicationDateCompResult: createDateValidation(
      createMinValidation(
        'drawDate',
        <FormattedMessage id='competitionForm.competitionDrawDateText' />
      ),
      'publicationDateCompResult',
      editableFields
    ),

    conclusionDate: createDateValidation(
      createMinValidation(
        'publicationDateCompResult',
        <FormattedMessage id='competitionForm.competitionPublicationDateCompResultText' />
      ),
      'conclusionDate',
      editableFields
    ),
  });
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CandidatureState } from '../../models/CandidatureState';
import { Arrays } from '../../utils/Arrays';
import { EmptyResults } from '../EmptyResults';

export const DocumentTable = ({
  documents,
  showDelete,
  onDelete,
  handleFileDownload,
  candidatureState
}) => {
  if (Arrays.isEmpty(documents) || candidatureState === CandidatureState.ASSIGNED) {
    return <EmptyResults />;
  }

  return (
    <table className='table'>
      <thead>
        <tr>
          <th>
            <FormattedMessage id='document.table.header.type' />
          </th>
          <th>
            <FormattedMessage id='document.table.header.name' />
          </th>
          <th>
            <FormattedMessage id='document.table.header.date' />
          </th>

          {showDelete && <th></th>}
        </tr>
      </thead>
      <tbody>
        {documents.map((doc) => (
          <tr key={doc.name}>
            <td>{doc.type}</td>
            <td>
              {handleFileDownload ? (
                <button
                  className='btn-link'
                  onClick={() => handleFileDownload(doc.path, doc.name)}
                >
                  {doc.description}
                </button>
              ) : (
                <a href={doc.path}>{doc.description}</a>
              )}
            </td>
            <td>{doc.creationDate}</td>
            {showDelete && (
              <td>
                <button
                  className='btn btn-outline-secondary'
                  onClick={() => onDelete(doc.name)}
                >
                  <FormattedMessage id='all.deleteText' />
                </button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

import dateFormat from 'date-fns/format';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import { FormattedMessage } from 'react-intl';
import { ConfigObject } from '../../config';

export function DateTimeInputField({
  name,
  value,
  errorMessage,
  setFieldValue,
  isInvalid,
  disabled,
  labelId,
  isHoliday,
}) {
  const pattern = ConfigObject.get().DATE_TIME_PATTERN;
  const holidayPattern = ConfigObject.get().HOLIDAY_PATTERN;

  const [date, setDate] = useState(
    typeof value === 'object' ? value : new Date(value)
  );

  const handleChange = (e) => {
    setDate(e);
    if (e) {
      setFieldValue(name, dateFormat(e, isHoliday ? holidayPattern : pattern));
    } else {
      setFieldValue(name, '');
    }
  };

  return (
    <Form.Group controlId={name}>
      <Form.Label>
        <FormattedMessage id={labelId} />
      </Form.Label>
      <Form.Control
        className='datetimepicker'
        as={DateTimePicker}
        format={isHoliday ? holidayPattern : pattern}
        clearIcon={null}
        value={date}
        onChange={handleChange}
        isInvalid={isInvalid}
        disabled={disabled}
      ></Form.Control>
      <Form.Control.Feedback type='invalid'>
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

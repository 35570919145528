import React from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { UploadDocumentType } from "../../../../components/candidature/UploadDocumentType";
import { createCustomErrorMessage } from "../../../../hooks/errorMessage";
import { Arrays } from "../../../../utils/Arrays";

function CandidatureFormSubmitDocuments({
  candidatureDocuments,
  handleAfter,
  handleBack,
  documents,
  setDocuments,
  error,
  setError,
}) {
  const intl = useIntl();

  const handleSubmitDocument = (
    file,
    description,
    fileType,
    memberCode,
    setSubmitting,
    setErrors,
    setFileName,
    setFieldValue,
    setTouched
  ) => {
    const newFile = {
      content: file,
      fileName: file.name,
      description,
      type: fileType,
      memberNif: memberCode,
    };
    documents.push(newFile);
    setDocuments([...documents]);
    setSubmitting(false);
    setFileName("");
    setFieldValue("file", null);
    setTouched("file", false);
  };

  const handleDeleteDocument = (index) => {
    const fs = documents;
    const sfs = setDocuments;
    return () => {
      fs.splice(index, 1);
      sfs([...fs]);
    };
  };

  const validate = () => {
    const generalDocumentsPresent = candidatureDocuments.generalDocuments
      .filter((genDoc) => genDoc.required)
      .every((genDoc) => documents.some((doc) => doc.type === genDoc.code));

    const memberDocumentsPresent = candidatureDocuments.memberDocuments.every(
      (membDoc) =>
        membDoc.documentTypes.every(
          (docType) =>
            !docType.required ||
            (docType.required &&
              documents.some(
                (doc) =>
                  doc.type === docType.code && doc.memberNif === membDoc.nif
              ))
        )
    );

    if (generalDocumentsPresent && memberDocumentsPresent) {
      handleAfter();
    } else {
      setError(
        createCustomErrorMessage(
          intl.formatMessage({ id: "errors.submitAllDocuments.text" })
        )
      );
    }
  };

  return (
    <>
      <div className={"form"}>
        <h5 className="mb-4 pb-2 border-bottom">
          <FormattedMessage id="candidature.pas.step2of5Text" />
        </h5>

        {candidatureDocuments.generalDocuments.map((documentType, index) => (
          <div key={index}>
            <label>
              {documentType.description}
              {documentType.required && <span className={"required"}>*</span>}
            </label>
            <div className="form bg-white pb-3 mb-4">
              <UploadDocumentType
                onSubmit={handleSubmitDocument}
                fileType={documentType.code}
                description={documentType.description}
                memberCode={null}
                acceptExtensions=".pdf,.docx,.jpg,.jpeg,.png"
              />
              {!Arrays.isEmpty(documentType.documents) && (
                <div className="row mt-3">
                  <div className="col-lg-10">
                    <span className="font-small">
                      <FormattedMessage id="documents.fileName.text" />
                    </span>
                  </div>
                </div>
              )}
              {documents.map(
                (file, index) =>
                  documentType.code === file.type &&
                  file.memberNif === null && (
                    <div key={index} className="border-top py-2">
                      <div className="d-flex justify-content-between">
                        <div>
                          {documentType.description}
                          <span className="mx-2">·</span>
                          <span>{file.fileName}</span>
                        </div>
                        <div>
                          <button
                            className="btn btn-link p-0"
                            type="button"
                            onClick={handleDeleteDocument(index)}
                          >
                            <FormattedMessage id="all.deleteText" />
                          </button>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        ))}

        {candidatureDocuments.memberDocuments.map((memberDocument, index) => (
          <div key={index}>
            {Arrays.isNotEmpty(memberDocument.documentTypes) && (
              <legend className="mb-3 mt-5">
                <FormattedMessage id="documents.candidature.memberDocument" />{" "}
                {memberDocument.name}
              </legend>
            )}
            {memberDocument.documentTypes.map((documentType, index) => (
              <div key={index}>
                <label>
                  {documentType.description}
                  {documentType.required && (
                    <span className={"required"}>*</span>
                  )}
                </label>
                <div className="form bg-white pb-3 mb-4">
                  <UploadDocumentType
                    onSubmit={handleSubmitDocument}
                    fileType={documentType.code}
                    description={documentType.description}
                    memberCode={memberDocument.nif}
                    acceptExtensions=".pdf,.docx,.jpg,.jpeg,.png"
                  />
                  {!Arrays.isEmpty(documentType.documents) && (
                    <div className="row mt-3">
                      <div className="col-lg-10">
                        <span className="font-small">
                          <FormattedMessage id="documents.fileName.text" />
                        </span>
                      </div>
                    </div>
                  )}
                  {documents.map(
                    (file, index) =>
                      documentType.code === file.type &&
                      memberDocument.nif === file.memberNif && (
                        <div key={index} className="border-top py-2">
                          <div className="d-flex justify-content-between">
                            <div>
                              {documentType.description}
                              <span className="mx-2">·</span>
                              <span>{file.fileName}</span>
                            </div>
                            <div>
                              <button
                                className="btn btn-link p-0"
                                type="button"
                                onClick={handleDeleteDocument(index)}
                              >
                                <FormattedMessage id="all.deleteText" />
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="form-actions">
        <div className="mr-auto">
          <Link onClick={handleBack} className="btn btn-link">
            <FormattedMessage id="all.backButtonText" />
          </Link>
        </div>
        <div className="ml-auto">
          <Button variant="primary" onClick={validate}>
            <FormattedMessage id="all.nextButtonText" />
          </Button>
        </div>
      </div>
    </>
  );
}

export default CandidatureFormSubmitDocuments;

import { Modal } from 'react-bootstrap';
import parse from "html-react-parser";

export function GenericInformationDialog({
  show,
  handleClose,
  title,
  body,
                                             confirmButtonMessage
}) {
  if (!show) {
    return <></>;
  } else {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
              {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { parse(body)}
        </Modal.Body>
        <Modal.Footer style={{
            display: "flex",
            justifyContent: "center",
        }}>
          <button className={'btn btn-outline-primary'} onClick={handleClose}>
              {confirmButtonMessage}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { FaDownload } from 'react-icons/fa';
import { CandidatureState } from '../../models/CandidatureState';

export function DocumentFilterBar({
  isCandidatureDocuments,
  numberOfDocuments,
  documentTypes,
  defaultFilter,
  onFilter,
  handleFileDownload,
  externalId,
  candidatureCode,
  candidatureState,
}) {
  const intl = useIntl();

  const [documentTypeFilter, setDocumentTypeFilter] = useState(
    defaultFilter ? defaultFilter : '-1'
  );

  const handleChange = (e) => {
    let documentTypeF = e.target.value === '-1' ? null : e.target.value;
    onFilter(documentTypeF);
    setDocumentTypeFilter(documentTypeF);
  };

  return (
    <div className='row'>
      <div className='col-sm-6'>
        <form className={'p-0 border-0'}>
          <div className={'form-inline'}>
            <div className={'form-group  big-input mr-3'}>
              <select
                className={'form-control rounded-0'}
                onChange={handleChange}
                value={documentTypeFilter}
              >
                <option value={'-1'}>
                  {intl.formatMessage({
                    id: 'messages.allTypeText',
                  })}
                </option>
                {documentTypes.map((type) => {
                  return (
                    <option key={type.code} value={type.code}>
                      {type.description}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </form>
      </div>
      {isCandidatureDocuments &&
        numberOfDocuments > 0 &&
        candidatureState !== CandidatureState.ASSIGNED && (
          <div className='col-sm-6 text-right'>
            <button
              className='btn btn-outline-primary'
              onClick={() =>
                handleFileDownload(
                  `/api/candidature/${externalId}/documents/all`,
                  `documentos_candidatura_${candidatureCode}.zip`
                )
              }
            >
              <FaDownload />
              <FormattedMessage id='documents.downloadAll' />
            </button>
          </div>
        )}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import {
  FaCheck,
  FaCheckCircle,
  FaEye,
  FaPrint,
  FaRegEnvelope,
  FaRegFileAlt,
  FaTimes
} from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  hasPermission,
  isCandidate,
  isIhruUser
} from '../../../../authentication/authenticationHelper';
import { AlertError } from '../../../../components/bootstrap/AlertError';
import { DeleteDialog } from '../../../../components/bootstrap/DeleteDialog';
import { TextAreaDialog } from '../../../../components/bootstrap/TextAreaDialog';
import { AskDocumentsAgainDialog } from '../../../../components/candidature/AskDocumentsAgainDialog';
import { AuthorizeCandidatureDialog } from '../../../../components/candidature/AuthorizeCandidatureDialog';
import { BookAppointmentDialog } from '../../../../components/candidature/BookAppointmentDialog';
import { CandidatureDecisionDialog } from '../../../../components/candidature/CandidatureDecisionDialog';
import { CandidatureDetailsTitle } from '../../../../components/candidature/CandidatureDetailsTitle';
import { ChangeCandidatureStateDialog } from '../../../../components/candidature/ChangeCandidatureStateDialog';
import { ChooseAssignedHabitationDialog } from '../../../../components/candidature/ChooseAssignedHabitationDialog';
import { ConfirmWithdrawalDialog } from '../../../../components/candidature/ConfirmWithdrawalDialog';
import DateSubmissionDocumentsModal from '../../../../components/candidature/DateSubmissionDocumentsModal';
import { DeliveryKeysDialog } from '../../../../components/candidature/DeliveryKeysDialog';
import DialogMessageCandidature from '../../../../components/candidature/DialogMessageCandidature';
import { ElegibilityValidationDialog } from '../../../../components/candidature/ElegibilityValidationDialog';
import { ExcludeDialog } from '../../../../components/candidature/ExcludeDialog';
import { ListCandidatureDetails } from '../../../../components/candidature/ListCandidatureDetails';
import { ScheduleContractAndKeyDeliveryDialog } from '../../../../components/candidature/ScheduleContractAndKeyDeliveryDialog';
import { ScoreDetailsTable } from '../../../../components/candidature/ScoreDetailsTable';
import { ValidateOtherContractsDialog } from '../../../../components/candidature/ValidateOtherContractsDialog';
import Loading from '../../../../components/Loading';
import { SubTemplate } from '../../../../components/SubTemplate';
import { TermsAndConditions } from '../../../../components/TermsAndConditions';
import { useGlobalContext } from '../../../../context';
import { createCustomErrorMessage } from '../../../../hooks/errorMessage';
import { useQuery } from '../../../../hooks/useQuery';
import { CandidatureState } from '../../../../models/CandidatureState';
import { CompetitionType } from '../../../../models/CompetitionType';
import {
  askDocumentsAgain,
  askMoreTime,
  authorizeCandidature,
  bookAppointment,
  changeCandidatureState,
  confirmChosenHabitation,
  confirmFinalDecision,
  confirmRegisteredContract,
  confirmWithdrawal,
  deleteCandidature,
  deliveryKeys,
  excludeCandidature,
  finalizeAnalysis,
  getCandidatureByExternalId,
  getCanExclude,
  getCanResign,
  getHasMemberOnOtherCompetition,
  getReasonAskTime,
  giveMoreTime,
  rejectExtensionDeadline,
  rescheduleContractCelebrationAndKeyDelivery,
  resignCandidature,
  sendMessageCandidature,
  suspendCandidature,
  validateElegibilityContractCandidature,
  validateOtherContracts
} from '../../../../rest/candidatureuser';
import { getAllowActions } from '../../../../rest/competition';
import { getPlatform } from '../../../../rest/platform';
import { getActionNotificationByCandidatureState } from '../../../../rest/templates';
import { handleError, isNotBusinessError } from '../../../../utils/handleError';
import {
  analyzeOkLink,
  celebrateManagerLink,
  verdictManagerLink
} from '../../../../utils/LinkCreator';
import { PlatformType } from '../../../../models/PlatformType';

const hasCode = (query) => {
  return query && query.get('submission');
};

const hasDocSubmited = (query) => {
  return query && query.get('docSubmited');
};

const hasDocSubmitLater = (query) => {
  return query && query.get('docSubmitLater');
};

const hasDocValidation = (query) => {
  return query && query.get('docValidation');
};

const hasNewDate = (query) => {
  return query && query.get('newDate');
};

const allowedToDelete = (candidature, allowedActions) => {
  return (
    candidature?.candidatureDto?.state !== CandidatureState.DELETED &&
    allowedActions?.canCancelCandidature
  );
};

const allowedToExclude = (canExclude) => {
  return canExclude?.flag;
};

const allowedToResign = (canResign) => {
  return canResign?.flag;
};

const allowedToValidateElegibiltyContract = (hasMemberOnOtherCompetition) => {
  return !hasMemberOnOtherCompetition?.flag;
};

const candidatureStateMatches = (candidature, state) => {
  return candidature.candidatureDto.state === state;
};

function ViewUserCandidature() {
  const { platformCode } = useParams();
  const { competitionCode } = useParams();
  const { externalId } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [candidature, setCandidature] = useState({});
  const [candidatureScoreDetails, setCandidatureScoreDetails] = useState({});
  const [defaultScoreDetails, setDefaultScoreDetails] = useState({});
  const [show, setShow] = useState(false);
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [showBookAppointmentDialog, setShowBookAppointmentDialog] = useState(false);
  const [showAskDocumentsAgainDialog, setShowAskDocumentsAgainDialog] = useState(false);
  const [showDeliveryKeysDialog, setShowDeliveryKeysDialog] = useState(false);
  const [showExcludeDialog, setShowExcludeDialog] = useState(false);
  const [showResignDialog, setShowResignDialog] = useState(false);
  const [showElegibilityValidationDialog, setShowElegibilityValidationDialog] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [showRequestExtensionSubmissionDateDialog, setShowRequestExtensionSubmissionDateDialog] =
    useState(false);
  const [showRejecExtensionSubmissionDateDialog, setShowRejecExtensionSubmissionDateDialog] =
    useState(false);
  const [showAcceptExtensionDeadlineRequestDialog, setShowAcceptExtensionDeadlineRequestDialog] =
    useState(false);

  const [showChooseAssignedHabitationsDialog, setShowChooseAssignedHabitationsDialog] =
    useState(false);

  const [showConfirmsWithdrawalDialog, setShowConfirmsWithdrawalDialog] = useState(false);

  const [showAuthorizeCandidatureDialog, setShowAuthorizeCandidatureDialog] = useState(false);

  const [showCandidatureDecisionDialog, setShowCandidatureDecisionDialog] = useState(false);

  const [showCorrectScoresModal, setShowCorrectScoresModal] = useState(false);

  const [changeCandidatureStateDialog, setChangeCandidatureStateDialog] = useState(false);

  const [showValidateOtherContractsDialog, setShowValidateOtherContractsDialog] = useState(false);

  const [scheduleContractAndKeyDeliveryDialog, setScheduleContractAndKeyDeliveryDialog] =
    useState(false);

  const [correctedFields, setCorrectedFields] = useState(DEFAULT_CORRECTED_FIELDS);

  const [errors, setErrors] = useState(DEFAULT_ERRORS);

  const [successMessage, setSuccessMessage] = useState('');
  const [allowedActions, setAllowedActions] = useState(false);
  const [canExclude, setCanExclude] = useState(false);
  const [canResign, setCanResign] = useState(false);
  const [hasMemberOnOtherCompetition, sethasMemberOnOtherCompetition] = useState(false);
  const [platform, setPlatform] = useState(null);
  const [askMoreTimeReason, setAskMoreTimeReason] = useState(null);
  const [actionNotification, setActionNotification] = useState({});
  const query = useQuery();
  const history = useHistory();
  const intl = useIntl();

  const { accessInfo } = useGlobalContext();

  useEffect(() => {
    async function fetchData() {
      try {
        let [
          { data: candidature },
          { data: allowedActions },
          { data: canExclude },
          { data: canResign },
          { data: hasMemberOnOtherCompetition },
          { data: platform }
        ] = await Promise.all([
          getCandidatureByExternalId(externalId),
          getAllowActions(competitionCode, platformCode),
          getCanExclude(externalId),
          getCanResign(externalId),
          getHasMemberOnOtherCompetition(externalId),
          getPlatform(platformCode)
        ]);

        const { data: actionNotification } = await getActionNotificationByCandidatureState(
          candidature.candidatureDto.state,
          candidature.candidatureDto.competitionType
        );

        setActionNotification(actionNotification);
        if (
          candidatureStateMatches(candidature, CandidatureState.ASK_MORE_TIME) &&
          isIhruUser(accessInfo)
        ) {
          const askMoreTimeReasonResponse = await getReasonAskTime(externalId);
          setAskMoreTimeReason(askMoreTimeReasonResponse.data);
        }

        if (platformCode === PlatformType.PAS) {
          candidature.candidatureDto.singleParentFamily = candidature.candidatureDto.singleParentFamily.toString();
        }

        setCandidature(candidature);
        if (candidature.candidatureDto.competitionType === CompetitionType.SERIALIZATION) {
          setCandidatureScoreDetails(candidature.candidatureDto.candidatureEAA.scoreDetails);
          setDefaultScoreDetails(candidature.candidatureDto.candidatureEAA.scoreDetails);
        }
        setAllowedActions(allowedActions);
        setCanExclude(canExclude);
        setCanResign(canResign);
        sethasMemberOnOtherCompetition(hasMemberOnOtherCompetition);
        setPlatform(platform);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error);
        setLoading(false);
      }
    }
    fetchData();
    if (hasCode(query) === 'true') {
      setShow(true);
      setSuccessMessage('candidature.successText');
    }

    if (hasDocSubmited(query) === 'true') {
      setShow(true);
      setSuccessMessage('candidature.docsSuccessText');
    }

    if (hasDocSubmitLater(query) === 'true') {
      setShow(true);
      setSuccessMessage('candidature.docsDeliveryLaterText');
    }

    if (hasDocValidation(query) === 'true') {
      setShow(true);
      setSuccessMessage('candidature.docValidationText');
    }

    if (hasNewDate(query) === 'true') {
      setShow(true);
      setSuccessMessage('candidature.newDateText');
    }
  }, [competitionCode]);

  const handlePrint = () => {
    window.print();
  };

  const handleSendMessage = async (values, setSubmitting) => {
    try {
      await sendMessageCandidature(externalId, values);
      setSuccessMessage('messages.success');
      setSubmitting(false);
      setShowMessageDialog(false);
      setShow(true);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const cancelCandidature = async () => {
    try {
      await deleteCandidature(externalId);
      candidature.candidatureDto.state = CandidatureState.DELETED;
      setCandidature({ ...candidature });
      setSuccessMessage('candidature.cancel.success.message');
      setShow(true);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const onConfirmDeleteCandidature = () => {
    setShowDialog(false);
    setLoading(true);
    cancelCandidature();
  };

  const handleCloseMessageModal = () => {
    setShowMessageDialog(false);
  };

  const onClose = () => {
    setShowDialog(false);
  };

  const handleCloseExcludeDialog = () => {
    setShowExcludeDialog(false);
  };

  const handleCloseResignDialog = () => {
    setShowResignDialog(false);
  };

  const handleCloseElegibilityValidationDialog = () => {
    setShowElegibilityValidationDialog(false);
  };

  const onClickCancelCandidature = async () => {
    setShowDialog(true);
  };

  const onClickExcludeCandidature = async () => {
    setShowExcludeDialog(true);
  };

  const onClickResignCandidature = async () => {
    setShowResignDialog(true);
  };

  const onClickValidateElegibility = async () => {
    setShowElegibilityValidationDialog(true);
  };

  const handleCloseBookAppointmentDialog = async () => {
    setShowBookAppointmentDialog(false);
  };

  const handleCloseAskDocumentsAgainDialog = async () => {
    setShowAskDocumentsAgainDialog(false);
  };

  const handleCloseDeliveryKeysDialog = async () => {
    setShowDeliveryKeysDialog(false);
  };

  const handleConfirmBookAppointment = async (bookAppointmentBody) => {
    setShowBookAppointmentDialog(false);
    setLoading(true);
    let dataToSend = {
      date: bookAppointmentBody.date,
      title: '',
      body: ''
    };
    try {
      const { data } = await bookAppointment(candidature.candidatureDto.externalId, dataToSend);
      setCandidature(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmAskDocumentsAgain = async (askDocumentsAgainBody) => {
    setShowAskDocumentsAgainDialog(false);
    setLoading(true);
    try {
      const { data } = await askDocumentsAgain(
        candidature.candidatureDto.externalId,
        askDocumentsAgainBody
      );
      setCandidature(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmElegibilityValidation = async (answer) => {
    setShowElegibilityValidationDialog(false);
    setLoading(true);
    try {
      const { data } = await validateElegibilityContractCandidature(
        candidature.candidatureDto.externalId,
        answer
      );
      setCandidature(data);
      sethasMemberOnOtherCompetition(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmExcludeCandidature = async (reason) => {
    setShowExcludeDialog(false);
    setLoading(true);
    try {
      const { data } = await excludeCandidature(candidature.candidatureDto.externalId, reason);
      setCandidature(data);
      setCanExclude(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmResignCandidature = async (reason) => {
    setShowResignDialog(false);
    setLoading(true);
    try {
      const { data } = await resignCandidature(candidature.candidatureDto.externalId, reason);
      setCandidature(data);
      setCanResign(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmDeliveryKeys = async () => {
    setShowDeliveryKeysDialog(false);
    setLoading(true);
    try {
      const { data } = await deliveryKeys(candidature.candidatureDto.externalId);
      setCandidature(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const requestExtensionSubmissionDate = async (reason) => {
    try {
      const requestExtensionResponse = await askMoreTime(externalId, reason);
      setCandidature(requestExtensionResponse.data);
      setSuccessMessage('request.extension.date.success.message');
      setShow(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRequestExtensionSubmissionDate = (reason) => {
    setShowRequestExtensionSubmissionDateDialog(false);
    setLoading(true);
    requestExtensionSubmissionDate(reason);
  };

  const rejectExtensionSubmissionDate = async (reason) => {
    try {
      const requestExtensionResponse = await rejectExtensionDeadline(externalId, reason);
      setCandidature(requestExtensionResponse.data);
      setSuccessMessage('reject.request.extension.date.success.message');
      setShow(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRejectExtensionSubmissionDate = (reason) => {
    setShowRejecExtensionSubmissionDateDialog(false);
    setLoading(true);
    rejectExtensionSubmissionDate(reason);
  };

  const acceptExtensionDeadlineRequest = async (body) => {
    try {
      const requestExtensionResponse = await giveMoreTime(externalId, body);
      setCandidature(requestExtensionResponse.data);
      setSuccessMessage('accept.request.extension.date.success.message');
      setShow(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptExtensionDeadlineRequest = (body) => {
    setLoading(true);
    setShowAcceptExtensionDeadlineRequestDialog(false);
    acceptExtensionDeadlineRequest(body);
  };

  async function handleConfirmChoosenHabitation(optionChoosed) {
    setShowChooseAssignedHabitationsDialog(false);
    try {
      setLoading(true);
      const { data: response } = await confirmChosenHabitation(externalId, optionChoosed);
      setCandidature(response);
      setSuccessMessage('accept.chosenHabitation.success.message');
      setShow(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleConfirmWithdrawal(optionChosen, resignReason) {
    setShowConfirmsWithdrawalDialog(false);
    let dataToSend = {
      optionChosen: optionChosen,
      resignReason: resignReason ? resignReason : ''
    };
    try {
      setLoading(true);
      const { data: response } = await confirmWithdrawal(externalId, dataToSend);
      setCandidature(response);
      setSuccessMessage('confirmWithdrawal.success.message');
      setShow(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleConfirmAuthorizeCandidature(optionChosen) {
    setShowConfirmsWithdrawalDialog(false);
    let dataToSend = {
      actionNotificationDto: actionNotification,
      optionChosen: optionChosen
    };
    try {
      setLoading(true);
      const { data: response } = await authorizeCandidature(externalId, dataToSend);
      setCandidature(response);
      setSuccessMessage('authorizeCandidature.success.message');
      setShow(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleFinalizeAnalysis() {
    if (scoresValidationIsOk()) {
      try {
        setLoading(true);
        const { data: response } = await finalizeAnalysis(externalId, candidatureScoreDetails);
        setCandidature(response);
        setSuccessMessage('finalizeAnalysis.success.message');
        setShow(true);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  }

  async function handleConfirmCandidatureDecision(optionChosen) {
    setShowCandidatureDecisionDialog(false);
    let dataToSend = {
      actionNotificationDto: actionNotification,
      optionChosen: optionChosen
    };
    try {
      setLoading(true);
      const { data: response } = await confirmFinalDecision(externalId, dataToSend);
      setCandidature(response);
      setSuccessMessage('candidatureDecision.success.message');
      setShow(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleValidateOtherContracts(optionChosen) {
    setShowValidateOtherContractsDialog(false);
    try {
      setLoading(true);
      const { data: response } = await validateOtherContracts(externalId, optionChosen);
      setCandidature(response);
      setSuccessMessage('validateOtherContracts.success.message');
      setShow(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleConfirmRegisteredContract(values) {
    setScheduleContractAndKeyDeliveryDialog(false);
    let dataToSend = {
      date: values.date,
      title: actionNotification.templateEmailTitle,
      body: values.templateEmailBody
    };
    try {
      setLoading(true);
      const { data: response } = await confirmRegisteredContract(externalId, dataToSend);
      setCandidature(response);
      setSuccessMessage('confirmRegisteredContract.success.message');
      setShow(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleRescheduleContractCelebrationAndKeyDelivery(values) {
    setScheduleContractAndKeyDeliveryDialog(false);
    let dataToSend = {
      date: values.date,
      title: actionNotification.templateEmailTitle,
      body: values.templateEmailBody
    };
    try {
      setLoading(true);
      const { data: response } = await rescheduleContractCelebrationAndKeyDelivery(
        externalId,
        dataToSend
      );
      setCandidature(response);
      setSuccessMessage('rescheduleContractCelebrationAndKeyDelivery.success.message');
      setShow(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSuspendCandidature() {
    try {
      setLoading(true);
      const { data: response } = await suspendCandidature(externalId);
      setCandidature(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleStateChange(newDeadline) {
    setChangeCandidatureStateDialog(false);

    const body = {
      newDeadline: newDeadline.date,
      previousState: candidature.candidatureAllowAction.state
    };

    try {
      setLoading(true);
      const { data: response } = await changeCandidatureState(externalId, body);
      setCandidature(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  const correctedFieldsHasErrors = () => {
    let hasErrors = false;
    const keys = Object.keys(errors);

    keys.forEach((key) => {
      if (errors[key].length !== 0) {
        hasErrors = true;
      }
    });

    return hasErrors;
  };

  const correctedFieldsIsEmpty = () => {
    let isEmpty = true;
    const keys = Object.keys(correctedFields);

    keys.forEach((key) => {
      if (correctedFields[key].length !== 0) {
        isEmpty = false;
      }
    });

    return isEmpty;
  };

  const scoresValidationIsOk = () => {
    if (candidature.candidatureDto.competitionType === CompetitionType.SERIALIZATION) {
      if (correctedFieldsIsEmpty()) {
        setError(
          createCustomErrorMessage(
            intl.formatMessage({ id: 'documents.error.scoresEmpty.message' })
          )
        );
        return false;
      }

      if (correctedFieldsHasErrors()) {
        setError(
          createCustomErrorMessage(
            intl.formatMessage({ id: 'documents.error.scoresErrors.message' })
          )
        );
        return false;
      }
    } else {
      return true;
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate
      hasBackButton
      forceRedirect={isCandidate(accessInfo) ? '/perfil/candidaturasUtilizador' : false}
      title={<CandidatureDetailsTitle candidature={candidature} />}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-9'}>
            <SuccessSubmissionAlert
              show={show}
              setShow={setShow}
              successMessageId={successMessage}
            />
            <AlertError error={error} />
            <ListCandidatureDetails
              candidature={candidature}
              hasMemberOnOtherCompetition={hasMemberOnOtherCompetition}
              platformCode={platformCode}
              viewMode={true}
            />
            <div className='mt-3 pl-3'>
              <TermsAndConditions
                value={candidature.candidatureDto.termsAndConditionsValues || []}
                isDisplay
              />
            </div>
            {hasCode(query) ? (
              <Link to='/' className={'btn btn-link mb-3'}>
                <FormattedMessage id='all.backButtonText' />
              </Link>
            ) : (
              <button onClick={() => history.goBack()} className={'btn btn-link mb-3'}>
                <FormattedMessage id='all.backButtonText' />
              </button>
            )}
          </div>
          <div className='col-md-3 side-menu'>
            <div className='mx-2 mb-4'>
              {candidature.candidatureDto.state === CandidatureState.ASSIGNED &&
                (isCandidate(accessInfo) ||
                  (isIhruUser(accessInfo) &&
                    hasPermission('SUBMIT_DOCUMENTS', accessInfo) &&
                    platform.canSubmitDocuments === true)) && (
                  <Link
                    to={`/programas/${platformCode}/concursos/${competitionCode}/candidatura/${externalId}/documentos`}
                    className={'btn btn-outline-primary mb-3'}>
                    <FaRegFileAlt />
                    <FormattedMessage id='all.submitDocuments' />
                  </Link>
                )}

              {hasPermission('GET_CANDIDATURE_HISTORY', accessInfo) &&
                candidature.state !== CandidatureState.STAGING && (
                  <Link
                    to={`/programas/${platformCode}/concursos/${competitionCode}/candidatura/${externalId}/history`}
                    className={'btn btn-outline-primary mb-3'}>
                    <FaEye />
                    <FormattedMessage id='candidature.history.action' />
                  </Link>
                )}
              {candidature.state !== CandidatureState.STAGING && (
                <Link
                  to={`/programas/${platformCode}/concursos/${competitionCode}/candidatura/${externalId}/documentos/search`}
                  className={'btn btn-outline-primary mb-3'}>
                  <FaEye />
                  <FormattedMessage id='candidature.show.documents.button' />
                </Link>
              )}
              <button className={'btn btn-outline-primary mb-3'} onClick={handlePrint}>
                <FaPrint />
                <FormattedMessage id='candidature.pdf.print' />
              </button>
              {isCandidate(accessInfo) && allowedToDelete(candidature, allowedActions) && (
                <button
                  className={'btn btn-outline-primary mb-3'}
                  onClick={onClickCancelCandidature}>
                  <FaTimes />
                  <FormattedMessage id='candidature.cancel.button' />
                </button>
              )}

              {hasPermission('EXCLUDE_CANDIDATURE', accessInfo) && allowedToExclude(canExclude) && (
                <button
                  className={'btn btn-outline-primary mb-3'}
                  onClick={onClickExcludeCandidature}>
                  <FaTimes />
                  <FormattedMessage id='candidature.exclude.button' />
                </button>
              )}
              {hasPermission('RESIGN_CANDIDATURE', accessInfo) && allowedToExclude(canResign) && (
                <button
                  className={'btn btn-outline-primary mb-3'}
                  onClick={onClickResignCandidature}>
                  <FaTimes />
                  <FormattedMessage id='candidature.resign.button' />
                </button>
              )}
              {candidatureStateMatches(
                candidature,
                CandidatureState.CONTRACT_ELIGIBLE_VALIDATION
              ) &&
                hasPermission('VALIDATE_CONTRACTS_ACTIVES', accessInfo) &&
                allowedToValidateElegibiltyContract(hasMemberOnOtherCompetition) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={onClickValidateElegibility}>
                    <FaCheck />
                    <FormattedMessage id='candidature.validateElegibity.title' />
                  </button>
                )}

              {candidature.state !== CandidatureState.STAGING &&  candidature.candidatureDto?.state !== CandidatureState.EXCLUDED &&(
                <button
                  className={'btn btn-outline-primary mb-3'}
                  onClick={() => setShowMessageDialog(true)}>
                  <FaRegEnvelope />
                  <FormattedMessage id='all.reply' />
                </button>
              )}
              {candidatureStateMatches(candidature, CandidatureState.DOCUMENT_VALIDATION_INITIAL) &&
                hasPermission('DOCUMENT_VALIDATION_INIT', accessInfo) && (
                  <Link
                    to={`/programas/${platformCode}/concursos/${competitionCode}/candidatura/${externalId}/validarDocumentos`}
                    className={'btn btn-outline-primary mb-3'}>
                    <FaRegFileAlt />
                    <FormattedMessage id='documents.validation.button' />
                  </Link>
                )}

              {candidatureStateMatches(candidature, CandidatureState.POS_APPOINTMENT) &&
                hasPermission('FINALIZE_VISIT', accessInfo) && (
                  <Link
                    to={`/candidatura/${externalId}/posvisita`}
                    className={'btn btn-outline-primary mb-3'}>
                    <FaRegFileAlt />
                    <FormattedMessage id='candidature.posAppointment.action' />
                  </Link>
                )}

              {(candidatureStateMatches(candidature, CandidatureState.APPOINTMENT_BOOKING) ||
                candidatureStateMatches(candidature, CandidatureState.WAIT_APPOINTMENT)) &&
                hasPermission('APPOINTMENT', accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => setShowBookAppointmentDialog(true)}>
                    <FaRegFileAlt />
                    <FormattedMessage id='candidature.book.appointment' />
                  </button>
                )}

              {candidatureStateMatches(candidature, CandidatureState.KEY_DELIVERY) &&
                hasPermission('DELIVERY_KEYS', accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => setShowDeliveryKeysDialog(true)}>
                    <FaRegFileAlt />
                    <FormattedMessage id='candidature.delivery.keys' />
                  </button>
                )}
              {candidatureStateMatches(candidature, CandidatureState.DOCUMENT_VALIDATION_FINAL) &&
                hasPermission('ROLLBACK_FINAL_VALIDATION', accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => setShowAskDocumentsAgainDialog(true)}>
                    <FaRegFileAlt />
                    <FormattedMessage id='candidature.ask.documents.again' />
                  </button>
                )}
              {candidatureStateMatches(candidature, CandidatureState.DOCUMENT_DELIVERY) &&
                isCandidate(accessInfo) && (
                  <Link
                    className={'btn btn-outline-primary mb-3'}
                    to={`/programas/${platformCode}/concursos/${competitionCode}/candidatura/${externalId}/documentosFinais`}>
                    <FaRegFileAlt />
                    <FormattedMessage id='candidature.submitInsurance' />
                  </Link>
                )}

              {candidatureStateMatches(candidature, CandidatureState.DOCUMENT_VALIDATION_FINAL) &&
                hasPermission('DOCUMENT_VALIDATION_FINAL', accessInfo) && (
                  <Link
                    className={'btn btn-outline-primary mb-3'}
                    to={`/programas/${platformCode}/concursos/${competitionCode}/candidatura/${externalId}/documentosFinais/validar`}>
                    <FaRegFileAlt />
                    <FormattedMessage id='candidature.validateInsurance' />
                  </Link>
                )}

              {candidatureStateMatches(candidature, CandidatureState.EXCLUSION_PROJECT) &&
              (platformCode === 'PAA' || platformCode === 'PAS') &&
                hasPermission('WAIT_CONTESTATION', accessInfo) && (
                  <Link
                    to={verdictManagerLink(externalId)}
                    className={'btn btn-outline-primary mb-3'}>
                    <FaRegFileAlt />
                    <FormattedMessage id='candidature.verdict.button' />
                  </Link>
                )}
              {(platformCode === 'PAA' || platformCode === 'PAS') &&
                candidatureStateMatches(candidature, CandidatureState.CONTRACT_CELEBRATION) &&
                hasPermission('CELEBRATE_CONTRACT', accessInfo) && (
                  <Link
                    to={celebrateManagerLink(externalId)}
                    className={'btn btn-outline-primary mb-3'}>
                    <FaRegFileAlt />
                    <FormattedMessage id='candidature.celebrateContract.button' />
                  </Link>
                )}
              {(platformCode === 'PAA' || platformCode === 'PAS') &&
                candidatureStateMatches(candidature, CandidatureState.ANALYZE) &&
                hasPermission('ANALYZE_CANDIDATURE_OK', accessInfo) && (
                  <Link to={analyzeOkLink(externalId)} className={'btn btn-outline-primary mb-3'}>
                    <FaRegFileAlt />
                    <FormattedMessage id='Candidature.action.analyzeOk' />
                  </Link>
                )}

              {(candidatureStateMatches(candidature, CandidatureState.WAIT_CONTESTATION) ||
                (candidatureStateMatches(candidature, CandidatureState.EXCLUSION_PROJECT) &&
                  platformCode === 'EAA')) &&
                hasPermission('CONTEST', accessInfo) && (
                  <Link
                    className={'btn btn-outline-primary mb-3'}
                    to={`/programas/${platformCode}/concursos/${competitionCode}/candidatura/${externalId}/contestar`}>
                    <FaRegFileAlt />
                    <FormattedMessage id='Candidature.action.contestation' />
                  </Link>
                )}

              {candidatureStateMatches(candidature, CandidatureState.ASSIGNED) &&
                isCandidate(accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => setShowRequestExtensionSubmissionDateDialog(true)}>
                    <FaRegFileAlt />
                    <FormattedMessage id='request.extension.date.submit' />
                  </button>
                )}

              {candidatureStateMatches(candidature, CandidatureState.ASK_MORE_TIME) &&
                hasPermission('GIVE_MORE_TIME', accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => setShowAcceptExtensionDeadlineRequestDialog(true)}>
                    <FaCheck />
                    <FormattedMessage id='accept.request.extension.date.submit' />
                  </button>
                )}

              {candidatureStateMatches(candidature, CandidatureState.ASK_MORE_TIME) &&
                hasPermission('REJECT_EXTENSION_DEADLINE', accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => setShowRejecExtensionSubmissionDateDialog(true)}>
                    <FaTimes />
                    <FormattedMessage id='reject.request.extension.date.submit' />
                  </button>
                )}
              {candidatureStateMatches(candidature, CandidatureState.CHOOSE_ASSIGNED_HABITATIONS) &&
                hasPermission('CREATE_CANDIDATURE', accessInfo) &&
                isCandidate(accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => setShowChooseAssignedHabitationsDialog(true)}>
                    <FaCheck />
                    <FormattedMessage id='candidature.chooseAssignedHabitation.button.text' />
                  </button>
                )}
              {candidatureStateMatches(candidature, CandidatureState.WAIT_OTHER_WITHDRAWALS) &&
                hasPermission('CONFIRM_WITHDRAWAL', accessInfo) &&
                isIhruUser(accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => setShowConfirmsWithdrawalDialog(true)}>
                    <FaCheck />
                    <FormattedMessage id='candidature.confirmsWithdrawal.button.text' />
                  </button>
                )}
              {candidatureStateMatches(candidature, CandidatureState.ASSIGNMENT_PROPOSAL) &&
                hasPermission('AUTHORIZE_CANDIDATURE', accessInfo) &&
                isIhruUser(accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => setShowAuthorizeCandidatureDialog(true)}>
                    <FaCheck />
                    <FormattedMessage id='candidature.authorizeCandidature.button.text' />
                  </button>
                )}
              {platformCode === 'EAA' &&
                candidature.candidatureDto.competitionType === CompetitionType.SERIALIZATION &&
                candidatureStateMatches(candidature, CandidatureState.ANALYZE) &&
                isIhruUser(accessInfo) &&
                hasPermission('ANALYZE_CANDIDATURE_OK', accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => setShowCorrectScoresModal(true)}>
                    <FormattedMessage id='correctScores.button.text' />
                  </button>
                )}
              {platformCode === 'EAA' &&
                candidatureStateMatches(candidature, CandidatureState.ANALYZE) &&
                isIhruUser(accessInfo) &&
                hasPermission('ANALYZE_CANDIDATURE_OK', accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => handleFinalizeAnalysis()}>
                    <FaCheck />
                    <FormattedMessage id='candidature.finalizeAnalysis' />
                  </button>
                )}
              {candidatureStateMatches(candidature, CandidatureState.PROPOSAL) &&
                hasPermission('ANALYZE_CANDIDATURE_OK', accessInfo) &&
                isIhruUser(accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => setShowCandidatureDecisionDialog(true)}>
                    <FaCheck />
                    <FormattedMessage id='candidature.candidatureDecision.button.text' />
                  </button>
                )}
              {candidatureStateMatches(candidature, CandidatureState.VALIDATE_OTHER_CONTRACTS) &&
                hasPermission('VALIDATE_OTHER_CONTRACTS', accessInfo) &&
                isIhruUser(accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => setShowValidateOtherContractsDialog(true)}>
                    <FaCheck />
                    <FormattedMessage id='candidature.validateOtherContracts.button.text' />
                  </button>
                )}
              {platformCode === 'EAA' &&
                candidatureStateMatches(candidature, CandidatureState.CONTRACT_CELEBRATION) &&
                isIhruUser(accessInfo) &&
                hasPermission('CELEBRATE_CONTRACT', accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => setScheduleContractAndKeyDeliveryDialog(true)}>
                    <FaCheck />
                    <FormattedMessage id='candidature.confirmRegisteredContract.button.text' />
                  </button>
                )}
              {platformCode === 'EAA' &&
                candidatureStateMatches(candidature, CandidatureState.KEY_DELIVERY) &&
                isIhruUser(accessInfo) &&
                hasPermission('DELIVERY_KEYS', accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => setScheduleContractAndKeyDeliveryDialog(true)}>
                    <FaRegEnvelope />
                    <FormattedMessage id='candidature.rescheduleContractCelebrationAndKeyDelivery.button.text' />
                  </button>
                )}
              {CandidatureState.getAvailableToSuspend().includes(
                candidature.candidatureDto.state
              ) &&
                isIhruUser(accessInfo) &&
                hasPermission('SUSPEND_CANDIDATURE', accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3'}
                    onClick={() => handleSuspendCandidature()}>
                    <FaCheck />
                    <FormattedMessage id='candidature.suspend.button.text' />
                  </button>
                )}
              {candidatureStateMatches(candidature, CandidatureState.SUSPENDED) &&
                isIhruUser(accessInfo) &&
                hasPermission('SUSPEND_CANDIDATURE', accessInfo) && (
                  <button
                    className={'btn btn-outline-primary mb-3 text-uppercase'}
                    onClick={() => setChangeCandidatureStateDialog(true)}>
                    <FormattedMessage
                      id={`candidatureState.changeTo${candidature.candidatureAllowAction.state}`}
                    />
                  </button>
                )}
            </div>
          </div>
        </div>
        <DeleteDialog
          show={showDialog}
          handleConfirmDelete={onConfirmDeleteCandidature}
          handleClose={onClose}
          titleId='candidature.cancel.dialog.title'
          bodyId='candidature.cancel.dialog.body'
        />
        <DialogMessageCandidature
          showModalMessage={showMessageDialog}
          handleCloseMessageModal={handleCloseMessageModal}
          handleSendMessage={handleSendMessage}
        />

        <BookAppointmentDialog
          show={showBookAppointmentDialog}
          handleClose={handleCloseBookAppointmentDialog}
          handleConfirm={handleConfirmBookAppointment}
        />

        <DeliveryKeysDialog
          show={showDeliveryKeysDialog}
          handleClose={handleCloseDeliveryKeysDialog}
          handleConfirm={handleConfirmDeliveryKeys}
        />

        <AskDocumentsAgainDialog
          show={showAskDocumentsAgainDialog}
          handleClose={handleCloseAskDocumentsAgainDialog}
          handleConfirm={handleConfirmAskDocumentsAgain}
        />

        <ExcludeDialog
          show={showExcludeDialog}
          handleClose={handleCloseExcludeDialog}
          handleConfirm={handleConfirmExcludeCandidature}
          titleId='candidature.exclude.button'
          actionId='candidature.exclude.action'
          reasonId='messages.exclude.reason'
        />

        <ExcludeDialog
          show={showResignDialog}
          handleClose={handleCloseResignDialog}
          handleConfirm={handleConfirmResignCandidature}
          titleId='candidature.resign.button'
          actionId='candidature.resign.action'
          reasonId='messages.resign.reason'
        />

        <ElegibilityValidationDialog
          show={showElegibilityValidationDialog}
          handleClose={handleCloseElegibilityValidationDialog}
          handleConfirm={handleConfirmElegibilityValidation}
        />

        <TextAreaDialog
          show={showRequestExtensionSubmissionDateDialog}
          onClose={() => setShowRequestExtensionSubmissionDateDialog(false)}
          onConfirm={handleRequestExtensionSubmissionDate}
          titleId='request.extension.date.title'
          inputLabelId='request.extension.date.reason'
          confirmButtonTextId='request.extension.date.submit'
          emptyTextErrorId='request.extension.date.empty.reason'
        />

        <TextAreaDialog
          show={showRejecExtensionSubmissionDateDialog}
          onClose={() => setShowRejecExtensionSubmissionDateDialog(false)}
          onConfirm={handleRejectExtensionSubmissionDate}
          titleId='reject.request.extension.date.title'
          inputLabelId='reject.request.extension.date.reason'
          confirmButtonTextId='reject.request.extension.date.submit'
          emptyTextErrorId='reject.request.extension.date.empty.reason'
          descriptiveText={askMoreTimeReason}
          descriptiveTextId='request.extension.date.user.reason'
        />

        <DateSubmissionDocumentsModal
          show={showAcceptExtensionDeadlineRequestDialog}
          handleClose={() => setShowAcceptExtensionDeadlineRequestDialog(false)}
          onSubmit={handleAcceptExtensionDeadlineRequest}
          descriptiveText={askMoreTimeReason}
          descriptiveTextId='request.extension.date.user.reason'
        />

        <ChooseAssignedHabitationDialog
          show={showChooseAssignedHabitationsDialog}
          handleClose={() => setShowChooseAssignedHabitationsDialog(false)}
          handleConfirm={handleConfirmChoosenHabitation}
        />

        <ConfirmWithdrawalDialog
          show={showConfirmsWithdrawalDialog}
          handleClose={() => setShowConfirmsWithdrawalDialog(false)}
          handleConfirm={handleConfirmWithdrawal}
        />

        <AuthorizeCandidatureDialog
          show={showAuthorizeCandidatureDialog}
          handleClose={() => setShowAuthorizeCandidatureDialog(false)}
          handleConfirm={handleConfirmAuthorizeCandidature}
          actionNotification={actionNotification}
          setActionNotification={setActionNotification}
        />

        <CandidatureDecisionDialog
          show={showCandidatureDecisionDialog}
          handleClose={() => setShowCandidatureDecisionDialog(false)}
          handleConfirm={handleConfirmCandidatureDecision}
          actionNotification={actionNotification}
          setActionNotification={setActionNotification}
          isSubstituteScoresHigher={
            candidature.candidatureDto.competitionType === CompetitionType.SERIALIZATION
              ? candidature.candidatureDto.candidatureEAA.scoreDetails?.substituteScoresHigher
              : false
          }
        />

        {candidature.candidatureDto.competitionType === CompetitionType.SERIALIZATION && (
          <ScoreDetailsTable
            show={showCorrectScoresModal}
            handleClose={() => setShowCorrectScoresModal(false)}
            candidature={candidature}
            candidatureScoreDetails={candidatureScoreDetails}
            setCandidatureScoreDetails={setCandidatureScoreDetails}
            defaultCandidatureScoreDetails={defaultScoreDetails}
            correctedFields={correctedFields}
            setCorrectedFields={setCorrectedFields}
            errors={errors}
            setErrors={setErrors}
            hasErrors={correctedFieldsHasErrors}
            updateMode={true}
          />
        )}

        <ValidateOtherContractsDialog
          show={showValidateOtherContractsDialog}
          handleClose={() => setShowValidateOtherContractsDialog(false)}
          handleConfirm={handleValidateOtherContracts}
        />

        {platformCode === 'EAA' &&
          candidatureStateMatches(candidature, CandidatureState.CONTRACT_CELEBRATION) && (
            <ScheduleContractAndKeyDeliveryDialog
              show={scheduleContractAndKeyDeliveryDialog}
              handleClose={() => setScheduleContractAndKeyDeliveryDialog(false)}
              handleConfirm={handleConfirmRegisteredContract}
              templateEmailBody={actionNotification.templateEmailBody}
              isReschedule={false}
            />
          )}

        {platformCode === 'EAA' &&
          candidatureStateMatches(candidature, CandidatureState.KEY_DELIVERY) && (
            <ScheduleContractAndKeyDeliveryDialog
              show={scheduleContractAndKeyDeliveryDialog}
              handleClose={() => setScheduleContractAndKeyDeliveryDialog(false)}
              handleConfirm={handleRescheduleContractCelebrationAndKeyDelivery}
              templateEmailBody={actionNotification.templateEmailBody}
              isReschedule={true}
            />
          )}

        <ChangeCandidatureStateDialog
          show={changeCandidatureStateDialog}
          handleClose={() => setChangeCandidatureStateDialog(false)}
          handleConfirm={handleStateChange}
          state={candidature.candidatureAllowAction.state}
        />
      </div>
    </SubTemplate>
  );
}

function SuccessSubmissionAlert({ show, setShow, successMessageId }) {
  if (!show) {
    return <></>;
  }
  return (
    <Alert variant={'success'} className='text-center' onClose={() => setShow(false)} dismissible>
      <div className='mb-2'>
        <FaCheckCircle />
      </div>
      <FormattedMessage id={successMessageId} />
    </Alert>
  );
}

const DEFAULT_CORRECTED_FIELDS = {
  adjustedCandidatureYearsPoints: '',
  adjustedIncapacityPoints: '',
  adjustedUntitledPoints: '',
  adjustedDependentsPoints: '',
  adjustedOver65Points: '',
  adjustedMotivePointsEAA: '',
  candidatureYearsNumberJustification: '',
  incapacityJustification: '',
  untitledJustification: '',
  dependentsJustification: '',
  over65Justification: '',
  motiveEAAJustification: ''
};

const DEFAULT_ERRORS = {
  adjustedCandidatureYearsPoints: '',
  adjustedIncapacityPoints: '',
  adjustedUntitledPoints: '',
  adjustedDependentsPoints: '',
  adjustedOver65Points: '',
  adjustedMotivePointsEAA: ''
};

export default ViewUserCandidature;
